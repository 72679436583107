import React from "react";
import { Link } from "react-router-dom";


const AdminReportsHome = () => {

  return (
    <>
      <h1>Reports</h1>
      

      <div className="flex-wrap">

        <Link to="budgeted">
          <div className="card outline">
            <div className="title flex-cont align-center">
              <i className="fas fa-money-check-alt margin-right-1 gray font-1-5"></i>
              <div>Budgeted Reports</div>
              
            </div>

            <div className="button ghost margin-top-1">
              VIEW
              <i className="fas fa-chevron-right margin-left-1 margin-top-2px font-075"></i>
            </div>
          </div>
        </Link>

        <Link to="consumed">
          <div className="card outline">
            <div className="title flex-cont align-center">
              <i className="fas fa-clock margin-right-1 gray font-1-5"></i>
              <div>Consumed Reports</div>
              
            </div>

            <div className="button ghost margin-top-1">
              VIEW
              <i className="fas fa-chevron-right margin-left-1 margin-top-2px font-075"></i>
            </div>
          </div>
        </Link>
        
      </div>
    </>
  )
}

export default AdminReportsHome