import React, { useState, useEffect } from 'react';
import { useIsFirstRender } from '../../hooks/useIsFirstRender';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router';
import { usePrevious } from '../../hooks/usePrevious';
import AdminTeamsNav from './AdminTeamsNav';


const AdminTeamsLayout = ({currentCompany, children}) => {

  const navigate = useNavigate("/");
  const isFirstRender = useIsFirstRender();
  const prevCompany = usePrevious(currentCompany);

  useEffect(() => {
    if (prevCompany && currentCompany) {
      if (prevCompany !== currentCompany) {
        navigate("/teams")
      }
    }    
  }, [currentCompany]);

  return (
    <>
      <AdminTeamsNav />
      {children}
    </>
  )
}

function mapState(state) {
  return { 
    currentCompany: state.authState.currentCompany
  };
} 

export default connect(mapState)(AdminTeamsLayout);