import React, { useState, useEffect } from 'react';
import store from '../../redux/store';
import { connect } from 'react-redux';

import NewEntry2 from './NewEntry2';
import NewEntry from './NewEntry';
import NewEntry3 from './NewEntry3';
import Entry from './Entry';
import Modal from '../../components/Modal';

import { destroyEntries, deleteEntry, removeEntry, removeEntries } from '../../redux/entries/entryActions';
import { useApi } from '../../api';
import { useWatch } from "react-hook-form";
import { removeError } from '../../redux/ui/uiActions';

let count = 0;
const EntrySection = ({unregister, setError, control, watch, formState, register, handleSubmit, errors, getValues, trigger, reset, clearErrors, setValue, updateLineError, selectedPeriodIndex, currentSummary, allowAdding, fetchTimesheetWeeks, fetchEntries, projectsLoading, projects, phases, categories, timesheet, readOnly, children, entriesLoading, entries, timesheetId, selected, timesheetWeek, periodIndex,}) => {



  const [removeLineModalOpened, setRemoveLineModalOpened] = useState(false);
  const [removeEntryId, setRemoveEntryId] = useState(null);
  const [removeEntryInputName, setRemoveEntryInputName] = useState(null);
  const [currentEntries, setCurrentEntries] = useState(null);

  // useEffect(() => {
  //   alert(`EntrySection: ${JSON.stringify(setValue, null, 2)}`);
  // }, [setValue]);

  useEffect(() => {
    if (entries) {
      setCurrentEntries(entries.filter(e => e.weekStart === timesheetWeek.weekStart))
    }
  }, [entries]);

  const [entryDeleteResult, entryDeleteLoading, entryDeleteLoaded, entryDeleteError, doEntryDeleteFetch, setEntryDeleteResult] = useApi();

  const removeLine = async () => {
    // alert("A " + removeEntryInputName);
    doEntryDeleteFetch(deleteEntry, {payload: {entry_id: removeEntryId}});
    
  };

  useEffect(() => {

    if (entryDeleteLoaded) {
      // alert(removeEntryInputName);
      if (removeEntryInputName) {
        // alert(removeEntryInputName);
        // unregister(removeEntryInputName)

        // setValue(`${removeEntryInputName}._destroy`, "1")
        // setValue(`${removeEntryInputName}.hours.FIRST SUN`, 0)
        // setValue(`${removeEntryInputName}.hours.MON`, 0)
        // setValue(`${removeEntryInputName}.hours.TUE`, 0)
        // setValue(`${removeEntryInputName}.hours.WED`, 0)
        // setValue(`${removeEntryInputName}.hours.THU`, 0)
        // setValue(`${removeEntryInputName}.hours.FRI`, 0)
        // setValue(`${removeEntryInputName}.hours.SAT`, 0)
        // setValue(`${removeEntryInputName}.hours.SUN`, 0)
        // setValue(`${removeEntryInputName}.th`, 0)

        getValues('weeks').forEach((week, wi) => {
          const entries = week.entries;

          entries.forEach((entry, ei) => {
            if (entryDeleteResult.ids.includes(entry.id?.toString())) {
              // alert(`wi: ${wi}; ei: ${ei}`)
              
              setValue(`weeks.${wi}.entries.${ei}._destroy`, "1")
              // setValue(`weeks.${wi}.entries.${ei}.FIRST SUN`, 0)
              setValue(`weeks.${wi}.entries.${ei}.MON`, 0)
              setValue(`weeks.${wi}.entries.${ei}.TUE`, 0)
              setValue(`weeks.${wi}.entries.${ei}.WED`, 0)
              setValue(`weeks.${wi}.entries.${ei}.THU`, 0)
              setValue(`weeks.${wi}.entries.${ei}.FRI`, 0)
              setValue(`weeks.${wi}.entries.${ei}.SAT`, 0)
              setValue(`weeks.${wi}.entries.${ei}.SUN`, 0)
              setValue(`weeks.${wi}.entries.${ei}.hours.MON`, 0)
              setValue(`weeks.${wi}.entries.${ei}.hours.TUE`, 0)
              setValue(`weeks.${wi}.entries.${ei}.hours.WED`, 0)
              setValue(`weeks.${wi}.entries.${ei}.hours.THU`, 0)
              setValue(`weeks.${wi}.entries.${ei}.hours.FRI`, 0)
              setValue(`weeks.${wi}.entries.${ei}.hours.SAT`, 0)
              setValue(`weeks.${wi}.entries.${ei}.hours.SUN`, 0)
              // setValue(`weeks.${wi}.entries.${ei}.day9hour`, 0)

              // setValue(`weeks.${wi}.entries.${ei}.day1hour`, 0)
              // setValue(`weeks.${wi}.entries.${ei}.day2hour`, 0)
              // setValue(`weeks.${wi}.entries.${ei}.day3hour`, 0)
              // setValue(`weeks.${wi}.entries.${ei}.day4hour`, 0)
              // setValue(`weeks.${wi}.entries.${ei}.day5hour`, 0)
              // setValue(`weeks.${wi}.entries.${ei}.day6hour`, 0)
              // setValue(`weeks.${wi}.entries.${ei}.day7hour`, 0)
              // setValue(`weeks.${wi}.entries.${ei}.day8hour`, 0)
              // setValue(`weeks.${wi}.entries.${ei}.day9hour`, 0)
              setValue(`weeks.${wi}.entries.${ei}.th`, 0)
            }
          })
        })

        toggleRemoveLineModal(removeEntryId);



        // entryDeleteResult.ids.forEach(id => {
        //   setValue(`weeks.${periodIndex}.entries.${}`)
        // })
      }
      // alert('here')
      store.dispatch(destroyEntries(entryDeleteResult.ids));
    }
    // if (entryDeleteLoaded) {
    //   toggleRemoveLineModal(removeEntryId);
    //   fetchEntries();
    // }



  }, [entryDeleteLoaded]);

  const toggleRemoveLineModal = (id, entryInputName) => {
    setRemoveLineModalOpened(prev => !prev);
    
    if (!removeEntryId) {
      setRemoveEntryId(id);
      setRemoveEntryInputName(entryInputName);
    }
    else {
      setRemoveEntryId(null);
      // setRemoveEntryInputName(null);
    }
    
    // removeLine(id)
  }

  const [newLineOpened, setNewLineOpened] = useState(false);



  const totalPeriodHours = currentSummary?.[selectedPeriodIndex]?.totalHours || 0.0;

  const inputName = `weeks.${periodIndex}`

  const w = useWatch({
    control,
    name: `${inputName}`,
    defaultValue: getValues(`${inputName}`)
  });

  


  const cancelClicked = () => {
    setNewLineOpened(false);
    unregister(`weeks.${periodIndex}.entries.0`)
  }

  // useEffect(() => {
  //   if (!newLineOpened) {
  //     unregister(`weeks.${periodIndex}.entries.0`)
  //   }
  //   else {
  //     register(`weeks.${periodIndex}.entries.0`)
  //   }
  // }, [newLineOpened])

  const sum = () => {
    let total = 0.0;
    let found = false;
    if (w && w.entries && !found) {
      w.entries.forEach(entry => {
        if (entry && entry.hours && !found) {
          const h = Object.values(entry.hours);
          const isEmpty = h.filter(e => e !== undefined).length === 0;
          // alert(JSON.stringify(h))

          if (!isEmpty && h && !found) {
            h.forEach(hh => {
              const pf = parseFloat(hh) ;
              total = total + (!pf || isNaN(pf) ? 0 : pf)
              
            })
            
          }
          else {
            // currentSummary.forEach(p => {
            //   const v = totalPeriodHours;
            //   
            //   total = total + parseFloat(totalPeriodHours);
            // })

            total = total + parseFloat(totalPeriodHours);
            found = true
          }


          // if (h) {
          //   h.forEach(hh => {
  
          //     // total = total + parseFloat(hh)
          //     const pf = parseFloat(hh) ;
          //     total = total + (!pf || isNaN(pf) ? 0 : pf)
              
          //   })
            
          // }
        }
      })
    }
    return total;
  }
  

  
  count ++;
  return (
    <>

      <input type="hidden" name={`${inputName}.date`} {...register(`${inputName}.date`)} defaultValue={timesheetWeek?.date} />
      {/* {inputName}
      <div>
        EntrySection c: {count}
      </div> */}

      {/* <div 
      onClick={() => {
        alert(JSON.stringify(getValues(inputName), null, 2))
      }}
      className="button">
        week vals
      </div> */}


      
        <div className={selected ? "flex-cont column" : "display-none"}>

          {/* <pre>
            readOnly: {readOnly.toString()}
          </pre> */}
          {entriesLoading &&
            <div className="spinner margin-2"></div>
          }
          {!entriesLoading &&

          <>
          {currentEntries && currentEntries.map((entry, index) => {




            if (readOnly) {
              return <NewEntry selectedPeriodIndex={selectedPeriodIndex} unregister={unregister}  newLineOpened={newLineOpened} cancelClicked={cancelClicked} setError={setError} control={control} watch={watch} weekInputName={inputName} entryIndex={index + 1} periodIndex={periodIndex} formState={formState} register={register} handleSubmit={handleSubmit} errors={errors} getValues={getValues} trigger={trigger} reset={reset} clearErrors={clearErrors} setValue={setValue} updateLineError={updateLineError} key={entry.id} fetchTimesheetWeeks={fetchTimesheetWeeks} projectsLoading={projectsLoading} projects={projects} categories={categories} phases={phases}  timesheet={timesheet} readOnly={readOnly} andyEntry={entry}  timesheetId={timesheetId} toggleRemoveLineModal={toggleRemoveLineModal} totalPeriodHours={sum()} isLast={index+1 === currentEntries.length} index={index} selectedTimesheetWeek={timesheetWeek}/>
            }
            return <NewEntry   selectedPeriodIndex={selectedPeriodIndex}  unregister={unregister}  newLineOpened={newLineOpened} cancelClicked={cancelClicked} setError={setError} control={control} watch={watch} weekInputName={inputName} entryIndex={index + 1} periodIndex={periodIndex} formState={formState} register={register} handleSubmit={handleSubmit} errors={errors} getValues={getValues} trigger={trigger} reset={reset} clearErrors={clearErrors} setValue={setValue} updateLineError={updateLineError} key={entry.id} fetchTimesheetWeeks={fetchTimesheetWeeks} fetchEntries={fetchEntries} projectsLoading={projectsLoading} projects={projects} categories={categories} phases={phases} readOnly={readOnly} andyEntry={entry}  timesheetId={timesheetId} toggleRemoveLineModal={toggleRemoveLineModal} index={index} totalPeriodHours={sum()}  isLast={index+1 === currentEntries.length} selectedTimesheetWeek={timesheetWeek} />
          })}


          {true && sum() !== null && 
            <>
              <br/>
              <div style={{position: 'relative', borderTop: '2px solid rgba(0,0,0,.1)', margin: '.5em 0', padding: '.5em 0'}} className="align-center justify-end inline-flex">
                <div style={{color: 'gray', fontWeight: 'bold', fontSize: '.8em'}} className="nowrap label-text margin-right-05">WEEK TOTAL:</div>
                  <div className="bold" style={{lineHeight: '.9'}}>
                    {sum()}
                  </div>
              </div>
            </>
          } 


          

          {!readOnly && allowAdding && newLineOpened &&
            <>
              {/* <input type="text" name={`${inputName}.entries.0.hours.MON`} {...register(`${inputName}.entries.0.hours.MON`)} defaultValue={0.0} />
              <input type="text" name={`${inputName}.entries.0.hours.TUE`} {...register(`${inputName}.entries.0.hours.TUE`)} defaultValue={0.0} /> */}

              <NewEntry selectedPeriodIndex={selectedPeriodIndex}  unregister={unregister} cancelClicked={cancelClicked} setError={setError} control={control} watch={watch} weekInputName={inputName} entryIndex={0} periodIndex={periodIndex} formState={formState} register={register} handleSubmit={handleSubmit} errors={errors} getValues={getValues} trigger={trigger} reset={reset} clearErrors={clearErrors} setValue={setValue} updateLineError={updateLineError} setNewLineOpened={setNewLineOpened} key={"-999"} fetchTimesheetWeeks={fetchTimesheetWeeks} fetchEntries={fetchEntries} projectsLoading={projectsLoading} projects={projects} categories={categories} phases={phases} readOnly={readOnly} andyEntry={null}  timesheetId={timesheetId} toggleRemoveLineModal={toggleRemoveLineModal} index={"A"} selectedTimesheetWeek={timesheetWeek} />
            </>
          }
          {!readOnly && allowAdding && !newLineOpened &&
            <div className="margin-top-0">
              <div onClick={() => setNewLineOpened(true)} className={`button ghost`}>
                <i className="fas fa-plus margin-right-half font-075"></i>
                <div>Add New Entry</div>
              </div>
            </div>
          }
          {false && newLineOpened &&
            <div className="margin-top-0">
              <div onClick={() => setNewLineOpened(false)} className={`button ghost`}>
                <i className="fas fa-minus margin-right-half font-075"></i>
                <div>Remove New Entry</div>
              </div>
            </div>
          }
        

          {removeLineModalOpened === true && 
            <Modal focusTrap={false} size="small" toggleModal={toggleRemoveLineModal}>
              <div className='modal-header flex-cont align-center'>
                <h1 className="flex-1">Delete Line</h1>
                <div onClick={toggleRemoveLineModal} className="ghost button close">
                  <i className="fas fa-times"></i>
                </div>
              </div>

              <div className="padding-1">
                Are you sure you want to delete this line?
              </div>

              <div className='modal-footer flex-cont align-center'>
                
                <div onClick={toggleRemoveLineModal} className="button ghost">Cancel</div>

                {/* <input onClick={() => removeLine()} type="submit" value="Delete" className="margin-left-1 button danger" /> */}

                <div onClick={() => removeLine()} className={`${entryDeleteLoading && 'disabled'} margin-left-1 button danger`}>
                  {!entryDeleteLoading && 
                    <>Delete</>
                  }

                  {entryDeleteLoading && <div className="spinner"></div>  }
                </div>
              </div>
            </Modal>
          }
          </>
          }
        </div>
        
      
    </>
  )
};


const mapState = state => ({
  currentSummary: state.timesheetState.currentSummary,
});

export default connect(mapState)(EntrySection);
