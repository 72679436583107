import { andyFetch, actionReturn } from '../../api';

export const getBudgetPhases = (params) => {

  return async (dispatch, getState) => {

    const url = "admin/budget_phases";

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};

export const deleteBudgetPhase = (params) => {
  return async (dispatch, getState) => {
    const url = "admin/budget_phases/delete";
    try {
      const response = await andyFetch(url, params, "POST");
      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);
      } else {
        throw new Error('createBudgetPhase error.');
      }
    }
    catch (e) {
    }
  }
};



export const createBudgetPhase = (params) => {

  return async (dispatch, getState) => {

    const url = "admin/budget_phases";

    try {

      const response = await andyFetch(url, params, "POST");

      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);

      } else {
        throw new Error('createBudgetPhase error.');
      }
    }
    catch (e) {
    }
  }
};

export const updateBudgetPhase = (params) => {
  // alert(JSON.stringify(params, null, 2))

  return async (dispatch, getState) => {

    const url = `admin/budget_phases/${params?.payload?.budget_phase?.id}`;

    try {

      const response = await andyFetch(url, params, "PATCH");

      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);

      } else {
        throw new Error('updateBudgetPhase error.');
      }
    }
    catch (e) {
      
    }
  }
};