import React, { useEffect, useState } from 'react';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link, Outlet, useLocation
} from 'react-router-dom';

import * as yup from "yup";
import { useForm } from 'react-hook-form';

import * as msal from "@azure/msal-browser";
import store from '../../redux/store';
import { connect } from 'react-redux';
import { setTokenResponse } from '../../redux/authActions';

import Modal from '../../components/Modal';
import { useApi } from '../../api';
import { createUser, updateUser, getUsers } from '../../redux/admin/userActions';
import { getCompanies } from '../../redux/admin/companyActions';
import { getRoles} from '../../redux/admin/roleActions';


import SubNav from '../../components/SubNav';


const AdminAdminLayout = ({children}) => {

  const [modalOpened, setModalOpened] = useState(false);
  const [editingUser, setEditingUser] = useState(null);

  const [result, loading, loaded, error, doFetch, setResult] = useApi();
  
  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = () => {
    doFetch(getUsers);
  }



  const toggleModal = (reload = false) => {
    // alert('here');
    setModalOpened(old => !old);

    if (reload) {
      fetchUsers();
    }
  }

  const editClicked = user => {
    setEditingUser(user);
    toggleModal(false);
  }

  useEffect(() => {
    if (!modalOpened) {
      setEditingUser(null);
    }
  }, [modalOpened])

  return (
    <>
      <div className="margin padding-2x">
        <h1>Administrator</h1>
      </div>
    </>
  )
}

export default AdminAdminLayout;