import React from 'react';

// import { connect } from 'react-redux';


const Pagination = ({handlePageChangeFn, pagy, currentPage, disabled}) => {

  const handlePageChange = (page) => {
    handlePageChangeFn(page);
  };

  if (!pagy) return null;
  if (pagy.totalPages === 1) return null;
  

  return(
    <>
      {pagy &&
          <div className={`margin-bottom-1 flex-cont align-center pagination-cont ${disabled ? 'disabled' : ''}`}>
            {currentPage > 1 ? (
                <div onClick={() =>handlePageChange(currentPage-1)} className="button ghost">
                  <i className="margin-0 fas fa-chevron-left"></i>
                </div>
              ) : (
                <div className="invisible ghost button">
                  <i className="margin-0 fas fa-chevron-left"></i>
                </div>
              )
            }
            <div  className="font-1 bold margin-right-1 margin-left-1">
              Page {currentPage ? currentPage : "-"}
              <span className="gray"> / {pagy.totalPages ? pagy.totalPages : "-"}</span>
            </div>
            {pagy.totalPages !== 1 && currentPage !== pagy.totalPages ? (
                <div onClick={() => handlePageChange(currentPage+1)} className="button ghost">
                  <i className="margin-0 fas fa-chevron-right"></i>
                </div>
              ) : (
                <div className="invisible ghost button">
                  <i className="margin-0 fas fa-chevron-right"></i>
                </div>
              )
            }
          </div>
        }
    </>
  );
}

// const mapState = state => ({
//   pagy: state.periodState.pagy
// });

export default Pagination;