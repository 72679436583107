import React, {useEffect} from 'react';


export const ActivitySelect = ({selectedProject, setValue, getValues, selectedProjectID, hide, disabled, myErrors, selectedActivity, selectActivityChange, register, inputName, existingEntry, activities, projects}) => {


  const shouldShowProjectSelect = () => {
    if (!projects || projects.length === 0) return false;
    if (!existingEntry?.projectId) return true;
  
    const projectIDs = projects.map(project => project.id?.toString());
  
    return projectIDs.includes(existingEntry?.projectId?.toString());
  }

  const final = () => {
    if (selectedActivity) return selectedActivity.number;
    return (existingEntry?.activityNumber ? existingEntry?.activityNumber : "")
  }
  
  const shouldShowSelect = () => {
    return shouldShowProjectSelect();
  }

  const shouldDisplayActivities = () => {
    // return false
    if (selectedProject?.id?.toString()?.charAt(0) === "X" || selectedProject?.id?.toString()?.charAt(0) === "x") {
      return false;
    }

    return true;
  }

  const reg = register(`${inputName}.selectedActivity`, {
    // required: "Select a phase"

    validate: v => {
      
      // alert('here')
      if (hide) return true;
      if (v === undefined) return true;
      

      if (!shouldDisplayActivities()) {
        // alert(selectedProjectID)
        return true;
      }
      else {

      }



      if (v) {
        return true
      }
      return "Select a milestone"
    }
  })

  const renderSelect = () => {

    if (!shouldDisplayActivities() || !selectedProject) {
      return(
        <>
          <div className="dummy-select wide">
            ---
          </div>
          <input type="hidden" name={`${inputName}.selectedActivity`}  ref={reg.ref} defaultValue={""} />
        </>
      )
    }





    if (disabled || !shouldShowProjectSelect()) {
      if (existingEntry?.activityName) {
        return (
          <>
            <div className="dummy-select wide">
              {existingEntry?.activityNumber} - {existingEntry?.activityName}
            </div>
            <input type="hidden" name={`${inputName}.selectedActivity`}  ref={reg.ref} defaultValue={final()} />
          </>
        )
      }
      else {
        return (
          <>
            <div className="dummy-select wide">
              Milestone not found.
            </div>
            <input type="hidden" name={`${inputName}.selectedActivity`}  ref={reg.ref} defaultValue={null} />
          </>
        )
      }
    }
    else {
      return (
        <select
          disabled={disabled}
          tabIndex={0} 
          // defaultValue={final()}
          className="wide"
          onBlur={reg.onBlur}
          ref={reg.ref} 
          onChange={(e) => {
            reg.onChange(e); // method from hook form register
            selectActivityChange(e)
          }}
          name={`${inputName}.selectedActivity`}  
          id={`${inputName}.selectedActivity`}
        >
          <option value="">Select milestone:</option>
          {activities?.map((activity, key) => {
            return <option  key={key} value={activity.number?.toString()}>{`${activity.number} - ${activity.name}`}</option>
          })}
        </select>
      )
    }
  }

  return (
    <>
      <label className={myErrors?.selectedActivity ? "margin-right-4px error-label" : "margin-right-4px"}>  
        <div className="label-text">Milestone</div>
        {/* <div>
          {JSON.stringify(selectedProjectID?.toString()?.charAt(0), null, 2)}
        </div> */}
        {/* <div>
          {JSON.stringify(final())}
        </div> */}
        {/* <div className="button" onClick={() => {
          alert(JSON.stringify(getValues(`${inputName}.selectedActivity`)))
        }}></div> */}

        {/* <div className="code">
          <pre>
            {JSON.stringify(selectedProject, null, 2)}
          </pre>
        </div> */}

        {renderSelect()}






        {false && <>
        {(shouldShowSelect()) ? (
          <>

            {disabled &&
              <>
                {/* <select tabIndex={0} disabled id={`${inputName}.selectedActivity`}>
                  <option value="" disabled>---</option>
                </select> */}
                {existingEntry?.activityName &&
                  <>
                    <div className="dummy-select wrap">
                      {existingEntry?.activityName}
                    </div>
                    <input type="hidden" name={`${inputName}.selectedActivity`}  ref={reg.ref} defaultValue={final()} />
                  </>
                }
                {!existingEntry?.activityName &&
                  <div className="dummy-select wrap">
                    -
                  </div>
                }
              </>
            }
            {!disabled &&
              <select disabled={disabled || !shouldDisplayActivities()}  tabIndex={0} defaultValue={final()} 
                onBlur={reg.onBlur}
                ref={reg.ref} 
                onChange={(e) => {
                  reg.onChange(e); // method from hook form register
                
                  selectActivityChange(e)
                }}
                className="wrap"
              
              
              name={`${inputName}.selectedActivity`}  id={`${inputName}.selectedActivity`}  >
                  
                  {shouldDisplayActivities() &&
                    <option value="">Select milestone:</option>
                  }
                  {!shouldDisplayActivities() &&
                    <option value="">---</option>
                  }
                  {activities?.map((activity, key) => {
                    return <option selected={activity.number === final()}  key={key} value={activity.number}>{`${activity.number} - ${activity.name}`}</option>
                  })}
      
                </select>
              }
            </>
          ) : (
            

            <>
              {existingEntry?.activityName &&
                <>
                  <div className="dummy-select wrap">
                    {existingEntry?.activityName}
                  </div>
                  <input type="hidden" name={`${inputName}.selectedActivity`}  ref={reg.ref} defaultValue={final()} />
                </>
              }
              {!existingEntry?.activityName &&
                <div className="dummy-select wrap">
                  -
                </div>
              }
              {/* <select tabIndex={0} disabled id={`${inputName}.selectedProject`}>
                <option value="" disabled>---</option>
              </select> */}
                              {/* <select className="wrap" tabIndex={0} disabled id={`${inputName}.selectedActivity`}>
                  <option value="" disabled>---</option>
                </select> */}

            </>
          )
        }</>}
      </label>

    </>

  )
}




export default ActivitySelect;