import React from 'react';
import Modal from '../components/Modal';

import TimesheetPrintViewer from './TimesheetPrintViewer';

const TimesheetPrintModal = ({opened, toggleModal, timesheet}) => {

  return (
    <Modal size="large" toggleModal={toggleModal} focusTrap={false}>


        <TimesheetPrintViewer toggleModal={toggleModal} opened={opened} timesheet={timesheet} />


    </Modal>
  )
}

export default TimesheetPrintModal;