import React, {useEffect, useState} from 'react';
import AutoComplete from '../../components/AutoComplete';

const Row = ({item}) => {

  return (
    <div className="flex-cont">
      <div className='flex-1'>{item.id} - {item.name}</div>
    </div>
  )
}



export const ProjectSelect3 = ({getValues, selectedProject, setSelectedProject, setSelectedActivity, setSelectedSubactivity, projectsLoading, setValue, hide, disabled, myErrors,  selectProjectChange, register, inputName, existingEntry, projects}) => {

  

  const [actuallySelectedItem, setActuallySelectedItem] = useState(null);


  const onSelect = item => {
    // alert('')
    setActuallySelectedItem(item);
    setSelectedProject(item);
    setSelectedActivity(null);
    setSelectedSubactivity(null);
    setValue(`${inputName}.selectedSubactivity`, '');
    setValue(`${inputName}.selectedActivity`, '');

    // alert(getValues(`${inputName}.selectedActivity`))
  }

  useEffect(() => {
    if (existingEntry && projects && projects.length !== 0) {
      const foundProject = projects?.find(project => project.id === existingEntry.projectId);
      if (foundProject) {
        setSelectedProject(foundProject);
      }
    }
  }, [existingEntry, projects]);

  const projectMissing = () => {
    if (!existingEntry) return false;
    if (existingEntry.projectId === null || existingEntry.projectId === undefined ||  existingEntry.projectId === "") return false;

    if (!projects?.length) return true;

    return !projectsLoading && !selectedProject;
  }


  const renderRow = item => <Row item={item} />

  const renderMissing = () => {
    return (
      <div>
        {/* <div tyle={{marginBottom: '4px'}} >
        <i className="fas fa-exclamation-triangle margin-right-half yellow"></i>
        <span className="bold yellow">Invalid project.</span>
        </div> */}
        <div style={{marginBottom: '4px'}} className="gray bold font-075">PROJECT</div>
        <div>
          
          <div className="dummy-select">
          {!existingEntry &&
            <>
              No projects found.
            </>
          }
          {existingEntry && existingEntry?.projectId == null &&
            <>
              No project found.
            </>
          }
          {existingEntry && existingEntry?.projectId != null &&
            <>
              {existingEntry?.projectId} - {existingEntry?.projectName}
            </>
          }
            
          </div>
          <input
            style={{display: 'none'}}
            type="text"
            name={`${inputName}.selectedProject`} 
            id={`${inputName}.selectedProject`} 
            defaultValue={existingEntry?.projectId} 
            {...register(`${inputName}.selectedProject`, {
              required: `Project required.`
            })}
          />
        </div>
        
      </div>
    )
  }

  const renderContent = () => {
    if (projectsLoading) {
      return (
        <div className="loading-input-dummy">
          <div  className="spinner">

          </div>
        </div>
      )
    }

    if (!projects || projects.length === 0) {
      // return renderMissing();
    }

    if (projectMissing()) {
      // return renderMissing();
    }


    return (
      <AutoComplete 
        canReset={false}
        setValue={setValue}
        onSelect={(item) => onSelect(item)}
        // defaultValue={formProjectDelegate?.projectid}
        // defaultSearch={formProjectDelegate ? `${formProjectDelegate.projectid} - ${formProjectDelegate.projectname}` : ""}
        defaultValue={existingEntry?.projectId}
        defaultSearch={existingEntry ? `${existingEntry.projectId} - ${existingEntry.projectName}` : ''}
        name={`${inputName}.selectedProject`}
        items={projects}
        fullName={"FULL NAME"}
        register={register}
        errors={myErrors?.selectedProject}
        label="Project"
        labelFn={(item) => `${item.id} - ${item.name}`}
        labelComponent={renderRow}
        valueFn={(item) => item.id }
        searchableAttributes={["name", "id"]}
      />
    )
  
    
  }

  return (<>
    {/* <pre>
      SELECTED: {JSON.stringify(selectedProject, null, 2)}
    </pre> */}

    {renderContent()}


  </>)
  
}

export default ProjectSelect3;