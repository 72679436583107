import React from 'react';
import { useWatch } from 'react-hook-form';
import { DateTime } from 'luxon';


const TimesheetFooter = ({control, selectedPeriod, timesheetResult, setSelectedWeekIndex, selectedWeekIndex}) => {

  // const weekWatch = useWatch({
  //   control,
  //   name: `week_summary_hours.${weekIndex}`,
  //   defaultValue: 0
  // });

  const entriesWatch = useWatch({
    control,
    name: `week_summary_hours`,
    defaultValue: [timesheetResult?.total_hours]
  });

  const sum = entriesWatch?.reduce((current, previous) => { return parseFloat(current)+parseFloat(previous)}, 0);


  const isFirstWeek = selectedWeekIndex === 0;
  const isLastWeek = timesheetResult ? (selectedWeekIndex === timesheetResult.summary.length-1) : false;

  return (
    <>
      <div className="week-selector-cont flex-cont align-center margin-right-2">
        <div onClick={() => setSelectedWeekIndex(o => (o > 0 ? o-1 : o))} className={`${isFirstWeek ? 'selector-disabled' : ''} margin-right-half`}>
          <i className="week-selector padding-1x margin-right-halfx fas fa-chevron-left yellow"></i>
        </div>
        <div className="week">{selectedPeriod ? `${DateTime.fromISO(selectedPeriod.week_start).toLocaleString()} - ${DateTime.fromISO(selectedPeriod.week_end).toLocaleString()}` : "-"}</div>
        <div onClick={() => setSelectedWeekIndex(o => (o < timesheetResult.summary.length-1 ? o+1 : o))} className={`${isLastWeek ? 'selector-disabled' : ''} margin-left-half`}>
          <i className="week-selector padding-1x margin-left-halfx fas fa-chevron-right yellow"></i>
        </div>
        </div>

        <div className="hour-totals flex-cont align-center margin-right-1">
        <div className="bold gray font-08 margin-right-half">WEEK TOTAL:</div>
        <div style={{marginBottom: '2px'}} className="bold margin-right-1">{entriesWatch[selectedWeekIndex].toLocaleString()}</div>
        <div className="bold gray font-08 margin-right-half">TIMESHEET TOTAL:</div>
        <div style={{marginBottom: '2px'}} className="bold margin-right-1">{sum.toLocaleString()}</div>
      </div>
    </>
  )
}

export default TimesheetFooter;


