import React, { useState, useEffect } from 'react';
import { useApi } from '../api';
import { DateTime } from 'luxon';
import { connect } from 'react-redux';

import { getSupportTickets  } from '../redux/supportTickets/supportTicketActions';
import { getUsers } from '../redux/admin/userActions';
import { Link, useNavigate, useParams } from 'react-router-dom';
import CheckBoxes from '../components/CheckBoxes';
import SupportTicket from './SupportTicket';


const truncateString = (string = '', maxLength = 20) => {
  if (string == null || string.toString().length <= 0) {
    return '';
  }

  return (
    string.length > maxLength 
    ? `${string.substring(0, maxLength)}…`
    : string
  )
}

const SupportTickets = ({currentUser}) => {

  const { id } = useParams();
  const [page, setPage] = useState(1);
  const handlePageChange = (p) => {
    setPage(p);
  };
  const [currentSupportTicketID, setCurrentSupportTicketID] = useState(id);


  const navigate = useNavigate();

  const [result, loading, loaded, error, doFetch, setResult, pagy] = useApi();



  // useEffect(() => {

    

  //   if (id != null && result != null && result.length !== 0) {

      
  //     const find = result.find(item => item.id?.toString() === id?.toString())
  //     setCurrentSupportTicket(find)
  //   }

  // }, [id, result])

  useEffect(() => {
    fetchSupportTickets();
  }, [page])

  const fetchSupportTickets = () => {
    doFetch(getSupportTickets, {query: {page: page}});
  }

  

  const supportTicketClicked = supportTicketID => {
    // navigate(`/support-tickets/${supportTicket.id}`)
    window.history.pushState({},"HBA Kronos",`/support-tickets/${supportTicketID}`)
    setCurrentSupportTicketID(supportTicketID);

  }
  const toggleModal = (reload=false) => {
    window.history.pushState({},"HBA Kronos",`/support-tickets`)
    setCurrentSupportTicketID(null);

    if (reload == true) {
      fetchSupportTickets();
    }
    
  }

  const [tab, setTab] = useState("all");

  const tabCount = (name) => {

    if (result == null) return 0;

    if (name === "all"){
      return result.length;
    }
    else {
      const f = result.filter(supportTicket => supportTicket.statusname.toString().toLowerCase() === name);
      return f.length;
    }
  }

  const filtered = () => {
    if (result) {

      let f = result.filter(p => selectedStatuses.includes(p.statusname.toString().toLowerCase()));
      f = f.filter(p => selectedAsses.some(ass => p.asses.includes(ass)));


      return f
      

      

    }
    return null
  }

  const [selectedAsses, setSelectedAsses] = useState(["unassigned", "assigned to others", "assigned to me"]);
  const [selectedStatuses, setSelectedStatuses] = useState(["new", "doing", "done"]);
  // const [checkBoxes, setCheckBoxes] = useState({
  //   all: false,
  //   unassi
  // })



  return (
    <>
      <div className="flex-cont">
        <h1 className="flex-cont">
          Support Tickets
        </h1>
      </div>

      {/* <pre>{JSON.stringify(selectedAsses)}</pre> */}

      <div className="margin-bottom-3 flex-cont align-center">
        {/* <div className="bold gray font-075 margin-right-half">STATUS </div> */}
        {/* <div className={`margin-right-2 selector-cont ${loading ? 'disabled' : ''}`}>          
          <Selector label={"All"} name="all" setTab={setTab} tab={tab} count={tabCount("all")} />
          <Selector label={"New"} name="new" setTab={setTab} tab={tab} count={tabCount("new")} />
          <Selector label={"Doing"} name="doing" setTab={setTab} tab={tab} count={tabCount("doing")} />
          <Selector label={"Done"} name="done" setTab={setTab} tab={tab} count={tabCount("done")} />
        </div> */}

        <div className={`margin-right-2 check-boxes ${loading ? 'disabled' : ''}`}>          
          <CheckBoxes disabled={loading} label={"New"} name="new" boxes={selectedStatuses} setBoxes={setSelectedStatuses}  count={tabCount("new")} />
          <CheckBoxes disabled={loading} label={"Doing"} name="doing" boxes={selectedStatuses} setBoxes={setSelectedStatuses}  count={tabCount("new")} />
          <CheckBoxes disabled={loading} label={"Done"} name="done" boxes={selectedStatuses} setBoxes={setSelectedStatuses}  count={tabCount("doing")} />
        </div>

        {/* <div className="bold gray font-075 margin-right-half"></div> */}
        <div className={`check-boxes ${loading ? 'disabled' : ''}`}>          
          {/* <CheckBoxes label={"All"} name="all" setBoxes={setSelectedAsses}  count={tabCount("all")} /> */}
          <CheckBoxes disabled={loading} label={"Unassigned"} name="unassigned" boxes={selectedAsses} setBoxes={setSelectedAsses}  count={tabCount("new")} />
          <CheckBoxes disabled={loading} label={"Assigned to me"} name="assigned to me" boxes={selectedAsses} setBoxes={setSelectedAsses}  count={tabCount("doing")} />
          <CheckBoxes disabled={loading} label={"Assigned to others"} name="assigned to others" boxes={selectedAsses} setBoxes={setSelectedAsses}  count={tabCount("new")} />
        </div>
      </div>

      {currentSupportTicketID != null && 
        <SupportTicket toggleModal={toggleModal} currentSupportTicketID={currentSupportTicketID} />
      }
      {loading && result == null && <div className="spinner"></div> }

      {/* <Pagination currentPage={page} pagy={pagy} handlePageChangeFn={handlePageChange} /> */}

      {result &&
        <table className={`margin-top-3x basic ${loading ? 'table-loading' : ''}`}>
        <tr>
          <th></th>

          <th>Status</th>
          <th>ID</th>
          <th>Code</th>
          <th>Company</th>
          <th>Assigned To  </th>
          <th>Submitted By </th>
          <th>Description</th>
          <th>From</th>
          <th>Date</th>
  
          <th></th>
        </tr>
        {filtered()?.map(supportTicket => 
          <tr>
            <td className="fit nowrap">

            <div onClick={() => supportTicketClicked(supportTicket.id)} className="margin-left-0 button outline font-08">
              VIEW
            </div>


            </td>
            <td className={`fit nowrap bold`}>
              {supportTicket.statusname?.toUpperCase()}
            </td>
            <td className="fit nowrap">
              #{supportTicket.id}
            </td>
            <td className="fit nowrap">
              <div className="text-link pointer" onClick={() => supportTicketClicked(supportTicket.id)}>
                {supportTicket.code}
              </div>
 
              
            </td>


            <td className="fit nowrap">
              {supportTicket.company}
            </td>

            <td className="fit nowrap">
              {supportTicket.assignedfname} {supportTicket.assignedlname}
            </td>



            <td className="fit nowrap">
              {supportTicket.submittingfname} {supportTicket.submittinglname}
              {supportTicket.has_acting_as &&
                <div className='font-075 gray'>
                  as {supportTicket.actingasfname} {supportTicket.actingaslname}
                </div>
              }
            </td>
            <td className="">
                {truncateString(supportTicket?.description, 100)}
            </td>
            <td className="fit nowrap">
              {supportTicket.screen}
            </td>
            <td className="fit nowrap">
            {supportTicket.createdat ? DateTime.fromISO(supportTicket.createdat).toLocaleString(DateTime.DATETIME_SHORT) : '-'}
            </td>
              
            <td></td>
          </tr>

          
        )}
      </table>
}

      {/* <pre>{JSON.stringify(result, null, 2)}</pre> */}
    </>
  )
}




const mapState = state => ({
  reviewCount: state.budgetHeaderState.reviewCount,
  currentCompany: state.authState.currentCompany,
  currentUser: state.authState.currentUser,
});

export default connect(mapState)(SupportTickets);