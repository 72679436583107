
import React, { useEffect, useState } from 'react';
import Modal from '../../components/Modal';
import { useApi } from '../../api';
import { deleteBudgetPhase, getBudgetPhases, createBudgetPhase, updateBudgetPhase } from '../../redux/admin/budgetPhaseActions';

import * as yup from "yup";
import { useForm } from 'react-hook-form';




const PhaseForm = ({toggleModal, formPhase}) => {

  const { watch, register, formState: {errors}, handleSubmit, getValues, setValue } = useForm({

  });

  const [result, loading, loaded, error, doFetch, setResult] = useApi();

  const onSubmit = async data => {
    
    let d = {
      budget_phase: {
        ...data
      }
    }

    if (formPhase && formPhase.id) {
      doFetch(updateBudgetPhase, {payload: d});
    }
    else {
      doFetch(createBudgetPhase, {payload: d});
    }

  }

  useEffect(() => {
    if (loaded) {
      toggleModal(true);
    }
  }, [loaded]);

  const [deleteModalOpened, setDeleteModalOpened] = useState(false);

  const toggleDeleteModal = () => {
    setDeleteModalOpened(o => !o);
  }

  const [deleteResult, deleteLoading, deleteLoaded, deleteError, doDeleteFetch, setDeleteResult] = useApi();
  
  const tryDeletePhase = () => {
    // alert('To be implemented.');
    // return 
    if (formPhase) {
      const payload = {
        budget_phase: {
          id: formPhase.id
        }
      }
      doDeleteFetch(deleteBudgetPhase, {payload: payload});
    }
  }

  useEffect(() => {
    if (deleteLoaded) {
      toggleDeleteModal();
      toggleModal(true);
    }
  }, [deleteLoaded]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="modal-header flex-cont align-center">
        <h2 className="flex-1">{formPhase ? "Edit" : "New"} Phase</h2>
        <div onClick={toggleModal} className="ghost button close">
          <i className="fas fa-times"></i>
        </div>
      </div>

      <div className="padding-1">

       

        <div className="flex-cont">

          <input {...register("id")} type="hidden" name="id" value={formPhase?.id} />


          <label htmlFor="phasename"  className="margin-right-half"> 
            <div style={{marginBottom: '4px'}} className="gray bold font-075 uppercase">Phase Name</div>
            <input 
              type="text" 
              {...register("phasename", {
                required: "Enter a name"
              })}
              placeholder="Phase Name"
              name="phasename"
              defaultValue={formPhase?.phasename}
              className={`${errors.phasename ? 'has-errors' : ''}`}
            />
            {errors.phasename && <div className="field-error">{errors.phasename.message}</div>}
          </label>
        </div>

      </div>

      <div className="modal-footer align-center flex-cont">
        
        <input type="submit" value="Save" className={`${loading ? 'disabled' : ''} button`} />

        {loading &&
          <div className="margin-left-1 spinner"></div>
        }

        <div className="flex-1"></div>
        <div onClick={toggleDeleteModal} className="button danger">Delete</div>
      </div>

      {deleteModalOpened &&
        <Modal size="small" toggleModal={toggleDeleteModal} focusTrap={false}>
          <div className="modal-header flex-cont align-center">
            <h2 className="flex-1">Delete Phase</h2>
            <div onClick={toggleDeleteModal} className="ghost button close">
              <i className="fas fa-times"></i>
            </div>
          </div>

          <div className="padding-1">
            Are you sure you want to delete <span className="bold">{formPhase?.phasename}</span>?
          </div>

          <div className="modal-footer align-center flex-cont">
            <div onClick={toggleDeleteModal} className="ghost button margin-right-1">Cancel</div>
            <div onClick={tryDeletePhase} className={`${deleteLoading ? 'disabled' : ''} danger button`}>
              {deleteLoading && <div className="spinner button-spinner"></div> }
              Delete
            </div>
          </div>
        </Modal>
      }

    </form>


  )
}



const AdminPhases = ({children}) => {

  const [phases, phasesLoading, phasesLoaded, phasesError, doPhasesFetch, setPhasesResult] = useApi();
  
  useEffect(() => {
    fetchPhases();
  }, []);

  const fetchPhases = () => {
    doPhasesFetch(getBudgetPhases);
  }

  const [addOpened, setAddOpened] = useState(false);

  const [newPhaseNmae, setNewPhaseName] = useState("");


  const [newPhase, newPhaseLoading, newPhaseLoaded, newPhaseError, doNewPhaseFetch, setNewPhaseResult] = useApi();

  const trySavePhase = () => {
    const payload = {
      phasename: newPhaseNmae
    };
    doNewPhaseFetch(createBudgetPhase, {payload: payload});
  }

  useEffect(() => {
    if (newPhaseLoaded) {
      setAddOpened(false);
      setNewPhaseName("");
      fetchPhases();
    }
  }, [newPhaseLoaded]);

  const [editModalOpened, setEditModalOpened] = useState(false);
  const [editingPhase, setEditingPhase] = useState(null);

  const toggleEditModal = (reload=false) => {
    setEditModalOpened(o => !o);

    if (reload) {
      fetchPhases();
    }
  }

  const editPhaseClicked = (phase) => {
    
    setEditingPhase(phase);
    setEditModalOpened(true);
  }


  return (
    <>
      <div className="section margin-bottom-1">
        <h2 className="no-margin margin-bottom-1">Budget Phases</h2>

        {editModalOpened && toggleEditModal &&
          <Modal toggleModal={toggleEditModal}>
            <PhaseForm formPhase={editingPhase} toggleModal={toggleEditModal}/>
          </Modal>
        }

        {phasesLoading && <div className="spinner"></div>}
        {true &&
          <table className="basic">
            {phases?.map(phase => 
              <tr>
                <td className="full">
                  {phase.phasename}
           
                </td>
                <td>
                  <i onClick={() => editPhaseClicked(phase)} className="circle button fas fa-pencil-alt"></i>
                </td>
              </tr>
            )}
          </table>
        }

        <div className="margin-top-1">

          {addOpened &&
            <div className="flex-cont align-center">
              <input onChange={e => setNewPhaseName(e.target.value)} placeholder="New phase" className="margin-right-1" type="text" name="test" id=""/>
              
              
              <div onClick={trySavePhase} className={`${newPhaseLoading ? 'disabled' : ''} button margin-right-1`}>
                {newPhaseLoading && <div className="button-spinner spinner"></div>}
                Add
              </div>
              <div onClick={() => setAddOpened(o => !o)} className="button circle">
                <i className="fas fa-times"></i>
              </div>
            </div>
          }

          {!addOpened &&
            <div onClick={() => setAddOpened(o => !o)} className="button ghost">
              <i className="fas fa-plus margin-right-half"></i>
              <span>Add Phase</span>
            </div>
          }

        </div>

        
      </div>
    </>
  )
}

export default AdminPhases;



