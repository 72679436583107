import React, { useState, useEffect, useRef } from 'react';
import { useApi } from '../../api';
import { DateTime } from 'luxon';
import { connect } from 'react-redux';

import { repostTimesheet, getTimesheetLogsDynamics,  } from '../../redux/timesheets/timesheetActions';
import { Link } from 'react-router-dom';
import Modal from '../../components/Modal';
import Pagination from '../../components/Pagination';
import CheckBoxes from '../../components/CheckBoxes';
import { truncateString } from '../../utils';


function useInterval(callback, timeout, search) {
  const timeoutId = useRef();

  // Remember the latest callback.
  useEffect(() => {
    timeoutId.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {

    clearTimeout(timeoutId.current);
    timeoutId.current = setTimeout(callback, timeout);

    return () => clearTimeout(timeoutId.current);

    // 
    // function tick() {
    //   savedCallback.current();
    // }
    // if (search !== null) {
    //   let id = setTimeout(tick, delay);
    //   return () => clearTimeout(id);
    // }
  }, [search]);
}

const TimesheetPostingLogsDynamics = ({currentCompany}) => {

  const [result, loading, loaded, error, doFetch, setResult, pagy] = useApi();
  const [onlyInCompanyChecked, setOnlyInCompanyChecked] = useState(false);
  const [search, setSearch] = useState(null);
  const [repostID, setRepostID] = useState(null);
  const [page, setPage] = useState(1);
  const [selectedStatuses, setSelectedStatuses] = useState([]);


  useInterval(() => { 
    setPage(1); 
    doFetch(getTimesheetLogsDynamics, {query: {all_companies: onlyInCompanyChecked ? null : true, search: search, page: 1}});
    
    // setCount(o => o+1);
  }, 500  , search);

  const handlePageChange = (p) => {
    setPage(p);
  };

  const searchInputChanged = e => {
    const value = e.target.value;
    setSearch(value);
  }


  useEffect(() => {
    fetchDynamics();
  }, [])

  useEffect(() => {
    fetchDynamics();
    setCurrentLog(null);
    
  }, [onlyInCompanyChecked, page, currentCompany, selectedStatuses]);

  useEffect(() => {
    setPage(1);
  }, [onlyInCompanyChecked, currentCompany, selectedStatuses]);

  const fetchDynamics = () => {
    
    doFetch(getTimesheetLogsDynamics, {query: {all_companies: onlyInCompanyChecked ? null : true, search: search, page: page}});
  }


  const [currentLog, setCurrentLog] = useState(null);

  const viewErrorClicked = timesheet => {
    setCurrentLog(timesheet);

  }
  const toggleModal = () => {
    setCurrentLog(null);
  }

  const statusBadge = status => {
    if (status === "2 Succeeded") return <div className='status-badge budget approved'>{status}</div>
    if (status === "1 Failed") return <div className='status-badge budget returned'>{status}</div>
    if (status === "0 In Queue") return <div className='status-badge budget pending'>{status}</div>
  }

  return (
    <div>

      <div className="seearch-cont margin-bottom-3 flex-cont align-center">
        {true &&
          <div className="margin-right-1 flex-cont align center">
            <input checked={onlyInCompanyChecked} onChange={e => setOnlyInCompanyChecked(e.target.checked)}  type="checkbox" name="" id="" className="margin-right-half" />
            <div className="bold gray uppercase font-075 nowrap">Only in {currentCompany}</div>
          </div>
        }
        <input onChange={e => searchInputChanged(e)} placeholder="Search timesheets..." type="text" name="" id=""/>
      </div>

      {currentLog && 
        <Modal toggleModal={toggleModal} focusTrap={false}>

          <div className="modal-header flex-cont align-center">
            <h2 className="flex-1">TM{currentLog?.timesheetid}</h2>
            <div onClick={toggleModal} className="ghost button close">
              <i className="fas fa-times"></i>
            </div>
          </div>

          <div className="padding-1">
            <div>
              {currentLog?.message}
            </div>
          </div>
          <div className="modal-footer"></div>
        </Modal>
      }

      <Pagination currentPage={page} pagy={pagy} handlePageChangeFn={handlePageChange} />
       <div className="relative">
       {(loading)  && <div className="absolute spinner"></div> }

      {result &&
        <table className={`${(loading) ? 'table-loading' : ''} margin-top-3x basic`}>
        <tr>
          <th>Status</th>
          <th>ID</th>
          <th>Timesheet</th>
          <th>Message</th>
          <th></th>
          <th></th>
        </tr>
        {result?.map(log => 
          <tr>
            <td className="fit nowrap bold">
              <div className="status-badge-cont">
                {statusBadge(log.status)}
              </div>
              
            </td>
            <td className="fit nowrap">
              {log.id}
            </td>
            <td className="fit nowrap">
              <Link className="text-link" target="_blank" to={`/timesheets/${log.timesheetid}`}>
                TM{log.timesheetid}
              </Link>
            </td>
            <td className="">
                {truncateString(log?.message, 100)}
                
              </td>
              <td className="fit nowrap">
                {log.message != null &&
                  <div onClick={() => viewErrorClicked(log)} className="margin-left-0 button ghost font-08">
                    VIEW
                  </div>
                }
              </td>
            <td></td>
          </tr>

          
        )}
      </table>
}
</div>

      {/* <pre>{JSON.stringify(result, null, 2)}</pre> */}
    </div>
  )
}

const mapState = state => ({
  currentCompany: state.authState.currentCompany,
});

export default connect(mapState)(TimesheetPostingLogsDynamics);