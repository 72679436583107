import React from "react";
import { isNumeric } from "../../utils";
import { DateTime } from "luxon";

const SummaryDay = ({hours, day}) => {

  const parsedHours = isNumeric(hours) ? parseFloat(hours) : 0.0;

  return (
    <>
      {day.date &&
        <td className="fit nowrap center">
          <div className="bold">{parsedHours.toLocaleString()}</div>
          <div className="grayx font-09">{DateTime.fromISO(day.date).toLocaleString({...DateTime.DATE_SHORT, year: '2-digit' })}</div>
        </td>     
      }
      {!day.date &&
        <td className="fit nowrap center">
          <i className="lightgray fas fa-times"></i>
        </td>     
      }


    </>
  )
}




export default SummaryDay;