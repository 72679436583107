import React, { useEffect, useState } from 'react';

// import TimesheetsNav from './TimesheetsNav';
import { useApi } from '../../api';
import { connect } from 'react-redux';

import { getSubmittedProjects, getMyProjects, getProjects } from '../../redux/projects/projectActions';
import { getReviewCount } from '../../redux/manager/budgetHeaders/budgetHeaderActions';
import { DateTime } from 'luxon';
import {
  Link
} from 'react-router-dom';

import TH from './TH';
import { useSortBudgets, toNum, isNumber } from './useSortBudgets';


const BudgetingReview = ({currentCompany, children}) => {


  const [reviewCountResult, reviewCountLoading, reviewcountLoaded, reviewcountError, doReviewCountFetch, setReviewCountResult] = useApi();

  useEffect(() => {
    fetchProjects();
    //doReviewCountFetch(getReviewCount);
  }, [currentCompany]);

  const [projects, projectsLoading, projectsLoaded, projectsError, doProjectsFetch, setProjectsResult] = useApi();

  const fetchProjects = () => {
    doProjectsFetch(getSubmittedProjects, {query: {status: 1}});
  }

  const {sorted, sortByColumn, setSorted, sortedColumn, sortDirection, columnClicked} = useSortBudgets();

  useEffect(() => {
    if (projects) {
      setSorted(sortByColumn([...projects], sortedColumn, sortDirection));
    }
  }, [sortedColumn, projects, sortDirection]);

  useEffect(() => {
  }, [sorted]);
  
  return (
    <>
      <div className="flex-cont align-center">
        <h1 className="flex-cont">
          <div className="margin-right-half medgray">{currentCompany}</div>
          Review Budgets
        </h1>
      </div>  

      {projectsError &&
        <div>
          There was an error fetching your projects.
        </div>
      }

      {!projectsLoading && (projects && projects.length === 0) &&
        <div>
          There are no budgets to review.
        </div>
      }
      

      {projectsLoading &&
        <div className="spinner"></div>
      }
      {!projectsLoading && sorted && sorted.length !== 0 &&
        <table className="basic">
          <thead>
            <tr>
            <TH direction={sortDirection} classNames={["fit", "nowrap"]} column="id" sortedColumn={sortedColumn} label="Project ID" columnClicked={columnClicked} />

            <TH direction={sortDirection} classNames={["fit", "nowrap"]} column="budgetHeaderStatus" sortedColumn={sortedColumn} label="Status" columnClicked={columnClicked} />

            <TH direction={sortDirection} classNames={[]} column="name" sortedColumn={sortedColumn} label="Name" columnClicked={columnClicked} />

            <TH direction={sortDirection} classNames={[]} column="approveddate" sortedColumn={sortedColumn} label="Budget Approved Date" columnClicked={columnClicked} />

            <TH direction={sortDirection} classNames={["fit"]} column="hoursBudgeted" sortedColumn={sortedColumn} label="Budgeted Hours" columnClicked={columnClicked} />

            <TH direction={sortDirection} classNames={["fit"]} column="hoursRemaining" sortedColumn={sortedColumn} label="Remaining Hours" columnClicked={columnClicked} />

            <TH direction={sortDirection} classNames={["fit"]} column="billing" sortedColumn={sortedColumn} label="% Billing" columnClicked={columnClicked} />
            <th></th>
            </tr>
          </thead>
          <tbody>
            {sorted && sorted.map( project => 
              <tr>
                
                
                <td className="fit nowrap">
                  <Link className="text-link" to={`/budgeting/projects/${project.id}`}>{project.id}</Link>
                </td>

                <td className="fit nowrap">
                  <Link className="text-link" to={`/budgeting/projects/${project.id}`}>
                    <>
                      {project.budgetHeaderStatus &&
                        <div className="status-badge-cont">
                          <div className={`budget status-badge ${project.budgetHeaderStatus.toLowerCase()}`}>
                            {project.budgetHeaderStatus.toUpperCase() }
                          </div>
                        </div>
                      }
                      {!project.budgetHeaderStatus &&
                        <div className="bold font-075 gray">NEEDS BUDGET</div>
                      }
                    </>
                  </Link>
                </td>

                
                <td className="nowrap">
                  {project.name}
                </td>
                <td className="fit nowrap">
                  {project.approveddate ? DateTime.fromISO(project.approveddate).toLocaleString() : ""}
                </td>
                <td className="fit">
                  {project.hoursBudgeted}
                </td>
                <td className="fit">
                  {project.hoursBudgeted !== null &&
                    <>{+(toNum(project.hoursBudgeted) - toNum(project.hoursRemaining)).toFixed(2)}</>
                  }
                </td>
                <td className="fit">
                  {project.billing}%
                </td>
                <td></td>
              </tr>
            )}
          </tbody>
        </table>
      }
    </>
  )
}



const mapState = state => ({
  currentCompany: state.authState.currentCompany,
});

export default connect(mapState)(BudgetingReview);
