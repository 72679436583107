import React, { useEffect } from 'react';

import BudgetingNav from './BudgetingNav';
import { connect } from 'react-redux';



const BudgetingLayout = ({currentCompany, children}) => {


  
  
  return (
    <>
      {/* <ViewAsBanner /> */}
      <BudgetingNav />
      {children}
    </>
  )
}



function mapState(state) {
  return { 
    // tokenResponse: state.authState.tokenResponse,
    currentCompany: state.authState.currentCompany
  };
} 

export default connect(mapState)(BudgetingLayout);
