import React from 'react';


import { connect } from 'react-redux';

import AdminTeamsForm from './AdminTeamsForm';



const AdminOfficeManagerTeamsNew = ({currentCompany}) => {

  

  return (
    <>
      <div className="xxx margin padding-2x">

        <h1 className="flex-cont">
          <div className="medgray margin-right-half">{currentCompany}</div>
          New Office Manager Team
        </h1>

        <AdminTeamsForm type="Office" />

      </div>
    
    </>
  );
}

function mapState(state) {
  return { 
    // tokenResponse: state.authState.tokenResponse,
    currentCompany: state.authState.currentCompany
  };
} 

export default connect(mapState)(AdminOfficeManagerTeamsNew);
