import React, {useState, useEffect} from 'react';
import { DateTime } from 'luxon';

export const isNumber = str => {
  if (str === null || str === undefined || str === "") return false;
  const parsed = parseFloat(str);
  return !isNaN(parsed);
}
export const toNum = str => {
  if (str === undefined || str === null) {
    return 0.0
  }
  const parsed = parseFloat(str);
  if (!parsed || isNaN(parsed)) return 0.0;

  return parsed.toFixed(2);
}

export const useSortTimesheets = () => {

  const [sorted, setSorted] = useState(null);
  const [sortedColumn, setSortedColumn] = useState("employeeName");
  const [sortDirection, setSortDirection] = useState(-1);

  const columnClicked = column => {
    setSortDirection(o => o * -1);
    setSortedColumn(column);
  }

  const getType = column => {
    if (column === "weekStart" || column === "weekEnd") return "date";
    if (column === "totalHours") return "number";
    return "string"
  }

  const sortByColumn = (items, column, direction) => {
    let array = [...items];

    const type = getType(column);

    if (type === "date") {
      return array.sort((a, b) => {
          if (!a[column]) return direction;
          if (!b[column]) return direction*-1;

          
          const dateA = DateTime.fromISO(a[column]);
          const dateB = DateTime.fromISO(b[column]);
          if (!dateA || !dateB) return 0;

          if (direction === -1) {
            return dateA.toMillis() - dateB.toMillis()
          }
          else {
            return dateB.toMillis() - dateA.toMillis()
          }
          
        }
      )
    }
    if (type === "string") {
      return array.sort(
        (a, b) => {

          if (!a[column]) return direction;
          if (!b[column]) return direction*-1;


          if (a[column] && b[column]) {
            if (direction === -1) {
              return a[column].localeCompare(b[column])
            }
            else {
              
              return b[column].localeCompare(a[column])
            }
          }
          // return 0
         
        } 
      );
    }
    if (type === "number") {
      return array.sort(
        (a, b) => {

          

          if (column === "hoursRemaining") {


            const valA = toNum(a.hoursBudgeted) - toNum(a.hoursRemaining);
            const valB = toNum(b.hoursBudgeted) - toNum(b.hoursRemaining);

            if (direction === -1) {
              return parseFloat(valA) - parseFloat(valB)
            }
            else {
              return parseFloat(valB) - parseFloat(valA)
            }
          }

          if (a[column] === null || a[column] === undefined || !isNumber(a[column])) return direction;
          if (b[column] === null || b[column] === undefined || !isNumber(b[column])) return direction*-1;


          if (  isNumber(a[column]) && isNumber(b[column]) ) {
            if (direction === -1) {
              return parseFloat(a[column]) - parseFloat(b[column])
            }
            else {
              return parseFloat(b[column]) - parseFloat(a[column])
            }
          }
          return 0
        } 
      );
    }
  }




  return {sorted, sortByColumn, setSorted, sortedColumn, sortDirection, columnClicked};

}

