import { andyFetch, actionReturn } from '../../api';

export const getPrintProject = (params) => {
  return async (dispatch, getState) => {
    const url = `projects/print_project`;

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};

export const getProjects = (params) => {

  return async (dispatch, getState) => {

    
    const url = "projects";

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};

export const getAllProjects = (params) => {

  return async (dispatch, getState) => {

    
    const url = "projects/all";

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return actionReturn(json, response);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};


export const getMyProjects = (params) => {

  return async (dispatch, getState) => {

    
    const url = "projects/mine";

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};

export const getMyBudgetHeadersForExpenseReports = (params) => {
  return async (dispatch, getState) => {
    const url = "projects/mine_for_expense_reports";
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();

        // dispatch(setReviewCount(json.reviewCount));

        return actionReturn(json);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};

export const getMyAdminProjects = (params) => {
  return async (dispatch, getState) => {
    const url = "projects/mine_admin";
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();

        // dispatch(setReviewCount(json.reviewCount));

        return actionReturn(json);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};

export const getAssignedProjects = (params) => {
  return async (dispatch, getState) => {
    const url = "projects/assigned";
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();

        
        return actionReturn(json);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};

export const getHasAssignedProjects = (params) => {
  return async (dispatch, getState) => {
    const url = "projects/has_assigned";
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();

        
        return actionReturn(json);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};
export const getSubmittedProjects = (params) => {
  return async (dispatch, getState) => {
    const url = "projects/submitted";
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};
export const getApprovedProjects = (params) => {
  return async (dispatch, getState) => {
    const url = "projects/approved";
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};

export const getProject = (params) => {
  return async (dispatch, getState) => {
    const url = "projects/show_project";
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};

// export const setReviewCount = (reviewCount) => {
//   return dispatch => {
//     dispatch({
//       type: "SET_BUDGET_REVIEW_COUNT",
//       reviewCount: reviewCount
//     });
//   }
// }

