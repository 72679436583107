import React from 'react';

const SummaryWeekTotal = ({setValue, register, weekIndex, totalHours}) => {

  setValue(`week_summary_hours.${weekIndex}`, parseFloat(totalHours), { });

  return (
    <>
      {parseFloat(totalHours).toLocaleString()}
      <input  {...register(`week_summary_hours.${weekIndex}`)} 
        type="hidden" 
        name={`week_summary_hours.${weekIndex}`} 
        defaultValue={parseFloat(totalHours)} 
      />
    </>
  )
}

export default SummaryWeekTotal;