import React, { useEffect, useState } from 'react';
import { useApi } from '../../../api';
import { connect } from 'react-redux';

import {
  Link, useNavigate,
} from 'react-router-dom';
import { usePrevious } from '../../../hooks/usePrevious';
import { getMyTimesheetDelegates } from '../../../redux/manager/users/userActions';

import { useForm } from 'react-hook-form';
import DelegateForm from './TimesheetDelegateForm';

const DelegateTimesheets = ({currentCompany, children}) => {

  const [reload, setReload] = useState(1);
  const [timesheetDelegates, timesheetDelegatesLoading, timesheetDelegatesLoaded, timesheetDelegatesError, doTimesheetDelegatesFetch, setTimesheetDelegatesResult] = useApi();
  const [formTimesheetDelegate, setFormTimesheetDelegate] = useState(null);
  const [modalOpened, setModalOpened] = useState(false);

  const navigate = useNavigate('/');
  const prevCompany = usePrevious(currentCompany);

  useEffect(() => {
    fetchTimesheetDelegates();
  }, [currentCompany, reload]);

  useEffect(() => {
    if (!modalOpened) {
      setFormTimesheetDelegate(null);
    }
  }, [modalOpened]);






  const toggleModal = () => {
    setModalOpened(o => !o);
  }



  const fetchTimesheetDelegates = () => {
    doTimesheetDelegatesFetch(getMyTimesheetDelegates);
  }

  const addDelegateClicked = () => {

  }

 

  const editClicked = (timesheetDelegate) => {
    setFormTimesheetDelegate(timesheetDelegate);
    toggleModal();
  }




  

  return (
    <>
      {modalOpened &&
        <DelegateForm formTimesheetDelegate={formTimesheetDelegate}  setReload={setReload} toggleModal={toggleModal} />
      }

      <div className="flex-cont align-center">
        <h1 className="flex-cont flex-1">
          <div className="margin-right-half medgray">{currentCompany}</div>
          Timesheet Delegates
        </h1>

        <div onClick={toggleModal} className="button">
          Add Timesheet Delegate
        </div>
      </div>  
      

      <div className="content margin-top-1">


        {timesheetDelegatesError &&
          <div>
            There was an error fetching your delegate employees.
          </div>
        }


        {timesheetDelegatesLoading &&
          <div className="spinner"></div>
        }

        {/* <pre>
          {JSON.stringify(timesheetDelegates, null, 2)}
        </pre> */}
        {timesheetDelegates && timesheetDelegates.length !== 0 &&
          <table className="basic">
            <thead>
              <tr>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {timesheetDelegates?.map(timesheetDelegate =>
                <tr>
                  <td className="fit nowrap">{timesheetDelegate.delegate_user}</td>
                  <td className="fit nowrap">{timesheetDelegate.company}</td>
                  <td  className='full'>{timesheetDelegate.timesheetid} - {timesheetDelegate.timesheetname}</td>
                  <td>
                    <div onClick={() => editClicked(timesheetDelegate)} className="button ghost">
                      <i className="fas fa-pencil-alt"></i>
                    </div>
                  </td>
                </tr>
              )}
              
            </tbody>
          </table>
        }
        {!timesheetDelegatesLoading && (!timesheetDelegates || timesheetDelegates.length === 0) &&
          <em>No results.</em>
        }
      </div>
    </>
  )
}



const mapState = state => ({
  reviewCount: state.budgetHeaderState.reviewCount,
  currentCompany: state.authState.currentCompany,
});

export default connect(mapState)(DelegateTimesheets);
