import { useState, useEffect, useRef, useCallback } from "react";
import store from './redux/store';
import { NavLink, Link, Outlet, useNavigate } from 'react-router-dom';
import { addError, addSuccess } from './redux/ui/uiActions';
import { setReAuth } from './redux/authActions';
import queryString from 'query-string';

// export const API_URL = process.env.NODE_ENV === "development" ? "http://localhost:3001" : "https://hbarails-custom-docker.azurewebsites.net";
// export const FRONTEND_URL = process.env.NODE_ENV === "development" ? "http://localhost:3000" : "https://kronos.hba.com";

export const API_URL = process.env.REACT_APP_API_URL;
export const FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL;

//  "https://hbarails-custom-docker.azurewebsites.net"
// export const API_URL = "http://localhost:3001";
// export const FRONTEND_URL = "http://localhost:3000";



// const queryString = require('query-string');



export const normalFetch = async (url, payload=null, method="GET") => {
  const fullUrl = `${API_URL}/${url}`;
  const token = store?.getState()?.authState.tokenResponse?.accessToken;
  let options = {
    method: method, 
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': token,
    }
  };

  if (payload && method !== 'GET') {
    options.body = JSON.stringify(payload);
  } 

  let response;

  

  try {
    response = await fetch(fullUrl, options);
    


    if (response.ok) {
      const j = await response.json();
      return j;
    } else {
      const e = await response.json();
      if (e.status === 401) {

      }
      throw e.message;
    }
  }
  catch (e) {
    
    let msg = e;
    
    if (!msg) {
      msg = "There was an error. Please try again."
    }

    msg = msg.toString();
    store.dispatch(addError(msg));
    return response;
  }
}




export const actionReturn = (responseJSON, fullResponse) => {

  // alert(JSON.stringify(responseJSON, null, 2))

  return (
    {
      data: responseJSON.data,
      successes: responseJSON.successes,
      fullResponse: fullResponse,
    }
  )
  
}

export const useApi = (skip=false) => {


  const apiRef = useRef();
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(null);
  const [refreshIndex, setRefreshIndex] = useState(0);
  const [apiFunction, setApiFunction] = useState(null);
  const [query, setQuery] = useState(null);
  const [payload, setPayload] = useState(null);
  const [params, setParams] = useState({});

  const [pagy, setPagy] = useState(null);

  const resetApi = () => {
    setResult(null);
    setLoading(false);
    setLoaded(false);
    setError(null);
    // setRefreshIndex(0);
    // setApiFunction()
  }


  const doFetch = (f, p={}) => {
    setApiFunction(() => f);
    setParams(p);
    setRefreshIndex(refreshIndex + 1);
  };




  const ttt = async (cancelled) => {

    // setPagy("HELLO")
    // setAndy("MICHAEL")

    

    setLoading(!loading);

    try {
      
      setLoading(true);
      const response = await store.dispatch(apiFunction(params));
      
      if (response == null) {



        setLoading(false);
        // setError(response.error);
        
        
        setLoaded(false);

        throw new Error('Error')
      }
      else if (response.message) {
        setLoading(false);
        // setError(response.error);
        //
        
        setLoaded(false);
        setError(response.message)
      }
      else {
        
        if (!cancelled) {

          const json = response.json;

          
          // alert(JSON.stringify(response, null, 2));
          

          if (json && json.error) {
            alert(JSON.stringify(json.error));
            
          }

          
    
          setResult(response.data);
          console.log('response', response)


          if (response.successes != null) {
            response.successes.forEach(msg => store.dispatch(addSuccess(msg)))
          }

          if (response.fullResponse) {
          

            const fullResponse = response.fullResponse;

            
            // alert(JSON.stringify(fullResponse.headers.get('Current-Page'), null, 2))

            if (fullResponse.headers.get('Current-Page')) {
              setPagy({
                currentPage: parseInt(fullResponse.headers.get('Current-Page')),
                pageItems: parseInt(fullResponse.headers.get('Page-Items')),
                totalCount: parseInt(fullResponse.headers.get('Total-Count')),
                totalPages: parseInt(fullResponse.headers.get('Total-Pages')),
              })
            }
          }

          setLoading(false);
          setLoaded(true);

          

          

          

          


        }
      }
    }
    catch (e) {
      
      setLoading(false);
      setError(e);
    }
    finally {
      setLoading(false);
    }
  };

 
  useEffect(() => {

    apiRef.current = Date.now()

    setLoaded(false);


    let cancelled = false;
    if (!apiFunction || skip) {
      setResult(null);
      setLoading(false);
      
    } 
    else {

      ttt(cancelled);
    } 
    
    return () => {
      
      cancelled = true;
    };
  }, [refreshIndex]);


  





  return [result, loading, loaded, error, doFetch, setResult, pagy, apiRef, resetApi];
}


export const andyFetch = async (url, {query=null, payload=null}, method="GET") => {
  const fullUrl = `${API_URL}/${url}.json?${queryString.stringify(query)}`;

  // const token = "";
  const token = store?.getState()?.authState.tokenResponse?.accessToken;
  const viewAsUserID = localStorage.getItem('viewAsUserID');
  const currentCompanyID = localStorage.getItem('currentCompanyID');
  // const currentAthleteID = store.getState().sessionState.currentAthlete?.id || "";
  let options = {
    method: method, 
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': token,
      'X-VIEW-AS-USER-ID': viewAsUserID,
      'X-CURRENT-COMPANY-ID': currentCompanyID,
    }
  };

  if (payload && method !== 'GET') {
    options.body = JSON.stringify(payload);
  }

  let response;

  try {
    store.dispatch(setReAuth(false));
    response = await fetch(fullUrl, options);


    
    if (response.ok) {


      return response;
    } else {
      const e =  response;

      

      const j = await e.json();

      
      
      // .json();

      // alert(JSON.stringify(e, null, 2))
      
      if (e.status === 401) {

        store.dispatch(setReAuth(true));
        
        // window.location.reload();
      }
      throw j;
    }
  }
  catch (e) {

    // alert(JSON.stringify(e, null, 2))
    // return response;
    

    let msg = e.message;
    
    if (!msg) {
      msg = "There was an error. Please try again."
    }
    msg = msg.toString();

    if (msg === "Invalid auth token.") {
      
      // store.dispatch(setNeedToRefresh());

      store.dispatch(setReAuth(true));
    }
    else {
      store.dispatch(addError(msg));
      
    }

    

    return response;
  }
};