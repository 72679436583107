import React, { useEffect, useState } from 'react';
import { useApi } from '../../../api';
import { connect } from 'react-redux';

import {
  Link, useNavigate,
} from 'react-router-dom';
import { usePrevious } from '../../../hooks/usePrevious';
import { getMyProjectDelegates } from '../../../redux/manager/users/userActions';

import { useForm } from 'react-hook-form';
import DelegateForm from './ProjectDelegateForm';

const ExpenseHome = ({currentCompany, children}) => {

  const [reload, setReload] = useState(1);
  const [projectDelegates, projectDelegatesLoading, projectDelegatesLoaded, projectDelegatesError, doProjectDelegatesFetch, setProjectDelegatesResult] = useApi();
  const [formProjectDelegate, setFormProjectDelegate] = useState(null);
  const [modalOpened, setModalOpened] = useState(false);

  const navigate = useNavigate('/');
  const prevCompany = usePrevious(currentCompany);

  useEffect(() => {
    fetchProjectDelegates();
  }, [currentCompany, reload]);

  useEffect(() => {
    if (!modalOpened) {
      setFormProjectDelegate(null);
    }
  }, [modalOpened]);






  const toggleModal = () => {
    setModalOpened(o => !o);
  }



  const fetchProjectDelegates = () => {
    doProjectDelegatesFetch(getMyProjectDelegates);
  }

  const addDelegateClicked = () => {

  }

 

  const editClicked = (projectDelegate) => {
    setFormProjectDelegate(projectDelegate);
    toggleModal();
  }




  

  return (
    <>
      {modalOpened &&
        <DelegateForm formProjectDelegate={formProjectDelegate}  setReload={setReload} toggleModal={toggleModal} />
      }

      <div className="flex-cont align-center">
        <h1 className="flex-cont flex-1">
          <div className="margin-right-half medgray">{currentCompany}</div>
          Delegate
        </h1>

        <div onClick={toggleModal} className="button">
          Add Delegate
        </div>
      </div>  
      

      <div className="content margin-top-1">


        {projectDelegatesError &&
          <div>
            There was an error fetching your delegate employees.
          </div>
        }


        {projectDelegatesLoading &&
          <div className="spinner"></div>
        }

        {/* <pre>
          {JSON.stringify(projectDelegates, null, 2)}
        </pre> */}
        {projectDelegates && projectDelegates.length !== 0 &&
          <table className="basic">
            <thead>
              <tr>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {projectDelegates?.map(projectDelegate =>
                <tr>
                  <td className="fit nowrap">{projectDelegate.delegate_user}</td>
                  <td className="fit nowrap">{projectDelegate.company}</td>
                  <td  className='full'>{projectDelegate.projectid} - {projectDelegate.projectname}</td>
                  <td>
                    <div onClick={() => editClicked(projectDelegate)} className="button ghost">
                      <i className="fas fa-pencil-alt"></i>
                    </div>
                  </td>
                </tr>
              )}
              
            </tbody>
          </table>
        }
        {!projectDelegatesLoading && (!projectDelegates || projectDelegates.length === 0) &&
          <em>No results.</em>
        }
      </div>
    </>
  )
}



const mapState = state => ({
  reviewCount: state.budgetHeaderState.reviewCount,
  currentCompany: state.authState.currentCompany,
});

export default connect(mapState)(ExpenseHome);
