import React, { useState, useEffect } from 'react';
import { useApi } from '../api';
import { DateTime } from 'luxon';
import { connect } from 'react-redux';

import { getSupportTickets, updateSupportTicket, getSupportTicket, deleteSupportTicket  } from '../redux/supportTickets/supportTicketActions';
import { getSupportTicketUsers } from '../redux/admin/userActions';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Modal from '../components/Modal';

import AutoComplete from '../components/AutoComplete';
import { useForm } from 'react-hook-form';



const SupportTicket = ({currentSupportTicketID, toggleModal, }) => {

  const { trigger, reset, watch, register, formState: { errors }, handleSubmit, getValues, setValue } = useForm({
    // resolver: yupResolver(TeamSchema),
  });
  

  useEffect(() => {
    if (currentSupportTicketID != null) {
      fetchUsers();
      fetchSupportTicket();
    }
    
  }, [currentSupportTicketID])

  const [users, usersLoading, usersLoaded, usersError, doUsersFetch, setUsersResult] = useApi();
  const [saveTicketResult, saveTicketLoading, saveTicketLoaded, saveTicketError, doSaveTicketFetch, setSaveTicketResult] = useApi();
  const [deleteTicketResult, deleteTicketLoading, deleteTicketLoaded, deleteTicketError, doDeleteTicketFetch, setDeleteTicketResult] = useApi();
  const [currentSupportTicket, getTicketLoading, getTicketLoaded, getTicketError, doGetTicketFetch, setGetTicketResult] = useApi();

  // const users = result?.users;

  const fetchUsers = () => {
    doUsersFetch(getSupportTicketUsers);
  }
  const fetchSupportTicket = () => {
    if (currentSupportTicketID == null) return;


    doGetTicketFetch(getSupportTicket, {query: {id: currentSupportTicketID}})
  }

  const isDisabled = false;

  const saveClicked = () => {
    if (currentSupportTicketID == null) return;

    const payload = {
      support_ticket: {
        ...getValues(),
        id: currentSupportTicketID
      }
    }
    // alert(JSON.stringify(payload, null, 2))
    doSaveTicketFetch(updateSupportTicket, {payload: payload})
  }

  const deleteClicked = () => {
    if (currentSupportTicketID == null) return;

    const payload = {
      support_ticket: {
        id: currentSupportTicketID
      }
    }
    // alert(JSON.stringify(payload, null, 2))
    doDeleteTicketFetch(deleteSupportTicket, {payload: payload})
  }

  useEffect(() => {
    if (saveTicketLoaded == true) {
      toggleModal(true);
    }
    
  }, [saveTicketLoaded]);

  useEffect(() => {
    if (deleteTicketLoaded == true) {
      toggleModal(true);
    }
    
  }, [deleteTicketLoaded]);

  if (currentSupportTicketID == null) return null;

  return (
    <Modal toggleModal={toggleModal} focusTrap={false}>

      {getTicketLoading && <div className="padding-2"><div className="spinner"></div></div>  }
      {!getTicketLoading && currentSupportTicket != null && <>



          <div className="modal-header flex-cont align-center">

            <div>
              <div className="font-1-5 align-start">
                <span className="bold">Ticket #{currentSupportTicketID} </span> - <span> {currentSupportTicket.code} </span>
              </div>
              
            </div>
            <div className="flex-1"></div>
            <div>
              <div onClick={toggleModal} className="ghost button close">
                <i className="fas fa-times"></i>
              </div>
            </div>
            
          </div>

          <div className="padding-1">

 

            <div className="flex-cont">
              <div className="margin-right-1">

                <label htmlFor="statusid">
                  <div className="label-text">Status</div>
                  <select
                    className={`${errors?.statusid ? 'has-errors' : ''}`} 
                    {...register("statusid")}
                    name="statusid"
                    defaultValue={currentSupportTicket?.statusid}
                  >
                    <option value="1">New</option>
                    <option value="2">Doing</option>
                    <option value="3">Done</option>

                  </select>

                </label>
                
              </div>
              
              <AutoComplete 
                canReset={false}
                trigger={trigger}
                disabled={isDisabled}
                onSelect={() => null} 
                
                // resetField={resetField}
                getValues={getValues}
                labelComponent={user => <div>{user.fname} {user.lname}</div>} 
                searchableAttributes={["fname", "lname"]} 
                label="Assigned to"
                labelFn={(user) => `${user.fname} ${user.lname}`}
                valueFn={(user) => user.workerrecid }
                setValue={setValue}
                defaultSearch={currentSupportTicket?.assignedworkerrecid ? `${currentSupportTicket.assignedfname} ${currentSupportTicket.assignedlname}` : ''}
                defaultValue={currentSupportTicket?.assignedworkerrecid}
                name={`assignedworkerrecid`}
                // selectedItemHash={
                //   (!entry || (entry?.project?.projectid == null || entry?.project?.projectid?.length == 0)) ? (
                //     null
                //   ) : (
                //     entry?.project
                //   )
                // }

                // validation={
                //   (hide) ? {required: null} : {required: 'Project required'}
                // }

                items={users}
                itemsLoading={usersLoading}
                register={register}
                errors={errors?.assignedworkerrecid} 
                blankMessage="No users found."
                
              />

            </div>



                
              



              {/* <div className='bold'>
                {currentSupportTicket.submittingfname} {currentSupportTicket.submittinglname}
              </div>
              {currentSupportTicket.has_acting_as &&
                <div className="gray font-09">
                  {currentSupportTicket.actingasfname} {currentSupportTicket.actingaslname}
                </div>
              } */}
              <table className="margin-top-2 vertical font-1-25">
                <tr>
                  <th>Submitted by</th>
                  <td>
                    {currentSupportTicket.submittingfname} {currentSupportTicket.submittinglname} <span className="gray">{currentSupportTicket.submittingemail}</span>
                    {currentSupportTicket.has_acting_as &&
                      <div className="font-09">
                        acting as {currentSupportTicket.actingasfname} {currentSupportTicket.actingaslname}
                      </div>
                    }
                  </td>
                </tr>
                <tr>
                  <th>Date</th>
                  <td>{currentSupportTicket.createdat ? DateTime.fromISO(currentSupportTicket.createdat).toLocaleString(DateTime.DATETIME_SHORT) : '-'}</td>
                </tr>
                <tr>
                  <th>Company</th>
                  <td>{currentSupportTicket.company}</td>
                </tr>
                <tr>
                  <th>Submitted from</th>
                  <td>{currentSupportTicket.screen}</td>
                </tr>
                <tr>
                  <th>Code</th>
                  <td>{currentSupportTicket.code}</td>
                </tr>
                <tr>
                  <th>Description</th>
                  <td>{currentSupportTicket.description}</td>
                </tr>
                
                
              </table>
          </div>
          <div className="modal-footer">
            <div className="flex-cont">
              <button onClick={deleteClicked} disabled={deleteTicketLoading || saveTicketLoading} className="button ghost danger">
                {deleteTicketLoading && <div className="spinner button-spinner"></div> }
                Delete
              </button>
              <div className="flex-1"></div>
              <button onClick={saveClicked} className="button" disabled={deleteTicketLoading || saveTicketLoading}>
                {saveTicketLoading && <div className="spinner button-spinner"></div> }
                Save 
              </button>
            </div>
          </div>

          </>}
        </Modal>
  )

}

export default SupportTicket;