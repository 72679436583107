import React, {useState, useEffect} from "react";
import { DateTime, Info } from 'luxon';
import { submitTimesheetAlpha, saveTimesheetAlpha, getTimesheetAlpha, getProjectsAlpha, getMilestonesAlpha, getTasksAlpha } from "../../redux/alphaTimesheets/alphaTimesheetActions";
import { useApi } from '../../api';
import { usePrevious } from '../../hooks/usePrevious';
import { connect } from 'react-redux';
import { useForm, useWatch } from 'react-hook-form';
import {
  useParams, useNavigate,
} from 'react-router-dom';

import Summary from './Summary';
import TimesheetWeek from './TimesheetWeek';
import Modal from "../../components/Modal";
import TimesheetFooter from "./TimesheetFooter";
import TimesheetSubmitModal from "./TimesheetSubmitModal";
import TimesheetPrintModal from "../TimesheetPrintModal";

function useInterval(callback, delay) {
  const intervalRef = React.useRef(null);
  const savedCallback = React.useRef(callback);
  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);
  React.useEffect(() => {
    const tick = () => savedCallback.current();
    if (typeof delay === 'number') {
      intervalRef.current = window.setInterval(tick, delay);
      return () => window.clearInterval(intervalRef.current);
    }
  }, [delay]);
  return intervalRef;
}





const AlphaTimesheet= ({currentUser, currentCompany, viewOnly}) => {

  const [isFixing, setIsFixing] = useState(false);

  const fixTimesheetClicked = () => {
    setIsFixing(o => !o);
  }

  const finalViewOnly = isFixing ? false : !!viewOnly;


  const navigate = useNavigate('/');
  const { id } = useParams();
  const prevCompany = usePrevious(currentCompany);
  const { resetField, unregister, setError, trigger, control, watch, register, handleSubmit, formState: { errors, isDirty }, getValues, reset, clearErrors, setValue, formState } = useForm();

  const [timesheetResult, timesheetLoading, timesheetLoaded, timesheetError, doTimesheetFetch, setTimesheetResult] = useApi();
  const [projectsResult, projectsLoading, projectsLoaded, projectsError, doProjectsFetch, setProjectsResult] = useApi();
  const [selectedWeekIndex, setSelectedWeekIndex] = useState(0);

  const [editingNoteInputName, setEditingNoteInputName] = useState(null);



  const [offset, setOffset] = useState(0);
  const scrolled = offset > 100;

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    // clean up code
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  

  useEffect(() => {
    if (prevCompany && currentCompany) {
      if (prevCompany !== currentCompany) {
        navigate("/timesheets")
      }
    }    
  }, [currentCompany]);


  useEffect(() => {
    fetchTimesheet();
    fetchProjects();
  }, [id, currentCompany])


  const fetchProjects = () => {
    doProjectsFetch(getProjectsAlpha, {query: {timesheetid: id}});
  }
  const fetchTimesheet = () => {
    doTimesheetFetch(getTimesheetAlpha, {query: {id: id}})
  }

  

  const selectedPeriod = timesheetResult ? timesheetResult?.summary?.[selectedWeekIndex] : null;

  const [timesheetSaveResult, timesheetSaveLoading, timesheetSaveLoaded, timesheetSaveError, doTimesheetSaveFetch, setTimesheetSaveResult] = useApi();
  const [submitResult, submitLoading, submitLoaded, submitError, doSubmitFetch, setSubmitResult] = useApi();
  const [submitNote, setSubmitNote] = useState('');
  const [submitModalOpened, setSubmitModalOpened] = useState(false);
  const [dupesFound, setDupesFound] = useState();
  const [unsavedNewEntry, setUnsavedNewEntry] = useState();

  const toggleSubmitModal = () => {
    setSubmitModalOpened(o => !o);
  }

  // useEffect(() => {
  //   alert(unsavedNewEntry)
  // }, [unsavedNewEntry])

  const tryTimesheetSave =  (fromAutoSave=false) => {

    if (shouldDisableButtons) return;

    // const t = await trigger();
    // // const entryErrors = errors?.week?.[weekIndex]; //?.entries?.[entryIndex];
    // // alert(JSON.stringify(entryErrors, null, 2))
    // if (!t) return; 

    if (checkUnsaved()) {
      setUnsavedNewEntry(true);
      return;
    }
    else {
      setUnsavedNewEntry(false);
    }


    // if (fromAutoSave === true) {
    //   setIsAutoSaving(true);
    // }


    const data = getValues();
    let payload = {
      timesheet: {
        timesheetid: id,
        id: id,
        ...data,
      }
      
    }

    if (isFixing) {
      payload.admin_is_editing = "1";
    }

    // alert(JSON.stringify(payload.admin_is_editing, null, 2))

    // return


    doTimesheetSaveFetch(saveTimesheetAlpha, {
      payload: payload
    })
  }

  const tryTimesheetSubmit = () => {

    if (shouldDisableButtons) return;


    const data = getValues();

    

    const payload = {
      submit_note: submitNote,
      id: id,
      timesheetid: id,
      timesheet: {
        id: id,
        timesheetid: id,
        ...data,
      }
    }
    doSubmitFetch(submitTimesheetAlpha, {
      payload: payload
    })
  } 

  const submitButtonClicked = async () => {

    if (shouldDisableButtons) return;

    if (checkDupes()) {
      setDupesFound(true);
    }
    else {
      setDupesFound(false);
    }

    if (checkUnsaved()) {
      setUnsavedNewEntry(true);
      return
    }
    else {
      setUnsavedNewEntry(false);
    }

    const t = await trigger();
    const data = getValues();
    // alert(JSON.stringify(data, null, 2))
    setSubmitModalOpened(true);
  }

  useEffect(() => {

    if (!timesheetSaveLoading && timesheetSaveLoaded) {
      const now = new Date().getTime();
      setLastSavedAt(now);
      setIsAutoSaving(false);
    }
    
  }, [timesheetSaveLoading, timesheetSaveLoaded]);

  useEffect(() => {

    if (!submitLoading && submitLoaded) {
      const now = new Date().getTime();
      setLastSavedAt(now);
      setIsAutoSaving(false);
    }
    
  }, [submitLoading, submitLoaded]);

  useEffect(() => {

    if (timesheetSaveLoaded) {
      reset({}, { keepValues: true, keepIsValid: true });
    }
    
  }, [timesheetSaveLoaded]);

  useEffect(() => {

    if (submitLoaded) {
      navigate('/timesheets')
    }
    
  }, [submitLoaded]);

  const [isAutoSaving, setIsAutoSaving] = useState(false);
  const [lastSavedAt, setLastSavedAt] = useState(new Date().getTime());

  useInterval(() => {
    if (shouldDisableButtons) return;
    // if (isAutoSaving) {
    //   setIsAutoSaving(false);
    //   return;
    // }

    

    const now = new Date().getTime();
    console.log('diff', now-lastSavedAt);
    console.log('isDirty', isDirty);
    if (now - lastSavedAt > 900000) { // 15 minutes
//isDirty
      if ( !timesheetSaveLoading && isDirty) {

        console.log('checkUnsaved()', checkUnsaved())

        if (checkUnsaved()) {
          // setUnsavedNewEntry(true);
          return;
        }
        else {
          // setUnsavedNewEntry(false);
        }

        setIsAutoSaving(true);
        tryTimesheetSave();
      }
    }

    
  }, 30000); // 30 seconds

  const [entryLoading, setEntryLoading] = useState(false);

  const shouldDisableButtons = finalViewOnly || !timesheetResult || submitLoading || timesheetSaveLoading || timesheetLoading || projectsLoading || entryLoading;

  const shouldShowButtons = () => {

    if (viewOnly) return false;

    if (isFixing) return false;


    if (timesheetResult && timesheetResult?.statusid !== 2 && timesheetResult?.statusid !== 1 && timesheetResult?.statusid !== 5 && timesheetResult?.statusid !== 6) {
      return true;
    }

    return false;
  } 

  const hasErrors = () => {
    return Object.keys(errors).length !== 0
  } 

  const checkUnsaved = () => {
    let i=0;
    for(i=0; i<timesheetResult.summary?.length; i++) {
      const iii = `week.${i}.entries.0`;
      const value = getValues(iii);

      if (value) {
        return true;
        break;
      }

    }

    return false;
  }

  const checkDupes = () => {
    const values = getValues();
    if (!values?.week) return false;

    let found = false;
    values.week.some(week => {
      const entries = week?.entries?.filter(e => e._destroy !== "1");

      if (!entries) {
        found = false
        return true
      }

      const uniqueValues = new Set(entries.map(v => `${v.projecid} ${v.activitynumber} ${v.subactivitynum}`));
      if (uniqueValues.size < entries.length) {
        
        
        
        found = true;
        return true;
      }

      return false


    })

    return found;
  }

  const [printModalOpened, setPrintModalOpened] = useState(false);

  const togglePrintModal = () => {
    setPrintModalOpened(o => !o);
  }


  const canFix = timesheetResult && [0,2,3,6].includes(timesheetResult.statusid) && currentUser.roleid === 2;

  

  return (
    <>
      <form  action="">
      {printModalOpened &&
        <TimesheetPrintModal opened={printModalOpened} timesheet={timesheetResult} toggleModal={togglePrintModal} />
      }

      {unsavedNewEntry &&
        <Modal focusTrap={false} size="small" toggleModal={() => {setUnsavedNewEntry(false)}}>
          <div className='modal-header flex-cont align-center'>
            {/* <h1 className="flex-1">Submit Timesheet</h1> */}
            <h2 className="flex-1 nowrap">Unsaved New Entry</h2>
            <div className="flex-1"></div>
            <div onClick={() => setUnsavedNewEntry(false)} className="ghost button close">
              <i className="fas fa-times"></i>
            </div>
          </div>

          <div className="padding-1">
            <div>
              You have opened a new entry without saving it. Please save the new entry and try again.
            </div>
            

          </div>

          <div className='modal-footer flex-cont align-center'>
            
            {/* <div onClick={() => setDupesFound(false)} className="button">Yes, continue</div>
            <div className="flex-1"></div>
            <div onClick={toggleSubmitModal} className="ghost button">Okay</div> */}
          </div>
        </Modal>
      }


      {submitModalOpened &&
        <TimesheetSubmitModal submitLoading={submitLoading} submitNote={submitNote} setSubmitNote={setSubmitNote} toggleSubmitModal={toggleSubmitModal} hasErrors={hasErrors} dupesFound={dupesFound} setDupesFound={setDupesFound} tryTimesheetSubmit={tryTimesheetSubmit} />
      }

      <div className={`${scrolled ? 'scrolled' : ''} alpha-timesheet-footer`}>
        <div className="flex-cont align-center">
          
          {timesheetResult && <>
            
              <TimesheetFooter control={control} selectedPeriod={selectedPeriod} timesheetResult={timesheetResult} setSelectedWeekIndex={setSelectedWeekIndex} selectedWeekIndex={selectedWeekIndex} />

            </>
          }
          

          <div className="flex-1"></div>

          <div onClick={togglePrintModal} className="button outline margin-right-half">
            <i className="fas fa-print margin-right-half"></i>
            <span>Print</span>
          </div>

          {shouldShowButtons() && 
            <>

              <div onClick={tryTimesheetSave} className={`${shouldDisableButtons ? 'disabled' : ''} outline button margin-right-half`}>
                Save
                {timesheetSaveLoading &&
                  <div className="spinner button-spinner"></div>
                }
              </div>

              <div onClick={submitButtonClicked} className={`${shouldDisableButtons ? 'disabled' : ''} button margin-right-0`}>
                Save & Submit
                {submitLoading &&
                  <div className="spinner button-spinner"></div>
                }
              </div>
            </>
          }
        </div>
      </div>


      {isAutoSaving &&
        <Modal focusTrap={false} canClose={false} size="small">
          <div className="padding-2">
            <div className="flex-cont align-center">
              <div className="spinner font-1 margin-right-1"></div>
              <div className="font-1-25">Autosaving...</div>
            </div>
            
            
          </div>
        </Modal>
      }
      
      <div className="flex-cont margin-bottom-2">

        <div>

          <div className="flex-cont align-center">
            <div className="bold font-1-5 margin-right-1">TM{id}</div>
            {!isFixing && timesheetResult &&
              <div className={`bold font-08 alpha-timesheet-status ${timesheetResult?.status?.toLowerCase()}`}>{timesheetResult?.status}</div>
            }
            
            {isFixing &&
              <>  
                <div className="bold margin-right-half blue">STATUS: </div>
                <select 
                  className="admin-editable"
                  {...register("statusid")} 
                  defaultValue={timesheetResult ? timesheetResult.statusid : "0"} 
                  name="statusid"
                >
                  <option value={0}>Draft</option>
                  <option value={1}>Submitted</option>
                  <option value={2}>Approved</option>
                  <option value={3}>Returned</option>   
                  {/* <option value={5}>Posted</option>   */}
                  <option value={6}>Posting Failed</option>               
                </select>
              </>
              
            
            }
          </div>
          
          {!timesheetLoading && timesheetResult &&
            <>
              <div className="margin-bottom-0">{timesheetResult.emp_fname} {timesheetResult.emp_lname}</div>
              {timesheetResult?.delegate_workerrecid &&
                <div className="font-075">Created by {timesheetResult?.delegate_fname} {timesheetResult?.delegate_lname}</div>
              }
            </>
          }
        </div>
        <div className="flex-1"></div>

        {canFix &&
          <>
            {!isFixing && timesheetResult &&
              <div>
                <div onClick={fixTimesheetClicked} className="button admin-button admin-button-outline margin-right-half">
                  {/* <i className="fas fa-user-lock margin-right-half"></i> */}
                  <div className="admin-button-badge">ADMIN:</div>
                  <div>Edit Timesheet</div>
                </div>
              </div>
            }
          </>
        }

        
        {canFix && isFixing &&
          <div className="admin-fixing-toast">
            <div className="admin-badge">ADMIN</div> 
            <div className="margin-right-1">You are editing this timesheet.</div>
            <div onClick={fixTimesheetClicked}  className={`${shouldDisableButtons ? 'disabled' : ''} font-075 button ghost margin-right-half`}>CANCEL</div>
            <div onClick={() => tryTimesheetSave()} className={`${shouldDisableButtons ? 'disabled' : ''} font-075 button admin-button`}>
              SAVE
              {timesheetSaveLoading &&
                <div className="spinner button-spinner"></div>
              }
              
            </div>
          </div>
        
        }

        

        

        <div>
          <div onClick={togglePrintModal} className="button outline margin-right-half">
            <i className="fas fa-print margin-right-half"></i>
            <span>Print</span>
          </div>
        {(shouldShowButtons()) && 
          <>
            <div onClick={tryTimesheetSave} className={`${shouldDisableButtons ? 'disabled' : ''} outline button margin-right-half`}>
              Save
              {timesheetSaveLoading &&
                <div className="spinner button-spinner"></div>
              }
            </div>

            <div onClick={submitButtonClicked} className={`${shouldDisableButtons ? 'disabled' : ''} button margin-right-0`}>
              Save & Submit
              {submitLoading &&
                <div className="spinner button-spinner"></div>
              }
            </div>
            </>
          }
        </div>

      </div>


      {timesheetLoading &&
          <>
            <div className="margin-bottom-1 loading-gradient" style={{width: "10rem", height: "1.55rem"}}></div>
            <div style={{height: '200px'}} className="margin-bottom-half section loading-gradient"></div>
            <div style={{height: '300px'}} className="margin-bottom-half section loading-gradient"></div>
          </>
        }

      {!timesheetLoading && timesheetResult &&
          <>

          {timesheetResult.submission_note && timesheetResult.statusid === 1 &&
            <div className="better section margin-top-1 margin-bottom-half">
              <div className="flex-cont align-center">
                <i className="gray font-1-25 fas fa-comment-dots margin-right-half"></i>
                <div className="font-08 margin-right-1 bold gray">SUBMISSION NOTE: </div>
                <div style={{marginBottom: '2px'}}>{timesheetResult.submission_note}</div>
              </div>
            </div>
          }
            
            
          
          
          <div style={{background: 'none'}} className="better section margin-top-1 margin-bottom-half">

            <h2 className="no-margin margin-bottom-1 font-1-25">
              Select Period: 
              {/* <span className="font-08 normal">{selectedPeriod && selectedPeriod.week_end && DateTime.fromISO(selectedPeriod.week_end).toLocaleString()}</span> */}
            </h2>

            <Summary errors={errors} timesheetResult={timesheetResult} setValue={setValue} control={control} register={register} setSelectedWeekIndex={setSelectedWeekIndex} selectedWeekIndex={selectedWeekIndex} />
          </div>

          

            {timesheetResult.summary?.map((week, weekIndex) => {

              if (!week) return null;

              return (
                <TimesheetWeek isFixing={isFixing} viewOnly={finalViewOnly} shouldDisableButtons={shouldDisableButtons} shouldShowButtons={shouldShowButtons()} setEntryLoading={setEntryLoading} setEditingNoteInputName={setEditingNoteInputName} unregister={unregister} timesheetResult={timesheetResult} setTimesheetResult={setTimesheetResult} key={weekIndex} timesheetID={id} selectedPeriod={selectedPeriod} selectedWeekIndex={selectedWeekIndex} weekIndex={weekIndex} week={week} getValues={getValues} control={control} register={register} projectsResult={projectsResult} trigger={trigger} errors={errors} setValue={setValue} resetField={resetField} projectsLoading={projectsLoading} />
              )

            })}
          
          

          {/* <div className="section">
            <div className="button" onClick={() => trigger()}>TRIGGER</div>
          </div> */}

        </>
      }
      </form>
    </>
  )
}




const mapState = state => ({
  currentCompany: state.authState.currentCompany,
  currentUser: state.authState.currentUser,
});

export default connect(mapState)(AlphaTimesheet);