import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import AppMaint from './AppMaint';
import * as serviceWorker from './serviceWorker';

import { Provider } from "react-redux";
import store from './redux/store'


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>
  // <Provider store={store}>
  //   <App />
  // </Provider>

  <>
    {process.env.REACT_APP_MAINT_ON === "true" &&
      <AppMaint />
    }
    {process.env.REACT_APP_MAINT_ON !== "true" &&
      <Provider store={store}>
        <App />
      </Provider>
    }
  </>,
);


// ReactDOM.render(
//   // <React.StrictMode>
//     <>
//       {/* {process.env.REACT_APP_MAINT_ON === "true" &&
//         <AppMaint />
//       } */}
//       {/* {process.env.REACT_APP_MAINT_ON !== "true" && */}
//         <Provider store={store}>
//           <App />
//         </Provider>
//       {/* } */}
//     </>,
//   // </React.StrictMode>,
//   document.getElementById('root')
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
