
import React from "react";


const CheckBoxes = ({disabled=false, isCheckAll=false, checkAllChecked=false, boxes=[], setBoxes, name, label, count}) => {

  const isSelected = () => {
    if (checkAllChecked === true) return true;
    return boxes.some(box => box === name)
  }

  const checkBoxClicked = () => {
    // if (isCheckAll) 

    if (disabled === true) return;


    setBoxes(o => {
      if (o.includes(name)) {
        return o.filter(v => v !== name);
      }
      else {
        return [...o, name];
      }
    });
  }

  const isChecked = () => {
    return boxes.includes(name)
  }


  return(
    <div onClick={checkBoxClicked}  className={`${disabled ? 'disabled' : ''} check-box-cont`}>
      
      <div className="flex-cont align-center">
        <div className={`${disabled ? 'disabled' : ''} ${isChecked() ? 'selected' : ''} check-box margin-right-half`}>
          <div className="check"></div>
        </div>
        <div className="check-box-label">{label}</div>

        {/* <div>
          {JSON.stringify(disabled, null, 2)}
        </div> */}
        {/* {(!!count) &&
          <div className="unread-count margin-left-half">
            <div className="number">{count} </div>
          </div>
        }
         */}
      </div>
    </div>
  )

}

export default CheckBoxes;