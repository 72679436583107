const initialState = {
  formTeam: null,
};

const teamReducer = ( state = initialState, action) => {
  switch(action.type) {
    case "SET_FORM_TEAM":
      return {
        ...state,
        formTeam: action.formTeam
      }
    default :
      return state
  }
}

export default teamReducer;