import React, { useState, useEffect, useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import { DateTime, Info } from 'luxon';
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import store from '../../redux/store';
// import { updateEntry, addEntry, api, getTimesheet, getProjects, getPhases, getCategories, submitEntry, setUnsavedEntries } from './actions';
import { getTimesheetSummary, setCurrentTimesheetSummary, addSavingEntryId, removeSavingEntryId } from '../../redux/timesheets/timesheetActions';
import { createEntry, addEntry, changeEntry, saveNote } from '../../redux/entries/entryActions';
import { useApi } from '../../api';

import Modal from '../../components/Modal';
import { ErrorMessage } from '@hookform/error-message';
import { useWatch } from "react-hook-form";
import {SubactivitySelect, ActivitySelect, ProjectSelect} from './ActivitySelect';
import ProjectSelect3 from './ProjectSelect3';
import ActivitySelect3 from './ActivitySelect3';
import SubactivitySelect3 from './SubactivitySelect3';

let count = 0;
// wed: yup.number().transform((cv, ov) => ov === '' ? undefined : cv).max(999).matches().nullable().positive().label('Wednesday hours').typeError(' Wednesday hours must be a number'),

// .test(`${day}-no-spaces`, `Remove spaces from ${day[0].toUpperCase() + day.substring(1)}. hours.`, v => !v.includes(' '))
// .test(`${day}-is-number`, `${day[0].toUpperCase() + day.substring(1)}. hours must be a number.`, value => testIfNumber(value))
// .test(`${day}-is-positive`, `${day[0].toUpperCase() + day.substring(1)}. hours must be greater than or equal to 0.`, val =>  !val || val >= 0)
// .matches(/^(\d+(\.\d{0,2})?|\.?\d{1,2})$/, {excludeEmptyString: true, message: `${day[0].toUpperCase() + day.substring(1)}. hours must not have more than two decimal places.`})
// .test(`${day}-is-less-than-999`, `${day[0].toUpperCase() + day.substring(1)}. must be less than 1000 hours.`, (value => !value || parseFloat(value) < 1000))


const testIfNumber = (value) => {
  // if (!value) return false
  // // alert(value);
  // const s = value.toString();


  // if (s === "" || s.includes(" ")) {
  //   return false;
  // }

  return !isNaN(value);
}

const validPlaces = (value) => {
 
  // const regex = /^(\d+(\.\d{0,2})?|\.?\d{1,2})$/g;
 
  const regex = /^\s*-?(\d+(\.\d{1,2})?|\.\d{1,2})\s*$/;
  const found = value.match(regex);

  return (found && found.length > 0)
}

const dayValidation = (day, hide) =>  {

  // return "Green"

  // return {
  //   andy: "simon"
  // }


      // return (v && true) || `Please enter a number for ${day}`
      // return !v.includes(' ') || `Remove spaces from ${day}`
      // return testIfNumber(v) || `Please enter a number for ${day}`
      // return validPlaces(v) || `${day} should be a number with no more than 2 decimal places`
      // return parseFloat(v) >= 0 || `${day} should be a positive number`
      // return parseFloat(v) < 1000 || 'should be less than 1000'

  if (hide) {
    return null
  } 
  

  return {
    present: v => {

      if (v === null || v === undefined || v === "") {
        return `Please enter a number ${JSON.stringify(v)} for ${day}`
      }

      return true;
      // return (v && true) || `Please enter a number for ${day}`
    },
    noSpaces: v => {
      return !v.includes(' ') || `Remove spaces from ${day}`
    },
    isNumber: v => {
      return testIfNumber(v) || `Please enter a number for ${day}`
    },
    
    places: v => {
      return validPlaces(v) || `${day} should be a number with no more than 2 decimal places`
    },
    
    lessThanThousand: v => parseFloat(v) < 1000 || 'should be less than 1000',
    twentyFive: v => {
      return ((parseFloat(v) * 100) % 25 === 0) || "Please enter a number in 0.25 increments."
    }

    // positive: v => parseFloat(v) >= 0 || `${day} should be a positive number`,

    // if ((parseFloat(vv) * 100) % 25 !== 0) return "Enter a number in 0.25 increments."
  }
}



const NewEntry3 = ({selectedPeriodIndex, unregister, cancelClicked, setError, control, watch, periodIndex, weekInputName, entryIndex, weekIndex,  formState, register, handleSubmit, errors, getValues, trigger, reset, clearErrors, setValue, updateLineError, totalPeriodHours, isLast, setNewLineOpened, readOnly, fetchTimesheetWeeks, fetchEntries, projectsLoading, projects, phases, categories, timesheet, andyEntry, timesheetId, index, selectedTimesheetWeek, toggleRemoveLineModal}) => {

  const inputName = `${weekInputName}.entries.${entryIndex}`
  const myErrors = errors?.['weeks']?.[periodIndex]?.['entries']?.[entryIndex];
  const [sumHours, setSumHours] = useState(0.0);
  const [saveDisabled, setSaveDisabled] = useState(false);
  
  const errorArray = () => {

    const myErrors = errors?.['weeks']?.[periodIndex]?.['entries']?.[entryIndex];

    if (!myErrors) return [];


    const {hours, ...notHours} = myErrors;
    let hourErrors = [];

    if (hours) {
      hourErrors = Object.values(hours).map(v => {
        return (v.message);
      });
    }

    let others = [];

    if (notHours) {
      others = Object.values(notHours).map(v => {
        return (v.message);
      });
    }


    return [...hourErrors, ...others];
 
  }


  const periodDays = () => {
    let days = [];
    let count = 1;
    let weekStart = DateTime.fromISO(selectedTimesheetWeek.weekStart);
    let weekEnd = DateTime.fromISO(selectedTimesheetWeek.weekEnd)
    let currentDay = weekStart;
    
    while (currentDay <= weekEnd) {
      days.push([`${currentDay.toFormat('EEE').toUpperCase()}`, currentDay, count]);
      currentDay = currentDay.plus({day: 1});
      count += 1;
    }

    return days;
  }
  const [subactivities, setSubactivities] = useState(null);
  const [activities, setActivities] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [selectedSubactivity, setSelectedSubactivity] = useState(null);
  const [existingEntry, setExistingEntry] = useState(andyEntry);



  useEffect(() => {
    if (selectedTimesheetWeek && selectedTimesheetWeek.timesheetEntries) {
      setExistingEntry(selectedTimesheetWeek.timesheetEntries[index]);
    }
  }, [selectedTimesheetWeek]);

  const [responseText, setResponseText] = useState("");
  const [entryCreateResult, entryCreateLoading, entryCreateLoaded, entryCreateError, doEntryCreateFetch, setEntryCreateResult, pagy] = useApi();


  const [summaryResult, summaryLoading, summaryLoaded, summaryError, doSummaryFetch, setSummaryResult] = useApi();  
  const fetchSummary = () => {
    doSummaryFetch(getTimesheetSummary, {query: {id: timesheetId}});
  }
  useEffect(() => {
    if (summaryLoaded && summaryResult) {
      store.dispatch(setCurrentTimesheetSummary(summaryResult));
    }
  }, [summaryLoaded]);

  useEffect(() => {
    if (entryCreateLoaded) {
      setNoteOpened(false);

      

      if (entryCreateResult) {
        if (!existingEntry) {
          store.dispatch(addEntry(entryCreateResult.timesheetEntries));
          if (setNewLineOpened) {
            // reset(inputName)
            unregister(inputName)
            setNewLineOpened(false)
          }
        }

      }


    }
  }, [entryCreateLoaded]);
  

  useEffect(() => {
    if (!existingEntry) return;

    if (entryCreateLoading) {
      store.dispatch(addSavingEntryId(existingEntry.id))
    }
  }, [entryCreateLoading]);





  const [expanded, setExpanded] = useState(!existingEntry);

  const statusText = () => {
    let r = "";
    if (!existingEntry) {
      r = "";
    }
    else if (existingEntry.statusid === 0) {
      r = "DRAFT";
    }
    
    else if (existingEntry.statusid === 1) {
      r = "SUBMITTED";
    }
    else if (existingEntry.statusid === 2) {
      r = "APPROVED";
    }
    else if (existingEntry.statusid === 3) {
      r = "RETURNED";
    }
    else if (existingEntry.statusid === 4) {
      r = "COPIED";
    }

    return r;
  }

  const rowClassName = () => {
    if (!existingEntry) {
      return "row entry-cont new"
    }

    if (existingEntry.statusid === 0) {
      return "row entry-cont created"
    }
    if (existingEntry.statusid === 1) {
      return "row entry-cont created"
    }
    if (existingEntry.statusid === 2) {
      return "row entry-cont submitted"
    }
    if (existingEntry.statusid === 3) {
      return "row entry-cont returned"
    }

    if (existingEntry.statusid === 5) {
      return "row entry-cont posted"
    }

    if (existingEntry.statusid === 6) {
      return "row entry-cont posting failed"
    }


  }

  const isDisabled = () => {
    if (readOnly) {
      return true;
    }
    if (entryCreateLoading) {
      return true;
    }
    if (!existingEntry) {
      return false;
    }
    if (existingEntry.statusid === 1 || existingEntry.statusid === 2 || existingEntry.statusid === 5 || existingEntry.statusid === 6) {
      return true;
    }
  }

  const selectsDisabled = () => {
    if (!existingEntry) {
      return false;
    }

    if (isDisabled()) {
      return true;
    }
  }

  const [noteSaveResult, noteSaveLoading, noteSaveLoaded, noteSaveError, doNoteSaveFetch, setNoteSaveResult] = useApi();
  const [note, setNote] = useState(null);
  const [noteOpened, setNoteOpened] = useState(false);
  const [noteLabel, setNoteLabel] = useState('');

  const pencilClicked = (i) => {
    setNoteLabel(`day${i+1}note`);
    setNoteOpened(true);
    setNote(existingEntry ? existingEntry[`day${i+1}note`] : getValues()[`day${i+1}note`])
  }

  const toggleNoteModal = () => {
    setNoteOpened(o => !o);
  }

  const trySaveNote = () => {
    if (existingEntry) {
      const payload = {
        id: existingEntry?.id,
        timesheet_entry: {
          [noteLabel]: note
        }
      }
      doNoteSaveFetch(saveNote, {payload: payload});
      setValue(`${inputName}.${noteLabel}`, note);
    }
    else {
      setValue(`${inputName}.${noteLabel}`, note);
      toggleNoteModal();
    }
  }

  useEffect(() => {
    if (noteSaveLoaded) {
      if (noteSaveResult) {

        store.dispatch(changeEntry(noteSaveResult.timesheetEntry));
        setExistingEntry(noteSaveResult.timesheetEntry);
        setNote(null);
        toggleNoteModal();
        

        
      }
    }
  }, [noteSaveLoaded]);


  const totalWatchedHours = (hourObj) => {
    if (!hourObj) return 0.0;

    const h = Object.values(hourObj);
    
    let total = 0.0;
    if (!h) {
      total = 0.0;
    }
    else {
      h.forEach(hour => {
        // total = total + parseFloat(hour)
        const pf = parseFloat(hour) ;
        total = total + (!pf || isNaN(pf) ? 0 : pf)
        
      })
    }

    if (isNaN(total)) {
      total = 0
    }

    return total;
  }

  const hoursHash = () => {
    let h = {}

    periodDays().forEach((a, i) => {
      h[a[0]] = existingEntry ? existingEntry[`day${i+1}hour`] : 0.0
      return true
    })

    return h
  }

  const hoursArray = () => {
    let h = []

    periodDays().forEach((a, i) => {
      const v = existingEntry ? existingEntry[`day${i+1}hour`] : 0.0;
      h.push(v)
    })

    return h
  }

  useEffect(() => {
    if (existingEntry) {
      setValue(`${inputName}.th`, existingEntry.totalPeriodHours, {shouldValidate: false});

    }
    else {
    }

  }, [existingEntry]);

  const www = useWatch({
    control,
    name: [`${inputName}.hours.MON`, `${inputName}.hours.TUE`, `${inputName}.hours.WED`, `${inputName}.hours.THU`, `${inputName}.hours.FRI`, `${inputName}.hours.SAT`, `${inputName}.hours.SUN`],
    defaultValue: hoursArray,
    // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
  });

  const hasValues = () => {
    if (!www) return false
    if (www.length === 0) return false
    if (www.filter(e => e !== undefined).length === 0) return false

    return true
  }

  const sum = () => {
    if (!hasValues()) return false;

    let total = 0;

    www.forEach(n => {
      if (n) {
        const pf = parseFloat(n) ;
        total = total + (!pf || isNaN(pf) ? 0 : pf)
      }
    })

    return total;
  }

  const watchProjectId = useWatch({
    control,
    name: `${inputName}.projectid`,
    defaultValue: ""
  })


  const [hide, setHide] = useState(false);
  const watchDestroy = useWatch({
    control,
    name: `${inputName}._destroy`,
    defaultValue: "0"
  })

  useEffect(() => {
    if (watchDestroy === "1") {
      setHide(true)
    }
  }, [watchDestroy])

  const checkDupes = () => {
    const values = getValues();
    if (!values?.weeks) return false;

    const week = values.weeks[selectedPeriodIndex];
    let found = false;
    const entries = week.entries;

    if (!entries) {
      return false;
    }

    const newEntry = values.weeks[selectedPeriodIndex].entries[0];
    const myString = `${newEntry.selectedProject} ${newEntry.selectedActivity} ${newEntry.selectedSubactivity}`;


    
    const array = entries.filter(v => v._destroy !== "1")
      .map(v => `${v.selectedProject} ${v.selectedActivity} ${v.selectedSubactivity}`)
      .filter(v => (v._destroy !== "1" && v === myString));


    // const uniqueValues = new Set(entries.map(v => `${v.selectedProject} ${v.selectedActivity} ${v.selectedSubactivity}`));
    if (array.length > 1) {
      
      
      
      found = true;
      return true;
    }
    
    

    return found;
  }

  const [dupesFound, setDupesFound] = useState(false);

  const saveNewClicked = async (override) => {

    // setCancelled(false);
    // setValue(`weeks.${periodIndex}.entries.0.th`, sum(www))
    // alert(override)
    if (checkDupes() && !override) {

      
      setDupesFound(true);
    }
    else {
      setDupesFound(false);
      const t = await trigger([`weeks.${periodIndex}.entries.0.selectedProject`, `weeks.${periodIndex}.entries.0.selectedActivity`, `weeks.${periodIndex}.entries.0.selectedSubactivity`]);

      // alert('here')

        if (!t) return
        // let e = formState.errors?.['weeks']?.[periodIndex]?.['entries']?.[entryIndex];



        // // alert(JSON.stringify(Object.keys(e)?.length, null, 2))
        // if (e && Object.keys(e)?.length !== 0) {
        //   return
        // }

        const data = getValues(`weeks.${periodIndex}.entries.${entryIndex}`);


        
        const payload = {
          timesheet_entry: {
            ...data
          }
        }

        doEntryCreateFetch(createEntry, {payload: payload});
    }



    
  };

  const toggleOverrideModal = () => {
    setDupesFound(false);
  }
  
  const [p, setP] = useState(null);

  useEffect(() => {
    if (projects ) {
      setP(
        [

          {
            id: "Test id",
            name: "Test name"
          }
        ]
      );
      // 
    }
  }, [projects]);

  return (
    <div className={`inline-blockx ${hide && 'line-hidden'}`} key={existingEntry?.id || inputName}>
     
     <div onClick={() => alert(JSON.stringify(getValues(inputName), null, 2))} className="button"></div>
      {dupesFound &&
        <Modal focusTrap={false} size="small" toggleModal={toggleOverrideModal}>
          <div className='modal-header flex-cont align-center'>
            {/* <h1 className="flex-1">Submit Timesheet</h1> */}
            <div className="flex-1"></div>
            <div onClick={toggleOverrideModal} className="ghost button close">
              <i className="fas fa-times"></i>
            </div>
          </div>

          <div className="padding-1">


            <div>
              This project/milestone/task already exists for this week. Are you sure you want to continue?
            </div>
            

          </div>

          <div className='modal-footer flex-cont align-center'>
            <div onClick={() => saveNewClicked(true)} className="button">Yes, continue</div>
            <div className="flex-1"></div>
            <div onClick={toggleOverrideModal} className="ghost button">Cancel</div>
            
            
          </div>
        </Modal>
      }


      {noteOpened &&
        <Modal focusTrap={!isDisabled()} toggleModal={toggleNoteModal}>
          <div className='modal-header flex-cont align-center'>
            <div className="flex-1">
              <h1 className="margin-bottom-1">Note</h1>
            </div>
            <div onClick={toggleNoteModal} className="button close ghost">
              <i className="fas fa-times"></i>
            </div>
          </div>
          <div className="padding-1">
            

            {isDisabled() && 
              <div>
                <textarea disabled={isDisabled()} value={existingEntry ? existingEntry[noteLabel] : getValues()[noteLabel]} />
              </div>
            }
            {!isDisabled() && 
              <textarea value={note} onChange={e => setNote(e.target.value)} defaultValue={existingEntry ? existingEntry[noteLabel] : getValues()[noteLabel]} />
            }
          </div>

          <div className="modal-footer align-center flex-cont">
            <div onClick={toggleNoteModal} className="ghost button margin-right-1">Close</div>

            {!isDisabled() && 
              <div onClick={trySaveNote} className={`button margin-right-1 ${noteSaveLoading ? 'disabled' : ''}`}>Save</div>
            }
            {noteSaveLoading &&
              <div className="spinner"></div>
            }
          </div>
        </Modal>

        
      }

    <div className={rowClassName()}>


        <div className="flex-cont align-items-end">
          {/* <div>
            {existingEntry?.id}
          </div> */}

          {existingEntry && 
            <div style={{marginBottom: '.5em'}} className={`flex-cont align-center margin-right-half font-075 status-badge-cont ${existingEntry?.status?.toLowerCase()}`}>
              <div className="status-badge-shell">
                <div className="status-badge">
                  {existingEntry?.status?.toUpperCase()}
                </div>
              </div>
            </div>
          }
          {!existingEntry &&
            <div style={{marginBottom: '.5em'}} className={`flex-cont align-center margin-right-half font-075 status-badge-cont ${existingEntry?.status?.toLowerCase()}`}>
              <div className="status-badge-shell">
                <div className="status-badge">
                  NEW
                </div>
              </div>
            </div>
          }



            <ProjectSelect3 getValues={getValues} projectsLoading={projectsLoading} setValue={setValue} hide={hide} disabled={selectsDisabled()} myErrors={myErrors} selectedProject={selectedProject} setSelectedProject={setSelectedProject} selectedActivity={selectedActivity} setSelectedActivity={setSelectedActivity}  setSelectedSubactivity={setSelectedSubactivity} projects={p} register={register} inputName={inputName} existingEntry={existingEntry}   /> 

            <ActivitySelect3  watchProjectId={watchProjectId} projectsLoading={projectsLoading} setValue={setValue} hide={hide} disabled={selectsDisabled()} myErrors={myErrors} setSelectedProject={setSelectedProject} setSelectedActivity={setSelectedActivity}  setSelectedSubactivity={setSelectedSubactivity} selectedActivity={selectedActivity} selectedProject={selectedProject} projects={p} register={register} inputName={inputName} existingEntry={existingEntry}   /> 

            <SubactivitySelect3 setSelectedSubactivity={setSelectedSubactivity} selectedSubactivity={selectedSubactivity} projectsLoading={projectsLoading} setValue={setValue} hide={hide} disabled={selectsDisabled()} myErrors={myErrors} selectedActivity={selectedActivity} selectedProject={selectedProject} projects={p} register={register} inputName={inputName} existingEntry={existingEntry}   /> 
            

          <input  {...register(`${inputName}.id`)} type="hidden" name={`${inputName}.id`} defaultValue={existingEntry ? existingEntry.id : null} />
          <input  {...register(`${inputName}._destroy`)} type="hidden" name={`${inputName}._destroy`} defaultValue={"0"} />
          <input  {...register(`${inputName}.timesheetid`)} type="hidden" name={`${inputName}.timesheetid`} defaultValue={timesheetId} />
          <input  {...register(`${inputName}.weekStart`)} type="hidden" name={`${inputName}.weekStart`} defaultValue={selectedTimesheetWeek.weekStart} />
          <input  {...register(`${inputName}.weekEnd`)} type="hidden" name={`${inputName}.weekEnd`} defaultValue={selectedTimesheetWeek.weekEnd} />

          {existingEntry && 
            <>
              <input  {...register(`${inputName}.day1note`)} type="hidden" name={`${inputName}.day1note`} defaultValue={existingEntry?.day1note} />
              <input  {...register(`${inputName}.day2note`)} type="hidden" name={`${inputName}.day2note`} defaultValue={existingEntry?.day2note} />
              <input  {...register(`${inputName}.day3note`)} type="hidden" name={`${inputName}.day3note`} defaultValue={existingEntry?.day3note} />
              <input  {...register(`${inputName}.day4note`)} type="hidden" name={`${inputName}.day4note`} defaultValue={existingEntry?.day4note} />
              <input  {...register(`${inputName}.day5note`)} type="hidden" name={`${inputName}.day5note`} defaultValue={existingEntry?.day5note} />
              <input  {...register(`${inputName}.day6note`)} type="hidden" name={`${inputName}.day6note`} defaultValue={existingEntry?.day6note} />
              <input  {...register(`${inputName}.day7note`)} type="hidden" name={`${inputName}.day7note`} defaultValue={existingEntry?.day7note} />
              <input  {...register(`${inputName}.day8note`)} type="hidden" name={`${inputName}.day8note`} defaultValue={existingEntry?.day8note} />
            </>
          }
          {!existingEntry &&
            <>
              <input  {...register(`${inputName}.day1note`)} type="hidden" name={`${inputName}.day1note`}  />
              <input  {...register(`${inputName}.day2note`)} type="hidden" name={`${inputName}.day2note`}  />
              <input  {...register(`${inputName}.day3note`)} type="hidden" name={`${inputName}.day3note`}  />
              <input  {...register(`${inputName}.day4note`)} type="hidden" name={`${inputName}.day4note`}  />
              <input  {...register(`${inputName}.day5note`)} type="hidden" name={`${inputName}.day5note`}  />
              <input  {...register(`${inputName}.day6note`)} type="hidden" name={`${inputName}.day6note`}  />
              <input  {...register(`${inputName}.day6note`)} type="hidden" name={`${inputName}.day6note`}  />
              <input  {...register(`${inputName}.day8note`)} type="hidden" name={`${inputName}.day8note`}  />
            </>
          }




            <div className="flex-cont align-centerx">
              {periodDays() && periodDays().map((a, i) => {

                const day = a[1];
                const label = a[0];

                if (!day) {
                  return(
                    <>
                      INVALID
                    </>
                  )
                }
                return (
                  <>
                  {existingEntry ? (
                      <label key={i} className={myErrors?.hours?.[label] ? 'error-label margin-right-4px' : 'margin-right-4px'}>
                        {/* <div  onClick={() => pencilClicked(i)} className="flex-cont align-center">
                          <div className="flex-1 label-text">{`${day.toFormat('EEE')}`}</div>
                          <i className="pencil fas fa-pencil-alt"></i>
                        </div> */}

                        {/* <div>{JSON.stringify(myErrors?.hours?.[label], null, 2)}</div> */}
                        {/* <div>{entryIndex}</div> */}
                        {entryIndex === 1 &&
                          <div style={{
                            color: 'gray',
                            marginBottom: '1em',
                            fontWeight: 'bold',
                            width: '5px'
                          }}>
                            {day?.toLocaleString({month: '2-digit', day: '2-digit', year: '2-digit' })} 
                          </div> 
                        }


                        <div className="relative flex-cont align-centerx">

                          
                          <div className="flex-1 label-text">{`${day.toFormat('EEE')}`}</div>
                          {/* <div>{`Test: ${existingEntry[`day${i+1}note`] ? 'note-present' : ''}`}</div> */}
              
                          <i onClick={() => pencilClicked(i)} className={`${existingEntry[`day${i+1}note`] ? 'note-present' : ''} pencil fas fa-pencil-alt`}></i>
                  
                        </div>

      
      
                        

                        {/* <div>{existingEntry ? existingEntry[`day${day.weekday}hour`] : 99}</div> */}
                        <input tabIndex={i+1}  defaultValue={existingEntry ? existingEntry[`day${i+1}hour`] : 0.0}  
                        {...register(`${inputName}.hours.${label}`, {
                          validate: dayValidation(label, hide)
                        })} name={`${inputName}.hours.${label}`} className={`hour-input ${isDisabled() ? 'disabled-input' : ''}`} type="text" />

                        <input  {...register(`${inputName}.dates[${i}]`)} type="hidden" name={`${inputName}.dates[${i}]`} value={day.toISODate()} />
                        
                      </label>
                    ) : (
                      <label  key={i} className={errors && errors.hours && errors.hours[`${label}`] ? 'error-label margin-right-4px' : 'margin-right-4px'}>

                        <div onClick={() => pencilClicked(i)} className="relative flex-cont align-centerx">
                          <div className="flex-1 label-text">{`${day.toFormat('EEE')}`}</div>
                          <i className="pencil fas fa-pencil-alt"></i>
                        </div>
                        
                        
         

                        {/* <div>{existingEntry ? existingEntry[`day${day.weekday}hour`] : 99}</div> */}
                        <input   defaultValue={0.0}  {...register(`${inputName}.hours.${label}`, {
                          validate: dayValidation(label, hide)
                          
                        })} name={`${inputName}.hours.${label}`} className="hour-input" type="text" />
                        <input  {...register(`${inputName}.dates[${i}]`)} type="hidden" name={`${inputName}.dates[${i}]`} value={day.toISODate()} />
                        
                      </label>
                    )
                    }

                  </>
                );
              })}
            </div>
            <label className="center">
              <div style={{marginBottom: '4px'}} className="label-text">Hours</div>
              <div className="dummy-input bold">
                {/* {sum(www) || (existingEntry ? existingEntry.totalPeriodHours : 0)} */}

                {hasValues() && sum(www)}
                {!hasValues() && (existingEntry ? existingEntry.totalPeriodHours : 0)}
                
                {/* {sum(www) && sum(www)} */}
                {/* {www && sum(www)}
                {!www && (existingEntry ? existingEntry.totalPeriodHours : 'help')} */}
              </div>   
              <input name={`${inputName}.th`} {...register(`${inputName}.th`)} type="hidden" defaultValue={existingEntry ? existingEntry.totalPeriodHours : 0} 
              />
              
            </label>

            {/* <div className="flex-1"></div> */}
          <div className="flex-cont align-centerx">
            
          
            {entryCreateLoading ? (
              <div style={{fontSize: '.75em'}} className="entry-button margin-left-1 margin-right-1 spinner"></div>
            ) : (
              <div>
                
              </div>
            )
                
            }
            {!isDisabled() && !readOnly &&
            <>

              
              {/* <div>
                {existingEntry?.createdDate}
              </div> */}
              {false && <button tabIndex={20} disabled={entryCreateLoading} type="submit" className={`${(entryCreateLoading || (errorArray() && errorArray().length !== 0)) ? "disabled ghost button margin-right-4px margin-left-4px" : "button ghost margin-right-4px margin-left-4px"}`} >
                {/* <i className="no-margin big fas fa-save"></i> */}
                {/* {existingEntry ? "UPDATE ENTRY" : "SAVE NEW ENTRY"} */}
                SAVE
              </button>}

              {existingEntry &&
                <div onClick={() => toggleRemoveLineModal(existingEntry.id, inputName) } className={`entry-button circle button margin-left-1 ${entryCreateLoading ? 'disabled' : ''}`}>
                  <i className="no-margin big fas fa-times"></i>
                  {/* DELETE */}
                </div>
              }
              {!existingEntry &&
                <>
                <div className="flex-cont align-center">
                
                <div onClick={() => saveNewClicked(false)} tabIndex={20} disabled={entryCreateLoading} className={`${(saveDisabled || entryCreateLoading || (errorArray() && errorArray().length !== 0)) ? "entry-buttonx disabled button margin-right-0 margin-left-1" : "margin-left-1 button margin-right-0"}`} >
                  {/* <i className="no-margin big fas fa-save"></i> */}
                  {/* {existingEntry ? "UPDATE ENTRY" : "SAVE NEW ENTRY"} */}
                  ADD
                </div>
                <div onClick={cancelClicked} className={`ghost entry-buttonx margin-top-1x margin-left-05 button circlex ${entryCreateLoading ? 'disabled' : ''}`}>
                  {/* <i className="no-margin big fas fa-times"></i> */}
                  {/* DELETE */}
                  CANCEL
                </div>
                </div>
                </>
              }
              </>
            }
          </div>
            
        
        </div>



      {existingEntry && existingEntry.returnnote && existingEntry.statusid !== 2 &&
        <div style={{color: 'darkred'}}>
          <strong>MANAGER'S NOTE: </strong>
          {existingEntry.returnnote}
        </div>
      }

      
      

      
    </div>

    {/* <div>Errors: {errors}</div> */}
    {/* <div>{JSON.stringify(errorArray())}</div> */}
{/* 
    <ErrorMessage
      errors={errors}
      name="singleErrorInput"
      render={({ message }) => <p>{message}</p>}
    />
   */}
    {errorArray() && 
      <ol className="error-cont">
        {errorArray().map(e => 
          <li>{e}</li>
        
        )}
      </ol>
    }
    </div>
  );
};

const mapState = state => ({

});

export default connect(mapState)(NewEntry3);
