import React from 'react';
import Modal from '../../components/Modal';

const TimesheetSubmitModal = ({submitLoading, submitNote, setSubmitNote, toggleSubmitModal, hasErrors, dupesFound, setDupesFound, tryTimesheetSubmit}) => {


  return (
    <>
  {dupesFound && !hasErrors() &&
    <Modal focusTrap={false} size="small" toggleModal={toggleSubmitModal}>
      <div className='modal-header flex-cont align-center'>
        {/* <h1 className="flex-1">Submit Timesheet</h1> */}
        <h2 className="flex-1">Duplicate Projects</h2>
        <div className="flex-1"></div>
        <div onClick={toggleSubmitModal} className="ghost button close">
          <i className="fas fa-times"></i>
        </div>
      </div>

      <div className="padding-1">
        <div>
          You have one or more duplicate projects in a week. Are you sure you want to continue?
        </div>
        

      </div>

      <div className='modal-footer flex-cont align-center'>
        
        <div onClick={() => setDupesFound(false)} className="button">Yes, continue</div>
        <div className="flex-1"></div>
        <div onClick={toggleSubmitModal} className="ghost button">Cancel</div>
      </div>
    </Modal>
  }
  {hasErrors() &&
    <Modal focusTrap={false} size="small" toggleModal={toggleSubmitModal}>
      <div className='modal-header flex-cont align-center'>
        {/* <h1 className="flex-1">Submit Timesheet</h1> */}
        <div className="flex-1"></div>
        <div onClick={toggleSubmitModal} className="ghost button close">
          <i className="fas fa-times"></i>
        </div>
      </div>

      <div className="padding-1">

        {hasErrors() &&
          <div>
            There are some issues with your timesheet. Please fix the highlighted issues and submit again.
          </div>
        }

      </div>

      <div className='modal-footer flex-cont align-center'>

      </div>
    </Modal>

  }
  {!hasErrors() && !dupesFound &&
    <Modal canClose={!submitLoading} focusTrap={false} toggleModal={toggleSubmitModal}>
      <div className='modal-header flex-cont align-center'>
        <h2 className="flex-1">Submit Timesheet</h2>
        <div onClick={toggleSubmitModal} className="ghost button close">
          <i className="fas fa-times"></i>
        </div>
      </div>

      <div className="padding-1">

        <textarea value={submitNote} onChange={e => setSubmitNote(e.target.value)} placeholder="Submission notes">

        </textarea>
      </div>

      <div className='modal-footer flex-cont align-center'>
        <div onClick={tryTimesheetSubmit} className={`${submitLoading ? 'disabled' : ''} margin-right-1 button`}>
          Submit
          {submitLoading &&
            <div className="spinner button-spinner"></div>
          }
        </div>
        <div className="flex-1"></div>
        <div onClick={toggleSubmitModal} className={`${submitLoading ? 'disabled' : ''} button ghost`}>Cancel</div>
      </div>
    </Modal>

  }

    </>
  )
}

export default TimesheetSubmitModal;





