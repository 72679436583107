import React, {useState, useEffect} from "react";
import { getMilestonesAlpha, getTasksAlpha, deleteEntry } from "../../redux/alphaTimesheets/alphaTimesheetActions";
import { useApi } from '../../api';
import { useWatch } from 'react-hook-form';
import AutoComplete from "../../components/AutoComplete";
import TimesheetEntryDay from './TimesheetEntryDay';
import TimesheetEntryTotal from './TimesheetEntryTotal';
import Modal from "../../components/Modal";


const toNum = str => {
  if (str === undefined || str === null || str === "") {
    return 0.0
  }
  const parsed = parseFloat(str);
  if (!parsed || isNaN(parsed)) return 0.0;

  return parsed;
}


const TimesheetEntry = ({isFixing, setTimesheetResult, viewOnly, entryLoading, timesheetID, week=null, resetField, trigger, errors, setValue, getValues, control, projectsLoading, projectsResult, selectedWeekIndex, weekIndex, entry, register, entryIndex}) => {
  const [milestonesResult, milestonesLoading, milestonesLoaded, milestonesError, doMilestonesFetch, setMilestonesResult] = useApi();
  const [tasksResult, tasksLoading, tasksLoaded, tasksError, doTasksFetch, setTasksResult] = useApi();
  const [fullyPaid, setFullyPaid] = useState(entry?.milestone_fully_paid);
  const inputName = `week.${weekIndex}.entries.${entryIndex}`;

  const [hide, setHide] = useState(false);
  const watchDestroy = useWatch({
    control,
    name: `${inputName}._destroy`,
    defaultValue: "0"
  })

  useEffect(() => {
    if (watchDestroy === "1") {
      setHide(true)
    }
  }, [watchDestroy]);

  const newEntryValues = {
    id: null,
    _destroy: "0",
    projectid: "",
    activitynumber: "",
    subactivitynum: "",
    day1note: "",
    day1: "2023-05-01",
    day1hour: "",
    day2note: "",
    day2: "2023-05-02",
    day2hour: "",
    day3note: "",
    day3: "2023-05-03",
    day3hour: "",
    day4note: "",
    day4: "2023-05-04",
    day4hour: "",
    day5note: "",
    day5: "2023-05-05",
    day5hour: "",
    day6note: "",
    day6: "2023-05-06",
    day6hour: "",
    day7note: "",
    day7: "2023-05-07",
    day7hour: "",
    total_entry_hours: 0
  }

  const currentProjectId = useWatch({
    control,
    name: `week.${weekIndex}.entries.${entryIndex}.projectid`,
    defaultValue: entry?.project?.projectid
  });

  const currentMilestoneId = useWatch({
    control,
    name: `week.${weekIndex}.entries.${entryIndex}.activitynumber`,
    defaultValue: entry?.activitynumber
  });

  const currentMilestoneHasTasksWatch = useWatch({
    control,
    name: `week.${weekIndex}.entries.${entryIndex}.milestone_has_tasks`,
    defaultValue: entry?.milestone_has_tasks
  });

  const currentMilestoneHasTasks = () => {
    if (currentMilestoneId == null) return false;

    if (currentMilestoneHasTasksWatch == null || currentMilestoneHasTasksWatch.toString() !== "true") {
      return false;
    }

    return true;
  }


  const entryWatch = useWatch({
    control,
    name: `week.${weekIndex}.entries.${entryIndex}`,
    defaultValue: entry ? entry : newEntryValues
  });

  const fetchMilestones = projectid => {
    doMilestonesFetch(getMilestonesAlpha, {query: {projectid: projectid}});
  }

  const fetchTasks = activitynumber => {
    doTasksFetch(getTasksAlpha, {query: {projectid: currentProjectId, activitynumber: currentMilestoneId}});
  }

  const totalEntryHours = () => {
    // if (!entry) return -1;
    if (!entryWatch) return 0.0;
    let total = 0.0;

    [1,2,3,4,5,6,7].forEach(key => {
      if (!entry && weekIndex == 0) {
        console.log('entryWatch', (entryWatch[`day${key}hour`]))
      }
      total += toNum(entryWatch[`day${key}hour`]);
    });

    return total;
  } 


  const entryErrors = errors?.week?.[weekIndex]?.entries?.[entryIndex];
  const projectErrors = errors?.week?.[weekIndex]?.entries?.[entryIndex]?.projectid;
  const milestoneErrors = errors?.week?.[weekIndex]?.entries?.[entryIndex]?.activitynumber;
  const taskErrors = errors?.week?.[weekIndex]?.entries?.[entryIndex]?.subactivitynum;

  const daysErrors = entryErrors?.daysTest;


  const hasValue = (val) => {
    if (val === null || val === undefined || val.length === 0) return false;

    return true;
  }


  const isXProject = () => {

    const projectIDString = currentProjectId?.toString()?.toUpperCase();

    if (projectIDString?.charAt(0) === "X") return true;

    const firstFour = projectIDString.substring(0,4);
    if (firstFour === "LD-X") return true;

    return false;
  }



  const milestoneDisabled = !hasValue(currentProjectId) || isXProject() === true || (milestonesLoaded && !!!milestonesResult.length);
  const taskDisabled = !!!currentMilestoneId || milestoneDisabled === true || !currentMilestoneHasTasks();

  const getAllErrors =  () => {
    let a = [];

    if (entryErrors) {
      a = [...Object.keys(entryErrors)?.map(key => entryErrors[key]?.message)]
    }
    if (daysErrors) {
      a = [...a, ...Object.keys(daysErrors)?.map(key => daysErrors[key]?.hour?.message)]
    }
    return a.filter(e => e != null);
  }

  const allErrors = getAllErrors();



  const isDisabled = () => {

    if (isFixing) return false;

    if (viewOnly) {
      return true;
    }
    if (entryLoading) {
      return true;
    }
    if (!entry) {
      return false;
    }
    if (entry.statusid === 1 || entry.statusid === 2 || entry.statusid === 5 || entry.statusid === 6) {
      return true;
    }
  }

  const entryDisabled = isDisabled();

  const [entryDeleteResult, entryDeleteLoading, entryDeleteLoaded, entryDeleteError, doEntryDeleteFetch, setEntryDeleteResult] = useApi();
  const [removeModalOpened, setRemoveModalOpened] = useState(false);

  const toggleRemoveEntryModal = () => {
    setRemoveModalOpened(o => !o);
  }

  const tryDeleteEntry = async (entryID) => {
    // alert("A " + removeEntryInputName);
    doEntryDeleteFetch(deleteEntry, {payload: {entry_id: entryID}});
    
  };

  const weeksWithRemovedEntries = (oldWeeks, ids) => {
    let newWeekArray = [...oldWeeks];

    newWeekArray.forEach((w, index) => {
      w.entries = w.entries?.filter(entry => !ids.includes(entry.id.toString()) )
    })

    return newWeekArray;
  }

  useEffect(() => {

    if (entryDeleteLoaded) {
      getValues('week').forEach((week, wi) => {
        const entries = week.entries;

        entries.forEach((entry, ei) => {
          if (entryDeleteResult.ids.includes(entry.id?.toString())) {

            setValue(`week.${wi}.entries.${ei}._destroy`, "1");
            setValue(`week.${wi}.entries.${ei}.total_entry_hours`, 0);

            [1,2,3,4,5,6,7].forEach(key => {
              setValue(`week.${wi}.entries.${ei}.day${key}hour`, 0)
            });

          }
        })
      })
      setRemoveModalOpened(false);
      // setTimesheetResult(old => {
      //   return (
      //     {
      //       ...old,
      //       summary: [
      //         ...weeksWithRemovedEntries(old.summary, entryDeleteResult.ids)
      //       ]
      //     }
      //   ) 
      // })
    }
  }, [entryDeleteLoaded]);

  const onMilestoneSetValue = item => {
    // alert('here')

    if (item != null) {
      setValue(`week.${weekIndex}.entries.${entryIndex}.milestone_has_tasks`, item.has_tasks);
      setFullyPaid(item.fully_paid);
    }
    else {
      setValue(`week.${weekIndex}.entries.${entryIndex}.milestone_has_tasks`, false);
      setFullyPaid(false);
    }
    
  }

  const milestoneRequired = () => {

    if (isXProject() === true) return false;


    return true;
    
  } 

  const getMilestoneLabel = (item) => {
    if (item) {
      if (item.fully_paid == true) {
        return `${item.activitynumber} - ${item.activityname}`
      }
      else {
        return `${item.activitynumber} - ${item.activityname}`
      }
      
    }
    else {
      return null;
    }
    
  }

  const getMilestoneLabelComponent = (item) => {
    if (item) {
      if (item.fully_paid == true) {
        return <><div><span className="yellow bold"><i className="fas fa-exclamation-triangle margin-right-half"></i> FULLY BILLED/PAID</span> {item.activitynumber} - {item.activityname}</div></>
      }
      else {
        return <><div>{item.activitynumber} - {item.activityname}</div></>
      }
      
    }
    else {
      return null;
    }
  }


  
  return (
    <>
      {removeModalOpened &&
        <Modal canClose={!!!entryDeleteLoading} focusTrap={false} size="small" toggleModal={toggleRemoveEntryModal}>
          <div className='modal-header flex-cont align-center'>
            <h2 className="flex-1">Delete Entry</h2>
            <div onClick={toggleRemoveEntryModal} className="ghost button close">
              <i className="fas fa-times"></i>
            </div>
          </div>

          <div className="padding-2">
            Are you sure you want to delete this entry?
          </div>

          <div className='modal-footer flex-cont align-center'>
            
            <div onClick={toggleRemoveEntryModal} className="button ghost">Cancel</div>

            {/* <input onClick={() => removeLine()} type="submit" value="Delete" className="margin-left-1 button danger" /> */}

            <div onClick={() => tryDeleteEntry(entry?.id)} className={`${entryDeleteLoading && 'disabled'} margin-left-1 button danger`}>
              Delete
              {entryDeleteLoading && <div className="spinner button-spinner"></div>  }
            </div>
          </div>
        </Modal>
      
      }
    <div className={`alpha-entry-row ${hide && 'line-hidden'}`}>

      <div className="flex-cont align-items-end">
        
        {/* <div onClick={() => alert(JSON.stringify(entryWatch, null, 2))} className="button">
          ENTRY
        </div> */}

        {/* <div>
          <pre>
            {JSON.stringify(entry, null, 2)}
          </pre>
        </div> */}

        {entry &&
          <div className="alpha-entry-cell">
            {!isFixing &&
              <div className={`alpha-entry-status ${entry.status?.toString().toLowerCase()}`}>
                {entry.status}
              </div>
            }
            {isFixing &&
              <select 
                className="admin-editable"
                {...register(`${inputName}.statusid`)} 
                defaultValue={entry ? entry.statusid : "0"} 
                name={`${inputName}.statusid`}
              >
                <option value={0}>Draft</option>
                <option value={1}>Submitted</option>
                <option value={2}>Approved</option>
                <option value={3}>Returned</option>   
                {/* <option value={5}>Posted</option>   */}
                <option value={6}>Posting Failed</option>               
              </select>
              }
          </div>
          
        }
        {!entry &&
          <div className="alpha-entry-cell">
            <div className={`alpha-entry-status new`}>
              New
            </div>
          </div>
        }
        <div className="alpha-entry-cell">
          <input  
            {...register(`${inputName}._destroy`)}
            type="hidden" 
            name={`${inputName}._destroy`} 
            defaultValue={"0"} 
          />
        </div>
        

        <input 
          defaultValue={entry?.id} 
          {...register(`${inputName}.id`)} 
          type="hidden" 
          name={`${inputName}.id`}
        />

 
        

        <div className="alpha-entry-cell alpha-entry-autocomplete">
          <AutoComplete 
            canReset={false}
            trigger={trigger}
            disabled={entryDisabled}
            onSelect={() => null} 
            resetField={resetField}
            getValues={getValues}
            labelComponent={item => <div>{item.projectid} - {item.projectname}</div>} 
            searchableAttributes={["projectid", "projectname"]} 
            label="Project"
            labelFn={(item) => `${item.projectid} - ${item.projectname}`}
            valueFn={(item) => item.projectid }
            setValue={setValue}
            defaultSearch={entry ? `${entry?.project?.projectid} - ${entry?.project?.projectname}` : ''}
            defaultValue={entry?.project?.projectid}
            name={`week.${weekIndex}.entries.${entryIndex}.projectid`}
            selectedItemHash={
              (!entry || (entry?.project?.projectid == null || entry?.project?.projectid?.length == 0)) ? (
                null
              ) : (
                entry?.project
              )
            }

            validation={
              (hide) ? {required: null} : {required: 'Project required'}
            }

            items={projectsResult}
            itemsLoading={projectsLoading}
            register={register}
            errors={projectErrors} 
            blankMessage="No projects found."
            
          />
          {/* <br />
          <input
            type="text"
            name={`week.${weekIndex}.entries.${entryIndex}.projectname`} 
            id={`week.${weekIndex}.entries.${entryIndex}.projectname`} 
            defaultValue={entry?.project?.projectname} 
            // ref={register({
            //   required: "Required."
            // })}
            {...register(`week.${weekIndex}.entries.${entryIndex}.projectname`)}
          /> */}


          </div>
          
          <div className="alpha-entry-cell alpha-entry-autocomplete">

          <input
            type="hidden"
            name={`week.${weekIndex}.entries.${entryIndex}.milestone_has_tasks`} 
            id={`week.${weekIndex}.entries.${entryIndex}.milestone_has_tasks`} 
            defaultValue={entry?.milestone_has_tasks} 
            // ref={register({
            //   required: "Required."
            // })}
            {...register(`week.${weekIndex}.entries.${entryIndex}.milestone_has_tasks`)}
          />

          {fullyPaid &&
            <div className="yellow bold"><i className="fas fa-exclamation-triangle margin-right-half"></i>FULLY BILLED/PAID</div>
          }

          {/* <div>
            {JSON.stringify(fullyPaid,null,2)}
          </div> */}
            
          <AutoComplete 
            onSetValue={item =>  onMilestoneSetValue(item)}
            trigger={trigger}
            disabled={milestoneDisabled || entryDisabled}
            resetField={resetField}
            required={milestoneRequired}
            resetDependency={[currentProjectId]}
            getValues={getValues}
            blankMessage="This project does not have any milestones."
            canReset={false}
            onSelect={() => null} 
            labelComponent={item => getMilestoneLabelComponent(item)} 
            searchableAttributes={["activitynumber", "activityname"]} 
            validation={
              (hide || milestoneDisabled) ? {required: null} : {required: 'Milestone required'}
            }
            label="Milestone"
            labelFn={(item) => getMilestoneLabel(item)}
            valueFn={(item) => item.activitynumber } 
            setValue={setValue}
            defaultSearch={(!entry || entry?.activitynumber == null || entry?.activitynumber.length == 0) ? '' : `${entry?.activitynumber} - ${entry?.activityname}`}
            defaultValue={entry?.activitynumber}
            name={`week.${weekIndex}.entries.${entryIndex}.activitynumber`}
            items={milestonesResult}
            itemsLoading={milestonesLoading}
            fetchItems={() => fetchMilestones(currentProjectId)}
            selectedItemHash={
              (!entry || (entry?.activitynumber == null || entry?.activitynumber.length == 0)) ? (
                null
              ) : (
                {
                  activityname: entry?.activityname,
                  activitynumber: entry?.activitynumber,
                }
              )
            }
            register={register}
            errors={milestoneErrors} 
          />

          {/* <pre>
            {JSON.stringify(milestonesResult, null, 2)}
          </pre> */}
</div>

<div className="alpha-entry-cell alpha-entry-autocomplete">
        <AutoComplete 
            
            validation={
              (hide || taskDisabled) ? {required: null} : {required: 'Task required'}
            }
            disabled={taskDisabled || entryDisabled}
            trigger={trigger}
            resetField={resetField}
            getValues={getValues}
            blankMessage="This milestone does not have any tasks."
            canReset={false}
            onSelect={() => null} 
            labelComponent={item => item ? <div>{item.subactivityname}</div> : null} 
            searchableAttributes={["subactivitynum", "subactivityname"]} 
            resetDependency={[currentProjectId, currentMilestoneId]}
            label="Task"
            labelFn={(item) => item ? `${item.subactivityname}` : null}
            valueFn={(item) => item.subactivitynum }
            setValue={setValue}
            defaultSearch={(!entry || entry?.subactivitynum == null || entry?.subactivitynum.length == 0) ? '' : `${entry?.subactivityname}`}
            defaultValue={entry?.subactivitynum}
            name={`week.${weekIndex}.entries.${entryIndex}.subactivitynum`}
            items={tasksResult}
            itemsLoading={tasksLoading}
            fetchItems={() => fetchTasks(currentMilestoneId)}
            selectedItemHash={
              (!entry || (entry?.subactivitynum == null || entry?.subactivitynum.length == 0)) ? (
                null
              ) : (
                {
                  subactivityname: entry?.subactivityname,
                  subactivitynum: entry?.subactivitynum,
                }
              )
            }
            register={register}
            errors={taskErrors} 
          />
        </div>


{/*       
        <div className="alpha-entry-cell alpha-entry-autocomplete">
            <pre>
              {JSON.stringify(week?.test?.slice(1), null, 2)}
            </pre>
          </div> */}

      {entryIndex !== 0 && entry?.dates?.slice(1)?.map((date, dayIndex) => {

        
        return (
          <>
            
            <TimesheetEntryDay hide={hide} entryDisabled={entryDisabled} control={control} setValue={setValue} entry={entry} dayIndex={dayIndex+1}  entryErrors={entryErrors} weekIndex={weekIndex} selectedWeekIndex={selectedWeekIndex} entryIndex={entryIndex} register={register} date={date} />
          </>
        )
          
      })}

      <TimesheetEntryDay hide={hide} entryDisabled={entryDisabled} control={control} setValue={setValue} entry={entry} dayIndex={entry?.dates?.length} entryErrors={entryErrors} weekIndex={weekIndex} selectedWeekIndex={selectedWeekIndex} entryIndex={entryIndex} register={register} date={entry?.dates?.[0]} />

      {entryIndex === 0 && week &&
        <>

          {week?.test?.slice(1).map((day, dayIndex) => {
            return (
                          
              <TimesheetEntryDay hide={hide} entryDisabled={entryDisabled} control={control} setValue={setValue} entry={entry} dayIndex={dayIndex+1} entryErrors={entryErrors} weekIndex={weekIndex} selectedWeekIndex={selectedWeekIndex} entryIndex={entryIndex} register={register} date={day} />
         
            )
          })}
          <TimesheetEntryDay hide={hide} entryDisabled={entryDisabled} control={control} setValue={setValue} entry={entry} dayIndex={week?.test?.length} entryErrors={entryErrors} weekIndex={weekIndex} selectedWeekIndex={selectedWeekIndex} entryIndex={entryIndex} register={register} date={week?.test?.[0]} />
        </>
      }

      <div className="alpha-entry-cell">

        <div className="entry-cell-label">HOURS</div>

        {/* <input value={} type="text" name="" id="" /> */}

        {/* <div>
          {totalEntryHours()}
        </div> */}

        <TimesheetEntryTotal setValue={setValue} totalHours={totalEntryHours()} entry={entry} register={register} name={`week.${weekIndex}.entries.${entryIndex}.total_entry_hours`} />

        
        
        
        
      
      
      </div>

      {!entryDisabled &&
        <div className="alpha-entry-cell">
          {entry &&
            <div onClick={() => toggleRemoveEntryModal(entry.id, inputName) } className={`entry-button circle button margin-left-1 ${entryDisabled ? 'disabled' : ''}`}>
              <i className="no-margin big fas fa-times"></i>
              {/* DELETE */}
            </div>
          }
        </div>
      }
      </div>
      {/* {totalEntryHours()}
      {!entry &&
          <div className="">
            <pre>
              {JSON.stringify(entryWatch, null, 2)}
            </pre>
          </div>
        
        } */}
      
      {/* <pre>
        {JSON.stringify(allErrors, null, 2)}
      </pre> */}
      {entry && entry.returnnote && (entry.statusid !== 2 || entry.statusid !== 5 || entry.statusid !== 6 ) &&

        <div className=" alpha-error-cont flex-cont align-center managers-note">
          <i className="margin-left-half fas fa-comment-dots font-1 margin-right-half"></i>
          <div className="font-08 margin-right-half bold">MANAGER'S NOTE: </div>
          <div style={{marginBottom: '2px'}}>{entry.returnnote}</div>
        </div>

      }
      {!!allErrors?.length &&

          <div className="alpha-error-cont">
            <div className="flex-cont">
              {/* <div className="flex-1"></div> */}
              <ul>
                {allErrors?.map(error => <li>{error}</li>)} 
              </ul>
            </div>
            
          </div>

      }
      

      


      
    </div>
  </>
  )
}

export default TimesheetEntry;