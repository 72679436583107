import { useNavigate, useLocation } from "react-router-dom"
import { useState } from "react";


const TimesheetPostingLogsSubnav = ({}) => {

  const navigate = useNavigate('/logs/timesheets');
  const location = useLocation();

  const [shouldDisableTabs, setShouldDisableTabs] = useState(false);
  

  const getTab = (location) => {
    if (!location || !location.pathname) {
      return "";
    }
    const parts = location.pathname.split("/");
    if (!parts || parts.length === 0) return ""
    return parts[parts.length-1];
  }

  const tab = getTab(location);

  const setTab = tab => {
    navigate(`/logs/timesheets/${tab}`);
  }


  return (
    <div className="margin-bottom-3">

      <pre>
        {JSON.stringify(tab, null, 2)}
      </pre>
      <div className={`selector-cont ${shouldDisableTabs ? 'disabled' : ''}`}>
        <div onClick={() => setTab("failures")}  className={`${(tab === 'timesheets' || tab === 'failures') ? 'selected' : ''} selector`}>

          

            <div className="flex-cont align-center">
              {(false) &&
                <div className="unread-count">
                  <div className="number">{false ? null : "-"} </div>
                </div>
}
              <div>Failed</div>
            </div>
          
          
        </div>
        <div onClick={() => setTab("successes")}  className={`${tab === 'successes' ? 'selected' : ''} selector`}>
          <div className="flex-cont align-center">

            {(false) &&
              <div className="unread-count">
                <div className="number">{false ? null : "-"} </div>
              </div>
            }
            <div>Succeeded</div>
          </div>
        </div>

        <div onClick={() => setTab("queue")}  className={`${tab === 'queue' ? 'selected' : ''} selector`}>
          <div className="flex-cont align-center">

            {(false) &&
              <div className="unread-count">
                <div className="number">{false ? null : "-"} </div>
              </div>
            }
            <div>In Queue</div>
          </div>
        </div>

        <div onClick={() => setTab("TSMTimesheetD365")}  className={`${tab === 'TSMTimesheetD365' ? 'selected' : ''} selector`}>
          <div className="flex-cont align-center">

            {(false) &&
              <div className="unread-count">
                <div className="number">{false ? null : "-"} </div>
              </div>
            }
            <div>TSMTimesheetD365</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TimesheetPostingLogsSubnav;