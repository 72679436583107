import React, { useEffect } from 'react';
import {
  NavLink
} from 'react-router-dom';
import { connect } from 'react-redux';
import { useApi } from '../../api';


const isActive = ({ isCurrent }) => {
  return isCurrent ? { className: "active" } : null
}



const BudgetingNav = ({viewAsUser, children, reviewCount}) => {


  const [reviewCountResult, reviewCountLoading, reviewcountLoaded, reviewcountError, doReviewCountFetch, setReviewCountResult] = useApi();


  useEffect(() => {
    // doReviewCountFetch(getReviewCount);
  }, []);


  return(
    <ul className="sub-nav">
      {/* <li>
        <div className="code">
          <pre>
          {JSON.stringify(viewAsUser, null, 2)}
          </pre>
        </div>
        
      </li> */}
      <li>
        <NavLink end to="/budgeting">My Budgets</NavLink>
      </li>

      {viewAsUser && (viewAsUser.roleid === 3 || viewAsUser.roleid === 2) &&
        <>
        <li>
          <NavLink to="/budgeting/review">
            <div className="flex-cont align-center">
              <div className="flex-cont align-center justify-center unread-countx">
                {/* <div className="number">
                  {(reviewCount === 0 || reviewCount) ? reviewCount : "-"}
                </div> */}
              </div>
              <div>Review</div>
            </div>
          </NavLink>
          
        </li>
        {false &&
          <li>
            <NavLink to="/budgeting/approved">
              <div className="flex-cont align-center">
                <div className="flex-cont align-center justify-center unread-countx">
                  {/* <div className="number">
                    {(reviewCount === 0 || reviewCount) ? reviewCount : "-"}
                  </div> */}
                </div>
                <div>Approved</div>
              </div>
            </NavLink>
          </li>
        }
        </>
      }
      
      {viewAsUser && (viewAsUser.roleid === 4 || viewAsUser.roleid === 1 || viewAsUser.roleid === 8 || viewAsUser.roleid === 9) && false &&
        <>
        <li>
          <NavLink to="/budgeting/list/returned">
            <div className="flex-cont align-center">
              <div className="flex-cont align-center justify-center unread-countx">
                {/* <div className="number">
                  {(reviewCount === 0 || reviewCount) ? reviewCount : "-"}
                </div> */}
              </div>
              <div>Returned</div>
            </div>
          </NavLink>
        </li>
        <li>
          <NavLink to="/budgeting/list/needs-budget">
            <div className="flex-cont align-center">
              <div className="flex-cont align-center justify-center unread-countx">
                {/* <div className="number">
                  {(reviewCount === 0 || reviewCount) ? reviewCount : "-"}
                </div> */}
              </div>
              <div>Needs Budget</div>
            </div>
          </NavLink>
        </li>
        <li>
          <NavLink to="/budgeting/list/new">
            <div className="flex-cont align-center">
              <div className="flex-cont align-center justify-center unread-countx">
                {/* <div className="number">
                  {(reviewCount === 0 || reviewCount) ? reviewCount : "-"}
                </div> */}
              </div>
              <div>New</div>
            </div>
          </NavLink>
        </li>
        <li>
          <NavLink to="/budgeting/list/revised">
            <div className="flex-cont align-center">
              <div className="flex-cont align-center justify-center unread-countx">
                {/* <div className="number">
                  {(reviewCount === 0 || reviewCount) ? reviewCount : "-"}
                </div> */}
              </div>
              <div>Revised</div>
            </div>
          </NavLink>
        </li>
        <li>
          <NavLink to="/budgeting/list/pending">
            <div className="flex-cont align-center">
              <div className="flex-cont align-center justify-center unread-countx">
                {/* <div className="number">
                  {(reviewCount === 0 || reviewCount) ? reviewCount : "-"}
                </div> */}
              </div>
              <div>Pending</div>
            </div>
          </NavLink>
        </li>
        <li>
          <NavLink to="/budgeting/list/approved">
            <div className="flex-cont align-center">
              <div className="flex-cont align-center justify-center unread-countx">
                {/* <div className="number">
                  {(reviewCount === 0 || reviewCount) ? reviewCount : "-"}
                </div> */}
              </div>
              <div>My Approved</div>
            </div>
          </NavLink>
        </li>
        </>
      }
      {viewAsUser?.roleid === 2 && false &&
        <li>
          <NavLink to="/budgeting/list/approved">
            <div className="flex-cont align-center">
              <div className="flex-cont align-center justify-center unread-countx">
                {/* <div className="number">
                  {(reviewCount === 0 || reviewCount) ? reviewCount : "-"}
                </div> */}
              </div>
              <div>My Approved</div>
            </div>
          </NavLink>
        </li>
      }
      {(viewAsUser?.roleid === 4 || viewAsUser?.roleid === 2 || viewAsUser?.roleid === 3) &&
        <>
          <li>
            <NavLink to="/budgeting/all">
              <div className="flex-cont align-center">
                <div className="flex-cont align-center justify-center unread-countx">
                  {/* <div className="number">
                    {(reviewCount === 0 || reviewCount) ? reviewCount : "-"}
                  </div> */}
                </div>
                <div>All Budgets</div>
              </div>
            </NavLink>
          </li>
          <li>
            <NavLink to="/budgeting/reports">
              <div className="flex-cont align-center">
                <div className="flex-cont align-center justify-center unread-countx">
                  {/* <div className="number">
                    {(reviewCount === 0 || reviewCount) ? reviewCount : "-"}
                  </div> */}
                </div>
                <div>Reports</div>
              </div>
            </NavLink>
          </li>
        </>
      }
    </ul>
  );
}


function mapStateToProps(state) {
  return { 
    reviewCount: state.budgetHeaderState.reviewCount,
    viewAsUser: state.authState.viewAsUser,
  };
} 

export default connect(mapStateToProps)(BudgetingNav);
