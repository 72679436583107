import React, { useState, useEffect } from 'react';
import { DateTime, Info } from 'luxon';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link, Outlet, useLocation, useParams, useNavigate,
} from 'react-router-dom';

import { useForm } from 'react-hook-form';
import store from '../redux/store';
import { copyTimesheet, getMyTimesheets, getTimesheet, getTimesheetWeeks, getTimesheetSummary, setCurrentTimesheetSummary } from '../redux/timesheets/timesheetActions';
import { getAssignedProjects } from '../redux/projects/projectActions';
import { getPhases } from '../redux/phases/phaseActions';
import { getCategories } from '../redux/categories/categoryActions';
import { getEntries, setEntries, saveTimesheet, submitTimesheet } from '../redux/entries/entryActions';
import { clearStore } from '../redux/authActions';


import { useApi } from '../api';
import { connect } from 'react-redux';

import Modal from '../components/Modal';
import EntrySection from './entries/EntrySection';
import TimesheetWeeks from './TimesheetWeeks';
import TimesheetPrintModal from './TimesheetPrintModal';

import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';

import { usePrevious } from '../hooks/usePrevious';

const CopyModal = ({toggleCopyModal, timesheetId}) => {

  useEffect(() => {
    fetchTimesheets();
  }, []);


  const [timesheets, loading, loaded, error, doFetch, setResult] = useApi();

  const fetchTimesheets = () => {
    doFetch(getMyTimesheets);
  }

  const [prevTs, setPrevTs] = useState(null);

  const [copyResult, copyLoading, copyLoaded, copyError, doCopyFetch, setCopyResult] = useApi();

  useEffect(() => {
    if (timesheets && timesheets.length > 0) {
      setPrevTs([...timesheets].reverse()[0].id);
    }
  }, [timesheets]);


  const handleCopyTimesheet = async () => {
    doCopyFetch(copyTimesheet, {payload: {id: timesheetId, prev: prevTs}});
  }

  useEffect(() => {
    if (copyLoaded) {
      fetchEntries();
    }
    
  }, [copyLoaded]);

  const [entriesResult, entriesLoading, entriesLoaded, entriesError, doEntriesFetch, setEntriesResult] = useApi();
  const fetchEntries = () => {
    doEntriesFetch(getEntries, {query: {timesheetid: timesheetId}});
  }
  useEffect(() => {
    if (entriesLoaded && entriesResult) {
      store.dispatch(setEntries(entriesResult));
      toggleCopyModal();
    }
  }, [entriesLoaded]);


  return(
    <Modal toggleModal={toggleCopyModal}>
      <div className='modal-header flex-cont align-center'>
        <h1 className="flex-1">Copy Projects</h1>
        <div onClick={toggleCopyModal} className="ghost button close">
          <i className="fas fa-times"></i>
        </div>
      </div>

      <div className="padding-1">

        This will override your current entries for this timesheet.

        <div className="margin-bottom-2"></div>

        <div className="flex-cont align-center">
          <div className="bold margin-right-1">Copy from:</div>
          <select className="auto-width" value={prevTs} onChange={e => setPrevTs(e.target.value)}>
              
              {timesheets && [...timesheets].reverse().map(timesheet => {
                return(
                  <option value={timesheet.number}>{`${timesheet.number}: ${DateTime.fromISO(timesheet.weekStart).toLocaleString()} - ${DateTime.fromISO(timesheet.weekEnd).toLocaleString()}`}</option>
                )
              })}
            </select>
          </div>
      </div>

      <div className='modal-footer flex-cont align-center'>
        <input onClick={handleCopyTimesheet} type="submit" value="Copy" className="margin-right-1 button" />
        {(copyLoading || entriesLoading) &&
          <div className="spinner"></div>
        }
        <div className="flex-1"></div>
        <div onClick={toggleCopyModal} className="button ghost">Cancel</div>
      </div>
    </Modal>
  )
}


let count = 0;
const TimesheetScreen = ({currentCompany, savingEntryIds, viewOnly=false, currentSummary, timesheets, unsavedEntries, fullScreenLoading, weekSummary, weeks, timesheet, isLoading, weeksIsLoading, entries}) => {
  const navigate = useNavigate('/');
  const { unregister, setError, trigger, control, watch, register, handleSubmit, formState: { errors }, getValues, reset, clearErrors, setValue, formState } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange'
  });

  
  // Fetch timesheet
  const { timesheetId } = useParams();
  const [timesheetResult, timesheetLoading, timesheetLoaded, timesheetError, doTimesheetFetch, setTimesheetResult] = useApi();
  const [entriesResult, entriesLoading, entriesLoaded, entriesError, doEntriesFetch, setEntriesResult] = useApi();
  const [projects, projectsLoading, projectsLoaded, projectsError, doProjectsFetch, setProjectsResult] = useApi();
  const [phases, phasesLoading, phasesLoaded, phasesError, doPhasesFetch, setPhasesResult] = useApi();
  const [categories, categoriesLoading, categoriesLoaded, categoriesError, doCategoriesFetch, setCategoriesResult] = useApi();
  const [summaryResult, summaryLoading, summaryLoaded, summaryError, doSummaryFetch, setSummaryResult] = useApi();  

  const prevCompany = usePrevious(currentCompany);

  useEffect(() => {
    if (prevCompany && currentCompany) {
      if (prevCompany !== currentCompany) {
        navigate("/timesheets")
      }
    }    
  }, [currentCompany]);


  useEffect(() => {
    
    store.dispatch(clearStore('ENTRIES'));
    store.dispatch(clearStore('TIMESHEETS'));


    fetchTimesheet();
    fetchEntries();
    fetchProjects();
    fetchSummary();

    return function cleanup() {
      store.dispatch(clearStore('ENTRIES'));
      store.dispatch(clearStore('TIMESHEETS'));
      // store.dispatch(clearStore('PROJECTS'));
    }
  }, []);



  const fetchTimesheet = () => {
    doTimesheetFetch(getTimesheet, {query: {id: timesheetId}});
  }

  const fetchEntries = () => {
    doEntriesFetch(getEntries, {query: {timesheetid: timesheetId}});
  }

  useEffect(() => {
    if (entriesLoaded && entriesResult) {
      store.dispatch(setEntries(entriesResult));
    }
  }, [entriesLoaded]);


  
  const fetchProjects = () => {
    doProjectsFetch(getAssignedProjects, {query: {timesheetid: timesheetId}});
  }
  const fetchSummary = () => {
    doSummaryFetch(getTimesheetSummary, {query: {id: timesheetId}});
  }
  useEffect(() => {
    if (summaryLoaded && summaryResult) {
      store.dispatch(setCurrentTimesheetSummary(summaryResult));
    }
  }, [summaryLoaded]);

  const [selectedPeriodIndex, setSelectedPeriodIndex] = useState(0);
  const [selectedPeriod, setSelectedPeriod] = useState(null);

  useEffect(() => {
    if (currentSummary) {
      let blankWeekIndex = currentSummary.findIndex(w => parseInt(w.totalHours) === 0);
      if (blankWeekIndex !== -1) {
        setSelectedPeriod(currentSummary[blankWeekIndex]);
        setSelectedPeriodIndex(blankWeekIndex);
      }
      else {
        setSelectedPeriod(currentSummary[0]);
        setSelectedPeriodIndex(0);
      }
    }
  }, [currentSummary]);

  const selectPeriodIndex = (i, p) => {
    setSelectedPeriodIndex(i);
    setSelectedPeriod(p);
  }




  const [submitModalOpened, setSubmitModalOpened] = useState(false);

  const handleSubmitModalClick = () => {
    setSubmitModalOpened(true);
  }
  const toggleSubmitModal = () => {
    setSubmitModalOpened(old => !old);
  }


  const [submitResult, submitLoading, submitLoaded, submitError, doSubmitFetch, setSubmitResult] = useApi();
  const [submitNote, setSubmitNote] = useState('');



  const [returnedEntries, setReturnedEntries] = useState(null);
  const [copiedEntries, setCopiedEntries] = useState(null);
  const [submittedEntries, setSubmittedEntries] = useState(null);
  const [approvedEntries, setApprovedEntries] = useState(null);
  const [draftEntries, setDraftEntries] = useState(null);

  useEffect(() => {
    // setReturnedEntries(entries);
    // alert('here');
    if (entries) {
      setReturnedEntries(entries.filter(e => e.statusid === 3));
      setCopiedEntries(entries.filter(e => e.statusid === 4));
      setSubmittedEntries(entries.filter(e => e.statusid === 1));
      setApprovedEntries(entries.filter(e => e.statusid === 2));
      setDraftEntries(entries.filter(e => e.statusid === 0));
    }
    else {
      setReturnedEntries(null);
      setCopiedEntries(null);
    }

    // setCurrentTimesheetStatus(getCurrentTimesheetStatus());
  }, [entries]);


  useEffect(() => {

    setCurrentTimesheetStatus(getCurrentTimesheetStatus());
  }, [entries, timesheetResult]);





  const [currentTimesheetStatus, setCurrentTimesheetStatus] = useState(null);


  const getCurrentTimesheetStatus = () => {
    // alert(JSON.stringify(timesheetResult))
    if (!timesheetResult || !entries ) {
      return null;
    }
    if (entries.length === 0) {
      return "draft";
    }

    // setReturnedEntries(entries.filter(e => e.statusid === 3));
    // setCopiedEntries(entries.filter(e => e.statusid === 4));
    // setSubmittedEntries(entries.filter(e => e.statusid === 1));
    // setApprovedEntries(entries.filter(e => e.statusid === 2));
    // setDraftEntries(entries.filter(e => e.statusid === 0));

    const draft = entries.filter(e => e.statusid === 0);
    const submitted = entries.filter(e => e.statusid === 1);
    const approved = entries.filter(e => e.statusid === 2);
    const returned = entries.filter(e => e.statusid === 3);
    const posted = entries.filter(e => e.statusid === 5);
    const failed = entries.filter(e => e.statusid === 6);

    if (draft && draft.length !== 0) {
      return "draft";
    }
    if (returned && returned.length !== 0) {
      return "returned";
    }
    if (failed && failed.length !== 0) {
      return "posting failed";
    }
    if (posted && posted.length !== 0) {
      return "posted";
    }
    if (returned && returned.length !== 0) {
      return "returned";
    }
    if (approved && approved.length === entries.length) {
      return "approved";
    }
    return "submitted";
  }

  const [warningMessage, setWarningMessage] = useState(null);
  const [submitButtonIsDisabled, setSubmitButtonIsDisabled] = useState(true);


  useEffect(() => {
    setWarningMessage(getWarningMessage());

   
    
  }, [currentTimesheetStatus]);

  useEffect(() => {
    setSubmitButtonIsDisabled(getSubmitButtonIsDisabled());
  }, [currentTimesheetStatus, entries, timesheetResult]);




  const getWarningMessage = () => {
    if (!currentTimesheetStatus) {
      return null;
    }

    if (currentTimesheetStatus === "returned") {
      return "Please correct the returned entries below before re-submitting.";
    }
    return null;
  }


  const nonDestroyedEntries = entries?.filter(e => e._destroy !== "1");


  const getSubmitButtonIsDisabled = () => {
    if (!timesheetResult || !nonDestroyedEntries ) {
      return true
    }
    // alert(currentTimesheetStatus)
    // alert('0');
    if (nonDestroyedEntries?.length === 0) return true
    // alert('1');
    if (currentTimesheetStatus === "draft") return false
    // alert('2');
    if (currentTimesheetStatus === "approved") return true
    // alert('3');
    if (currentTimesheetStatus === "returned") return false
    // alert('4');
    if (currentTimesheetStatus === "submitted") return true

    if (currentTimesheetStatus === "posted") return true

    if (currentTimesheetStatus === "posting failed") return true
    // alert('5');

    return true;

  }


  const [copyModalOpened, setCopyModalOpened] = useState(false);

  const toggleCopyModal = () => {
    setCopyModalOpened(old => !old);
  }


  useEffect(() => {
    if (submitLoaded) {
      navigate(`/timesheets`);
    }
  }, [submitLoaded]);

  const addingAllowed = () => {
    return (timesheetResult && timesheetResult?.statusid !== 2 && timesheetResult?.statusid !== 1 && timesheetResult?.statusid !== 5 && timesheetResult?.statusid !== 6);
  }

  const onSubmit = data => {
    alert(JSON.stringify(data, null, 2))
  }

  useEffect(() => {
    
  }, [errors])


  const [printModalOpened, setPrintModalOpened] = useState(false);

  const togglePrintModal = () => {
    setPrintModalOpened(o => !o);
  }

  const [saveAndQuitLoading, setSaveAndQuitLoading] = useState(false);
  const [saveModalOpened, setSaveModalOpened] = useState(false);
  const [errorModalOpened, setErrorModalOpened] = useState(false);


  const showSaveAndSubmitButtons = addingAllowed();



  const [lineErrors, setLineErrors] = useState([]);

  const updateLineError = (errors) => {

    // setLineErrors(o => [...errors, ])

  }

  

  const handleSubmitTimesheet = async () => {

    const data = getValues();

    const payload = {

    }

    doSubmitFetch(submitTimesheet, {payload: {note: submitNote, id: timesheetId, ...data}});

    // try {
    //   // // const response = await store.dispatch(submitTimesheet(json));

    //   // store.dispatch(showFullscreenLoading("Submitting timesheet..."));
    //   // const response = await store.dispatch(api('timesheets/submit', {id: timesheet.id}, null, 'POST', 'SUBMIT_TIMESHEET'));
    //   // store.dispatch(hideFullscreenLoading());
    //   // 
    //   // navigate(`/timesheets`);
    // }
    // catch (e) {
    //   // store.dispatch(hideFullscreenLoading());
    //   alert(e);

    //   // setIsLoading(false);
    // }
  }
  useEffect(() => {
    // navigate(`/timesheets`);
    if (submitLoaded) {
      navigate(`/timesheets`);
    }

    // alert(submitLoaded);
  }, [submitLoaded]);

  const [timesheetSaveResult, timesheetSaveLoading, timesheetSaveLoaded, timesheetSaveError, doTimesheetSaveFetch, setTimesheetSaveResult] = useApi();

  const toggleSaveModal = () => {
    setSaveModalOpened(o => !o)
  }
  const saveAndQuitCicked =  async () => {
    // const t = await trigger();
    // alert(JSON.stringify(t, null, 2))

    // alert(formState.isValid);
    setSaveModalOpened(true);


    if (true) {
      const data = getValues();

      const payload = {

      }

      doTimesheetSaveFetch(saveTimesheet, {payload: data});
    }
    
    
  }
  useEffect(() => {

    // alert('timesheetSaveLoaded')
    
    if (timesheetSaveLoaded) {
      navigate(`/timesheets`);
    }
  }, [timesheetSaveLoaded]);


  const checkDupes = () => {
    const values = getValues();
    if (!values?.weeks) return false;


    let found = false;
    values.weeks.some(week => {
      const entries = week?.entries?.filter(e => e._destroy !== "1");

      if (!entries) {
        found = false
        return true
      }

      const uniqueValues = new Set(entries.map(v => `${v.selectedProject} ${v.selectedActivity} ${v.selectedSubactivity}`));
      if (uniqueValues.size < entries.length) {
        
        
        
        found = true;
        return true;
      }

      return false


    })
    
    

    return found;
  }

  const [dupesFound, setDupesFound] = useState();

  const submitButtonClicked = async () => {

    if (checkDupes()) {
      setDupesFound(true);
    }
    else {
      setDupesFound(false);
    }

    const t = await trigger();
    const data = getValues();
    // alert(JSON.stringify(data, null, 2))
    setSubmitModalOpened(true);
    
    
   
  }


  const hasErrors = () => {
    return Object.keys(errors).length !== 0
  } 


  

  


  // count ++;
  return (
    <>
      {printModalOpened &&
        <TimesheetPrintModal opened={printModalOpened} timesheet={timesheetResult} toggleModal={togglePrintModal} />
      }

      {/* <div onClick={() => checkDupes()} className="button">

      </div> */}
{/* 
      <div className="code">
        <pre>
          {JSON.stringify(entries, null, 2)}
        </pre>
      </div> */}


      <div className="flex-cont">
          <div className="flex-cont align-center flex-1">
            {/* <h1 className="no-margin">Timesheet</h1>
            <h2 className="gray no-margin">
              {timesheetId}
            </h2> */}
            <div>
              <h1 className="font-1-5 no-margin-vertical">TM{timesheetId}</h1>
              
            </div>

            {timesheetResult && entries &&
              <div className={`margin-left-1 status-badge-cont ${timesheetResult?.status?.toLowerCase()} margin-top-05x flex-cont align-center`}>
                <div className={`font-075 status-badge`}>
                  <i className="fas fa-exclamation-triangle margin-right-half"></i>
                  <div>{timesheetResult?.status?.toUpperCase()}</div>
                </div>
                {/* <div className="status-message margin-left-1">
                  {warningMessage}
                </div> */}
              </div>
              }

            
            

            {/* <div>{JSON.stringify(selectedPeriod)}</div> */}
          </div>
          <div className="flex-cont  align-center">

            
            <div onClick={togglePrintModal} className="button admin-button margin-right-1">
              <span>Admin: Fix</span>
            </div>
            <div onClick={togglePrintModal} className="button ghost margin-right-1">
              <i className="fas fa-print margin-right-half"></i>
              <span>Print</span>
            </div>

            {/* <div>{viewOnly}</div> */}

            {!viewOnly &&
              <>
                {(entriesLoading || projectsLoading) &&
                  <div className="spinner"></div>
                }
                {!entriesLoading && showSaveAndSubmitButtons &&
                  <>
                  <div onClick={saveAndQuitCicked} className={`${saveAndQuitLoading ? 'disabled' : ''} relative button outline margin-right-half`}>
                    Save & Exit

                    {saveAndQuitLoading &&  
                      <div className="spinner button-spinner"></div>
                    }
                    
                  </div>
                  {/* <div>
                    {nonDestroyedEntries?.length}
                  </div> */}
                  <div onClick={submitButtonClicked} disabled={submitButtonIsDisabled || getSubmitButtonIsDisabled()} className={`${submitButtonIsDisabled ? 'disabled' : ''} button margin-right-1`}>
                    Submit Timesheet
                  </div>
                  </>
                }
              </>
            }
            

            
            
            <div>

              </div>
          </div>
      </div>
      <div className='margin-bottom-2'>
        <div className="boldx">{timesheetResult?.employeeName}</div>
        {timesheetResult?.delegate_workerrecid &&
          <div className="font-075">Created by {timesheetResult?.delegate_fname} {timesheetResult?.delegate_lname}</div>
        }
      </div>
{/* currentTimesheetStatus?.toLowerCase() */}
      


      {saveModalOpened && timesheetSaveLoading &&
        <Modal focusTrap={false} size="small" toggleModal={toggleSaveModal}>
            <div className='modal-header flex-cont align-center'>
              {/* <h1 className="flex-1">Submit Timesheet</h1> */}
              <div className="flex-1"></div>
              <div onClick={toggleSaveModal} className="ghost button close">
                <i className="fas fa-times"></i>
              </div>
            </div>

            <div className="padding-1">

              <div>
                Saving timesheet....
              </div>


              {/* {hasErrors() &&
                <div>
                  There are some issues with your timesheet. Please fix the highlighted issues and try again.
                </div>
              }
              {!hasErrors() &&
                <div>
                  Saving timesheet....
                </div>
              } */}

            </div>

            <div className='modal-footer flex-cont align-center'>

            </div>
          </Modal>
      }

      {submitModalOpened &&

        <>
          {dupesFound && !hasErrors() &&
            <Modal focusTrap={false} size="small" toggleModal={toggleSubmitModal}>
              <div className='modal-header flex-cont align-center'>
                {/* <h1 className="flex-1">Submit Timesheet</h1> */}
                <div className="flex-1"></div>
                <div onClick={toggleSubmitModal} className="ghost button close">
                  <i className="fas fa-times"></i>
                </div>
              </div>

              <div className="padding-1">

  
                <div>
                  You have one or more duplicate projects in a week. Are you sure you want to continue?
                </div>
                

              </div>

              <div className='modal-footer flex-cont align-center'>
                
                <div onClick={() => setDupesFound(false)} className="button">Yes, continue</div>
                <div className="flex-1"></div>
                <div onClick={toggleSubmitModal} className="ghost button">Cancel</div>
              </div>
            </Modal>
          }
          {hasErrors() &&
            <Modal focusTrap={false} size="small" toggleModal={toggleSubmitModal}>
              <div className='modal-header flex-cont align-center'>
                {/* <h1 className="flex-1">Submit Timesheet</h1> */}
                <div className="flex-1"></div>
                <div onClick={toggleSubmitModal} className="ghost button close">
                  <i className="fas fa-times"></i>
                </div>
              </div>

              <div className="padding-1">

                {hasErrors() &&
                  <div>
                    There are some issues with your timesheet. Please fix the highlighted issues and try again.
                  </div>
                }

              </div>

              <div className='modal-footer flex-cont align-center'>

              </div>
            </Modal>

          }
          {!hasErrors() && !dupesFound &&
            <Modal focusTrap={false} toggleModal={toggleSubmitModal}>
              <div className='modal-header flex-cont align-center'>
                <h1 className="flex-1">Submit Timesheet</h1>
                <div onClick={toggleSubmitModal} className="ghost button close">
                  <i className="fas fa-times"></i>
                </div>
              </div>

              <div className="padding-1">

                <textarea value={submitNote} onChange={e => setSubmitNote(e.target.value)} placeholder="Submission notes">

                </textarea>
              </div>

              <div className='modal-footer flex-cont align-center'>
                <input onClick={handleSubmitTimesheet} type="submit" value="Submit" className={`${submitLoading ? 'disabled' : ''} margin-right-1 button`} />
                {submitLoading &&
                  <div className="spinner margin-right-1"></div>
                }
                <div onClick={toggleSubmitModal} className="button ghost">Cancel</div>
              </div>
            </Modal>

          }
        </>
      }
      {copyModalOpened &&
        <CopyModal fetchEntries={fetchEntries} timesheetId={timesheetId} toggleCopyModal={toggleCopyModal} />
      }

      {timesheetResult?.submissionnote &&
        <div className="section margin-top-1">
          <strong>SUBMISSION NOTE: </strong>
          {timesheetResult?.submissionnote}
        </div>
      }

      <div className="section margin-top-1">
        <h2 className="no-margin margin-bottom-1 font-1-25">Period Ending {selectedPeriod && selectedPeriod.weekEnd && DateTime.fromISO(selectedPeriod.weekEnd).toLocaleString()}</h2>
        <TimesheetWeeks setValue={setValue} trigger={trigger} getValues={getValues} errors={errors} control={control} timesheet={timesheetResult} selectPeriodIndex={selectPeriodIndex} selectedPeriodIndex={selectedPeriodIndex} />
      </div>


      <div className="section margin-top-1" style={{position: 'absolute', minWidth: '92%'}}>
        <div className="flex-cont">
          <div className="flex-1">
            <h2 className="no-margin margin-bottom-1 font-1-25">
              <span>Entries</span>
            </h2>
            <div className="margin-bottom-2">
              {/* <div>selectedPeriod: {JSON.stringify(selectedPeriod)}</div> */}

              {selectedPeriod && selectedPeriod.weekStart && DateTime.fromISO(selectedPeriod.weekStart).toLocaleString()} - {selectedPeriod && selectedPeriod.weekEnd && DateTime.fromISO(selectedPeriod.weekEnd).toLocaleString()}
            </div>
            {summaryLoading &&
              <div className="spinner margin-2"></div>
            }
            {currentSummary?.map((timesheetWeek, i) => {
              return (
                <div className="inline-blockx">
                  <EntrySection unregister={unregister} setError={setError}  control={control} watch={watch} formState={formState} register={register} handleSubmit={handleSubmit} errors={errors} getValues={getValues} trigger={trigger} reset={reset} clearErrors={clearErrors} setValue={setValue}  totalPeriodHours={selectedPeriod?.totalHours || 0.0} readOnly={viewOnly} allowAdding={addingAllowed()} fetchEntries={fetchEntries} projectsLoading={projectsLoading} projects={projects} categories={categories} phases={phases} timesheet={timesheetResult} entriesLoading={entriesLoading} entries={entries} timesheetId={timesheetId} selectedPeriodIndex={selectedPeriodIndex} selected={selectedPeriodIndex === i} timesheetWeek={timesheetWeek} periodIndex={i} />
                </div>
              )
            })}
          </div>
        </div>

          
      </div>
    </>
  )

  
};





const mapState = state => ({
  timesheet: state.timesheetState.currentTimesheet,
  timesheets: state.timesheetState.data,
  weeks: state.timesheetState.weeks,
  weekSummary: state.timesheetState.weekSummary,
  isLoading: state.timesheetState.isLoading,
  weeksIsLoading: state.timesheetState.weeksIsLoading,
  entries: state.entryState.data,
  fullScreenLoading: state.timesheetState.fullScreenLoading,
  unsavedEntries: [],//state.timesheetEntryState.unsavedEntries,
  savingEntryIds: state.timesheetState.savingEntryIds,
  currentSummary: state.timesheetState.currentSummary,
  currentCompany: state.authState.currentCompany,
});

export default connect(mapState)(TimesheetScreen);
