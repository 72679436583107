
import { andyFetch, actionReturn } from '../../../api';

export const testAPI = (params) => {
  return async (dispatch, getState) => {
    const url = `manager/budget_headers/test_api_connection`;
    try {
      const response = await andyFetch(url, params, "POST");
      if (response.ok) {
        const json = await response.json();

        return actionReturn(json);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};

export const getBudgetReport = (params) => {
  return async (dispatch, getState) => {
    const url = "manager/budget_headers/report";
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();

        return actionReturn(json);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};

export const getReviewCount = (params) => {
  return async (dispatch, getState) => {
    const url = "manager/budget_headers/review_count";
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();

        // alert(JSON.stringify(json?.data?.review_count, null, 2))

        dispatch(setBudgetHeaderReviewCount(json?.data?.review_count));


        return actionReturn(json);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};

export const getInterProjects = (params) => {
  return async (dispatch, getState) => {
    const url = "manager/budget_headers/inter_projects";
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};

export const getRevisions = (params) => {
  return async (dispatch, getState) => {
    const url = "manager/budget_headers/revisions";
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};

export const submitBudgetHeader = (params) => {
  return async (dispatch, getState) => {
    const url = `manager/budget_headers/submit`;
    try {
      const response = await andyFetch(url, params, "POST");
      if (response.ok) {
        const json = await response.json();

        dispatch(setBudgetHeader(json.data));

        return actionReturn(json);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};

export const returnBudgetHeader = (params) => {
  return async (dispatch, getState) => {
    const url = `manager/budget_headers/return_budget_header`;
    try {
      const response = await andyFetch(url, params, "POST");
      if (response.ok) {
        const json = await response.json();

        dispatch(setBudgetHeader(json.data));

        return actionReturn(json);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};

export const approveBudgetHeader = (params) => {
  return async (dispatch, getState) => {
    const url = `manager/budget_headers/approve`;
    try {
      const response = await andyFetch(url, params, "POST");
      if (response.ok) {
        const json = await response.json();

        dispatch(setBudgetHeader(json.data));

        return actionReturn(json);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};

export const cancelRevision = (params) => {
  return async (dispatch, getState) => {
    const url = `manager/budget_headers/cancel_revision`;
    try {
      const response = await andyFetch(url, params, "POST");
      if (response.ok) {
        const json = await response.json();

        dispatch(setBudgetHeader(json.data));

        return actionReturn(json);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};


export const reviseBudgetHeader = (params) => {
  return async (dispatch, getState) => {
    const url = `manager/budget_headers/revise`;
    try {
      const response = await andyFetch(url, params, "POST");
      if (response.ok) {
        const json = await response.json();

        dispatch(setBudgetHeader(json.data));

        return actionReturn(json);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};



export const createBudgetFee = (params) => {
  return async (dispatch, getState) => {
    const url = `manager/budget_fees`;
    try {
      const response = await andyFetch(url, params, "POST");
      if (response.ok) {
        const json = await response.json();

        dispatch(addBudgetFee(json.data));


        return actionReturn(json);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};
export const deleteBudgetFee = (params) => {
  return async (dispatch, getState) => {
    const url = `manager/budget_fees/delete`;
    try {
      const response = await andyFetch(url, params, "POST");
      if (response.ok) {
        const json = await response.json();

        dispatch(removeBudgetFee(json.data.id));

        return actionReturn(json);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};
export const createBudgetLineTask = (params) => {
  return async (dispatch, getState) => {
    const url = `manager/budget_line_tasks`;
    try {
      const response = await andyFetch(url, params, "POST");
      if (response.ok) {
        const json = await response.json();

        dispatch(addBudgetLineTask(json.data));


        return actionReturn(json);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};
export const deleteBudgetLineTask = (params) => {
  return async (dispatch, getState) => {
    const url = `manager/budget_line_tasks/delete`;
    try {
      const response = await andyFetch(url, params, "POST");
      if (response.ok) {
        const json = await response.json();

        dispatch(removeBudgetLineTask(json.data.id));

        return actionReturn(json);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};
export const saveBudgetHeader = (params) => {
  return async (dispatch, getState) => {
    const url = `manager/budget_headers/save_budget_header`;
    try {
      const response = await andyFetch(url, params, "POST");
      if (response.ok) {
        const json = await response.json();



        dispatch(setBudgetHeader(json.data));


        return actionReturn(json);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};
export const getBudgetHeader = (params) => {
  return async (dispatch, getState) => {
    const url = "manager/budget_headers/show_budget_header";
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();
        
        
        dispatch(setBudgetHeader(json.data));

        return actionReturn(json);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};
export const setBudgetHeader = (budgetHeader) => {
  return dispatch => {
    dispatch({
      type: "SET_BUDGET_HEADER",
      budgetHeader: budgetHeader
    });
  }
}

export const setBudgetHeaderReviewCount = (reviewCount) => {
  return dispatch => {
    dispatch({
      type: "SET_BUDGET_HEADER_REVIEW_COUNT",
      reviewCount: reviewCount
    });
  }
}

export const clearBudgetHeader = () => {
  return dispatch => {
    dispatch({
      type: "CLEAR_BUDGET_HEADER",
    });
  }
}

export const addBudgetHeader = (budgetHeader) => {
  return dispatch => {
    dispatch({
      type: "ADD_BUDGET_HEADER",
      budgetHeader: budgetHeader
    });
  }
}
export const removeBudgetHeader = (budgetHeaderId) => {
  return dispatch => {
    dispatch({
      type: "REMOVE_BUDGET_HEADER",
      budgetHeaderId: budgetHeaderId
    });
  }
}
export const removeBudgetLineTask = (budgetLineTaskId) => {
  return dispatch => {
    dispatch({
      type: "REMOVE_BUDGET_LINE_TASK",
      budgetLineTaskId: budgetLineTaskId
    });
  }
}
export const addBudgetLineTask = (budgetLineTask) => {
  return dispatch => {
    dispatch({
      type: "ADD_BUDGET_LINE_TASK",
      budgetLineTask: budgetLineTask
    });
  }
}

export const removeBudgetFee = (budgetFeeId) => {
  return dispatch => {
    dispatch({
      type: "REMOVE_BUDGET_FEE",
      budgetFeeId: budgetFeeId
    });
  }
}
export const addBudgetFee = (budgetFee) => {
  return dispatch => {
    dispatch({
      type: "ADD_BUDGET_FEE",
      budgetFee: budgetFee
    });
  }
}





export const changeBudgetHeader = (budgetHeader) => {
  return dispatch => {
    dispatch({
      type: "CHANGE_BUDGET_HEADER",
      budgetHeader: budgetHeader
    });
  }
}