import React, { useEffect, useState, useRef } from 'react';
import { useApi } from '../../api';
import { connect } from 'react-redux';
import store from '../../redux/store';
import { saveBudgetHeader, clearBudgetHeader, addBudgetLineTask, getBudgetHeader, removeBudgetLineTask, createBudgetLineTask, deleteBudgetFee} from '../../redux/manager/budgetHeaders/budgetHeaderActions';


import { isNumeric } from '../../utils';

const BudgetFeeRow = ({currencySymbol, budgetPhases, watch, getValues, reset, errors, setRemovedIndexes, removedIndexes, setValue, readOnly, fee, register, index, budgetFeeCategories}) => {

  const [feeName, setFeeName] = useState(fee?.description);
  const [feeAmount, setFeeAmount] = useState(fee?.amount);

  const [settledCat, setSettledCat] = useState(null);

  // useEffect(() => {
  //   if (budgetFeeCategories && fee) {
  //     setSettledCat(fee.budgetfeecategoryid)
  //   }
  // }, [budgetFeeCategories, fee]);

  const [deletedFee, deleteFeeLoading, deleteFeeLoaded, deleteFeeError, doDeleteFeeFetch, setDeleteFeeResult] = useApi();

  // const deleteFeeClicked = () => {
  //   const payload = {
  //     id: fee.id, 
  //   }
  //   doDeleteFeeFetch(deleteBudgetFee, {payload: payload})
  // }

  const inputName = `budget_fees.${index}`;


  const [destroyed, setDestroyed] = useState(false);

  const deleteFeeClicked = () => {
    setRemovedIndexes(prevRemovedIndexes => [...prevRemovedIndexes, index]);
    const fn = `${inputName}._destroy`;
    setValue(fn, "1");
    setValue(`${inputName}.amount`, '');
    setValue(`${inputName}.description`, '');
    setValue(`${inputName}.budgetfeecategoryid`, '');
    setDestroyed(true);
  }

  
  const [testCount, setTestCount] = useState(0);

  useEffect(() => {
    if (fee) {
      // setValue(`budget_fees.${index}._destroy`, null)
      // reset({...getValues(), [`budget_fees.${index}._destroy`]: null})      
      // watch();
      
      // setValue(`budget_fees.${index}.budgetfeecategoryid`, fee.budgetfeecategoryid)
      // setValue(`budget_fees.${index}.description`, fee.description)
      // setValue(`budget_fees.${index}.amount`, fee.amount)
      // reset({
      //   ...getValues(),
      //   [`budget_fees.${index}.id`]: fee.id,
      //   [`inputName.budgetfeecategoryid`]: fee.budgetfeecategoryid,
      //   [`inputName.description`]: fee.description,
      //   [`inputName.amount`]: fee.amount,
      // });

      // alert(getValues(`budget_fees.${index}.id`), null, 2)
    }
    
  }, [fee])


  return (
    <>
    <td className="fit nowrap">
      <input  {...register(`budget_fees.${index}.id`)} defaultValue={fee?.id} type="hidden" name={`budget_fees.${index}.id`} />
      <input  {...register(`${inputName}._destroy`)} type="hidden" name={`${inputName}._destroy`} />

      {(!readOnly && !deleteFeeLoading) &&
        <i onClick={deleteFeeClicked} className="gray margin-right-1 icon-button fas fa-times"></i>
      }
      {budgetPhases && budgetPhases.length > 0 && 
        <select className={`${(readOnly) && 'disabled-input'}`} {...register(`budget_fees.${index}.budgetphaseid`, {
          validate: v => {
            if (destroyed) return true;
            if (!v) return "Select a phase."
            return true;
          }
        })} defaultValue={(fee?.budgetphaseid) ? fee?.budgetphaseid?.toString() : ""}   name={`budget_fees.${index}.budgetphaseid`} id="">
          <option disabled value="">Select phase:</option>
          {budgetPhases?.map(phase =>  {
              return(<option value={phase.id?.toString()}>{phase.phasename}</option>);
            }
          )}
        </select> 
      }

      {errors?.budget_fees?.[index]?.budgetphaseid &&
        <div className="error-cont">{errors?.budget_fees?.[index]?.budgetphaseid?.message}</div>
      }
    </td>
    <td>
              {/* <div onClick={() => setTestCount(o => o+1)} className="button">
                {testCount}
              </div>
            </td>
      <td> */}
        {/* <div className="code">
          <pre>
            {JSON.stringify(errors?.budget_fees?.[index], null, 2)}
          </pre>
        </div> */}
        
         <div className="flex-cont align-center">
          {/* <div>
            {JSON.stringify(fee, null, 2)}
          </div> */}
          

          {budgetFeeCategories && budgetFeeCategories.length > 0 && 

            

            <select className={`${(readOnly) && 'disabled-input'}`} {...register(`budget_fees.${index}.budgetfeecategoryid`, {
              validate: v => {
                if (destroyed) return true;
                if (!v) return "Select a category."
                return true;
              }
            })} defaultValue={(fee?.budgetfeecategoryid) ? fee?.budgetfeecategoryid?.toString() : ""}   name={`budget_fees.${index}.budgetfeecategoryid`} id="">
              <option disabled value="">Select category:</option>
              {budgetFeeCategories?.map(cat =>  {
                  return(<option value={cat.id?.toString()}>{cat.name}</option>);
                }
              )}
            </select> 
          }
        </div>
        {errors?.budget_fees?.[index]?.budgetfeecategoryid &&
          <div className="error-cont">{errors?.budget_fees?.[index]?.budgetfeecategoryid?.message}</div>
        }
      </td>
      <td>
        <div className="flex-cont align-center">
          {deleteFeeLoading && <div className="spinner font-05 margin-right-1"></div> }
          <input 
            className={`${(readOnly) && 'disabled-input'}`} 
            defaultValue={fee?.description} 
            {...register(`budget_fees.${index}.description`, {
              required: "Enter a description."
            })} 
            placeholder="Description" 
            type="text" 
            name={`budget_fees.${index}.description`} 
            id=""
          />
        </div>
        {errors?.budget_fees?.[index]?.description &&
          <div className="error-cont">{errors?.budget_fees?.[index]?.description?.message}</div>
        }
      </td>
      <td>
        <div className="relative flex-cont align-center">
          {/* <div  style={{fontSize: '.8em', position: 'absolute', left: '8px', top: '5px'}}>
            $
          </div> */}
          <div style={{paddingRight: '2px'}}>
            {currencySymbol} 
          </div>
          <input style={{paddingLeft: '0em'}} className={`${(readOnly) && 'disabled-input'}`} defaultValue={fee?.amount} {...register(`budget_fees.${index}.amount`, {
            validate: v => {
              // if (destroyed) return true;
              // if (!v) return "Enter a number."
              // if (!isNumeric(v)) return "Enter a number."
              // if (parseFloat(v) <= 0) return "Enter a number greater than 0."
              // return true;


              if (destroyed) return true;
              // if (v == null) return "Enter a number."
              if (v == null) return "Enter a number."
              if (v.length == 0) return "Enter a number."

              // const vv = v.replaceAll(',', '');
              const vv = v.toString().replace(/,/g, '');

              if (vv == null) return "Enter a number."
              if (vv.length == 0) return "Enter a number."
              if (!isNumeric(vv)) return "Enter a number."
              if (parseFloat(v) <= 0) return "Enter a number greater than 0."

              // if ((parseFloat(vv) * 100) % 25 !== 0) return "Enter a number in 0.25 increments."

              return true;
            }
          })} placeholder="Amount" type="text" name={`budget_fees.${index}.amount`} id=""/>
          
        </div>

        {errors?.budget_fees?.[index]?.amount &&
          <div className="error-cont">{errors?.budget_fees?.[index]?.amount?.message}</div>
        }
      </td>
      {/* <td></td> */}
    </>
  )
}

const mapState = state => ({
});
export default connect(mapState)(BudgetFeeRow);  