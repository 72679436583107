import React, {useState, useEffect} from 'react';
import { DateTime } from 'luxon';

export const isNumber = str => {
  if (str === null || str === undefined || str === "") return false;
  const parsed = parseFloat(str);
  return !isNaN(parsed);
}
export const toNum = str => {
  if (str === undefined || str === null || str === "") {
    return 0.0
  }
  const parsed = parseFloat(str);
  if (!parsed || isNaN(parsed)) return 0.0;

  return parsed.toFixed(2);
}

export const useSortBudgets = () => {

  const [sorted, setSorted] = useState(null);
  const [sortedColumn, setSortedColumn] = useState("projectid");
  const [sortDirection, setSortDirection] = useState(-1);
  const [sortAssociation, setSortAssociation] = useState("");

  const columnClicked = (column, ass) => {
    // alert(ass)
    setSortDirection(o => o * -1);
    setSortedColumn(column);
    setSortAssociation(ass)
  }

  const getType = column => {
    if (column === "approveddate") return "date";
    if (column === "hoursBudgeted" || column === "hoursRemaining" || column === "billing") return "number";
    return "string"
  }

  const sortByColumn = (items, column, direction) => {
    let array = [...items];

    const type = getType(column);

    if (type === "date") {
      return array.sort((a, b) => {
          if (!a[column]) return direction;
          if (!b[column]) return direction*-1;

          const dateA = DateTime.fromISO(a[column]);
          const dateB = DateTime.fromISO(b[column]);
          if (!dateA || !dateB) return -9999999999999;

          if (direction === -1) {
            return dateA.toMillis() - dateB.toMillis()
          }
          else {
            return dateB.toMillis() - dateA.toMillis()
          }
          
        }
      )
    }
    if (type === "string") {
      return array.sort(
        (a, b) => {
          if (!a[column]) return direction;
          if (!b[column]) return direction*-1;

          if (a[column] && b[column]) {
            if (direction === -1) {
              return a[column].localeCompare(b[column])
            }
            else {
              
              return b[column].localeCompare(a[column])
            }
          }
          // return 0
         
        } 
      );
    }
    if (type === "number") {
      return array.sort(
        (a, b) => {

          if (column === "hoursRemaining") {

            // if (
            //   a.hoursBudgeted === "" || a.hoursBudgeted === null || a.hoursBudgeted === undefined || !isNumber(a.hoursBudgeted) ||
            //   a.hoursRemaining === "" || a.hoursRemaining === null || a.hoursRemaining === undefined || !isNumber(a.hoursRemaining) 
            // ) {
            //   return direction;
            // }
            // if (
            //   b.hoursBudgeted === "" || b.hoursBudgeted === null || b.hoursBudgeted === undefined || !isNumber(b.hoursBudgeted) ||
            //   b.hoursRemaining === "" || b.hoursRemaining === null || b.hoursRemaining === undefined || !isNumber(b.hoursRemaining) 
            // ) return direction*-1;

            if (!isNumber(a.hoursBudgeted)) return direction*-1;
            if (!isNumber(b.hoursBudgeted)) return direction*1;

            // if (direction === -1) {
            //   return toNum(a[column]) - toNum(b[column])
            // }
            // else {
            //   return toNum(b[column]) - toNum(a[column])
            // }



            const valA = toNum(a.hoursBudgeted) - toNum(a.hoursRemaining);
            const valB = toNum(b.hoursBudgeted) - toNum(b.hoursRemaining);



            if (isNaN(valA) || valA === null || valA === undefined || !isNumber(valA)) return direction;
            if (isNaN(valB) || valB === null || valB === undefined || !isNumber(valB)) return direction*-1;

            if (direction === -1) {
              return parseFloat(valA) - parseFloat(valB)
            }
            else {
              return parseFloat(valB) - parseFloat(valA)
            }

            
          }

          if (a[column] === null || a[column] === undefined || !isNumber(a[column])) return direction;
          if (b[column] === null || b[column] === undefined || !isNumber(b[column])) return direction*-1;

          if (  isNumber(a[column]) && isNumber(b[column]) ) {
            if (direction === -1) {
              return parseFloat(a[column]) - parseFloat(b[column])
            }
            else {
              return parseFloat(b[column]) - parseFloat(a[column])
            }
          }
          // return 0
        } 
      );
    }
  }




  return {sorted, sortByColumn, setSorted, sortedColumn, sortDirection, columnClicked, sortAssociation};

}

