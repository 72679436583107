import { andyFetch, actionReturn } from '../../api';


// export const getAdminTimesheets = (params) => {

//   return async (dispatch, getState) => {

//     const url = "admin/timesheets";

//     try {
//       const response = await andyFetch(url, params, "GET");

//       if (response.ok) {
//         const json = await response.json();
//         return actionReturn(json);
//       } else {
//         throw new Error('There was an error.');
//       }
//     }
//     catch (e) {
      
//     }
//   }
// };

export const getHoursConsumedReport = (params) => {
  return async (dispatch, getState) => {
    const url = "admin/timesheets/consumed_report";
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();

        return actionReturn(json, response);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};


export const getAdminTimesheets = (params) => {
  return async (dispatch, getState) => {
    const url = "admin/timesheets";
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();

        return actionReturn(json, response);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};