import React, { useEffect, useState } from 'react';

// import TimesheetsNav from './TimesheetsNav';
import { useApi } from '../../api';
import { connect } from 'react-redux';

import { getMyAdminProjects, getMyProjects, getProjects } from '../../redux/projects/projectActions';
import { getReviewCount } from '../../redux/manager/budgetHeaders/budgetHeaderActions';
import { DateTime } from 'luxon';
import {
  Link, useNavigate,
} from 'react-router-dom';

import { usePrevious } from '../../hooks/usePrevious';

import BudgetPrintModal from './BudgetPrintModal';

import TH from './TH';
import { useSortBudgets, toNum, isNumber } from './useSortBudgets';
import Selector from '../../components/Selector';

const BudgetingHome = ({currentCompany, children}) => {

  const navigate = useNavigate('/');
  const prevCompany = usePrevious(currentCompany);

  const [tab, setTab] = useState("all");
  const [filtered, setFiltered] = useState(null);
  const [reviewCountResult, reviewCountLoading, reviewcountLoaded, reviewcountError, doReviewCountFetch, setReviewCountResult] = useApi();
  const [projects, projectsLoading, projectsLoaded, projectsError, doProjectsFetch, setProjectsResult] = useApi();

  useEffect(() => {
    // if (prevCompany && currentCompany) {
    //   if (prevCompany !== currentCompany) {
    //     fetchProjects();
    //   }
    // } 
    fetchProjects();   
  }, [currentCompany]);

  useEffect(() => {
    
    if (projects) {

      if (tab === "all"){
        setFiltered(projects);
      }
      else {
        const f = projects.filter(p => p.budgetHeaderStatus?.toString()?.toLowerCase() === tab);
        setFiltered(f);
      }

      

    }

  }, [projects, tab]);


  

  

  const fetchProjects = () => {
    doProjectsFetch(getMyProjects, {query: {status: "all"}});
  }

  const {sorted, sortByColumn, setSorted, sortedColumn, sortDirection, columnClicked} = useSortBudgets();

  useEffect(() => {
    if (filtered) {
      setSorted(sortByColumn([...filtered], sortedColumn, sortDirection));
    }
  }, [sortedColumn, filtered, sortDirection]);

  useEffect(() => {
    
  }, [sorted]);

  const [printModalOpened, setPrintModalOpened] = useState(false);
  const [printProject, setPrintProject] = useState(null);

  const printClicked = (project) => {
    setPrintModalOpened(true);
    setPrintProject(project);
  }
  const togglePrintModal = () => {
    setPrintModalOpened(o => !o);
  }

  const tabCount = (name) => {

    if (projects == null) return 0;

    if (name === "all"){
      return projects.length;
    }
    else {
      const f = projects.filter(p => p.budgetHeaderStatus?.toString()?.toLowerCase() === name);
      return f.length;
    }
  }


  
  return (
    <>
      {/* <div className="flex-cont align-center">
        <h1>My Budgets</h1>
      </div> */}

      {printModalOpened &&
        <BudgetPrintModal opened={printModalOpened} project={printProject} toggleModal={togglePrintModal} />
      }

      <div className="flex-cont align-center">
        <h1 className="flex-cont">
          <div className="margin-right-half medgray">{currentCompany}</div>
            My Budgets
        </h1>
      </div>  

      <div className="margin-bottom-3">
        <div className={`selector-cont ${projectsLoading ? 'disabled' : ''}`}>          
          <Selector label={"All"} name="all" setTab={setTab} tab={tab} count={tabCount("all")} />
          <Selector label={"Returned"} name="returned" setTab={setTab} tab={tab} count={tabCount("returned")} />
          <Selector label={"Needs Budget"} name="needs budget" setTab={setTab} tab={tab} count={tabCount("needs budget")} />
          <Selector label={"New"} name="new" setTab={setTab} tab={tab} count={tabCount("new")} />
          <Selector label={"Revised"} name="revised" setTab={setTab} tab={tab} count={tabCount("revised")} />
          <Selector label={"Pending"} name="pending" setTab={setTab} tab={tab} count={tabCount("pending")} />
          <Selector label={"Approved"} name="approved" setTab={setTab} tab={tab} count={tabCount("approved")} />
        </div>
      </div>
      

      <div className="content margin-top-1">

        {/* <pre>
          tab: {JSON.stringify(tab, null, 2)}

          {JSON.stringify(projects, null, 2)}
        </pre> */}

    

      
      {projectsError &&
        <div className='margin-top-4'>
          There was an error fetching your projects.
        </div>
      }

      {!projectsLoading && (sorted && sorted.length === 0) &&
        <div className='margin-top-4'>
          No results.
        </div>
      }

      {projectsLoading &&
        <div className="spinner"></div>
      }
      {!projectsLoading && sorted && sorted.length !== 0 &&
        <table className="basic">
          <thead>
            <tr>
              <TH direction={sortDirection} classNames={["fit", "nowrap"]} column="id" sortedColumn={sortedColumn} label="Project ID" columnClicked={columnClicked} />

              <TH direction={sortDirection} classNames={["fit", "nowrap"]} column="budgetHeaderStatus" sortedColumn={sortedColumn} label="Status" columnClicked={columnClicked} />

              <TH direction={sortDirection} classNames={[]} column="name" sortedColumn={sortedColumn} label="Name" columnClicked={columnClicked} />

              <TH direction={sortDirection} classNames={[]} column="firstname" sortedColumn={sortedColumn} label="Project Manager" columnClicked={columnClicked} />

              <TH direction={sortDirection} classNames={[]} column="approveddate" sortedColumn={sortedColumn} label="Budget Approved Date" columnClicked={columnClicked} />

              <TH direction={sortDirection} classNames={["fit"]} column="hoursBudgeted" sortedColumn={sortedColumn} label="Budgeted Hours" columnClicked={columnClicked} />

              <TH direction={sortDirection} classNames={["fit"]} column="hoursRemaining" sortedColumn={sortedColumn} label="Remaining Hours" columnClicked={columnClicked} />

              <TH direction={sortDirection} classNames={["fit"]} column="billing" sortedColumn={sortedColumn} label="% Billing" columnClicked={columnClicked} />
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {sorted && sorted.map( project => 
              <tr>
                
                
                <td className="fit nowrap">
                  <Link className="text-link" to={`/budgeting/projects/${project.id}`}>{project.id}</Link>
                </td>

                <td className="fit nowrap">
                  {/* <div>
                    Status: {project?.budgetHeaderStatus?.toUpperCase()}
                  </div> */}
                  <Link className="text-link" to={`/budgeting/projects/${project.id}`}>
                    <>
                  
                    {project.budgetHeaderStatus &&
                      <div className="status-badge-cont">
                        <div className={`budget status-badge ${project.budgetHeaderStatus.toLowerCase()}`}>
                          {project.budgetHeaderStatus.toUpperCase() }
                        </div>
                      </div>
                    }
                    {/* {!project.budgetHeaderStatus &&
                      <div className="bold font-075 gray">NEEDS BUDGET</div>
                    } */}
                  </>
                  </Link>
                </td>
                <td className="nowrapx">

                  {project.name}
                </td>
                <td className="fit nowrap">
                  {project.firstname} {project.lastname}
                </td>
                <td className="fit nowrap">
                  {project.approveddate ? DateTime.fromISO(project.approveddate).toLocaleString() : ""}
                </td>
                <td className="fit">
                  {project.hoursBudgeted}
                </td>
                <td className="fit">
                  {project.hoursBudgeted !== null &&
                    <>{+(toNum(project.hoursBudgeted) - toNum(project.hoursRemaining)).toFixed(2)}</>
                  }
                  {/* {
                    toNum(project.hoursRemaining)
                  } */}
                </td>
                <td className="fit">
                  {project.billing}%
                </td>
                <td  className="fit nowrap">

                    <Link to={`/budgeting/print/${project.id}`}>
                      <i  className="fas fa-print ghost button"></i>
                      {/* onClick={() => printClicked(project)} */}
                    </Link>
         
                </td>
                <td></td>
              </tr>
            )}
          </tbody>
        </table>
      }

      </div>
    </>
  )
}



const mapState = state => ({
  reviewCount: state.budgetHeaderState.reviewCount,
  currentCompany: state.authState.currentCompany,
});

export default connect(mapState)(BudgetingHome);
