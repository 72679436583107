import React, {useEffect, useState} from "react";
import { DateTime } from 'luxon';
import TimesheetEntry from "./TimesheetEntry";
import TimesheetWeekTotal from "./TimesheetWeekTotal";
import { createEntry } from "../../redux/alphaTimesheets/alphaTimesheetActions";
import { useApi } from "../../api";

const TimesheetWeek = ({isFixing, viewOnly, shouldShowButtons, shouldDisableButtons, setEditingNoteInputName, timesheetResult, setTimesheetResult, setEntryLoading, timesheetID, selectedPeriod, selectedWeekIndex, weekIndex, week, getValues, control, register, projectsResult, trigger, errors, setValue, resetField, projectsLoading, unregister}) => {

  const entries = week.entries;

  const [newEntryOpened, setNewEntryOpened] = useState(false);

  const [entryResult, entryLoading, entryLoaded, entryError, doEntryFetch, setEntryResult] = useApi();

  const addToArray = (array, newEntries) => {
    let newArray = [...array];

    newArray.forEach((w, index) => {
      w.entries.push(newEntries[index]);
    })

    return newArray;
  }

  useEffect(() => {
    if (entryLoaded && entryResult) {
      // alert(JSON.stringify(entryResult, null, 2))
      unregister(`week.${weekIndex}.entries.${0}`);
      setNewEntryOpened(false);
      
      


      setTimesheetResult(old => {
        return(
         {
           ...old,
           summary: [
             ...addToArray(old.summary, entryResult)
           ]
         }
        ) 
       })
    }

  }, [entryLoaded, entryResult])

  const saveNewEntry = async () => {

    // alert(JSON.stringify(timesheetResult?.summary, null, 2))
    const inputName = `week.${weekIndex}.entries.0`;
    const t = await trigger(inputName);
    // const entryErrors = errors?.week?.[weekIndex]; //?.entries?.[entryIndex];
    // alert(JSON.stringify(entryErrors, null, 2))
    if (!t) return; 

   

    const data = getValues(inputName);

    

    const payload = {
      timesheet_entry: {
        ...data,
        timesheetid: timesheetID,
        weekstartdate: week.week_start,
        weekenddate: week.week_end,
      }
      
    }

    // alert(JSON.stringify(payload, null, 2))

    doEntryFetch(createEntry, {
      payload: payload
    })

  }

  useEffect(() => {
    setEntryLoading(entryLoading);
  }, [entryLoading]);

  const cancelClicked = () => {
    unregister(`week.${weekIndex}.entries.${0}`);
    setNewEntryOpened(false);
    
  }

  


  return (
    <>
      <div className={selectedWeekIndex === weekIndex ? "" : "actually-invisible"}>
        <div className="better section margin-right-4">

          <div className="bold no-margin margin-bottom-05 font-1-25">
            Entries <span className="font-08 normal">{week.week_start && DateTime.fromISO(week.week_start).toLocaleString()} to {week.week_end && DateTime.fromISO(week.week_end).toLocaleString()}</span>
          </div>

          {entries.map((entry, entryIndex) => {

            const adjustedEntryIndex = entryIndex + 1;
            return (
              <TimesheetEntry isFixing={isFixing} setTimesheetResult={setTimesheetResult} viewOnly={viewOnly} setEditingNoteInputName={setEditingNoteInputName} timesheetID={timesheetID} key={`week.${weekIndex}.entries.${adjustedEntryIndex}`} resetField={resetField} trigger={trigger} errors={errors} setValue={setValue} getValues={getValues} control={control} projectsLoading={projectsLoading} projectsResult={projectsResult} selectedWeekIndex={selectedWeekIndex} weekIndex={weekIndex} entryIndex={adjustedEntryIndex} entry={entry} register={register} />
            )
          })}

        <TimesheetWeekTotal weekIndex={weekIndex} control={control} />

        </div>

        
        {shouldShowButtons &&
          <div className="section better margin-top-2">
            {!newEntryOpened && shouldShowButtons &&
              <div onClick={() => setNewEntryOpened(true)} className={`button ghost ${shouldDisableButtons ? 'disabled' : ''}`}>
                <i className="fas fa-plus margin-right-half"></i>
                Open New Entry
              </div>
            }

            {newEntryOpened &&
              <div className={`${newEntryOpened ? '' : 'hiddenx'}`}>

                <div className="bold font-1-25">New Entry</div>
                <TimesheetEntry isFixing={isFixing} setTimesheetResult={setTimesheetResult} viewOnly={viewOnly} entryLoading={entryLoading} setEditingNoteInputName={setEditingNoteInputName} timesheetID={timesheetID} week={selectedPeriod} key={`week.${weekIndex}.entries.${0}`} resetField={resetField} trigger={trigger} errors={errors} setValue={setValue} getValues={getValues} control={control} projectsLoading={projectsLoading} projectsResult={projectsResult} selectedWeekIndex={selectedWeekIndex} weekIndex={weekIndex} entryIndex={0} entry={null} register={register} />

                <div className="margin-top-1 flex-cont align-center">
         
                  <div style={{position: "sticky", left: '200px'}}>
                    <div onClick={() => cancelClicked()} className={`${entryLoading ? 'disabled' : ''} ghost button`}>
                      Cancel
                    </div>
                    <div onClick={saveNewEntry} className={`${entryLoading ? 'disabled' : ''} button`}>
                      Save Entry
                      {entryLoading && <div className="button-spinner spinner"></div>  }
                    </div>
                    
                  </div>
                  
                </div>

                
              </div>
            }
            
          </div>
        }
      </div>
    </>
  )
}

export default TimesheetWeek;