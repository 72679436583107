

import React, { useEffect } from 'react';
import SideNav from '../../components/SideNav';
import MainNav from '../../components/MainNav';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

const AdminLayout = ({currentUser, children, trySignout, dynamicsToken}) => {

  const location = useLocation();
  // const navigate = useNavigate("/");


  useEffect(() => {
    if (location.pathname === "/") {
      // navigate("/timesheets");
    } 
  }, [location.pathname]);

  const isAdmin = currentUser?.roleid === 2;

  return (
    <>
      <MainNav trySignout={trySignout} />
      {location.pathname !== "/" &&
        <SideNav />
      }

      <main className={`${isAdmin ? 'admin-main' : ''}`}>
        
        <div className="parent">
          <div className="content-cont flex-cont">
            <div className="relative narrow-2 content margin padding-top-1 padding-left-2">
                <div className="margin-right">
                {/* {JSON.stringify(dynamicsToken?.access_token)} */}
              </div>
              {children}
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
function mapState(state) {
  return { 
    currentAccount: state.authState.currentAccount,
    currentUser: state.authState.currentUser,
    submittedCount: state.timesheetState.submittedCount,
    dynamicsToken: state.authState.dynamicsToken,
  };
} 

export default connect(mapState)(AdminLayout);