import React, { useEffect, useState } from 'react';
import SupportTicketsNav from './SupportTicketsNav';


const SupportTicketsLayout = ({children}) => {

  return (
    <>
      <SupportTicketsNav />
      {children}
    </>
  )
}

export default SupportTicketsLayout;