const initialState = {
  data: [],
  pagy: null,
};

const budgetFeeReducer = ( state = initialState, action) => {
  switch(action.type) {
    case "CLEAR_BUDGET_FEES":
      return initialState
    case "SET_BUDGET_FEES":
      return {
        ...state,
        data: action.budgetFees
      }
    case "ADD_BUDGET_FEE":
      return {
        ...state,
        data: [...state.data, action.budgetFee]
      }
    case "REMOVE_BUDGET_FEE":
      return {
        ...state,
        data: [...state.data.filter(e => e.id.toString() !== action.budgetFeeId.toString())]
      }
    case "CHANGE_BUDGET_FEE":
      return {
        ...state,
        data: state.data.map(budgetFee => budgetFee.id === action.budgetFee.id ? {...action.budgetFee} : budgetFee)
      }
    default :
      return state
  }
}

export default budgetFeeReducer;