import { andyFetch, actionReturn } from '../../api';

export const changeCurrentCompany = (params) => {

  return async (dispatch, getState) => {

    const url = `companies/change_current_company`;

    try {
      const response = await andyFetch(url, params, "POST");

      if (response.ok) {
        const json = await response.json();

        const { data } = json;

        // alert(JSON.stringify(data, null, 2))
  
        if (data) {
          dispatch({
            type: 'SET_CURRENT_COMPANY', 
            currentCompany: data?.current_company,
          });
        }

        localStorage.setItem('currentCompanyID', data?.current_company);


        
        return actionReturn(json);

      } else {
        throw new Error('changeCurrentCompany error.');
      }
    }
    catch (e) {
      
    }
  }
};