import React, { useEffect, useState, useCallback, useRef } from 'react';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link, Outlet, useLocation
} from 'react-router-dom';

import * as yup from "yup";
import { useForm } from 'react-hook-form';

import * as msal from "@azure/msal-browser";
import store from '../../redux/store';
import { connect } from 'react-redux';
import { setTokenResponse } from '../../redux/authActions';

import Modal from '../../components/Modal';
import { useApi } from '../../api';
import { createUser, updateUser, getNewUsers } from '../../redux/admin/userActions';
import { getCompanies } from '../../redux/admin/companyActions';
import { getRoles} from '../../redux/admin/roleActions';
import { getEmployees, getNewEmployees } from '../../redux/admin/employeeActions';

import SubNav from '../../components/SubNav';
import Pagination from '../../components/Pagination';

import { debounce } from "lodash";

import Fuse from 'fuse.js'


const UserSchema = yup.object().shape({
  // fname: yup.string().required("First name is required."),
  // lname: yup.string().required("Last name is required."),
  email: yup.string().required("Email is required."),
  company: yup.string().required("Company is required."),
  enabled: yup.number().oneOf([0, 1]).required("Enabled is required."),
  roleid: yup.string().required("Role is required."),
  workerrecid: yup.string().required("Worker ID is required."),
});


const UserForm = ({currentCompany, toggleModal, formEmployee}) => {

  const { watch, register, formState: {errors}, handleSubmit, getValues, setValue } = useForm({
  });

  const [result, loading, loaded, error, doFetch, setResult] = useApi();
  const [companies, companiesLoading, companiesLoaded, companiesError, doCompaniesFetch, setCompaniesResult] = useApi();
  const [roles, rolesLoading, rolesLoaded, rolesError, doRolesFetch, setRolesResult] = useApi();
  const [employees, employeesLoading, employeesLoaded, employeesError, doEmployeesFetch, setEmployeesResult] = useApi();

  const fetchRoles = () => {
    doRolesFetch(getRoles);
  }
  const fetchCompanies = () => {
    doCompaniesFetch(getCompanies);
  }


  useEffect(() => {
    // fetchNewEmployees();
    fetchCompanies();
    fetchRoles();
  }, []);


  const onSubmit = async data => {
    let d = {
      user: {
        ...data
      }
    }

    doFetch(createUser, {payload: d});
    

  }

  useEffect(() => {
    if (loaded) {
      toggleModal(true);
    }
  }, [loaded]);

  // const roles = ["Employee", "Manager", "Administrator"];



  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="modal-header flex-cont align-center">
        <h2 className="flex-1">New User</h2>
        <div onClick={() => toggleModal(false)} className="ghost button close">
          <i className="fas fa-times"></i>
        </div>
      </div>

      <div className="padding-1">
        <div className="bold">
          {formEmployee.firstname} {formEmployee.lastname} - {formEmployee.recid}
        </div>
        <div className="flex-cont margin-top-1" >

          


          <input {...register('workerrecid', {
            required: 'Enter Worker ID.'
          })} type="hidden" name="workerrecid" value={formEmployee.recid} />
          <label htmlFor="company"  className="margin-right-1"> 
            {companiesLoading &&
              <div className="editable-cont loading">
                <div className="absolute spinner"></div>
              </div>
            }
            {!companiesLoading && companies &&
              <select 
              defaultValue={currentCompany} 
              {...register("company", {
                // required: "Select company."
              })} name="company">
                <option className="gray" disabled selected value="">Select company:</option>
                {companies?.map((company, index) => <option value={company?.legalentityid}>{company?.legalentityid}</option>)}
              </select>
            }
            {errors.company && <div className="field-error">{errors.company.message}</div>}
          </label>

          <label htmlFor="roleid"  className="margin-right-1"> 
            {rolesLoading &&
              <div className="editable-cont loading">
                <div className="spinner"></div>
              </div>
            }
            {!rolesLoading && roles?.length &&
              <select defaultValue={roles[0]?.roleid} 
              {...register('roleid', {
                required: "Select role."
              })} name="roleid">
                <option className="gray" disabled selected value="">Select role:</option>
                {roles?.map((role, index) => {
                    return (<option value={role?.roleid}>{role?.rolename}</option>)

                  
                })}
              </select>
            }
            {errors.roleid && <div className="field-error">{errors.roleid.message}</div>}
          </label>

          <label htmlFor="email"  className="margin-right-half"> 
            <input 
              type="text" 
              {...register('email', {
                required: 'Enter AAD login email.'
              })}
              placeholder="AAD Login Email"
              name="email"
              defaultValue={formEmployee?.workeremail}
              className={`${errors.email ? 'has-errors' : ''}`}
            />
            {errors.email && <div className="field-error">{errors.email.message}</div>}
          </label>

          <label htmlFor="enabled"  className="margin-right-half"> 
            <div style={{marginBottom: '4px'}} className="gray bold font-075 uppercase">ENABLED?</div>

            <div className="flex-cont align-center">
              <label className="margin-right-half">
                <input type="radio" {...register('enabled')} name='enabled'  value={1} defaultChecked={true}  />
                <span>Yes</span>
              </label>
              <br />
              <label>
                <input type="radio" {...register("enabled")} name="enabled" value={0}   />
                <span>No</span>
              </label>
            </div>
            
            {errors.enabled && <div className="field-error">{errors.enabled.message}</div>}
          </label>
        </div>

        {/* <div>{JSON.stringify(errors)}</div> */}
      </div>

      <div className="modal-footer align-center flex-cont">
        
        <input type="submit" value="Save" className={`${loading ? 'disabled' : ''} button`} />

        {loading &&
          <div className="margin-left-1 spinner"></div>
        }
      </div>

    </form>
  )
}


function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {

    
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setTimeout(tick, 500);
      return () => clearTimeout(id);
    }
  }, [delay]);
}


const AdminNewEmployees = ({currentCompany}) => {

  

  const [delay, setDelay] = useState(null)
  const [page, setPage] = useState(1);

  const handlePageChange = (p) => {
    setPage(p);
  };

  const [modalOpened, setModalOpened] = useState(false);
  const [editingEmployee, setEditingEmployee] = useState(null);

  // const [result, loading, loaded, error, doFetch, setResult] = useApi();




  const [newEmployees, newEmployeesLoading, newEmployeesLoaded, newEmployeesError, doNewEmployeesFetch, setNewEmployeesResult, pagy] = useApi();




  const fetchNewEmployees = () => {
    doNewEmployeesFetch(getNewEmployees, {query: {search: search, page: page}});
  }
  
  useEffect(() => {
    fetchNewEmployees();
  }, [page]);




  const toggleModal = (reload = false) => {
    // alert('here');
    setModalOpened(old => !old);

    if (reload) {
      fetchNewEmployees();
    }
  }

  const editClicked = employee => {
    setEditingEmployee(employee);
    toggleModal(false);
  }

  useEffect(() => {
    if (!modalOpened) {
      setEditingEmployee(null);
    }
  }, [modalOpened])


  const searchFn = () => {
    alert('here');
    // doNewEmployeesFetch(getNewEmployees, {query: {search: search, page: 1}});
  }
  const handler = useCallback(debounce(searchFn, 300), []);

  const [search, setSearch] = useState(null);

  const handleSearchSubmit = () => {
    alert('handleSearchSubmit');
  }


  const [lastType, setLastType] = useState(new Date().getTime());

 

  const [timer, setTimer] = useState(null);

  const searchInputChanged = e => {
    const value = e.target.value;
    setSearch(value);
  }

  // const timeout = setTimeout(() => {
  //   alert('here');
  //   clearTimeout(timeout);
  //   // doNewEmployeesFetch(getNewEmployees, {query: {search: search, page: 1}});
  // }, 1000);

  useInterval(() => {  
    
    // if (Date.now() - delay > 500) {
    //   setCount(count + 1);  
    // }


    doNewEmployeesFetch(getNewEmployees, {query: {search: search, page: 1}});
    
  }, delay);

  useEffect(() => {
    setDelay(Date.now());
    // 

  }, [search])



  // useEffect(() => {
  //   // doNewEmployeesFetch(getNewEmployees, {query: {search: search, page: 1}});

  //   if (timer) {
  //     clearTimeout(timer);
  //     // alert('Reset');
      
  //     const t = setTimeout(() => {
  //       clearTimeout(timer);
  //       doNewEmployeesFetch(getNewEmployees, {query: {search: search, page: 1}});
  //       // alert('Send');
  //     }, 300);
  
  //     setTimer(t);
  //   }
  //   else {

  //     // alert('Init');
      
  //     const t = setTimeout(() => {
  //       clearInterval(timer);
  //       doNewEmployeesFetch(getNewEmployees, {query: {search: search, page: 1}});
  //       // alert('Init Send');
  //     }, 300);
  
  //     setTimer(t);

  //   }

  // }, [search]);


  const [filtered, setFiltered] = useState(null);


  // useEffect(() => {
  //   if (newEmployees) {
  //     const fuse = new Fuse(newEmployees, {
  //       keys: ['fullname']
  //     });

  //     
  //     


  //     setFiltered(fuse.search(search));
  //   }
  // }, [newEmployees])

  // useEffect(() => {
  //   if (lastType) {
  //     const currentTime = new Date().getTime();

  //     // Clear interval now
  //     clearInterval(timer);

  //     timer = setTimeout(() => {
  //       alert('last typed')
  //     }, 1000);
  //   }
  // }, [lastType]);

  return (
    <>
      {modalOpened &&
        <Modal toggleModal={toggleModal}>
          <UserForm currentCompany={currentCompany} formEmployee={editingEmployee} toggleModal={toggleModal}/>
        </Modal>
      }

        <div className="xxx margin padding-2x">

          <div className="flex-cont">
            <h1 className="flex-cont">
              <div className="medgray margin-right-half">{currentCompany}</div>
              Add New Employees
            </h1>
          </div>

          
          {/* <div>FILTERED: {JSON.stringify(filtered)}</div> */}

          <div className="seearch-cont margin-bottom-1">
 
              <input onChange={e => searchInputChanged(e)} placeholder="Search new employees..." type="text" name="" id=""/>
     

          </div>
          
{/* 
          <div className="code">
            <pre>
              pagy: {JSON.stringify(pagy, null, 2)}
            </pre>
            <pre>
              page: {JSON.stringify(page, null, 2)}
            </pre>
          </div> */}

          <Pagination  currentPage={page} pagy={pagy} handlePageChangeFn={handlePageChange} />

          {/* <SubNav /> */}

          {/* <div className="button" onClick={() => toggleModal(false)}>
            <i className="fas fa-plus margin-right-half"></i>
            <span>Add User</span>  
          </div> */}

          

          <div className="margin-top-2">

            {newEmployeesLoading &&
              <div className="margin-left-1 spinner"></div>
            }

            {/* <div className="code">
              <pre>
                companies: {JSON.stringify(companies, null, 2)}
              </pre>
            </div> */}


            {!newEmployeesLoading &&
              <table className="basic">
                <tr>  
                  <th>First</th>
                  <th>Last</th>
                  <th className="fit nowrap">Worker Email</th>
                  <th>D365 ID</th>

                  <th></th>
                  <th></th>
                </tr>
                {newEmployees && newEmployees.map(employee => {
                  return (
                    <tr>
                      <td className="fit">{employee.firstname}</td>
                      <td className="fit nowrap">{employee.lastname}</td>
                      <td className="fit nowrap">
                        {employee.workeremail}
                      </td>
                      
                      <td className="fit">{employee.personnelnumber}</td>
                      
                      <td  className="fit">
                        <i className="ghost button fas fa-user-plus" onClick={() => editClicked(employee)}></i>
                      </td>
                      <td className="full"></td>
                    </tr>
                  )
                })}
              </table>
            }
          </div>

          <div className="margin-top-2">
            <Pagination  currentPage={page} pagy={pagy} handlePageChangeFn={handlePageChange} />
          </div>
          
        </div>

      
    </>
  );
}

function mapState(state) {
  return { 
    // tokenResponse: state.authState.tokenResponse,
    currentCompany: state.authState.currentCompany,
  };
} 

export default connect(mapState)(AdminNewEmployees);
