import { andyFetch, actionReturn } from '../../api';


export const getEntries = (params) => {

  return async (dispatch, getState) => {

    const url = "timesheet_entries";

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};

export const getManagedEntries = (params) => {
  return async (dispatch, getState) => {
    const url = "timesheet_entries/managed";
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};


export const createEntry = (params) => {

  return async (dispatch, getState) => {

    const url = "timesheet_entries";

    try {
      const response = await andyFetch(url, params, "POST");
      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);

      } else {
        throw new Error('createTimesheet error.');
      }
    }
    catch (e) {

      
    }
  }
};
export const submitTimesheet = (params) => {

  return async (dispatch, getState) => {

    const url = "timesheet_entries/submit_timesheet";

    try {
      const response = await andyFetch(url, params, "POST");
      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);

      } else {
        throw new Error('submitTimesheet error.');
      }
    }
    catch (e) {

      
    }
  }
};
export const saveTimesheet = (params) => {

  return async (dispatch, getState) => {

    const url = "timesheet_entries/save_timesheet";

    try {
      // const response = await andyFetch(url, params, "POST");
      // const json = await response.json();
      // 
      const response = await andyFetch(url, params, "POST");
      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);

      } else {
        throw new Error('saveTimesheet error.');
      }
      // if (response.ok) {
        
      //   return actionReturn(json);

      // } else {
        
      //   return json.message;
        
      //   // throw new Error('saveTimesheet error.');
      // }
    }
    catch (e) {

      
    }
  }
};

export const saveNote = (params) => {
  return async (dispatch, getState) => {
    const url = "timesheet_entries/save_note";
    try {
      const response = await andyFetch(url, params, "POST");
      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);
      } else {
        throw new Error('saveNote error.');
      }
    }
    catch (e) {
      
    }
  }
};

export const approveLine = (params) => {
  return async (dispatch, getState) => {
    const url = "timesheet_entries/approve";
    try {
      const response = await andyFetch(url, params, "POST");
      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);
      } else {
        throw new Error('approveLine error.');
      }
    }
    catch (e) {
      
    }
  }
};

export const returnLine = (params) => {
  return async (dispatch, getState) => {
    const url = "timesheet_entries/reject";
    try {
      const response = await andyFetch(url, params, "POST");
      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);
      } else {
        throw new Error('returnLine error.');
      }
    }
    catch (e) {
      
    }
  }
};


export const deleteEntry = (params) => {

  return async (dispatch, getState) => {

    const url = "timesheet_entries/delete";

    try {
      const response = await andyFetch(url, params, "POST");
      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);

      } else {
        throw new Error('deleteEntry error.');
      }
    }
    catch (e) {

      
    }
  }
};

export const setEntries = (entries) => {
  return dispatch => {
    dispatch({
      type: "SET_ENTRIES",
      entries: entries
    });
  }
}

export const addEntry = (entries) => {
  return dispatch => {
    dispatch({
      type: "ADD_ENTRY",
      entries: entries
    });
  }
}
export const removeEntry = (entryId) => {
  return dispatch => {
    dispatch({
      type: "REMOVE_ENTRY",
      entryId: entryId
    });
  }
}
export const removeEntries = (ids) => {
  // alert(JSON.stringify(ids, null, 2))
  return dispatch => {

    ids.forEach(id => {
      dispatch({
        type: "REMOVE_ENTRY",
        entryId: id
      });
    })
    
  }
}
export const destroyEntries = (ids) => {
  // alert(JSON.stringify(ids, null, 2))
  return dispatch => {

    ids.forEach(id => {
      // alert(id.toString())
      dispatch({
        type: "DESTROY_ENTRY",
        entryId: id
      });
    })
    
  }
}
export const changeEntry = (entry) => {
  return dispatch => {
    dispatch({
      type: "CHANGE_ENTRY",
      entry: entry
    });
  }
}