import React, { useEffect, useState, useRef } from 'react';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link, Outlet, useLocation
} from 'react-router-dom';

import * as yup from "yup";
import { useForm, useWatch } from 'react-hook-form';

import * as msal from "@azure/msal-browser";
import store from '../../redux/store';
import { connect } from 'react-redux';
import { setTokenResponse } from '../../redux/authActions';

import Modal from '../../components/Modal';
import { useApi } from '../../api';
import { getAdminTimesheets } from '../../redux/admin/timesheetActions';
import { getCompanies } from '../../redux/admin/companyActions';
import { getRoles} from '../../redux/admin/roleActions';
import Pagination from '../../components/Pagination';

import SubNav from '../../components/SubNav';

import { DateTime } from 'luxon';
import CheckBoxes from '../../components/CheckBoxes';

import { repostTimesheet, deleteTimesheet } from '../../redux/timesheets/timesheetActions';



function useInterval(callback, timeout, search) {
  const timeoutId = useRef();

  // Remember the latest callback.
  useEffect(() => {
    timeoutId.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {

    clearTimeout(timeoutId.current);
    timeoutId.current = setTimeout(callback, timeout);

    return () => clearTimeout(timeoutId.current);

    // 
    // function tick() {
    //   savedCallback.current();
    // }
    // if (search !== null) {
    //   let id = setTimeout(tick, delay);
    //   return () => clearTimeout(id);
    // }
  }, [search]);
}



const AdminTimesheets = ({viewAsUser, currentCompany}) => {

  const wrapperRef = useRef(null);

  useEffect(() => {
    // document.addEventListener("mousedown", handleClickOutside, false);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = event => {
    // alert(timesheetTooltipIsOpen)
    // hideTooltip();
    // // alert(timesheetTooltipIsOpen);
    // 
    // 

    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      hideTooltip();
    }
  };

  // const [timesheetTooltipIsOpen, setTimesheetTooltipIsOpen] = useState(false);
  const [timesheetTooltip, setTimesheetTooltip] = useState(null);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  


  const showTooltip = (id) => {
    document.addEventListener("mousedown", handleClickOutside, false);
    setTimesheetTooltip(id);
  }
  const hideTooltip = () => {
    document.removeEventListener("mousedown", handleClickOutside, false);
    setTimesheetTooltip(null);
  }

  const toolTipClicked = (id) => {

    if (timesheetTooltip == null) {
      showTooltip(id);
    }
    else {
      hideTooltip();
    }
    // if (!timesheetTooltipIsOpen) {
    //   showTooltip();
    // }
    // else {
    //   hideTooltip();
    // }
  }

  const [printModalOpened, setPrintModalOpened] = useState(false);
  const [printTimesheet, setPrintTimesheet] = useState(null);

  const printClicked = (timesheet) => {
    setPrintModalOpened(true);
    setPrintTimesheet(timesheet);
  }
  const togglePrintModal = () => {
    setPrintModalOpened(o => !o);
  }


  const [page, setPage] = useState(1);
  const [search, setSearch] = useState(null);
  const [modalOpened, setModalOpened] = useState(false);
  
  const [onlyInCompanyChecked, setOnlyInCompanyChecked] = useState(false);

  const [result, loading, loaded, error, doFetch, setResult, pagy, apiRef] = useApi();
  
  useEffect(() => {
    fetchTimesheets();
  }, []);

  const fetchTimesheets = () => {
    doFetch(getAdminTimesheets, {query: {"statuses[]": selectedStatuses, all_companies: onlyInCompanyChecked ? null : true, search: search, page: page}});
  }

  useEffect(() => {
    fetchTimesheets();
    setModalOpened(false);
  }, [onlyInCompanyChecked, page, currentCompany, selectedStatuses]);

  useEffect(() => {
    // setOnlyInCompanyChecked();
  }, [currentCompany]);




  const toggleModal = (reload = false) => {
    // alert('here');
    setModalOpened(old => !old);

    if (reload) {
      fetchTimesheets();
    }
  }



  useEffect(() => {

  }, [modalOpened])


  const [count, setCount] = useState(0);



  const handlePageChange = (p) => {
    setPage(p);
  };

  const searchInputChanged = e => {
    const value = e.target.value;
    setSearch(value);
  }

  const [delay, setDelay] = useState(null)

  useInterval(() => {  
    doFetch(getAdminTimesheets, {query: {all_companies: onlyInCompanyChecked ? null : true, search: search, page: 1}});
    
    
    setCount(o => o+1);
  }, 500  , search);

  // useEffect(() => {
  //   setDelay(3000);
  // }, [search])

  

  const statusBadge = (statusText) => {
    const s = statusText?.toUpperCase();

    if (s === "SUBMITTED" || s === "DRAFT") {
      return (
        <div className="bold font-075">
          {statusText?.toUpperCase()}
        </div>
      )
      
    }
    else if (s === "RETURNED") {
      return (
        <div className={`status-badge-cont ${statusText?.toLowerCase()} font-075`}>
          <div className="status-badge">
            {/* fas fa-exclamation-triangle  */}
            <i className={`${s === "RETURNED" ? 'fas fa-exclamation-triangle' : 'fas fa-check-circle'} margin-right-half font-1`}></i>
            <div className="font-1">{statusText?.toUpperCase()} </div>
          </div>
        </div>
      )
    }
    else {
      return (
        <div className={`status-badge-cont ${statusText?.toLowerCase()} font-075`}>
          <div className="status-badge">
            {/* fas fa-exclamation-triangle  */}
            {s === "POSTED" &&
              <i className={`fas fa-check-circle margin-right-half font-1`}></i>
            }
            <div className="font-1">{statusText?.toUpperCase()} </div>
          </div>
        </div>
      )
    }
  }

  const [delModalOpened, setDelModalOpened] = useState(false);
  const [delTimesheet, setDelTimesheet] = useState(null);

  const toggleDelModal = (reload=false) => {
    setDelModalOpened(o => !o);

    if (reload) {
      fetchTimesheets();
    }
    
  }

  const timesheetDeleteClicked = async (timesheet) => {
    setDelTimesheet(timesheet);
    toggleDelModal();
  }

  const [currentLog, setCurrentLog] = useState(null);

  const viewErrorClicked = timesheet => {
    setCurrentLog(timesheet);
  };

  const toggleErrorModal = () => {
    setCurrentLog( null);
  }

  

  const tabCount = status => {
    return null;
  }

  const [deleteResult, deleteLoading, deleteLoaded, deleteError, doDeleteFetch, setDeleteResult] = useApi();
  const tryDeleteTimesheet = () => {
    if (delTimesheet) {
      const payload = {
        timesheet: {
          id: delTimesheet.id
        }
      }
      doDeleteFetch(deleteTimesheet, {payload: payload});
    }
  }
  useEffect(() => {
    if (deleteLoaded) {
      toggleDelModal(true);
    }
  }, [deleteLoaded]);

  const [reportResult, repostLoading, repostLoaded, repostError, doRepostFetch, setRepostResult, repostPagy] = useApi();
  const [repostID, setRepostID] = useState(null);

  const repostClicked = timesheetid => {
    setRepostID(timesheetid);
    doRepostFetch(repostTimesheet, {payload: {timesheetid: timesheetid}});
  }

  useEffect(() => {
    // setRepostID(null);

    // alert(repostLoaded);

    if (repostLoaded) {
      // alert("here")
      fetchTimesheets();
    }
  }, [repostLoaded]);


  return (
    <>

{delModalOpened &&
        <Modal size="small" toggleModal={toggleDelModal} focusTrap={false}>
          <div className="modal-header flex-cont align-center">
            <h2 className="flex-1">Delete Timesheet</h2>
            <div onClick={toggleDelModal} className="ghost button close">
              <i className="fas fa-times"></i>
            </div>
          </div>

          <div className="padding-1">
            Are you sure you want to delete {delTimesheet?.number}?
          </div>

          <div className="modal-footer align-center flex-cont">
            <div onClick={toggleDelModal} className="ghost button margin-right-1">Cancel</div>
            <div onClick={tryDeleteTimesheet} className={`danger button margin-right-1 ${deleteLoading ? 'disabled' : ''}`}>Delete</div>
            {deleteLoading &&
              <div className="spinner"></div>
            }
          </div>
        </Modal>
      }

      {currentLog && 
        <Modal toggleModal={toggleErrorModal} focusTrap={false}>

          <div className="modal-header flex-cont align-center">
            <h2 className="flex-1">TM{currentLog?.id}</h2>
            <div onClick={toggleErrorModal} className="ghost button close">
              <i className="fas fa-times"></i>
            </div>
          </div>

          <div className="padding-1">
            {/* <div>
              <pre>
                {JSON.stringify(currentLog, null, 2)}
              </pre>
            </div> */}
            <div>
              {currentLog?.outboundmessage}
            </div>
          </div>
          <div className="modal-footer"></div>
        </Modal>
      }
        <div className="flex-cont">
          <h1 className="flex-cont">
            <div className="medgray margin-right-half">{currentCompany}</div>
            Manage Timesheets
          </h1>
        </div>

          <div className="seearch-cont margin-bottom-1 flex-cont align-center">

            {viewAsUser?.roleid?.toString() === "2" &&
              <div className="margin-right-1 flex-cont align center">
                <input checked={onlyInCompanyChecked} onChange={e => setOnlyInCompanyChecked(e.target.checked)}  type="checkbox" name="" id="" className="margin-right-half" />
                <div className="bold gray uppercase font-075 nowrap">Only in {currentCompany}</div>
              </div>
            }
            <input onChange={e => searchInputChanged(e)} placeholder="Search timesheets..." type="text" name="" id=""/>
          </div>

          <div className={`margin-bottom-2 margin-right-2 check-boxes ${loading ? 'disabled' : ''}`}>          
            <CheckBoxes disabled={loading} label={"Draft"} name="0" boxes={selectedStatuses} setBoxes={setSelectedStatuses}  count={tabCount("new")} />
            <CheckBoxes disabled={loading} label={"Submitted"} name="1" boxes={selectedStatuses} setBoxes={setSelectedStatuses}  count={tabCount("new")} />
            <CheckBoxes disabled={loading} label={"Approved"} name="2" boxes={selectedStatuses} setBoxes={setSelectedStatuses}  count={tabCount("doing")} />
            <CheckBoxes disabled={loading} label={"Returned"} name="3" boxes={selectedStatuses} setBoxes={setSelectedStatuses}  count={tabCount("doing")} />
            <CheckBoxes disabled={loading} label={"Posted"} name="5" boxes={selectedStatuses} setBoxes={setSelectedStatuses}  count={tabCount("doing")} />
            <CheckBoxes disabled={loading} label={"Posting Failed"} name="6" boxes={selectedStatuses} setBoxes={setSelectedStatuses}  count={tabCount("doing")} />
          </div>


          <Pagination currentPage={page} pagy={pagy} handlePageChangeFn={handlePageChange} />

          

          <div className="margin-top-2">

            {loading &&
              <div className="margin-left-1 spinner"></div>
            }

          

            {!loading &&
              <table className="margin-top-3x basic">
                <thead>
                  <tr>
                    {/* <th className="fit nowrap">
                      <div onClick={toggleAllTimesheets} className={allTimesheetsSelected() ? "selected check-box" : "check-box"}>
                        <div className="check"></div>
                      </div>
                    </th> */}
                    {/* <th className="fit nowrap">USER ID</th> */}
                    <th>Tools</th>
                    <th>Number</th>
                    <th>Company</th>
                    <th>Employee</th>
                    <th>Known As</th>

                    {true &&
                      <th className="fit nowrap">Created By</th>
                    }
                    
                    <th className="fit nowrap">Status</th>
                    <th className="fit nowrap">Start Date</th>
                    <th className="fit nowrap" d>End Date</th>
                    <th>Hours</th>
                    <th>Error</th>
                    <th>Journal</th>
                    <th>Outbound Time</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {result && result.length !== 0 && result?.map(timesheet => 
                  <tr>
                  {/* <td>
                    <div onClick={() => selectTimesheet(timesheet.id)} className={timesheetIsSelected(timesheet.id) ? "selected check-box" : "check-box"}>
                      <div className="check"></div>
                    </div>
                  </td> */}
             
     
                  <td>
                    <div ref={wrapperRef}>
                    
                    <div onClick={() => toolTipClicked(timesheet.id)} className="tooltip-cont ellipsis-v-cont">
                      <i className="fas fa-ellipsis-v"></i>
                      <div className={timesheetTooltip === timesheet.id ? "open timesheet tooltip" : "timesheet tooltip"}>
                        <ul>
                          <li><Link target="_blank" to={`/timesheets/${timesheet.id}`}>Open</Link></li>
                          {/* {timesheet.statusid === 0 &&
                            <li onClick={() => timesheetSubmitClicked(timesheet)}><a>Submit</a></li>
                          } */}
                          {timesheet.statusid === 6 && timesheet.outboundstatus === 3 && 
                              <li>
                                <a onClick={() => repostClicked(timesheet.id)}>Repost</a>
                              </li>
                          }
  
                          {timesheet.statusid === 0 &&
                            <li onClick={() => timesheetDeleteClicked(timesheet)}><a>Delete</a></li>
                          }
                        </ul>
                      </div>
            
                    </div>
                  </div>
                </td>
                    
           
        
                  
                  <td className="fit nowrap">
                    <Link className="text-link" to={`/timesheets/${timesheet.id}`}>
                      {timesheet.number}
                    </Link>
                  </td>
                  <td className="fit nowrap">
                    {timesheet.company}
                  </td>
                  <td className="fit nowrap">
                    <div className="flex-cont align-center">
                      {true &&
                        <div>{timesheet.employeeName}</div>
                      }
                      <span>
                        
                      </span>
                    </div>
                   
                  </td>
                  <td className="fit nowrap">
                    {timesheet.knownas}
                  </td>
                  {true &&
                    <td className="fit nowrap">
                      {true &&
                        <div>{timesheet.delegatename}</div>
                      }
                    </td>
                  }
                  <td className="center fit nowrap bold">
                    <Link className="text-link" to={`/timesheets/${timesheet.id}`}>
                      {statusBadge(timesheet.status)}
                    </Link>
                  </td>
                  <td className="fit nowrap">{DateTime.fromISO(timesheet.weekStart).toLocaleString()}</td>
                  <td className="fit nowrap">{DateTime.fromISO(timesheet.weekEnd).toLocaleString()}</td>
                  <td className="fit nowrap">{timesheet.totalHours}</td>
                   
                  <td className="fit nowrap">
                    {timesheet.outboundmessage != null &&
                      <div onClick={() => viewErrorClicked(timesheet)} className="margin-left-0 button ghost font-08">
                        VIEW
                      </div>
                    }
                  </td>
                  <td>{timesheet.journalnum}</td>
                  <td>{timesheet.createddate}</td>
                
                </tr>
      
                
              )}
              </tbody>
            </table>
            }
          </div>


          <div className="margin-top-2">
            <Pagination  currentPage={page} pagy={pagy} handlePageChangeFn={handlePageChange} />
          </div>



      
    </>
  );
}

function mapState(state) {
  return { 
    // tokenResponse: state.authState.tokenResponse,
    currentCompany: state.authState.currentCompany,
    viewAsUser: state.authState.viewAsUser,
  };
} 

export default connect(mapState)(AdminTimesheets);
