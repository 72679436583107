import { andyFetch, actionReturn } from '../../api';

export const getEmployees = (params) => {

  return async (dispatch, getState) => {

    const url = "admin/employees";

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return actionReturn(json, response);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};

export const getNewEmployees = (params) => {
  return async (dispatch, getState) => {
    const url = "admin/employees/new_employees";
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {

        const json = await response.json();
        return actionReturn(json, response);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};



// 

// if (response.headers.get('Current-Page')) {
//   setPagy({
//     currentPage: parseInt(response.headers.get('Current-Page')),
//     pageItems: parseInt(response.headers.get('Page-Items')),
//     totalCount: parseInt(response.headers.get('Total-Count')),
//     totalPages: parseInt(response.headers.get('Total-Pages')),
//   })
// }