import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { getEntries } from '../redux/entries/entryActions';
import { useApi } from '../api';

import {
  useParams
} from 'react-router-dom';

import { getTimesheet } from '../redux/timesheets/timesheetActions';

const TimesheetPrintScreen2 = () => {

  const { timesheetId } = useParams();

  useEffect(() => {
    fetchTimesheet();
    fetchEntries();
  }, []);

  const [entries, entriesLoading, entriesLoaded, entriesError, doEntriesFetch, setEntriesResult] = useApi();
  const fetchEntries = () => {
    doEntriesFetch(getEntries, {query: {timesheetid: timesheetId}});
  }

  const [timesheet, timesheetLoading, timesheetLoaded, timesheetError, doTimesheetFetch, setTimesheetResult] = useApi();
  const fetchTimesheet = () => {
    doTimesheetFetch(getTimesheet, {query: {id: timesheetId}});
  }


  return (
    <div style={{background: 'white', padding: '0'}}>

      <div style={{margin: '0', background: 'white', border: '1px solid rgba(0,0,0,.1)', width: '8.5in', height: '11in'}}>


      {timesheet &&

        <>
        {/* <div className="code">
          <pre>
            {JSON.stringify(timesheet, null, 2)}
          </pre>
        </div> */}
        <table className="print-head margin-bottom-3">
          <tr>
            <th>Employee Name</th>
            <td>{timesheet.employeeName}</td>
          </tr>
          <tr>
            <th>Employee ID</th>
            <td>{timesheet.partyNumber}</td>
          </tr>
          <tr>
            <th>Approval Status</th>
            <td>{timesheet.status}</td>
          </tr>
          <tr>
            <th>Timesheet Number</th>
            <td>{timesheet.number}</td>
          </tr>
          <tr>
            <th>Period From</th>
            <td>{timesheet.weekStart}</td>
          </tr>
          <tr>
            <th>Period To</th>
            <td>{timesheet.weekEnd}</td>
          </tr>
          
        </table>
        </>
      }
        



      <table className="print">
        <thead>
          <tr>
            <th>Proj.</th>
            <th>Activity</th>
            <th>Subactivity</th>
            <th>Cat.</th>
            <th>Start</th>
            <th>End</th>
            <th>Hr</th>
            <th>Su</th>
            <th>M</th>
            <th>Tu</th>
            <th>W</th>
            <th>Th</th>
            <th>F</th>
            <th>Sa</th>
            <th>Su</th>
          </tr>
        </thead>
        <tbody>
          {entries && entries.map(entry => {
            return(
              <tr>
                <td>{entry.projectName}<br />{entry.projectId}</td>
                <td>{entry.activityName}<br />{entry.activityNumber}</td>
                <td>{entry.subactivityName}<br />{entry.subactivityNumber}</td>
                <td>-</td>
                <td>{entry.weekStart}</td>
                <td>{entry.weekEnd}</td>
                <td className="bold center">{entry.totalPeriodHours}</td>
                <td className={`center ${entry.day1hour.toString() !== '0.0' ? 'boldx' : 'gray'}`}>{entry.day1hour}</td>
                <td className={`center ${entry.day2hour.toString() !== '0.0' ? 'boldx' : 'gray'}`}>{entry.day2hour}</td>
                <td className={`center ${entry.day3hour.toString() !== '0.0' ? 'boldx' : 'gray'}`}>{entry.day3hour}</td>
                <td className={`center ${entry.day4hour.toString() !== '0.0' ? 'boldx' : 'gray'}`}>{entry.day4hour}</td>
                <td className={`center ${entry.day5hour.toString() !== '0.0' ? 'boldx' : 'gray'}`}>{entry.day5hour}</td>
                <td className={`center ${entry.day6hour.toString() !== '0.0' ? 'boldx' : 'gray'}`}>{entry.day6hour}</td>
                <td className={`center ${entry.day7hour.toString() !== '0.0' ? 'boldx' : 'gray'}`}>{entry.day7hour}</td>
                <td className={`center ${entry.day8hour.toString() !== '0.0' ? 'boldx' : 'gray'}`}>{entry.day8hour}</td>
              </tr>
            )
          })}

        </tbody>
      </table>

      </div>
    </div>

  )

  
};





const mapState = state => ({
  // timesheet: state.timesheetState.currentTimesheet,
  // timesheets: state.timesheetState.data,
  // weeks: state.timesheetState.weeks,
  // weekSummary: state.timesheetState.weekSummary,
  // isLoading: state.timesheetState.isLoading,
  // // weekSummaryIsLoading: state.timesheetState.weekSummaryIsLoading,
  // weeksIsLoading: state.timesheetState.weeksIsLoading,
  // // projects: state.projectState.projects,
  // // phases: state.phaseState.phases,
  // // categories: state.categoryState.categories,
  // entries: state.timesheetEntryState.data,
  // fullScreenLoading: state.timesheetState.fullScreenLoading,
  // unsavedEntries: state.timesheetEntryState.unsavedEntries,
});

export default connect(mapState)(TimesheetPrintScreen2);
