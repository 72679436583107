import React, { useLayoutEffect, useEffect, useState, useRef } from 'react';

import { useForm } from 'react-hook-form';


import Modal from '../../../components/Modal';
import { useApi } from '../../../api';


import { getDelegateUsers } from '../../../redux/manager/users/userActions';
import { deleteTimesheetDelegate, createTimesheetDelegate, updateTimesheetDelegate } from '../../../redux/manager/timesheetDelegates/timesheetDelegateActions';


import { UserAutocomplete } from '../../admin/AdminTeamsForm'; 




const Row = ({item}) => {

  return (
    <div className="flex-cont">
      <div className='flex-1'>{item.timesheetid} - {item.timesheetname}</div>
      {/* <div className="uppercase margin-left-1 margin-right-1 bold">{item.timesheetname}</div> */}
    </div>
  )
}



const TimesheetDelegateForm = ({setReload, toggleModal, formTimesheetDelegate}) => {

  const { trigger, reset, watch, register, formState: { errors }, handleSubmit, getValues, setValue } = useForm({
    // resolver: yupResolver(TeamSchema),
  });

  const [result, loading, loaded, error, doFetch, setResult] = useApi();
  const [users, usersLoading, usersLoaded, usersError, doUsersFetch, setUsersResult] = useApi();
  const [deleteResult, deleteLoading, deleteLoaded, deleteError, doDeleteFetch, setDeleteResult] = useApi();


  const fetchUsers = () => {
    doUsersFetch(getDelegateUsers, {query: {no_pagy: true}});
  }



  useEffect(() => {
    // fetchManagerUsers();
    fetchUsers();

    // if (formTimesheetDelegate) {

    // }
  }, []);

  useEffect(() => {
    if (loaded) {
      setReload(o => o+1);
      toggleModal();
    }
  }, [loaded]);

  
  useEffect(() => {
    if (deleteLoaded) {
      toggleDeleteModal();
      setReload(o => o+1);
      toggleModal();
    }
  }, [deleteLoaded]);


  const onSubmit = async data => {
    const t = await trigger();

    if (!t) return;


    const formValues = getValues();

    
    let d = {
      id: formTimesheetDelegate?.id,
      timesheet_delegate: {
        ...formValues,
      }
    }

    // alert(JSON.stringify(d, null, 2))
    // return

    if (formTimesheetDelegate && formTimesheetDelegate.id) {
      doFetch(updateTimesheetDelegate, {payload: d});
    }
    else {
      doFetch(createTimesheetDelegate, {payload: d});
    }


  }

  const checkKeyDown = (e) => { 
    const keyCode = e.keyCode ? e.keyCode : e.which; 

    // alert(JSON.stringify(keyCode, null, 2));


    if (keyCode === 13) { 
      e.preventDefault() 
    }; 
  };


  
  

  const renderRow = item => <Row item={item} />


  const [deleteModalOpened, setDeleteModalOpened] = useState(false);

  const toggleDeleteModal = () => {
    setDeleteModalOpened(o => !o);
  }
  
  const tryDelete = () => {
    if (formTimesheetDelegate) {
      const payload = {
        timesheet_delegate: {
          id: formTimesheetDelegate.id
        }
      }
      doDeleteFetch(deleteTimesheetDelegate, {payload: payload});
    }
  }



  const somethingLoading = deleteLoading || usersLoading || loading;


  return (
    <Modal allowOverflow={true} focusTrap={false} size="medium" toggleModal={toggleModal}>

      <div className='modal-header flex-cont align-center'>
        <h1 className="flex-1">{formTimesheetDelegate ? 'Edit' : 'Add'} Timesheet Delegate</h1>
        <div onClick={toggleModal} className="button close ghost">
          <i className="fas fa-times"></i>
        </div>
      </div>

      <div className="padding-1" style={{marginBottom: '0px'}}>


        {(usersLoading) && <div className="spinner"></div> }

        {((!formTimesheetDelegate) || (formTimesheetDelegate)) && <>
          

        
        {!usersLoading && 
          <form onKeyDown={(e) => checkKeyDown(e)} onSubmit={handleSubmit(onSubmit)}>
            {/* <input {...register("id")} type="hidden" name="id" value={formTimesheetDelegate?.id} /> */}
            

            <label htmlFor="delegateworkerrecid"  className="margin-right-1 margin-bottom-2"> 
              <div style={{marginBottom: '4px'}} className="gray bold font-075 uppercase">DELEGATE</div>
              {usersLoading &&
                <div className="editable-cont loading">
                  <div className="absolute spinner"></div>
                </div>
              }
              {!usersLoading && users &&

                <UserAutocomplete 
                  fixedPosition={true}
                  setValue={setValue}
                  users={users} 
                  register={register} 
                  fullName={formTimesheetDelegate?.delegate_user}
                  defaultValue={formTimesheetDelegate?.delegateworkerrecid || ""} 
                  errors={errors?.delegateworkerrecid}
                  name={`delegateworkerrecid`} 
                />
              }
              
              {errors.delegateworkerrecid && <div className="field-error">{errors.delegateworkerrecid.message}</div>}
            </label>


            {errors.timesheetid && <div className="field-error">{errors.timesheetid.message}</div>}



            {deleteModalOpened &&
              <Modal size="small" toggleModal={toggleDeleteModal} focusTrap={false}>
                <div className="modal-header flex-cont align-center">
                  <h2 className="flex-1">Remove Delegate</h2>
                  <div onClick={toggleDeleteModal} className="ghost button close">
                    <i className="fas fa-times"></i>
                  </div>
                </div>

                <div className="padding-1">
                  Are you sure you want to remove this delegate?
                </div>

                <div className="modal-footer align-center flex-cont">
                  <div onClick={toggleDeleteModal} className="ghost button margin-right-1">Cancel</div>
                  <div onClick={tryDelete} className={`${somethingLoading ? 'disabled' : ''} danger button`}>
                    {deleteLoading && <div className="spinner button-spinner"></div> }
                    Remove
                  </div>
                </div>
              </Modal>
            }

          </form>
        }
        </>}
      </div>

      <div className="modal-footer">
        <div className="flex-cont align-center">
          <div onClick={toggleModal} className="margin-right-1 ghost button">Cancel</div>
          {formTimesheetDelegate &&
            <div onClick={toggleDeleteModal} className={`button danger ${somethingLoading ? 'disabled' : ''}`}>
              Remove
            </div>
          }
          <div className="flex-1"></div>


    
          <div onClick={onSubmit} className={`button ${somethingLoading ? 'disabled' : ''}`}>
            Save
            {loading &&
              <div className="spinner button-spinner"></div>
            }
          </div>

        </div>
      </div>


      

    </Modal>
  )
}

export default TimesheetDelegateForm;