import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import authReducer from './authReducer';
import uiReducer from './ui/uiReducer';
import timesheetReducer from './timesheets/timesheetReducer';
import periodReducer from './periods/periodReducer';
import entryReducer from './entries/entryReducer';
import projectUserReducer from './projectUsers/projectUserReducer';
import budgetHeaderReducer from './budgetHeaders/budgetHeaderReducer';
import budgetFeeReducer from './budgetFees/budgetFeeReducer';
// import budgetTaskReducer from './budgetTasks/budgetTaskReducer';
import budgetLineReducer from './budgetLines/budgetLineReducer';
import teamReducer from './admin/teamReducer';

const rootReducer = combineReducers({
  authState: authReducer,
  uiState: uiReducer,
  timesheetState: timesheetReducer,
  periodState: periodReducer,  
  entryState: entryReducer,
  projectUserState: projectUserReducer,
  budgetHeaderState: budgetHeaderReducer,
  budgetLineState: budgetLineReducer,
  // budgetTaskState: budgetTaskReducer,
  budgetFeeState: budgetFeeReducer,
  teamState: teamReducer
})


const store = createStore(rootReducer, compose(
    applyMiddleware(thunk),
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()  
  ) ); 
export default store;     