import React, { useEffect, useState } from 'react';

// import TimesheetsNav from './TimesheetsNav';
import { useApi } from '../../../api';
import { connect } from 'react-redux';

import { DateTime } from 'luxon';
import {
  Link, useNavigate, useParams,
} from 'react-router-dom';

import { usePrevious } from '../../../hooks/usePrevious';

import { useForm, useWatch } from 'react-hook-form';
import TH from '../TH';
import { useSortBudgets, toNum, isNumber } from '../useSortBudgets';

import { getExpenseHeader, createExpenseHeader } from '../../../redux/manager/expenseHeaders/expenseHeaderActions';
import { isNumeric } from '../../../utils';
import ExpenseLine from './ExpenseLine';
import { getMyBudgetHeadersForExpenseReports } from '../../../redux/projects/projectActions';
import Modal from '../../../components/Modal';
import ExpenseForm from './ExpenseForm';


const NewExpenseReport = ({currentUser, currentCompany}) => {


  return (
    <>

      <div className="flex-cont">
        <div className="flex-1">
          <h1 className='no-margin-bottom'>HBA INTERNATIONAL</h1>
          <h2 className='no-margin-top'>Travel & Expense Report</h2>
        </div>
        {/* <div>
          <div onClick={() => alert("To be implemented.")} className="margin-top-2 button">
            Submit
          </div>
        </div> */}
      </div>
      

      <ExpenseForm existingExpenseHeader={null} />
      
    </>
  )
}


function mapState(state) {
  return { 
    // tokenResponse: state.authState.tokenResponse,
    currentCompany: state.authState.currentCompany,
    currentUser: state.authState.currentUser,
  };
} 

export default connect(mapState)(NewExpenseReport);