import { andyFetch, actionReturn } from '../../api';

export const deleteEntry = (params) => {

  return async (dispatch, getState) => {

    const url = "timesheets_alpha/delete_entry";

    try {
      const response = await andyFetch(url, params, "POST");
      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);

      } else {
        throw new Error('deleteEntry error.');
      }
    }
    catch (e) {

      
    }
  }
};


export const saveTimesheetAlpha = (params) => {

  return async (dispatch, getState) => {

    const url = "timesheets_alpha/save_timesheet";

    try {

      const response = await andyFetch(url, params, "POST");
      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);

      } else {
        throw new Error('saveTimesheetAlpha error.');
      }

    }
    catch (e) {

    }
  }
};


export const submitTimesheetAlpha = (params) => {

  return async (dispatch, getState) => {

    const url = "timesheets_alpha/submit_timesheet";

    try {

      const response = await andyFetch(url, params, "POST");
      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);

      } else {
        throw new Error('submitTimesheetAlpha error.');
      }

    }
    catch (e) {

    }
  }
};

export const saveEntryNote = (params) => {
  return async (dispatch, getState) => {
    const url = "timesheets_alpha/save_entry_note";
    try {
      const response = await andyFetch(url, params, "POST");
      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);
      } else {
        throw new Error('saveEntryNote error.');
      }
    }
    catch (e) {
      
    }
  }
};


export const createEntry = (params) => {

  return async (dispatch, getState) => {

    const url = "timesheets_alpha/create_entry";

    try {
      const response = await andyFetch(url, params, "POST");
      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);

      } else {
        throw new Error('createTimesheet error.');
      }
    }
    catch (e) {

      
    }
  }
};



export const getTimesheetAlpha = (params) => {
  return async (dispatch, getState) => {
    const url = "timesheets_alpha/show_timesheet";
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);

      } else {
        throw new Error('getTimesheet error.');
      }
    }
    catch (e) {
      
    }
  }
}

export const getProjectsAlpha = (params) => {
  return async (dispatch, getState) => {
    const url = "timesheets_alpha/get_projects";
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);

      } else {
        throw new Error('getProjectsAlpha error.');
      }
    }
    catch (e) {
      
    }
  }
}

export const getMilestonesAlpha = (params) => {
  return async (dispatch, getState) => {
    const url = "timesheets_alpha/get_milestones";
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);

      } else {
        throw new Error('getMilestonesAlpha error.');
      }
    }
    catch (e) {
      
    }
  }
}

export const getTasksAlpha = (params) => {
  return async (dispatch, getState) => {
    const url = "timesheets_alpha/get_tasks";
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);

      } else {
        throw new Error('getTasksAlpha error.');
      }
    }
    catch (e) {
      
    }
  }
}

export const getTimesheetSummaryAlpha = (params) => {
  return async (dispatch, getState) => {
    const url = "timesheets_alpha/summary";
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);

      } else {
        throw new Error('getTimesheetSummaryAlpha error.');
      }
    }
    catch (e) {
      
    }
  }
}

