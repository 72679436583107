import React, { useState, useEffect } from 'react';
import { useApi } from '../../api';
import { DateTime } from 'luxon';
import { connect } from 'react-redux';

import { repostTimesheet, getTimesheetLogsDynamics, getTimesheetLogsOutbounds, getTimesheetLogsSuccesses, getTimesheetLogsFailures,  } from '../../redux/timesheets/timesheetActions';
import { Link } from 'react-router-dom';
import Modal from '../../components/Modal';
import Pagination from '../../components/Pagination';
import CheckBoxes from '../../components/CheckBoxes';
import { truncateString } from '../../utils';
import TimesheetPostingLogsSubnav from './TimesheetPostingLogsSubnav';



const TimesheetPostingLogs = ({currentCompany, children}) => {

  const [tab, setTab] = useState("failed");

  useEffect(() => {

  }, [tab])

  

  return(
    <>

      <div className="flex-cont align-center">
        <h1 className="flex-cont">
          {/* <div className="margin-right-half medgray">{currentCompany}</div> */}
            Timesheet Posting Logs
        </h1>
      </div> 

      <TimesheetPostingLogsSubnav />

      {children}

      



      {/* {tab === "failed" &&
        <TimesheetFailures /> 
      }
      {tab === "succeeded" &&
        <TimesheetSuccesses /> 
      }
      {tab === "waiting" &&
        <TimesheetOutbounds /> 
      }
      {tab === "dynamics" &&
        <TimesheetDynamics /> 
      } */}

    </>
  )
};

const mapState = state => ({
  reviewCount: state.budgetHeaderState.reviewCount,
  currentCompany: state.authState.currentCompany,
});

export default connect(mapState)(TimesheetPostingLogs);