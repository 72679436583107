const initialState = {
  data: [],
  pagy: null,
};

const projectUserReducer = ( state = initialState, action) => {
  switch(action.type) {
    case "CLEAR_PROJECT_USERS":
      return initialState
    case "SET_PROJECT_USERS":
      return {
        ...state,
        data: action.projectUsers
      }
    case "ADD_PROJECT_USER":
      return {
        ...state,
        data: [...state.data, action.projectUser]
      }
    case "REMOVE_PROJECT_USER":
      return {
        ...state,
        data: [...state.data.filter(e => e.id.toString() !== action.projectUserId.toString())]
      }
    case "CHANGE_PROJECT_USER":
      return {
        ...state,
        data: state.data.map(projectUser => projectUser.id === action.projectUser.id ? {...action.projectUser} : projectUser)
      }
    default :
      return state
  }
}

export default projectUserReducer;