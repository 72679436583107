import { andyFetch, actionReturn } from '../../../api';

export const getProjectUsers = (params) => {
  return async (dispatch, getState) => {
    const url = "manager/project_users";
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};

export const createProjectUser = (params) => {
  return async (dispatch, getState) => {
    const url = "manager/project_users";
    try {
      const response = await andyFetch(url, params, "POST");
      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};

export const updateProjectUser = (params) => {
  return async (dispatch, getState) => {
    const url = `manager/project_users/${params?.payload?.id}`;
    try {
      const response = await andyFetch(url, params, "PATCH");
      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};

export const deleteProjectUser = (params) => {
  return async (dispatch, getState) => {
    const url = "manager/project_users/delete";
    try {
      const response = await andyFetch(url, params, "POST");
      if (response.ok) {
 
        const json = await response.json();
        return actionReturn(json);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};

export const setProjectUsers = (projectUsers) => {
  return dispatch => {
    dispatch({
      type: "SET_PROJECT_USERS",
      projectUsers: projectUsers
    });
  }
}

export const addProjectUser = (projectUser) => {
  return dispatch => {
    dispatch({
      type: "ADD_PROJECT_USER",
      projectUser: projectUser
    });
  }
}
export const removeProjectUser = (projectUserId) => {
  return dispatch => {
    dispatch({
      type: "REMOVE_PROJECT_USER",
      projectUserId: projectUserId
    });
  }
}
export const changeProjectUser = (projectUser) => {
  return dispatch => {
    dispatch({
      type: "CHANGE_PROJECT_USER",
      projectUser: projectUser
    });
  }
}