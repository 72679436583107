

import React, { useEffect, useState } from 'react';
import Modal from '../../components/Modal';
import { saveEntryNote } from "../../redux/alphaTimesheets/alphaTimesheetActions";
import { useApi } from '../../api';
import { DateTime } from 'luxon';
import { useWatch } from 'react-hook-form';

const dayValidation = (day, hide) =>  {

  if (hide) {
    return null
  } 
  
  return {
    present: v => {

      if (v === null || v === undefined || v === "") {
        return `Please enter a number for ${day}`
      }

      return true;

    },
    noSpaces: v => {
      return !v.includes(' ') || `Remove spaces from ${day}`
    },
    isNumber: v => {
      return testIfNumber(v) || `Please enter a number for ${day}`
    },
    
    places: v => {
      return validPlaces(v) || `${day} should be a number with no more than 2 decimal places`
    },
    
    lessThanThousand: v => parseFloat(v) < 1000 || `${day} should be less than 1000`,
    twentyFive: v => {
      return ((parseFloat(v) * 100) % 25 === 0) || `Please enter a number in 0.25 increments for ${day}`
    }
  }
}
const testIfNumber = (value) => {


  return !isNaN(value);
}

const validPlaces = (value) => {
 
 
  const regex = /^\s*-?(\d+(\.\d{1,2})?|\.\d{1,2})\s*$/;
  const found = value.match(regex);

  return (found && found.length > 0)
}


const TimesheetEntryDay = ({hide, entryDisabled, control, setValue, entry, dayIndex, entryErrors, weekIndex, selectedWeekIndex, register, date, entryIndex}) => {

  const inputName = `week.${weekIndex}.entries.${entryIndex}`;

  
  const defaultHour = () => {

    if (date?.hours == null) {
      return "0.0"
    }

    if (entryIndex === 0) {
      if (dayIndex === 7 || dayIndex === 6) {
        return "0.0"
      }
    }

    return date.hours;
    
  }

  const defaultNote = () => {
    if (entry) return entry[`day${dayIndex}note`];

    return null;
  }

  const noteWatch = useWatch({
    control,
    name: `${inputName}.day${dayIndex}note`,
    defaultValue: defaultNote()
  });


  const myErrors = entryErrors?.[`day${dayIndex}hour`]?.message;
  // date?.day ? daysErrors?.[date.day]?.hour?.message : null;

  const [noteOpened, setNoteOpened] = useState(false);
  const [noteText, setNoteText] = useState(defaultNote());
  const [noteSaveResult, noteSaveLoading, noteSaveLoaded, noteSaveError, doNoteSaveFetch, setNoteSaveResult] = useApi();

  const trySaveNote = () => {
    if (entry) {
      const payload = {
        id: entry?.id,
        timesheet_entry: {
          [`day${dayIndex}note`]: noteText
        }
      }
      doNoteSaveFetch(saveEntryNote, {payload: payload});
      setValue(`${inputName}.day${dayIndex}note`, noteText, {  });
    }
    else {
      setValue(`${inputName}.day${dayIndex}note`, noteText, {  });
      setNoteOpened(false);
    }
  }

  useEffect(() => {
    if (noteSaveLoaded) {
      setNoteOpened(false);
    }
  }, [noteSaveLoaded]);

  

  return (
    <>
      {noteOpened &&
        <Modal focusTrap={!entryDisabled} canClose={!noteSaveLoading} size="small" toggleModal={() => setNoteOpened(false)}>
          <div className="modal-header">
            <div className="flex-cont">
              <div>
                <div className="bold font-1-5">Note</div>
                <div>{date.day} {date.date}</div>
              </div>
              <div className="flex-1"></div>
              <div onClick={() => setNoteOpened(false)} className={`${noteSaveLoading ? 'disabled' : ''} ghost button close`}>
                <i className="fas fa-times"></i>
              </div>
            </div>
            
          </div>
          <div className="padding-1">
            <div className="bold">{}</div>
            <textarea disabled={entryDisabled} value={noteText} onChange={e => setNoteText(e.target.value)}/>
          </div>
          <div className="modal-footer">
            {!entryDisabled &&
              <div className="flex-cont align-center">
                <div onClick={() => setNoteOpened(false)} className={`${noteSaveLoading ? 'disabled' : ''} ghost button`}>Cancel</div>
                <div className="flex-1"></div>
                <div onClick={trySaveNote}  className={`button margin-right-1 ${noteSaveLoading ? 'disabled' : ''}`}>
                  Save
                  {noteSaveLoading &&
                    <div className="button-spinner spinner"></div>
                  }
                </div>
                
              </div>
            }
          </div>
        </Modal>
      }
      {date?.date &&
        <div className="alpha-entry-cell alpha-entry-date">

          <div  className={`${myErrors ? 'has-errors' : ''} date entry-cell-label`}>
            {(entryIndex === 1 || entryIndex === 0) && 
              <div className="full-date">
                {DateTime.fromISO(date.iso).toLocaleString({...DateTime.DATE_SHORT, year: '2-digit' })}
              </div>
            }
            {/* {noteWatch} */}
            <div onClick={() => setNoteOpened(true)} className={`${!!noteWatch ? 'note-present' : ''} alpha-note-clicker flex-cont align-center bold`}>
                <div>{date.day}</div>
                <div><i className="margin-left-1 fas fa-pencil-alt"></i></div>
            </div>
            <span className="bold"></span>
            
          </div>
          <div className="">
            {/* {parseFloat(date.hours).toLocaleString()} */}

            <input
              className={`${myErrors ? 'has-errors' : ''}`}
              {...register(`week.${weekIndex}.entries.${entryIndex}.day${dayIndex}note`, {

              })} 
              type="hidden" 
              name={`week.${weekIndex}.entries.${entryIndex}.day${dayIndex}note`} 
              defaultValue={defaultNote()} 
            />

            <input
              className={`${myErrors ? 'has-errors' : ''}`}
              {...register(`week.${weekIndex}.entries.${entryIndex}.day${dayIndex}`, {

              })} 
              type="hidden" 
              name={`week.${weekIndex}.entries.${entryIndex}.day${dayIndex}`} 
              defaultValue={date.iso} 
            />
            
            <input
              className={`${entryDisabled ? 'disabled-input' : ''} ${myErrors ? 'has-errors' : ''}`}
              {...register(`week.${weekIndex}.entries.${entryIndex}.day${dayIndex}hour`, {
                validate: dayValidation(date.day, hide)
              })} 
              type="text" 
              name={`week.${weekIndex}.entries.${entryIndex}.day${dayIndex}hour`} 
              defaultValue={defaultHour()} 
              // disabled={entryDisabled}
            />

            
          </div>
          
        </div>     
      }


    </>
  )
}

export default TimesheetEntryDay;