import React, {useEffect, useState} from 'react';



export const ProjectSelect = ({hide, disabled, myErrors, selectedProject, selectProjectChange, register, inputName, existingEntry, projects}) => {

  
  const [projectErrors, setProjectErrors] = useState([]);
  const [errorMsg, setErrorMsg] = useState(null);


  const shouldShowProjectSelect = () => {
    if (!projects || projects.length === 0) {
      return false;
    }
    if (!existingEntry?.projectId) return true;
  
    const projectIDs = projects.map(project => project.id?.toString());
  
    return projectIDs.includes(existingEntry?.projectId?.toString());
  }

  const final = () => {
    // if (selectedProject) return selectedProject.number;
    return (existingEntry?.projectId ? existingEntry?.projectId : "")
  }


  const reg = register(`${inputName}.selectedProject`, {

    validate: v => {
      if (hide) return true;

      if (v) {
        return true
      }
      return "Select a project"
    }
  })

  const renderSelect = () => {

    if (disabled || !shouldShowProjectSelect()) {
      if (existingEntry?.projectName) {
        return (
          <>
            <div className="dummy-select wide">
              {existingEntry?.projectId} - {existingEntry?.projectName}
            </div>
            <input type="hidden" name={`${inputName}.selectedProject`}  ref={reg.ref} defaultValue={final()} />
          </>
        )
      }
      else {
        return (
          <div className="dummy-select wide">
            Project not found.
          </div>
        )
      }
    }
    else {
      return (
        <select
          disabled={disabled}
          tabIndex={0} 
          defaultValue={final()}
          className="wide"
          onBlur={reg.onBlur}
          ref={reg.ref} 
          onChange={(e) => {
            reg.onChange(e); // method from hook form register
            selectProjectChange(e)
          }}
          name={`${inputName}.selectedProject`}  
          id={`${inputName}.selectedProject`}
        >
          <option value="">Select project:</option>
          {projects?.map((project, key) => {
            return <option  key={key} value={project.id}>{`${project.id} - ${project.name}`}</option>
          })}
        </select>
      )
    }
  }


  return (
    <>
      <label className={myErrors?.selectedProject ? "margin-right-4px error-label" : "margin-right-4px"}>  
        <div className="label-text">Project</div>

        {renderSelect()}

        

      </label>

    </>

  )
}

export default ProjectSelect;