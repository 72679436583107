const initialState = {
  data: [],
  pagy: null,
};

const budgetLineReducer = ( state = initialState, action) => {
  switch(action.type) {
    case "CLEAR_BUDGET_LINES":
      return initialState
    case "SET_BUDGET_LINES":
      return {
        ...state,
        data: action.budgetLines
      }
    case "ADD_BUDGET_LINE":
      return {
        ...state,
        data: [...state.data, action.budgetLine]
      }
    case "REMOVE_BUDGET_LINE":
      return {
        ...state,
        data: [...state.data.filter(e => e.id.toString() !== action.budgetLineId.toString())]
      }
    case "CHANGE_BUDGET_LINE":
      return {
        ...state,
        data: state.data.map(budgetLine => budgetLine.id === action.budgetLine.id ? {...action.budgetLine} : budgetLine)
      }
    default :
      return state
  }
}

export default budgetLineReducer;