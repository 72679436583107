import { andyFetch, actionReturn } from '../../api';


export const getOfficeManagerTeam = (params) => {
  // aler t(params?.query?.id)
  return async (dispatch, getState) => {
    const url = `admin/office_manager_teams/${params?.query?.id}`;
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};


export const getOfficeManagerTeams = (params) => {
  return async (dispatch, getState) => {
    const url = "admin/office_manager_teams";
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();

        const ar = actionReturn(json, response);
        console.log("actionReturn", ar);
        return ar;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};


export const getManagerTeams = (params) => {
  return async (dispatch, getState) => {
    const url = "admin/office_manager_teams";
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();

        const ar = actionReturn(json, response);
        console.log("actionReturn", ar);
        return ar;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};


export const deleteOfficeManagerTeam = (params) => {
  return async (dispatch, getState) => {
    const url = "admin/office_manager_teams/delete";
    try {
      const response = await andyFetch(url, params, "POST");
      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);
      } else {
        throw new Error('createOfficeManagerTeam error.');
      }
    }
    catch (e) {
    }
  }
};



export const createOfficeManagerTeam = (params) => {

  return async (dispatch, getState) => {

    const url = "admin/office_manager_teams";

    try {

      const response = await andyFetch(url, params, "POST");

      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);

      } else {
        throw new Error('createOfficeManagerTeam error.');
      }
    }
    catch (e) {
    }
  }
};

export const updateOfficeManagerTeam = (params) => {

  return async (dispatch, getState) => {

    const url = `admin/office_manager_teams/${params?.payload?.id}`;

    try {

      const response = await andyFetch(url, params, "PATCH");

      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);

      } else {
        throw new Error('updateOfficeManagerTeam error.');
      }
    }
    catch (e) {
      
    }
  }
};

