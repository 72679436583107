import React, { useEffect, useState } from 'react';

// import TimesheetsNav from './TimesheetsNav';
import { useApi } from '../../../api';
import { connect } from 'react-redux';

import { getMyExpenseHeaders, getSubmittedExpenseHeaders, getApprovedExpenseHeaders } from '../../../redux/manager/expenseHeaders/expenseHeaderActions';
import { getReviewCount } from '../../../redux/manager/budgetHeaders/budgetHeaderActions';
import { DateTime } from 'luxon';
import {
  Link, useNavigate, useParams,
} from 'react-router-dom';

import { usePrevious } from '../../../hooks/usePrevious';


import TH from '../TH';
import { useSortExpenseReports, toNum, isNumber } from '../useSortExpenseReports';
import { getHasAssignedProjects } from '../../../redux/projects/projectActions';
import ReviewExpenseReportsPM from './ReviewExpenseReportsPM';

const ReviewExpenseReports = ({currentCompany, children}) => {

  const { tab } = useParams();

  const navigate = useNavigate('/');
  const prevCompany = usePrevious(currentCompany);

  useEffect(() => {
    fetchExpenseHeaders();   
  }, [currentCompany, tab]);



  const [expenseHeaders, expenseHeadersLoading, expenseHeadersLoaded, expenseHeadersError, doExpenseHeadersFetch, setExpenseHeadersResult] = useApi();

  const fetchExpenseHeaders = () => {
    doExpenseHeadersFetch(getSubmittedExpenseHeaders, {query: {stage: tab}});
  }

  const {sorted, sortByColumn, setSorted, sortedColumn, sortDirection, columnClicked} = useSortExpenseReports();

  useEffect(() => {
    if (expenseHeaders) {
      setSorted(sortByColumn([...expenseHeaders], sortedColumn, sortDirection));
    }
  }, [sortedColumn, expenseHeaders, sortDirection]);

  useEffect(() => {
  }, [sorted]);


  return (
    <>
      <div className="flex-cont align-center">
        <h1 className="flex-cont flex-1">
          <div className="margin-right-half medgray">{currentCompany}</div>
          Review Expense Reports
        </h1>

        {/* {projectsLoading &&
          <Link to="/expense-reports/new" className={`button disabled`}>
            New Report
            <div className="button-spinner spinner"></div>
          </Link>
        }
        {!projectsLoading && !projects &&
          <em className='gray'>You must be assigned a project to create an expense report.</em>
        }
        {projects && !projectsLoading &&
          <Link to="/expense-reports/new" className="button">
            New Report
          </Link>
        } */}
        
      </div>  
      

      <div className="content margin-top-1">


      {expenseHeadersError &&
        <div>
          There was an error fetching your expense reports.
        </div>
      }

      {!expenseHeadersLoading && (sorted == null || (sorted && sorted.length === 0)) &&
        <div>
          You do not currently have any expense reports to review.
        </div>
      }

      {expenseHeadersLoading &&
        <div className="spinner"></div>
      }
      {!expenseHeadersLoading && sorted && sorted.length !== 0 &&
        <table className="basic">
          <thead>
            <tr>
              <TH direction={sortDirection} classNames={["fit", "nowrap"]} column="number" sortedColumn={sortedColumn} label="Number" columnClicked={columnClicked} />


              <TH direction={sortDirection} classNames={["fit", "nowrap"]} column="projectid" sortedColumn={sortedColumn} label="Project ID" columnClicked={columnClicked} />

              <TH direction={sortDirection} classNames={["fit", "nowrap"]} column="statusname" sortedColumn={sortedColumn} label="Status" columnClicked={columnClicked} />

              <TH direction={sortDirection} classNames={["nowrap", "fit"]} column="projectname" sortedColumn={sortedColumn} label="Project Name" columnClicked={columnClicked} />

              <TH direction={sortDirection} classNames={["nowrap", "fit"]} column="employeename" sortedColumn={sortedColumn} label="Employee" columnClicked={columnClicked} />

              <TH direction={sortDirection} classNames={["fit", "nowrap"]} column="destination" sortedColumn={sortedColumn} label="Destination" columnClicked={columnClicked} />

              <TH direction={sortDirection} classNames={["fit", "nowrap"]} column="datestart" sortedColumn={sortedColumn} label="Start Date" columnClicked={columnClicked} />

              <TH direction={sortDirection} classNames={["fit", "nowrap"]} column="dateend" sortedColumn={sortedColumn} label="End Date" columnClicked={columnClicked} />

              <TH direction={sortDirection} classNames={["nowrap", "fit"]} column="totalexpenses" sortedColumn={sortedColumn} label="Total Expenses" columnClicked={columnClicked} />

              <th></th>
            </tr>
          </thead>
          <tbody>
            {sorted && sorted.map( expenseHeader => 
              <tr>

                <td className="fit nowrap">
                  <Link className="text-link" to={`/expense-reports/view/${expenseHeader.id}`}>
                    {expenseHeader.number}
                  </Link>
                </td>
                
                
                <td className="fit nowrap">
                  <Link className="text-link" to={`/expense-reports/view/${expenseHeader.id}`}>{expenseHeader.projectid}</Link>
                </td>

                <td className="fit nowrap">
                  <Link className="text-link" to={`/expense-reports/view/${expenseHeader.id}`}>
                    <>
                  
                    {expenseHeader.statusname &&
                      <div className="status-badge-cont">
                        <div className={`budget status-badge ${expenseHeader.statusname.toLowerCase()}`}>
                          {expenseHeader.statusname.toUpperCase() }
                        </div>
                      </div>
                    }
                    {/* {!project.budgetHeaderStatus &&
                      <div className="bold font-075 gray">NEEDS BUDGET</div>
                    } */}
                  </>
                  </Link>
                </td>

                <td className="nowrap">
                  {/* <div>
                    Status: {expenseHeader?.budgetHeaderStatus?.toUpperCase()}
                  </div> */}
                  {/* <Link className="text-link" to={`/expense-reports/view/${expenseHeader.id}`}> */}
                    {expenseHeader.projectname}
                  {/* </Link> */}
                </td>
                <td className="fit nowrap">
                  {expenseHeader.employeename}
                </td>
                <td className="fit nowrap">
                  {expenseHeader.destination}
                </td>
                <td className="fit nowrap">
                  {expenseHeader.datestart ? DateTime.fromISO(expenseHeader.datestart).toLocaleString() : ""}
                </td>
                <td className="fit nowrap">
                  {expenseHeader.dateend ? DateTime.fromISO(expenseHeader.dateend).toLocaleString() : ""}
                </td>
                <td className="fit center">
                  {expenseHeader.totalexpenses}
                </td>
                <td className='full'></td>
              </tr>
            )}
          </tbody>
        </table>
      }

      </div>
    </>
  )
}



const mapState = state => ({
  reviewCount: state.budgetHeaderState.reviewCount,
  currentCompany: state.authState.currentCompany,
});

export default connect(mapState)(ReviewExpenseReports);
