import { andyFetch, actionReturn } from '../../../api';

export const deleteProjectDelegate = (params) => {
  return async (dispatch, getState) => {
    const url = "manager/project_delegates/delete";
    try {
      const response = await andyFetch(url, params, "POST");
      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);
      } else {
        throw new Error('deleteProjectDelegate error.');
      }
    }
    catch (e) {
    }
  }
};

export const getMyManagedProjects = (params) => {
  return async (dispatch, getState) => {
    const url = "manager/project_delegates/my_managed_projects";
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};


export const updateProjectDelegate = (params) => {
  return async (dispatch, getState) => {
    const url = `manager/project_delegates/${params?.payload?.id}`;
    try {
      const response = await andyFetch(url, params, "PATCH");
      if (response.ok) {
        const json = await response.json();

        return actionReturn(json);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};

export const createProjectDelegate = (params) => {
  return async (dispatch, getState) => {
    const url = `manager/project_delegates`;
    try {
      const response = await andyFetch(url, params, "POST");
      if (response.ok) {
        const json = await response.json();

        return actionReturn(json);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};