import { useRef, useEffect } from 'react';

// export const useIsFirstRender = () => {
//   const isFirstRenderRef = useRef(true);
//   useEffect(() => {
//     isFirstRenderRef.current = false;
//   }, []);
//   return isFirstRenderRef.current;
// };

export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};