import React, { useState, useEffect } from 'react';
import { DateTime, Info } from 'luxon';
import store from '../../redux/store';
import { connect } from 'react-redux';
import EntrySection from '../entries/EntrySection';
import TimesheetWeeks from '../TimesheetWeeks';
import TimesheetScreenTest from '../TimesheetScreen';


import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link, Outlet, useLocation, useParams, useNavigate,
} from 'react-router-dom';

import { getTimesheet, getTimesheetWeeks, submitTimesheet } from '../../redux/timesheets/timesheetActions';
import { getProjects } from '../../redux/projects/projectActions';
import { getPhases } from '../../redux/phases/phaseActions';
import { getCategories } from '../../redux/categories/categoryActions';
import { getEntries, setEntries } from '../../redux/entries/entryActions';


import { useApi } from '../../api';



const TimesheetViewScreen = ({timesheets, unsavedEntries, fullScreenLoading, weekSummary, weeks, timesheet, isLoading, weeksIsLoading, entries}) => {
  const navigate = useNavigate('/');
  const { timesheetId } = useParams();

  const [selectedPeriodIndex, setSelectedPeriodIndex] = useState(0);
  const [selectedPeriod, setSelectedPeriod] = useState(null);

  // useEffect(() => {
  //   if (weeks) {
  //     let blankWeekIndex = weeks.findIndex(w => parseInt(w.totalHours) === 0);
  //     if (blankWeekIndex !== -1) {
  //       setSelectedPeriod(weeks[blankWeekIndex]);
  //       setSelectedPeriodIndex(blankWeekIndex);
  //     }
  //     else {
  //       setSelectedPeriod(weeks[0]);
  //       setSelectedPeriodIndex(0);
  //     }
  //   }
  // }, [weeks]);




  const [timesheetResult, timesheetLoading, timesheetLoaded, timesheetError, doTimesheetFetch, setTimesheetResult] = useApi();
  const [timesheetWeeksResult, timesheetWeeksLoading, timesheetWeeksLoaded, timesheetWeeksError, doTimesheetWeeksFetch, setTimesheetWeeksResult] = useApi();
  const [entriesResult, entriesLoading, entriesLoaded, entriesError, doEntriesFetch, setEntriesResult] = useApi();
  const [projects, projectsLoading, projectsLoaded, projectsError, doProjectsFetch, setProjectsResult] = useApi();
  const [phases, phasesLoading, phasesLoaded, phasesError, doPhasesFetch, setPhasesResult] = useApi();
  const [categories, categoriesLoading, categoriesLoaded, categoriesError, doCategoriesFetch, setCategoriesResult] = useApi();


  useEffect(() => {
    // store.dispatch(clearStore('ENTRIES'));
    // // store.dispatch(getTimesheet(timesheetId));
    // store.dispatch(api('projects', null, {page: ""}, 'GET', 'PROJECTS'));
    // // store.dispatch(getPhases());
    // // store.dispatch(getCategories());
    // // alert(timesheetId);
    // store.dispatch(api('timesheets', null, null, 'GET', 'TIMESHEETS'));
    // store.dispatch(api('timesheets/show_timesheet', null, {id: timesheetId}, 'GET', 'TIMESHEET'));
    // store.dispatch(api('timesheets/show_timesheet_weeks', null, {id: timesheetId}, 'GET', 'TIMESHEET_WEEKS'));
    // store.dispatch(api('timesheets/show_timesheet_weeks', null, {id: timesheetId}, 'GET', 'TIMESHEET_WEEK_SUMMARY'));
    
    // // alert('here 2');
    // store.dispatch(api('timesheet_entries', null, {timesheetid: timesheetId}, 'GET', 'ENTRIES'));


    // return function cleanup() {
    //   store.dispatch(hideFullscreenLoading());

    //   store.dispatch(clearStore('ENTRIES'));
    //   store.dispatch(clearStore('TIMESHEETS'));
    //   store.dispatch(clearStore('PROJECTS'));
    // }

    fetchTimesheet();
    fetchTimesheetWeeks();
    fetchEntries();
    fetchProjects();
    
  }, []);

  const fetchTimesheet = () => {
    doTimesheetFetch(getTimesheet, {query: {id: timesheetId}});
  }
  const fetchTimesheetWeeks = () => {
    doTimesheetWeeksFetch(getTimesheetWeeks, {query: {id: timesheetId}});
  }
  const fetchEntries = () => {
    doEntriesFetch(getEntries, {query: {timesheetid: timesheetId}});
  }
  useEffect(() => {
    if (entriesLoaded && entriesResult) {
      store.dispatch(setEntries(entriesResult));
    }
  }, [entriesLoaded]);

  const fetchProjects = () => {
    doProjectsFetch(getProjects);
  }
  const fetchPhases = () => {
    doPhasesFetch(getPhases);
  }
  const fetchCategories = () => {
    doCategoriesFetch(getCategories);
  }



  useEffect(() => {
    if (timesheetWeeksResult) {
      let blankWeekIndex = timesheetWeeksResult.findIndex(w => parseInt(w.totalHours) === 0);
      if (blankWeekIndex !== -1) {
        setSelectedPeriod(timesheetWeeksResult[blankWeekIndex]);
        setSelectedPeriodIndex(blankWeekIndex);
      }
      else {
        setSelectedPeriod(timesheetWeeksResult[0]);
        setSelectedPeriodIndex(0);
      }
    }
  }, [timesheetWeeksResult]);




  const selectPeriodIndex = (i, p) => {
    setSelectedPeriodIndex(i);
    setSelectedPeriod(p);
  }



  return (
    <>
      <TimesheetScreenTest viewOnly={true} />
    </>

  )

  
};





const mapState = state => ({
  timesheet: state.timesheetState.currentTimesheet,
  timesheets: state.timesheetState.data,
  weeks: state.timesheetState.weeks,
  weekSummary: state.timesheetState.weekSummary,
  isLoading: state.timesheetState.isLoading,
  weeksIsLoading: state.timesheetState.weeksIsLoading,
  entries: state.entryState.data,
  fullScreenLoading: state.timesheetState.fullScreenLoading,
  unsavedEntries: []//state.timesheetEntryState.unsavedEntries,
});

export default connect(mapState)(TimesheetViewScreen);
