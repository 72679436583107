import React, {useEffect, useState} from 'react';
import AutoComplete from '../../components/AutoComplete';

const Row = ({item}) => {

  return (
    <div className="flex-cont">
      <div className='flex-1'>{item.name}</div>
    </div>
  )
}



export const ActivitySelect3 = ({selectedProject, selectedActivity, setSelectedProject, setSelectedActivity, setSelectedSubactivity,  watchProjectId, projectsLoading, setValue, hide, disabled, myErrors,  register, inputName, existingEntry, projects}) => {

  const [canReset, setCanReset] = useState(false);

  // const [defaultSeletedItem, setDefaultSelectedItem] = useState(null);

  const renderRow = item => <Row item={item} />

  const onSelect = item => {
    setSelectedActivity(item);
    setSelectedSubactivity(null);
    setValue(`${inputName}.selectedSubactivity`, '');

    // alert('here')

    // alert("item: " + JSON.stringify(item, null, 2)); 
  }


  const getFoundActivity = (number) => {
    if (number == null) return null;
    const foundActivity = selectedProject?.activities?.find(activity => activity.number == number);
    return foundActivity
  }

  useEffect(() => {
    if (existingEntry?.activityNumber && selectedProject?.activities) {
      
      const found = getFoundActivity(existingEntry?.activityNumber);
      setSelectedActivity(found);
      
      

    }
  }, [existingEntry, selectedProject]);

  useEffect(() => {
    if (selectedProject) {
      setCanReset(true);
    }
  }, [selectedProject]);

  const activityMissing = () => {
    if (!existingEntry) return false;
    if (existingEntry.activityNumber === null || existingEntry.activityNumber === undefined ||  existingEntry.activityNumber === "") return false;
    if (projectsLoading) return false;

    if (selectedActivity) {
      return getFoundActivity(selectedActivity?.number) == null;
    }
    else {
      return false;
    }
  }






  const renderMissing = () => {
    return (
      <div>
        {/* <div tyle={{marginBottom: '4px'}} >
        <i className="fas fa-exclamation-triangle margin-right-half yellow"></i>
        <span className="bold yellow">Invalid project.</span>
        </div> */}
        <div style={{marginBottom: '4px'}} className="gray bold font-075">MILESTONE</div>
        <div>
          
          <div className="dummy-select">
          {!existingEntry &&
            <>
              No milestones found.
            </>
          }
          {existingEntry && existingEntry?.activityNumber == null &&
            <>
              No milestone found.
            </>
          }
          {existingEntry && existingEntry?.activityNumber != null &&
            <>
              {existingEntry?.activityName}
            </>
          }
            
          </div>
          <input
            style={{display: 'block'}}
            type="text"
            name={`${inputName}.selectedActivity`} 
            id={`${inputName}.selectedActivity`} 
            defaultValue={existingEntry?.activityNumber} 
            {...register(`${inputName}.selectedActivity`, {
              required: `Milestone required.`
            })}
          />
        </div>
        
      </div>
    )
  }

  const renderContent = () => {
    if (projectsLoading) {
      return (
        <div className="loading-input-dummy">
          <div  className="spinner">

          </div>
        </div>
      )
    }

    if (selectedProject == null) {
      // return null;
    }

    if (!selectedProject?.activities || selectedProject?.activities?.length === 0) {
      // return renderMissing();
    }

    if (activityMissing()) {
      // return renderMissing();
    }


    return (
      <AutoComplete 
        canReset={canReset}
        setValue={setValue}
        onSelect={(item) => onSelect(item)}
        // defaultValue={formProjectDelegate?.projectid}
        // defaultSearch={formProjectDelegate ? `${formProjectDelegate.projectid} - ${formProjectDelegate.projectname}` : ""}
        defaultValue={existingEntry?.activityNumber}
        defaultSearch={existingEntry?.activityName}
        name={`${inputName}.selectedActivity`}
        items={selectedProject?.activities}
        fullName={"FULL NAME"}
        register={register}
        errors={myErrors?.selectedActivity}
        label="Milestone"
        labelFn={(item) => `${item.name}`}
        labelComponent={renderRow}
        valueFn={(item) => item.number }
        searchableAttributes={["name"]}
      />
    )
  
    
  }






  return (
    <>
    {renderContent()}

    {/* <div onClick={() => alert(`${selectedActivity?.number}: ${getFoundActivity(selectedActivity?.number)}`) } className="button"></div> */}

      {/* <pre>
      selectedActivity: {JSON.stringify(`${selectedActivity?.number}`, null, 2)}
       <br/>
       <br/>
       getFoundActivity(): {JSON.stringify(`${getFoundActivity(selectedActivity?.number)}`, null, 2)}
       <br/>
       <br/>
       !existingEntry?.activityNumber: {JSON.stringify(existingEntry?.activityNumber, null, 2)}
       <br/>
       <br/>
       selectedProject?.activities: {JSON.stringify(selectedProject?.activities, null, 2)}
      </pre> */}


      {/* {projectsLoading &&
        <div className="loading-input-dummy">
          <div  className="spinner">

          </div>
        </div>
      }

      {activityMissing() && 
        <div>
          <div style={{marginBottom: '4px'}} className="gray bold font-075">MILESTONE</div>
          <div>
            
            <div className="dummy-select">
              <i className="fas fa-exclamation-triangle margin-right-half"></i>
              {existingEntry?.activityName}
            </div>
            <input
              style={{display: 'none'}}
              type="text"
              name={`${inputName}.selectedActivity`} 
              id={`${inputName}.selectedActivity`} 
              defaultValue={existingEntry?.activityNumber} 
              {...register(`${inputName}.selectedActivity`, {
                required: `Milestone required.`
              })}
            />
          </div>
          
        </div>
      }

      {!projectsLoading && !!selectedProject?.activities?.length &&
              
        <AutoComplete 
          canReset={canReset}
          setValue={setValue}
          onSelect={(item) => onSelect(item)}
          // defaultValue={formProjectDelegate?.projectid}
          // defaultSearch={formProjectDelegate ? `${formProjectDelegate.projectid} - ${formProjectDelegate.projectname}` : ""}
          defaultValue={existingEntry?.activityNumber}
          defaultSearch={existingEntry?.activityName}
          name={`${inputName}.selectedActivity`}
          items={selectedProject?.activities}
          fullName={"FULL NAME"}
          register={register}
          errors={myErrors?.selectedActivity}
          label="Milestone"
          labelFn={(item) => `${item.name}`}
          labelComponent={renderRow}
          valueFn={(item) => item.number }
          searchableAttributes={["name"]}
        />
      } */}

    </>
  )
  
}

export default ActivitySelect3;