import React, { useState, useEffect, useRef } from 'react';
// import { Link, navigate } from "@reach/router"


import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link, Outlet, useLocation, useNavigate
} from 'react-router-dom';


import { DateTime } from 'luxon';
import store from '../redux/store';
// import { getTimesheets, createTimesheet, api, showFullscreenLoading, hideFullscreenLoading } from './actions';
import { connect } from 'react-redux';
import Modal from '../components/Modal';

import Pagination from '../components/Pagination';

import { getPeriods } from '../redux/periods/periodActions';
import { getMyTimesheets, postTimesheet, createPreviousTimesheet, updateTimesheet, getTimesheets, deleteTimesheet } from '../redux/timesheets/timesheetActions';
import { useApi } from '../api';
import { clearStore } from '../redux/authActions';
import TimesheetPrintModal from './TimesheetPrintModal';
import { getHasAssignedProjects } from '../redux/projects/projectActions';



const TimesheetsPast = ({newTimesheetOpened, toggleNewTimesheetOpened, isLoading, timesheets}) => {

  const navigate = useNavigate("/");

  const [periods, periodsLoading, periodsLoaded, periodsError, doPeriodsFetch, setPeriodsResult] = useApi();
  
  const fetchPeriods = () => {
    doPeriodsFetch(getPeriods);
  }

  useEffect(() => {
    fetchPeriods();
  }, []);

  const [copyFromPrevChecked, setCopyFromPrevChecked] = useState(false);

  const toggleCopyFromPrev = () => {
    setCopyFromPrevChecked(old => !old);
  }

  const [timesheetCreateResult, timesheetCreateLoading, timesheetCreateLoaded, timesheetCreateError, doTimesheetCreateFetch, setTimesheetCreateResult] = useApi();


  const handleCreateTimesheet = async () => {
    const payload = {...selectedPeriod, prev: prevTs, copy: copyFromPrevChecked};
    doTimesheetCreateFetch(createPreviousTimesheet, {payload: payload});    
  }

  useEffect(() => {
    if (timesheetCreateLoaded && timesheetCreateResult) {
      navigate(`/timesheets/${timesheetCreateResult.id}`);
    }
  }, [timesheetCreateLoaded, timesheetCreateResult]);

  const [prevTs, setPrevTs] = useState(null);

  useEffect(() => {
    if (timesheets && timesheets.length > 0) {
      setPrevTs([...timesheets].reverse()[0].id);
    }
  }, [timesheets]);


  // const getCurrentPeriod = () => {
  //   const day = DateTime.local().toObject().day;

  //   if (day <= 14) {
  //     return DateTime.local().startOf("month");
  //   }
  //   else {
  //     return DateTime.local().startOf("month").plus({days: 15});
  //   }
  // }



  // useEffect(() => {
  //   executeScroll();
  // }, [newTimesheetOpened, periods]);

  // const setRowRef = (el, date) => {
  //   periodRows.current[date] = el
  // };

  const currentRef = useRef(null)
  const parentRef = useRef(null)


  // const executeScroll = () => {

  //   if (currentRef && currentRef.current && parentRef && parentRef.current) {
  //     scrollParentToChild(parentRef.current, currentRef.current)
  //   }
  // } 
  // const scrollToRef = (ref) => scrollParentToChild(parentRef, ref);


  // function scrollParentToChild(parent, child) {

  //   // Where is the parent on page
  //   var parentRect = parent.getBoundingClientRect();
  //   // What can you see?
  //   var parentViewableArea = {
  //     height: parent.clientHeight,
  //     width: parent.clientWidth
  //   };
  
  //   // Where is the child
  //   var childRect = child.getBoundingClientRect();
  //   // Is the child viewable?
  //   var isViewable = (childRect.top >= parentRect.top) && (childRect.bottom <= parentRect.top + parentViewableArea.height);
  
  //   // if you can't see the child try to scroll parent
  //   if (!isViewable) {
  //     // scroll by offset relative to parent
  //     parent.scrollTop = (childRect.top + parent.scrollTop) - parentRect.top
  //   }
  
  
  // }


  const [selectedPeriod, setSelectedPeriod] = useState(null);
  const periodSelectRef = useRef(null);
  const periodRows = useRef({});
  const [pastPeriods, setPastPeriods] = useState(null);
  const [futurePeriods, setFuturePeriods] = useState(null);
  useEffect(() => {
    // store.dispatch(getTimesheets());
    if (periods) {

      setPastPeriods(periods.past);
      setFuturePeriods(periods.future);

      if (periods.past) {
        setSelectedPeriod(periods.past[0]);
      }
    }
  }, [periods]);

  // useEffect(() => {
  //   if (selectedPeriod) {
  //     periodRows && periodRows.current && periodRows.current[selectedPeriod.periodStart] && periodRows.current[selectedPeriod.periodStart].scrollIntoView({ behavior: 'smooth' });
  //   }
  // }, [periodRows])


  return(
    <Modal focusTrap={false} toggleModal={toggleNewTimesheetOpened}>
      <div className='modal-header flex-cont align-center'>
        <div className="flex-1">
          <h2 className="margin-bottom-1">Add Past Timesheet</h2>
          <h3>Select a period:</h3>
        </div>
        <div onClick={toggleNewTimesheetOpened} className="button close ghost">
          <i className="fas fa-times"></i>
        </div>
      </div>

      {/* <div onClick={executeScroll} className="button">SCROLL</div> */}

      {periodsLoading && 
        <div className="padding-3">
          <div className="spinner"></div>
        </div>
      }
      {!periodsLoading && <>



      <div ref={parentRef} style={{height: '40vh', overflowY: 'scroll'}} className="padding-1">

        {/* <div>{JSON.stringify(selectedPeriod)}</div> */}
        <table className="basic">
          <tr>
            <th className="fit"></th>
            <th>Start</th>
            <th>End</th>
            <th></th>
          </tr>
          {pastPeriods && pastPeriods.map(period =>{ 
            const s = DateTime.fromISO(period.periodStart).toLocaleString();
            const e = DateTime.fromISO(period.periodEnd).toLocaleString();

            return (
              <tr onClick={() => setSelectedPeriod(period)}  className={selectedPeriod && selectedPeriod.periodStart === period.periodStart ? "selectable selected" : "selectable"}>
                <td className="fit">

                </td>
                <td className="fit nowrap">{s}</td>
                <td className="fit nowrap">to {e}</td>
                <td></td>
              </tr>
            )

          } 
          )}
          <tr>
            <td ref={currentRef} colspan="4">
              <div className="bold"></div>
            </td>
          </tr>
          {/* {futurePeriods && futurePeriods.map(period => { 
            const s = DateTime.fromISO(period.periodStart).toLocaleString();
            const e = DateTime.fromISO(period.periodEnd).toLocaleString();

            return (
              <tr onClick={() => setSelectedPeriod(period)}  className={selectedPeriod && selectedPeriod.periodStart === period.periodStart ? "selectable selected" : "selectable"}>
                <td className="fit">

                </td>
                <td className="fit nowrap">{s}</td>
                <td className="fit nowrap">to {e}</td>
                <td></td>
              </tr>
            )

          } 
            
          )} */}
        </table>
      </div>

      <div className='modal-footer flex-cont align-center'>
          <label className="margin-right-2 flex-cont align-center">
            
            {/* <div>{prevTs}</div> */}

            {/* <div className="code">
              <pre>
                {JSON.stringify(prevTs, null, 2)}
              </pre>
            </div> */}
            {prevTs &&
              <>
                <div onClick={toggleCopyFromPrev} className={copyFromPrevChecked ? "selected check-box" : "check-box"}>
                  <div className="check"></div>
                </div>
                <div className="margin-left-1 margin-right-1 label-text nowrap">Copy projects from:</div>

                {copyFromPrevChecked &&
                  <select className="auto-width" value={prevTs} onChange={e => setPrevTs(e.target.value)}>
                    
                    {timesheets && [...timesheets].reverse().map(timesheet => {
                      return(
                        <option value={timesheet.id}>{`${timesheet.number}: ${DateTime.fromISO(timesheet.weekStart).toLocaleString()} - ${DateTime.fromISO(timesheet.weekEnd).toLocaleString()}`}</option>
                      )
                    })}
                  </select>
                }
              </>
            }
          </label>

        <div className="flex-1"></div>
        
        <div className="margin-right-1 flex-cont align-center">
          {timesheetCreateLoading &&
            <div className="margin-right-1 spinner"></div>
          }
          <input className={`${timesheetCreateLoading ? 'disabled' : ''} button`} onClick={handleCreateTimesheet} type="submit" value="Create" />
          
        </div>
        <div onClick={toggleNewTimesheetOpened} className="button ghost">Cancel</div>
      </div>

      </>}
    </Modal>
  )
}

const mapState = state => ({
  isLoading: state.timesheetState.isLoading,
});

export default connect(mapState)(TimesheetsPast);
