
import React from "react";


const Selector = ({tab, setTab, name, label, count}) => {


  return(
    <div onClick={() => setTab(name)}  className={`${tab === name ? 'selected' : ''} selector`}>
      <div className="flex-cont align-center">
        <div>{label}</div>
        {(!!count) &&
          <div className="unread-count margin-left-half">
            <div className="number">{count} </div>
          </div>
        }
        
      </div>
    </div>
  )

}

export default Selector;