import { andyFetch, actionReturn } from '../../api';

export const submitOneUnposted = (params) => {
  return async (dispatch, getState) => {
    const url = "timesheets/submit_one_unposted";
    try {
      const response = await andyFetch(url, params, "POST");
      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);

      } else {
        throw new Error('submitOneUnposted error.');
      }
    }
    catch (e) {
      
    }
  }
}

export const submitAllUnposted = (params) => {
  return async (dispatch, getState) => {
    const url = "timesheets/submit_all_unposted";
    try {
      const response = await andyFetch(url, params, "POST");
      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);

      } else {
        throw new Error('submitAllUnposted error.');
      }
    }
    catch (e) {
      
    }
  }
}

export const getTimesheetUnposted = (params) => {
  return async (dispatch, getState) => {
    const url = "timesheets/unposted";
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);

      } else {
        throw new Error('getTimesheetUnposted error.');
      }
    }
    catch (e) {
      
    }
  }
}

export const getTimesheetLogs = (params) => {
  return async (dispatch, getState) => {
    const url = "timesheets/logs";
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);

      } else {
        throw new Error('getTimesheetLogs error.');
      }
    }
    catch (e) {
      
    }
  }
}

export const getTimesheetLogsOutbounds = (params) => {
  return async (dispatch, getState) => {
    const url = "timesheets/outbounds";
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();
        return actionReturn(json, response);

      } else {
        throw new Error('getTimesheetLogs error.');
      }
    }
    catch (e) {
      
    }
  }
}

export const getTimesheetLogsSuccesses = (params) => {
  return async (dispatch, getState) => {
    const url = "timesheets/successes";
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();
        return actionReturn(json, response);

      } else {
        throw new Error('getTimesheetLogs error.');
      }
    }
    catch (e) {
      
    }
  }
}

export const getTimesheetLogsDynamics = (params) => {
  return async (dispatch, getState) => {
    const url = "timesheets/timesheet_dynamics";
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();
        return actionReturn(json, response);

      } else {
        throw new Error('getTimesheetLogsDynamics error.');
      }
    }
    catch (e) {
      
    }
  }
}

export const getTimesheetLogsFailures = (params) => {
  return async (dispatch, getState) => {
    const url = "timesheets/failures";
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();
        return actionReturn(json, response);

      } else {
        throw new Error('getTimesheetLogs error.');
      }
    }
    catch (e) {
      
    }
  }
}

export const getTimesheetReport = (params) => {
  return async (dispatch, getState) => {
    const url = "timesheets/report";
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);

      } else {
        throw new Error('getTimesheetReport error.');
      }
    }
    catch (e) {
      
    }
  }
}



export const submitTimesheet = (params) => {
  return async (dispatch, getState) => {
    const url = "timesheets/submit";
    try {
      const response = await andyFetch(url, params, "POST");
      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);

      } else {
        throw new Error('submitTimesheet error.');
      }
    }
    catch (e) {
      
    }
  }
}

export const copyTimesheet = (params) => {
  return async (dispatch, getState) => {
    const url = "timesheets/copy";
    try {
      const response = await andyFetch(url, params, "POST");
      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);

      } else {
        throw new Error('copyTimesheet error.');
      }
    }
    catch (e) {
      
    }
  }
}

export const repostTimesheet = (params) => {

  return async (dispatch, getState) => {
    // const token = getState()?.authState?.dynamicsToken?.access_token);

    const url = "timesheets/repost_timesheet";

    try {
      const response = await andyFetch(url, params, "POST");
      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);

      } else {
        throw new Error('repostTimesheet error.');
      }
    }
    catch (e) {

      
    }
  }
};



export const postTimesheet = (params) => {

  return async (dispatch, getState) => {
    // const token = getState()?.authState?.dynamicsToken?.access_token);

    const url = "post_timesheet";

    try {
      const response = await andyFetch(url, params, "POST");
      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);

      } else {
        throw new Error('postTimesheet error.');
      }
    }
    catch (e) {

      
    }
  }
};

export const getTimesheetsIApproved = (params) => {
  return async (dispatch, getState) => {
    const url = "timesheets/i_approved";
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();

        // const json = await response.json();
        

        dispatch({
          type: "SET_APPROVED_TIMESHEET_COUNT",
          approvedCount: json.approvedCount
        })

        
        return actionReturn(json, response);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};

export const getSubmittedCount = (params) => {
  return async (dispatch, getState) => {
    const url = "timesheets/submitted_count";
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};


export const getSubmittedTimesheets = (params) => {
  return async (dispatch, getState) => {
    const url = "timesheets/submitted";
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();

        dispatch({
          type: "SET_SUBMITTED_TIMESHEET_COUNT",
          submittedCount: json.submittedCount
        })


        return actionReturn(json);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};

export const getTimesheets = (params) => {
  return async (dispatch, getState) => {
    const url = "timesheets";
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();

        dispatch({
          type: "SET_SUBMITTED_TIMESHEET_COUNT",
          submittedCount: json.submittedCount
        })

        return actionReturn(json);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};

export const getMyTimesheets = (params) => {
  return async (dispatch, getState) => {
    const url = "timesheets/mine";
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();

        dispatch({
          type: "SET_SUBMITTED_TIMESHEET_COUNT",
          submittedCount: json.submittedCount
        })

        return actionReturn(json, response);

        // return actionReturn(json);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};

export const createTimesheet = (params) => {

  return async (dispatch, getState) => {

    const url = "timesheets";

    try {
      const response = await andyFetch(url, params, "POST");
      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);

      } else {
        throw new Error('createTimesheet error.');
      }
    }
    catch (e) {

      
    }
  }
};

export const createPreviousTimesheet = (params) => {

  return async (dispatch, getState) => {

    const url = "timesheets/create_previous";

    try {
      const response = await andyFetch(url, params, "POST");
      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);

      } else {
        throw new Error('createPreviousTimesheet error.');
      }
    }
    catch (e) {

      
    }
  }
};

export const updateTimesheet = (params) => {

  return async (dispatch, getState) => {

    const url = `timesheets`;

    try {

      const response = await andyFetch(url, params, "PATCH");

      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);

      } else {
        throw new Error('updateUser error.');
      }
    }
    catch (e) {
      
    }
  }
};


export const getTimesheet = (params) => {
  return async (dispatch, getState) => {
    const url = `timesheets/show_timesheet`;

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};

export const getPrintTimesheet = (params) => {
  return async (dispatch, getState) => {
    const url = `timesheets/print_timesheet`;

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};

export const getTimesheetWeeks = (params) => {
  
  return async (dispatch, getState) => {
    const url = `timesheets/show_timesheet_weeks`;

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        
        
        return actionReturn(json);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};


export const getTimesheetSummary = (params) => {
  
  return async (dispatch, getState) => {
    const url = `timesheets/summary`;

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        
        
        return actionReturn(json);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};
export const setCurrentTimesheetSummary = (currentSummary) => {
  return dispatch => {
    dispatch({
      type: "SET_CURRENT_TIMESHEET_SUMMARY",
      currentSummary: currentSummary
    });
  }
}

export const deleteTimesheet = (params) => {

  return async (dispatch, getState) => {

    const url = "timesheets/delete";

    try {
      const response = await andyFetch(url, params, "POST");
      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);

      } else {
        throw new Error('deleteTimesheet error.');
      }
    }
    catch (e) {

      
    }
  }
};


export const addSavingEntryId = (id) => {
  return dispatch => {
    dispatch({
      type: "ADD_SAVING_ENTRY_ID",
      id: id
    });
  }
}
export const removeSavingEntryId = (id) => {
  return dispatch => {
    dispatch({
      type: "REMOVE_SAVING_ENTRY_ID",
      id: id
    });
  }
}