import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import MainNav from '../components/MainNav';


const DashboardLayout = ({viewAsUser, currentUser, children, trySignout, dynamicsToken}) => {


  return (
  <>
    <MainNav trySignout={trySignout} />
    {/* <div className="inline-flex justify-centerx align-start" style={{display: 'flex', flex: 1, borderRadius: '20px', overflow: 'hidden', height: '900px', backgroundSize: 'cover', backgroundRepeat: 'none', backgroundPosition: 'center', backgroundImage: 'url(https://www.boutiquedesign.com/wp-content/uploads/2017/08/Jumeirah-Maldives-1080x640.jpg)', position: 'absolute', left: '5%', right: '5%'}}> */}
   {true &&

    <div className={`padding-bottom-4 dash-cont inline-flex justify-centerx align-start ${(currentUser?.roleid  === 2) ? 'admin' : ''}`} 
      style={{
        flexDirection: 'column',
      }}>
    <div className={`dash-bg ${(currentUser?.roleid  === 2) ? 'admin' : ''}`} 
      style={{
        
        backgroundImage: `url(https://www.hba.com/images/HBA-Home-Hero-Image-mock-1.jpg)`, 
        
        // left: '5%', 
        // right: '5%'
        }}></div>
      {viewAsUser && <>


        <Link to="/timesheets" className="flex-1x flex-cont justify-center align-center margin-bottom-1">
          <div className="dash-card">
            <i className="fas fa-clock font-3 margin-bottom-1"></i>
            <div className="bold">Timesheets</div>
          </div>
        </Link>


        {(viewAsUser.roleid === 4 || viewAsUser.roleid === 1 || viewAsUser.roleid === 8 || viewAsUser.roleid === 9 || viewAsUser.roleid  === 2 || viewAsUser.roleid  === 3) && <>
          {/* <Link style={{textAlign: 'center', borderRadius: '1em', background: 'white', padding: '1em'}} to="/timesheets/review" className="flex-1 flex-cont justify-center align-center margin-right-2">
            <div >
              <i className="fas fa-calendar font-3 margin-bottom-1"></i>
              <div className="bold">Review Times</div>
            </div>
          </Link> */}
          <Link to="/budgeting" className="flex-1x flex-cont justify-center align-center margin-bottom-1">
            <div className="dash-card">
              <i className="fas fa-money-check-alt font-3 margin-bottom-1"></i>
              <div className="bold">Budgeting</div>
              {/* <div>{viewAsUser?.roleid}</div> */}
            </div>
          </Link>
        </>}

        {(viewAsUser.roleid  === 2  || viewAsUser.roleid  === 3 ) && <>
          <Link to="/settings" className="flex-1x flex-cont justify-center align-center margin-bottom-1">
            <div className="dash-card">
              <i className="fas fa-cog font-3 margin-bottom-1"></i>
              <div className="bold">Admin</div>
            </div>
          </Link>
        </>}

        {(viewAsUser.roleid  === 3 || viewAsUser.roleid  === 7) && <>
          <Link to="/users" className="flex-1x flex-cont justify-center align-center margin-bottom-1">
            <div className="dash-card">
              <i className="fas fa-user font-3 margin-bottom-1"></i>
              <div className="bold">Users</div>
            </div>
          </Link>
        </>}

        {(viewAsUser.roleid === 1 || viewAsUser.roleid === 8 || viewAsUser.roleid === 9 || viewAsUser.roleid  === 2 || viewAsUser.roleid  === 3 || viewAsUser.roleid  === 4) && <>
        
          <a target="_blank" href="https://reports.hba.com" className="flex-1x flex-cont justify-center align-center margin-bottom-1">
            <div className="dash-card">
              <i className="fas fa-chart-line font-3 margin-bottom-1"></i>
              <div className="bold">PPR</div>
            </div>
          </a>
          
        </>}

        {process.env.REACT_APP_DEPLOYMENT_SLOT !== "prod" &&
          <Link to="/expense-reports" className="flex-1x flex-cont justify-center align-center ">
            <div className="dash-card">
              <i className="fas fa-file-invoice-dollar font-3 margin-bottom-1"></i>
              <div className="bold  center">Expense Reports</div>
            </div>
          </Link>
        }

        

        </>}

      </div>
      }
      </>
    
)
}

function mapState(state) {
  return { 
    currentAccount: state.authState.currentAccount,
    currentUser: state.authState.currentUser,
    viewAsUser: state.authState.viewAsUser,
    submittedCount: state.timesheetState.submittedCount,
    dynamicsToken: state.authState.dynamicsToken,
  };
} 

export default connect(mapState)(DashboardLayout);