import React, { useEffect, useLayoutEffect } from 'react';
import { DateTime, Info } from 'luxon';
import { connect } from 'react-redux';
import { useWatch } from "react-hook-form";

let count = 0;
const TimesheetWeek = ({setValue, trigger, entries, getValues, errors, control, watch, selectedPeriodIndex, selectPeriodIndex, i, p}) => {
 
  const getMyErrors = () => {
    return errors?.['weeks']?.[i]?.['entries']?.filter(e => true && e)?.length || 0
  }
  

  const dayArray = (week) => {
    if (!week) {
      return [];
    }

    let array = [];

    let i;
    let iStart = 0;
    let count = 0;
    let start = DateTime.fromISO(week.weekStart);
    let end = DateTime.fromISO(week.weekEnd);
    let d;

    // if (start.weekday === 7) {
    //   array = [start];
    //   start = start.plus({day: 1});
    //   // iStart = 1;
    //   // count = 1;
    // }
    // else {
    //   array =[null]
    // }

    // array =[null]
    
    for(i=iStart; i<7; i++) {


      // 
      d = start.plus({days: count});

      if (d.weekday === i+1 && d <= end) {
        array.push(d);
        count +=1;
      }
      else {
        array.push(null);
      }
    }

    

    return array;

    
  };

  useLayoutEffect(() => {
    // const v = getValues(`weeks.${i}`);
    // setValue(`weeks.${i}`, null);

    // 

  }, [])

  



  useEffect(() => {
    const v = getValues(`weeks.${i}`);
    // setValue(`weeks.${i}`, null);

    // 
    // 
  }, [p])

  const w = useWatch({
    control,
    name: `weeks.${i}`, // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
    defaultValue: getValues(`weeks.${i}`)
    // default value before the render
  });




  const hasValues = () => {
    if (!w) return false
    if (w.length === 0) return false
    if (Object.values(w).filter(e => e !== undefined).length === 0) return false

    return true
  }

  


  // 

  const sum = () => {
    let found = false;
    let total = 0.0;
    // if (!w || !w.entries)  return total;
    // 
    // 

    if (w && w.entries && !found) {
      w.entries.forEach(entry => {

        if (entry.hours && !found) {
          const h = Object.values(entry.hours);
          // alert(JSON.stringify(h))
          const isEmpty = h.filter(e => e !== undefined).length === 0;

          // 
          // 
      

          if (!isEmpty) {
            h.forEach(hh => {
              const pf = parseFloat(hh) ;
              total = total + (!pf || isNaN(pf) ? 0 : pf)
              
            })
            
          }
          else {
            total =  p.totalHours
            found = true
          }
        }
      
        // total = h.map(item => parseFloat(item.replace(/\s+/g, ''))).filter(item => item && !isNaN(item)).reduce((acc, curr) => {
        //   const pf = parseFloat(curr);
        //   if (!pf || isNaN(pf)) {
        //     return parseFloat(acc) + 0;
        //   }
        //   else {
        //     return parseFloat(acc) + pf;
        //   }
        // }, 0);
      })
    }

    


    return parseFloat(total).toFixed(1);
  }

  const indexOfDay = (day) => {

  }

  const sumForDay = (day, dayIndex) => {


    let foundEmpty = false;
    let total = 0;
    if (!w || !w.entries)  return total;

    
    w.entries.forEach((entry, index) => {

      if (foundEmpty === false) {

      

      const isEmpty = Object.values(entry?.hours).filter(e => e !== undefined).length === 0

      
      
      if (entry._destroy === "1") {
        total = 0.0
        foundEmpty = true;   
      }
      else if (isEmpty || !entry.hours || !entry.hours[day] ) {
        // total = p[`day${index}hour`]s
        // 
        // return p[`day${index}hour`]
        // 
        // 
        // 

        total = parseFloat(p[`day${dayIndex}hour`])
        foundEmpty = true;

        // return parseFloat(p[`day${index}hour`])
        // total = total + parseFloat(p[`day${index}hour`])
        
      }
      else {
        // total = total + parseFloat(entry.hours[day])
        // 
        // 
        // 
        const pf = parseFloat(entry.hours[day]) ;
        total = total + (!pf || isNaN(pf) ? 0 : pf)
      }

      }

    })

    return parseFloat(total);
  }

  // const days = ['FIRST SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];
  const days = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];

  const renderDays = (week) => {
    // return(
    //   <td>{dayArray(week).length}</td>
    // )
    let count = 0;
    return (

      <>
        {dayArray(week).map((day, i) => {

          

          if (day) {
            count+=1;
            return (
              <td className="fit nowrap center">
                <div>
                  <div className="clock-in align-center">
                    {/* <i className="far fa-clock margin-right-4px"></i>
                    <span>-</span> */}
                  </div>
                  <div className="bold">
                  {/* {JSON.stringify(w)} */}
                  {sumForDay(days[i], count)}
                    {/* {w && sumForDay(days[i])}
                    {!w && week[`day${count}hour`]} */}
                  </div>

                  <div className="font-075">
                    {day.toLocaleString()} 
                  </div>
                </div>
              </td>
            );
          }
          else {
            return (
              <td className="fit nowrap center">
                <div>
                  <i style={{color: 'rgba(0,0,0,.2)'}} className="fas fa-times"></i>
                </div>
              </td>
            );
          }
        })}
      </>
    );
  };

  count++;
  return (
    <tr onClick={() => selectPeriodIndex(i, p)} className={selectedPeriodIndex === i ? "selectable selected" : "selectable"}>
      {/* <td className="fit nowrap">{JSON.stringify(p)}</td> */}
      <td className="center fit nowrap"> 
        {/* Week c: {count}

        <duv className="button" onClick={() => trigger()}></duv> */}
        {getMyErrors() !== 0 &&
          <div className="status-badge-cont returned font-075">
            <div className="status-badge">
              <div className="margin-right-half">
                {getMyErrors()}
              </div>
              <div className="not-bold">
                {`ISSUE${getMyErrors() !== 1 ? "S" : ""}`}
              </div>
            </div>
          </div>
        }
        {p.returnedCount !== 0 &&
          <div className="status-badge-cont returned font-075">
            <div className="status-badge">
              <div className="margin-right-half">
                {p.returnedCount}
              </div>
              <div className="not-bold">
                RETURNED
              </div>
            </div>
          </div>
        }
      </td>
      <td className="center fit nowrap">
        {/* {p.entryCount && parseInt(p.entryCount) > 0 ? (
          <div className="badge"><span>{p.entryCount}</span></div>
        ) : (
          <div>0</div>
        ) } */}
        <strong>{i+1}</strong>
      </td>
      <td className="fit nowrap">{DateTime.fromISO(p.weekStart).toLocaleString()}</td>
      <td className="fit nowrap">{DateTime.fromISO(p.weekEnd).toLocaleString()}</td>
      
      {
        
        renderDays(p)
        
      }
      {/* <td>{JSON.stringify(dayArray(p))}</td> */}
      <td className="fit nowrap center">
        
        <div className="clock-in align-center">
          {/* <i className="far fa-clock margin-right-4px"></i>
          <span>-</span> */}
        </div>
        <div className="bold"> 
          {w && sum()}
          {!w && p.totalHours}
          
        </div>

        
      </td>
      <td></td>
    </tr>
  )
}


let parentCount=0
const TimesheetWeeks = ({setValue, trigger, entries, getValues, errors, control, watch, currentSummary, timesheetWeeks, timesheet, isLoading, weekSummary, timesheetId, weekSummaryIsLoading, selectPeriodIndex, selectedPeriodIndex}) => {


  useEffect(() => {
    // if (!weekSummary) {
    //   // store.dispatch(api('timesheets/show_timesheet_weeks', null, {id: timesheetId}, 'GET', 'TIMESHEET_WEEK_SUMMARY'));
    // }
  }, []);

  const w = useWatch({
    control,
    name: `weeks`, // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
    // defaultValue: getValues(`weeks.${i}`)
    // default value before the render
  });

  const sum = () => {
    let found = false;
    let total = 0.0;
    // if (!w || !w.entries)  return total;

    if (w && !found) {
      w.forEach(week => {
        if (week && week.entries && !found) {
          week.entries.forEach(entry => {
    
            if (entry && entry.hours && !found) {
              const h = Object.values(entry.hours);
              // alert(JSON.stringify(h))

              const isEmpty = h.filter(e => e !== undefined).length === 0;

              // 
              // 
          

              if (!isEmpty) {
                h.forEach(hh => {
                  const pf = parseFloat(hh) ;
                  total = total + (!pf || isNaN(pf) ? 0 : pf)
                  
                })
                
              }
              else {
                currentSummary.forEach(p => {

                  // 
                  total = total + parseFloat(p.totalHours);
                })
                found = true
              }


            }
          })
        }
      })
    }
    return parseFloat(total).toFixed(1);
  }




  parentCount++;
  return(
    <div>
      {/* {(!weekSummary || (weekSummaryIsLoading && !weekSummary)) && 
        <div className="margin-2 spinner"></div>
      } */}

      {/* <div>
        Weeks c: {parentCount}
      </div>
       */}
      {currentSummary &&
        <table className="basic no-stripes">
          <thead>
            <tr>
              <th></th>
              <th className="fit nowrap">WEEK</th>
              {/* <th className="fit nowrap">Number</th> */}
              <th className="fit nowrap">Start</th>
              <th className="fit nowrap">End</th>
              
              {Info.weekdays('short').map(day => {
                return (
                  <th  className="fit nowrap" key={day}>{day}</th>
                );
              })}
              <th>Hours</th>
              <th></th>
            </tr>
          </thead>
          
          <tbody>
            {currentSummary && currentSummary.map( (p, i) => {
              return (
                <TimesheetWeek setValue={setValue} trigger={trigger} entries={entries} getValues={getValues} errors={errors} control={control} watch={watch} p={p} i={i} selectPeriodIndex={selectPeriodIndex} selectedPeriodIndex={selectedPeriodIndex}  />
              );
            })}
          </tbody>
          <thead>
            <tr className="total">
              <th colSpan={10}></th>
              <th className="text-right">TOTAL</th>
              <th className="text-center">
                {!w && currentSummary && currentSummary[0].tsTotal}
                {w && sum()}
              </th>
              <th colSpan={1}></th>
            </tr>
          </thead> 
        </table>
      }
    </div>
  )
};

const mapState = state => ({
  weekSummary: state.timesheetState.weekSummary,
  // timesheet: state.timesheetState.currentTimesheet,
  weekSummaryIsLoading: state.timesheetState.weekSummaryIsLoading,
  currentSummary: state.timesheetState.currentSummary,
  entries: state.entryState.data,
});

export default connect(mapState)(TimesheetWeeks);
