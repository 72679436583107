import React, {useEffect, useState} from 'react';
import AutoComplete from '../../components/AutoComplete';

const Row = ({item}) => {

  return (
    <div className="flex-cont">
      <div className='flex-1'>{item.name}</div>
    </div>
  )
}



export const SubactivitySelect3 = ({selectedSubactivity, setSelectedSubactivity, selectedActivity, selectedProject, setSelectedActivity, watchProjectId, projectsLoading, setValue, hide, disabled, myErrors,  register, inputName, existingEntry, projects}) => {

  const [canReset, setCanReset] = useState(false);
  
  const renderRow = item => <Row item={item} />

  const onSelect = item => {
    setSelectedSubactivity(item)
  }


  const getFoundSubactivity = (number) => {
    if (number == null) return null;
    const found = selectedActivity?.subactivities?.find(sub => sub.id == number);
    return found
  }


  // const getFoundSubactivity = () => {
  //   if (!existingEntry?.subactivitynum) return null;
    
  //   const foundSubactivity = selectedActivity?.subactivities?.find(sub => sub.id === existingEntry.subactivitynum);

  //   return foundSubactivity
  // }


  useEffect(() => {
    if (existingEntry && selectedActivity?.subactivities) {
      const foundSub = getFoundSubactivity(existingEntry.subactivitynum);
      setSelectedSubactivity(foundSub);
    }
  }, [existingEntry, selectedActivity]);

  useEffect(() => {
    if (selectedActivity) {
      setCanReset(true);
    }
  }, [selectedActivity]);


  


  const subactivityMissing = () => {
    if (!existingEntry) return false;
    if (existingEntry.subactivitynum === null || existingEntry.subactivitynum === undefined ||  existingEntry.subactivitynum === "") return false;
    if (projectsLoading) return false;

    if (selectedSubactivity) {
      return getFoundSubactivity(selectedSubactivity?.id) == null;
    }
    else {
      return false;
    }
  }

  return (
    <>
      {/* <pre>
        SA: {JSON.stringify(selectedActivity, null, 2)}
      </pre> */}


      {projectsLoading &&
        <div className="loading-input-dummy">
          <div  className="spinner">

          </div>
        </div>
      }

      {subactivityMissing() && 
        <div>
          <div style={{marginBottom: '4px'}} className="gray bold font-075">TASK</div>
          <div>
            
            <div className="dummy-select">
              <i className="fas fa-exclamation-triangle margin-right-half"></i>
              {existingEntry?.subactivityname}
            </div>
            <input
              style={{display: 'none'}}
              type="text"
              name={`${inputName}.selectedSubactivity`} 
              id={`${inputName}.selectedSubactivity`} 
              defaultValue={existingEntry?.subactivityname} 
              {...register(`${inputName}.selectedSubactivity`, {
                required: `Task required.`
              })}
            />
          </div>
          
        </div>
      }

      {!projectsLoading && selectedActivity?.subactivities &&
              
        <AutoComplete
          canReset={canReset}
          setValue={setValue}
          onSelect={(item) => onSelect(item)}
          // defaultValue={formProjectDelegate?.projectid}
          // defaultSearch={formProjectDelegate ? `${formProjectDelegate.projectid} - ${formProjectDelegate.projectname}` : ""}
          defaultValue={existingEntry?.subactivitynum}
          defaultSearch={existingEntry?.subactivityname}
          name={`${inputName}.selectedSubactivity`}
          items={selectedActivity?.subactivities}
          fullName={"FULL NAME"}
          register={register}
          errors={myErrors?.selectedSubactivity}
          label="Task"
          labelFn={(item) => `${item.name}`}
          labelComponent={renderRow}
          valueFn={(item) => item.id }
          searchableAttributes={["name"]}
        />
      }

    </>
  )
  
}

export default SubactivitySelect3;