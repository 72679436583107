import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link, NavLink, Outlet, useLocation
} from 'react-router-dom';
import { connect } from 'react-redux';


const isActive = ({ isCurrent }) => {
  return isCurrent ? { className: "active" } : null
}



const SubNav = ({children, count}) => {


  return(
    <ul className="sub-nav">
      <li>
        <NavLink end to="/users">Employees</NavLink>
      </li>

      <li>
        <NavLink to="/users/new-employees">

          <div className="flex-cont align-center">
            <div className="flex-cont align-center justify-center unread-count">
              <div className="number">
                {count ? count : "-"}
              </div>
            </div>
            <div>Add New Employees</div>
          </div>
         
          

          {/* <div className="pulser stopped"></div> */}
        </NavLink>


      </li>
    </ul>
  );
}


function mapStateToProps(state) {
  return { 
    // currentUser: state.sessionState.currentUser,
  };
} 

export default connect(mapStateToProps)(SubNav);
