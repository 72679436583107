import React, { useEffect } from 'react';

import {
  BrowserRouter as Router,
  useParams
} from 'react-router-dom';

import { connect } from 'react-redux';

import AdminTeamsForm from './AdminTeamsForm';

import { getOfficeManagerTeam } from '../../redux/admin/officeManagerTeamActions';
import { useApi } from '../../api';


const AdminOfficeManagerTeamsEdit = ({formTeam, currentCompany}) => {
  
  
  const { id } = useParams();

  const [result, loading, loaded, error, doFetch, setResult, pagy] = useApi();
  
  useEffect(() => {
    fetchOfficeManagerTeam();
  }, []);

  const fetchOfficeManagerTeam = () => {
    doFetch(getOfficeManagerTeam, {query: {id: id}});
  }
  
  return (
    <>

    {/* <pre>
      {JSON.stringify(result, null, 2)}
    </pre> */}
      <div className="xxx margin padding-2x">

        <h1 className="flex-cont">
          <div className="medgray margin-right-half">{currentCompany}</div>
          Edit Office Manager Team
        </h1>

        {loading && <div className="spinner"></div>  }
        {result &&
          <AdminTeamsForm type="Office" formTeam={result} />
        }

        

      </div>
    
    </>
  );
}

function mapState(state) {
  return { 
    // tokenResponse: state.authState.tokenResponse,
    formTeam: state.teamState.formTeam,
    currentCompany: state.authState.currentCompany
  };
} 

export default connect(mapState)(AdminOfficeManagerTeamsEdit);
