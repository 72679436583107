import React, { useEffect } from 'react';


import { connect } from 'react-redux';
import DelegateNav from './DelegateNav';



const DelegateLayout = ({currentCompany, children}) => {

  // if (process.env.REACT_APP_DEPLOYMENT_SLOT == "prod") {
  //   return <></>
  // }
  
  
  return (
    <>
      {/* <ViewAsBanner /> */}
      <DelegateNav />
      {children}
    </>
  )
}



function mapState(state) {
  return { 
    // tokenResponse: state.authState.tokenResponse,
    currentCompany: state.authState.currentCompany
  };
} 

export default connect(mapState)(DelegateLayout);
