import React, { useState, useEffect } from 'react';
import XLSX from 'xlsx';
import { getTimesheetReport } from '../../redux/timesheets/timesheetActions';
import { useApi } from '../../api';
import { DateTime } from 'luxon';
import { connect } from 'react-redux';
import { slice } from 'lodash';
import Selector from '../../components/Selector';

const initialData = {
  cols: [{ name: "A", key: 0 }, { name: "B", key: 1 }, { name: "C", key: 2 }, { name: "D", key: 3 }, { name: "E", key: 4 }, { name: "F", key: 5 }, { name: "G", key: 6 }, { name: "H", key: 7 }, { name: "I", key: 8} ],
  data: [
    [ "Timesheet number", "Employee", "Name", "Start date", "End date", "Created by", "Approval Status", "Hoursx", "Company"],
    [    1, "sheetjs",    7262 ],
    [    2, "js-xlsx",    6969 ]
  ]
};



const initialApproverData = {
  cols: [{ name: "A", key: 0 }, { name: "B", key: 1 }, { name: "C", key: 2 }, { name: "D", key: 3 }, { name: "E", key: 4 }, { name: "F", key: 5 }, { name: "G", key: 6 }, ],
  data: [
    [ "Period End Date", "Manager Name", "Employee Name", "Timesheet Number", "Company", "Approval Status", "Hours"],
    [    1, "sheetjs",    7262 ],
    [    2, "js-xlsx",    6969 ]
  ]
};

const ApproverReport = ({setIsLoading, startDate, endDate, company, currentCompany, kind}) => {
  const [data, setData] = useState([]);
	const [cols, setCols] = useState([]);
  const [timesheets, loading, loaded, error, doFetch, setResult] = useApi();

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (company && startDate && endDate && kind) {
      // alert('here');
      fetchReport();
    }
    
  }, [startDate, endDate, company, kind]);

  useEffect(() => {
    if (timesheets) {
      setData(timesheets);

      if (kind === 'employee') {
        setCols(initialData.cols);
      }
      else {
        setCols(initialApproverData.cols);
      }
      
    }
  }, [timesheets]);

  const fetchReport = () => {
    doFetch(getTimesheetReport, {query: {kind: kind, company: company, start_date: startDate, end_date: endDate}});
  }
  

  const exportFile = () => {
		/* convert state to workbook */
		const ws = XLSX.utils.aoa_to_sheet(data);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "Report");
		/* generate XLSX file and send to client */
		XLSX.writeFile(wb, `Approver.Timesheet.Report.${DateTime.fromISO(startDate)?.toISODate()}.${DateTime.fromISO(endDate)?.toISODate()}.xlsx`)
	};

  const onFilterClick = () => {
    fetchReport();
  }

  return (
    <>
      {timesheets && !loading &&

        <>
          <div className="row">
            <div className="col-xs-12">
              <div className="button" disabled={!data.length || loading}  onClick={exportFile}>
                Export to Excel
              </div>
            </div>
          </div>
          {/* 
            <div className="code"> 
              <pre>
                {JSON.stringify(timesheets, null, 2)}
              </pre>
            </div> 
          */}
          <div className='margin-bottom-1 margin-top-2 font-1-5 bold'>Preview </div>
          {/* {data && data.length > 25 && <div className='margin-bottom-2 gray bold'>Showing first 25 employees. Download Excel file to see full list.</div>} */}


          <div className="table-responsive">
            <table className="basic table table-striped">
              <thead>
                {/* <tr>{cols?.map((c) => <th key={c.key}>{c.name}</th>)}</tr> */}
                <tr>
                  {cols?.map(c => <th key={0}>{ data[0][c.key] }</th>)}
                </tr>
              </thead>
              <tbody>
                {data?.slice(1, -1)?.map((r,i) => 
                  <tr key={i}>
                    {cols?.map(c => <td key={c.key}>{ r[c.key] }</td>)}
                  </tr>)
                }
              </tbody>
            </table>
            {/* {data && data.length > 25 && <div className='margin-top-2 gray bold'>Showing first 25 employees. Download Excel file to see full list.</div>} */}
          </div>
        </>
      }
    </>
  )


}

const EmployeeReport = ({setIsLoading, currentCompany, startDate, endDate, company, kind}) => {
  const [data, setData] = useState([]);
	const [cols, setCols] = useState([]);
  const [timesheets, loading, loaded, error, doFetch, setResult] = useApi();

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (startDate && endDate && kind) {
      // alert('here');
      fetchReport();
    }
    
  }, [startDate, endDate, company, kind]);

  useEffect(() => {
    if (timesheets) {
      setData(timesheets);

      if (kind === 'employee') {
        setCols(initialData.cols);
      }
      else {
        setCols(initialApproverData.cols);
      }
      
    }
  }, [timesheets]);

  const fetchReport = () => {
    doFetch(getTimesheetReport, {query: {kind: kind, company: company, start_date: startDate, end_date: endDate}});
  }
  

  const exportFile = () => {
		/* convert state to workbook */
		const ws = XLSX.utils.aoa_to_sheet(data);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "Report");
		/* generate XLSX file and send to client */
		XLSX.writeFile(wb, `Timesheet.Report.${DateTime.fromISO(startDate)?.toISODate()}.${DateTime.fromISO(endDate)?.toISODate()}.xlsx`)
	};

  const onFilterClick = () => {
    fetchReport();
  }

  const previewData = data ? data.slice(0, 25) : null;

  return (
    <>
      {timesheets && !loading &&

        <>
          <div className="row">
            <div className="col-xs-12">
              <div className="button" disabled={!data.length || loading}  onClick={exportFile}>
                Export to Excel
              </div>
            </div>
          </div>
          {/* 
            <div className="code"> 
              <pre>
                {JSON.stringify(timesheets, null, 2)}
              </pre>
            </div> 
          */}
          <div className='margin-bottom-1 margin-top-2 font-1-5 bold'>Preview </div>
          {previewData && previewData.length >= 25 && <div className='margin-bottom-2 gray bold'>Showing first 25 rows. Download Excel file to see full list.</div>}

          {/* {data && data.length &&
            div.gray
          } */}
          <div className="table-responsive">
            <table className="basic table table-striped">
              <thead>
                {/* <tr>{cols?.map((c) => <th key={c.key}>{c.name}</th>)}</tr> */}
                <tr>
                  {cols?.map(c => <th key={0}>{ data[0][c.key] }</th>)}
                  {/* <th>COMPANY</th> */}
                </tr>
              </thead>
              <tbody>
                {previewData?.slice(1, -1)?.map((r,i) => 
                  <tr key={i}>
                    {cols?.map(c => <td key={c.key}>{ r[c.key] }</td>)}
                    {/* <td>{r.company}</td> */}
                  </tr>)
                }
              </tbody>
            </table>
            {/* {data && data.length >= 25 && <div className='margin-top-2 gray bold'>Showing first 25 employees. Download Excel file to see full list.</div>} */}
          </div>
        </>
      }
    </>
  )


}

const TimesheetReport = ({currentCompany, viewAsUser}) => {

  const [startDate, setStartDate] = useState(DateTime.now().startOf('month').toISODate());
  const [endDate, setEndDate]     = useState(DateTime.now().endOf('month').toISODate());
  const [company, setCompany] = useState(currentCompany);
  const [kind, setKind] = useState('employee');
  const [selectedCompany, setSelectedCompany] = useState('one');
  
  useEffect(() => {
    setCompany(currentCompany);
  }, [currentCompany]);

  const checkBoxClicked = box => {

    if (!isLoading) {
      setSelectedCompany(box)
    }
    
  }


  const [isLoading, setIsLoading] = useState(false);

  return(
    <>

      <div className={`spinner-cont-cool ${isLoading && "entered"}`}>
        <div className="cool spinner"></div>
      </div>
       

      <div className="flex-cont align-center">
        <h1 className="flex-cont">
          {/* <div className="margin-right-half medgray">{currentCompany}</div> */}
            {kind && kind[0].toUpperCase() + kind.slice(1)} Timesheet Report
        </h1>
      </div> 


      {/* <div className="margin-top-1 margin-bottom-3">


        <div className="flex-cont align-center">
          <div onClick={() => setKind('employee')} className="pointer margin-right-2 flex-cont align-center">
            <div className={`check-box margin-right-1 ${kind === 'employee' ? 'selected' : ''}`}>
              <div className="check"></div>
            </div>
            <div className="gray bold">Employee Report</div>
          </div>

          <div onClick={() => setKind('approver')} className="pointer margin-right-2 flex-cont align-center">
            <div className={`check-box margin-right-1 ${kind === 'approver' ? 'selected' : ''}`}>
              <div className="check"></div>
            </div>
            <div className="gray bold">Approver Report</div>
          </div>


        </div>
      </div> */}

      <div className="margin-bottom-3">
        <div className={`selector-cont ${isLoading ? 'disabled' : ''}`}>          
          <Selector label={"Employee Report"} name="employee" setTab={setKind} tab={kind} count={null} />
          <Selector label={"Approver Report"} name="approver" setTab={setKind} tab={kind} count={null} />
        </div>
      </div>





      {currentCompany &&
        <div className="inline-flex align-center margin-bottom-3">
          {/* <label htmlFor="
          ">
            <div className="label-text">
              COMPANY
            </div>
            <select onChange={e => setCompany(e.target.value)} defaultValue={currentCompany} className="margin-right-2">
              <option value="">All</option>
              {viewAsUser?.my_companies?.map(company => {
                return (
                  <option key={company} value={company}>{company}</option>
                )
              })}
            </select>
          </label> */}
          

          <label htmlFor="">
            <div className="label-text">
              START
            </div>
            <input disabled={isLoading} onChange={e => setStartDate(e.target.value)} defaultValue={startDate} type="date" name="" id=""   />
          </label>
          

          <div className="margin-top-1 bold gray center margin-right-1 margin-left-1">
            TO
          </div>


          <label htmlFor="">
            <div className="label-text">
              END
            </div>
            <input disabled={isLoading} onChange={e => setEndDate(e.target.value)}  defaultValue={endDate} type="date" name="" id="" className="margin-right-2"  />
          </label>
          
          
          {/* <div className="button">
            Filter
          </div> */}

          <div className="margin-left-2 margin-top-half">
            <div className="flex-cont align-center">
              <div onClick={() => checkBoxClicked('one')} className={`pointer check-box-cont margin-right-2 flex-cont align-center ${isLoading && 'disabled'}`}>
                <div className={` ${isLoading && 'disabled'} check-box radio margin-right-1 ${selectedCompany === 'one' ? 'selected' : ''}`}>
                  <div className="check"></div>
                </div>
                <div className="gray bold">{currentCompany}</div>
              </div>

              <div onClick={() => checkBoxClicked('all')} className={`pointer check-box-cont  margin-right-2 flex-cont align-center ${isLoading && 'disabled'}`}>
                <div className={` ${isLoading && 'disabled'} check-box radio margin-right-1 ${selectedCompany === 'all' ? 'selected' : ''}`}>
                  <div className="check"></div>
                </div>
                <div className="gray bold">All Companies</div>
              </div>


            </div>
          </div>

          
        </div>
      }
      {kind == "approver" &&
        <ApproverReport setIsLoading={setIsLoading} currentCompany={currentCompany} startDate={startDate} endDate={endDate} company={selectedCompany} kind={kind} />
      }
      {kind == "employee" &&
        <EmployeeReport setIsLoading={setIsLoading} currentCompany={currentCompany} startDate={startDate} endDate={endDate} company={selectedCompany} kind={kind} />
      }

      
    </>
  )
};

const mapState = state => ({
  reviewCount: state.budgetHeaderState.reviewCount,
  currentCompany: state.authState.currentCompany,
  viewAsUser: state.authState.viewAsUser
});

export default connect(mapState)(TimesheetReport);