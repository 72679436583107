import React, { useEffect, useState } from 'react';

import { useApi } from '../../../api';
import { connect } from 'react-redux';

import { DateTime } from 'luxon';
import {
  Link, useNavigate, useParams,
} from 'react-router-dom';

import { usePrevious } from '../../../hooks/usePrevious';

import { useWatch } from 'react-hook-form';
import TH from '../TH';
import { useSortBudgets, toNum, isNumber } from '../useSortBudgets';
import { getExpenseHeader } from '../../../redux/manager/expenseHeaders/expenseHeaderActions';
import { isNumeric } from '../../../utils';
import { currencies } from './currencies';


const ExpenseLine = ({currentProjectGroup, mainAccounts, projectIDWatch, viewAsUser, taxes, existingExpenseHeader, billableWatch, currentBlob, setCurrentExpenseLineID, setCurrentLineIndex, homeCurrency, getValues, exchangeRates, inputsDisabled, errors, expenseCategories, existingExpenseLine, toggleUploadModal, count, setRemovedIndexes, hba=false, letter, selectedIndex, setSelectedIndex, setValue, control, register, index}) => {
  let inputName = ``;

  if (hba === true) {
    inputName = `hba_expense_lines_attributes.${index}`;
  } 
  else {
    inputName = `expense_lines_attributes.${index}`;
  }
  
  const manualTaxNumberWatch = useWatch({ control, name: `${inputName}.manual_tax_number` });
  const taxTypeWatch = useWatch({ control, name: `${inputName}.tax_type` });
  const taxWatch = useWatch({ control, name: `${inputName}.tax` });

  const currencyWatch = useWatch({ control, name: `${inputName}.currency` });
  const currencyAmountWatch = useWatch({ control, name: `${inputName}.currencyamount` });
  const transWatch = useWatch({ control, name: `${inputName}.transportation` });
  const lodgingWatch = useWatch({ control, name: `${inputName}.lodging` });
  const mealsWatch = useWatch({ control, name: `${inputName}.meals` });

  const [formattedAmt, setFormattedAmt] = useState(0);

  const readOnly = inputsDisabled;
  const project = {};

  const getCurrency = () => {
    if (!existingExpenseLine && !currencyWatch) return null;

    if (currencyWatch) return currencyWatch;

    return existingExpenseLine.currency;
  }

  const getTaxAmt = (start, taxNumber, taxType) => {

    const parsedTaxNumber = parseFloat(taxNumber);


    if (isNumeric(parsedTaxNumber)) {
      if (taxType === "pct") {
        return (parsedTaxNumber * .01) * start
      }
      else if (taxType === "net") {
        return parsedTaxNumber;
      }
      else {
        return 0;
      }
    }
    else {
      return 0;
    }
  }

  const calcTaxAmount = () => {
    let currAmt = parseFloat(currencyAmountWatch);
    if (!isNumeric(currAmt)) {
      currAmt = 0;
    }
    else {

    }

    const parsedTaxNumber = parseFloat(manualTaxNumberWatch);

    // console.log('parsedTaxNumber', parsedTaxNumber)
    // console.log('currAmt', currAmt)



    if (isNumeric(parsedTaxNumber)) {
      if (taxTypeWatch === "pct") {
        return (parsedTaxNumber * .01) * currAmt
      }
      else if (taxTypeWatch === "net") {
        return parsedTaxNumber;
      }
      else {
        return 0;
      }
    }
    else {
      return 0;
    }
  }



  const sumExpenses = () => {

    const array = [currencyAmountWatch, transWatch, lodgingWatch, mealsWatch];

    const sum = array.reduce((previous, current) => {

      if (current === null || current === undefined) {
        return previous;
      }

      const currentNoCommas = current?.toString()?.replace(/,/g, '');
      const currentNumber = Number(currentNoCommas);
      const currentParsed = parseFloat(currentNoCommas);


      if (isNaN(currentParsed) || isNaN(currentNumber)) {
        return previous;
      }

      return currentParsed + previous;
    }, 0);

    return parseFloat(sum);


  }

  const getAmt = () => {

    // alert('here');

    console.log("ANDY", 0)

    if (!exchangeRates) return 0;

    console.log("ANDY", 1)

    let amt = currencyAmountWatch;
    if (amt == null) {
      amt = getValues(`${inputName}.currencyamount`)
    }

    console.log("ANDY", 2)
    amt = parseFloat(amt);

    if (!isNumeric(amt)) {
      amt = 0;
    }

    console.log("ANDY", 3)
    let curr = currencyWatch;
    if (curr == null) {
      curr = getValues(`${inputName}.currency`);
    }

    console.log("ANDY", 4)

    console.log("curr", curr)
    console.log("amt", amt)
  
    if (curr == null || amt == null) return 0;

    let taxNumber = manualTaxNumberWatch;

    if (taxNumber == null) {
      taxNumber = getValues(`${inputName}.manual_tax_number`);
    }


    let taxType = taxTypeWatch;

    if (taxType == null) {
      taxType = getValues(`${inputName}.tax_type`);
    }


    let taxAmount = getTaxAmt(amt, taxNumber, taxType);

    console.log("ANDY", 5)


    let total = (taxAmount + parseFloat(amt)) / exchangeRates.rates[curr];


    console.log("taxNumber", taxNumber);
    console.log("taxType", taxType);
    console.log("amt", amt);
    console.log("taxAmount", taxAmount);
    console.log("total", total);

    if (!isNumeric(total)) total = 0;

    setFormattedAmt(total);

    return total;
  }

  const getExistingAmt = () => {
    if (!exchangeRates || !existingExpenseLine) return 0;


    let taxNumber = existingExpenseLine.tax_amount;
    let taxType = existingExpenseLine.tax_type;
    let amt = parseFloat(existingExpenseLine.currencyamount);
    

    if (!isNumeric(amt)) {
      amt = 0
    }

    let totalAmount = amt + getTaxAmt(amt, taxNumber, taxType);

    let curr = existingExpenseLine.currency;

    if (!curr) return 0;

    let total =  parseFloat(totalAmount) / exchangeRates.rates[curr];
    if (!isNumeric(total)) total = 0;

    setFormattedAmt(total);
    return total;
  }

  useEffect(() => {
    setValue(`${inputName}.meals`, getAmt());
  }, [currencyWatch, currencyAmountWatch, manualTaxNumberWatch, taxTypeWatch, taxWatch])

  useEffect(() => {
    setValue(`${inputName}.meals`, getExistingAmt());
  }, [existingExpenseLine, exchangeRates])

  useEffect(() => {
    setValue(`totalexpenses.${index}`, sumExpenses())
  }, [sumExpenses()]);

  const [destroyed, setDestroyed] = useState(false);

  const removeLineClicked = () => {
    setRemovedIndexes(prevRemovedIndexes => [...prevRemovedIndexes, index]);
    const fn = `${inputName}._destroy`;
    setValue(fn, true);
    setValue(`${inputName}.currencyamount`, '');
    setValue(`${inputName}.manual_tax_number`, '');
    setValue(`${inputName}.tax_type`, '');
    setValue(`${inputName}.tax`, '');
    setValue(`${inputName}.transportation`, '');
    setValue(`${inputName}.lodging`, '');
    setValue(`${inputName}.meals`, '');
    setDestroyed(true);
  }

  const mainAccountDefault = () => {
    if (!existingExpenseLine) return "";
    if (existingExpenseLine.expensecategoryid === null || existingExpenseLine.expensecategoryid === undefined) return "";

    return existingExpenseLine.expensecategoryid.toString();
  }

  const categoryDefault = () => {
    if (!existingExpenseLine) return "";
    if (existingExpenseLine.expensecategoryid === null || existingExpenseLine.expensecategoryid === undefined) return "";

    return existingExpenseLine.expensecategoryid.toString();
  }

  const taxDefault = () => {
    if (!existingExpenseLine) return "";
    if (existingExpenseLine.tax === null || existingExpenseLine.tax === undefined) return "";

    return existingExpenseLine.tax.toString();
  }

  const taxTypeDefault = () => {
    if (!existingExpenseLine) return "";
    if (existingExpenseLine.tax_type === null || existingExpenseLine.tax_type === undefined) return "";

    return existingExpenseLine.tax_type.toString();
  }

  // const randomKey = (+new Date * Math.random()).toString(36).substring(0,5);

  const cameraClicked = () => {
    setCurrentExpenseLineID(existingExpenseLine?.id);
    setCurrentLineIndex(index);
    toggleUploadModal();
  }

  // useEffect(() => {
    
   
    
  // }, [existingExpenseLine, billableWatch]);


  // if (billableWatch) {
  //   if (billableWatch !== existingExpenseHeader?.isbillable?.toString()) {
  //     // alert("Not the same")
  //     setValue(`${inputName}.expensecategoryid`, "");
  //   }
  //   else {
  //     // alert(existingExpenseLine?.expensecategoryid)
  //     setValue(`${inputName}.expensecategoryid`, existingExpenseLine?.expensecategoryid);
  //   }
  // }

  const taxDisabled = () => {
   return false 
  }


  const userCanEditCategory = () => {
    return true;
    if (!readOnly) return true;
    if (existingExpenseHeader && existingExpenseHeader.view_as_user_can_approve === true) {
      return true;
    }

    return false;


    // if (viewAsUser.isexpenseapprover) return true;
    // if (readOnly === true) return false;

    // return true;
  }


  return (
    <>
      <tr onClick={() => setSelectedIndex(`${letter}${index}`)} className={`highlightable ${selectedIndex === `${letter}${index}` ? 'highlighted' : ''}  ${destroyed ? 'hidden' : ''}`}>


        <td className='fit nowrap'>
            {(currentBlob?.blobname || !inputsDisabled) &&
              <div className="center">
                <div onClick={cameraClicked}  className="button outline">
                  <i className={`${currentBlob?.blobname ? 'present' : ''} camerax fas fa-camera margin-right-half `}></i>
                  <span className="bold">Receipt</span>
                </div>
                
              </div>
            }
            
            
            {/* <input 
              type="file" 
              name={`${inputName}.file`}
              {...register(`${inputName}.file`, {
                validate: v => {
                  return true;
                }
              })}  
            /> */}
        </td>
        <td className='fit nowrap'>
          {!inputsDisabled &&
            <div onClick={() => removeLineClicked(index)}  className="button ghost">
              <i className="fas fa-times"></i>
            </div>
          } 

          
          <input defaultValue={existingExpenseLine?.id}  {...register(`${inputName}.id`)} type="hidden" name={`${inputName}.id`} />
          <input  {...register(`${inputName}._destroy`)} type="hidden" name={`${inputName}._destroy`} />
        </td>
                    
        <td className='width-date'>
          <input 
            defaultValue={existingExpenseLine?.blobname}  
            {...register(`${inputName}.expense_line_file_attributes.blobname`)} 
            type="hidden" 
            name={`${inputName}.expense_line_file_attributes.blobname`} 
          />
          <input 
            defaultValue={existingExpenseLine?.filetype}  
            {...register(`${inputName}.expense_line_file_attributes.filetype`)} 
            type="hidden" 
            name={`${inputName}.expense_line_file_attributes.filetype`} 
          />

          <input 
            defaultValue={null}  
            {...register(`${inputName}.expense_line_file_attributes._destroy`)} 
            type="hidden" 
            name={`${inputName}.expense_line_file_attributes._destroy`} 
          />
          <label htmlFor="" className={`${errors?.expense_lines_attributes?.[index]?.date ? 'error-label' : ''}`}>
          <input 
            name={`${inputName}.date`}
            defaultValue={existingExpenseLine?.date}
            type='date'
            {...register(`${inputName}.date`, {
              validate: v => {
                if (destroyed) return true
                if (!v) return "Enter a date."
                if (!DateTime.fromISO(v)) return "Enter a date."
                // if (project?.actualStartDate && DateTime.fromISO(project.actualStartDate) && DateTime.fromISO(v) <= DateTime.fromISO(project.actualStartDate) ) {
                //   return "Target completion must be after the project's start date."
                // }
                return true;
              }
            })}
            className={`${(readOnly) && 'disabled-input'}`}
          />
          </label>
          {errors?.expense_lines_attributes?.[index]?.date &&
            <div className="error-cont">{errors?.expense_lines_attributes?.[index]?.date?.message}</div>
          }
        </td>
        <td className='fit nowrap right-text' style={{verticalAlign: 'middle'}}>
          <span className="bold">{hba ? letter : count}</span>
        </td>
        <td>
          <label htmlFor="" className={`${errors?.expense_lines_attributes?.[index]?.description ? 'error-label' : ''}`}>
          <input
            type="text"
            defaultValue={existingExpenseLine?.description}
            placeholder="Expense Description"
            name={`${inputName}.description`}
            {...register(`${inputName}.description`, {
              validate: v => {
                if (destroyed) return true;
                if (!v || v.length === 0) return "Enter a description.";

                return true;
              }
            })}
            className={`${(readOnly) && 'disabled-input'}`}
          />
          </label>
          {errors?.expense_lines_attributes?.[index]?.description &&
            <div className="error-cont">{errors?.expense_lines_attributes?.[index]?.description?.message}</div>
          }
        </td>
        <td className="fit">
          {/* <pre>
            {JSON.stringify(existingExpenseLine?.categoryname, null, 2)}
          </pre> */}

          {currentProjectGroup === "INDIRECT" &&
            <>
              {!mainAccounts &&
                <>
                  {existingExpenseLine?.categoryname &&
                    <div>
                      {existingExpenseLine?.categoryname}
                    </div>
                  }
                  {projectIDWatch == null &&
                    <div className='nowrap bold'>
                      Please select a project above.
                    </div>
                  }
                </>
              }
              {mainAccounts &&
                <label htmlFor="" className={`${errors?.expense_lines_attributes?.[index]?.expensecategoryid ? 'error-label' : ''}`}>
                  <select 
                    {...register(`${inputName}.expensecategoryid`, {
                      validate: v => {
                        if (destroyed) return true;
                        if (!v && v !== 0) return "Select account.";
                        return true;
                      }
                    })}  
                    name={`${inputName}.expensecategoryid`}
                    defaultValue={mainAccountDefault()} 
                    className={`auto-width ${userCanEditCategory() === false ? 'disabled-input' : ''}`}
                  >
                    <option value="">Select Account</option>
                    {mainAccounts?.map(account => {
                      return (
                        <option value={account.number}>{account.number} - {account.name}</option>
                      )
                    })}
                  </select>
                </label>
              }      
              
            </>
          }
          {currentProjectGroup !== "INDIRECT" &&
            <>
              {!expenseCategories &&
                <>
                  {existingExpenseLine?.categoryname &&
                    <div>
                      {existingExpenseLine?.categoryname}
                    </div>
                  }
                  {projectIDWatch == null &&
                    <div className='nowrap bold'>
                      Please select a project above.
                    </div>
                  }
                </>
              }
              {expenseCategories &&
                <label htmlFor="" className={`${errors?.expense_lines_attributes?.[index]?.expensecategoryid ? 'error-label' : ''}`}>
                  <select 
                    {...register(`${inputName}.expensecategoryid`, {
                      validate: v => {
                        if (destroyed) return true;
                        if (!v && v !== 0) return "Select category.";
                        return true;
                      }
                    })}  
                    name={`${inputName}.expensecategoryid`}
                    defaultValue={categoryDefault()} 
                    className={`auto-width ${userCanEditCategory() === false ? 'disabled-input' : ''}`}
                  >
                    <option disabled value="">Select Category</option>
                    {expenseCategories?.map(cat => {
                      return (
                        <option value={cat.categoryid?.toString()}>{cat.glnumber} {cat.category} - {cat.categoryname}</option>
                      )
                    })}
                  </select>
                </label>
              }
            </>
          
          
          }
          
          {errors?.expense_lines_attributes?.[index]?.expensecategoryid &&
            <div className="nowrap error-cont">{errors?.expense_lines_attributes?.[index]?.expensecategoryid?.message}</div>
          }
        </td>
        
        <td className='fit'>
          {/* <input  
            {...register(`${inputName}.currency`, {
              validate: v => {
                return true;
              }
            })}  
            name={`${inputName}.currency`}
            defaultValue={existingExpenseLine?.currency} 
            type="text"  
            placeholder="Currency"
            className={`width-money ${(readOnly) && 'disabled-input'}`}  
          /> */}
          <label htmlFor="" className={`${errors?.expense_lines_attributes?.[index]?.currency ? 'error-label' : ''}`}>
            <select
              {...register(`${inputName}.currency`, {
                validate: v => {
                  if (destroyed) return true;
                  if (!v) return "Select a currency."
                  return true;
                }
              })}  
              name={`${inputName}.currency`}
              defaultValue={existingExpenseLine?.currency || ""} 
              className={`auto-width ${(readOnly) && 'disabled-input'}`}
            >
              <option value="" disabled>Select Currency</option>
              { currencies && Object.entries(currencies).map(([key, value]) => {
                return ( 
                  <option value={key}>
                    {key} - {value.name}
                  </option>
                )
              })}
            </select>
          </label>

          {errors?.expense_lines_attributes?.[index]?.currency &&
            <div className="nowrap error-cont">{errors?.expense_lines_attributes?.[index]?.currency?.message}</div>
          }
        </td>
        <td className='fit'>
          <label htmlFor="" className={`${errors?.expense_lines_attributes?.[index]?.currencyamount ? 'error-label' : ''}`}>
            <input  {...register(`${inputName}.currencyamount`, {
                validate: v => {
                  if (destroyed) return true;
                  if (!v) return "Enter a number."
                  if (!isNumeric(v)) return "Enter a number."
                  if (parseFloat(v) <= 0) return "Enter a number greater than 0."
                  return true;
                }
              })}  
              name={`${inputName}.currencyamount`}
              defaultValue={existingExpenseLine?.currencyamount} 
              type="text"  
              placeholder="Amount"
              className={`width-money`}
              // ={`width-money ${(readOnly) && 'disabled-input'}`}  
            />
          </label>

          {errors?.expense_lines_attributes?.[index]?.currencyamount &&
            <div className="nowrap error-cont">{errors?.expense_lines_attributes?.[index]?.currencyamount?.message}</div>
          }
        </td>

        <td className='fit'>
        {/* <pre>
          existingExpenseLine?.has_manual_tax: {JSON.stringify(existingExpenseLine?.has_manual_tax, null, 2)}
        </pre>
        <pre>
          existingExpenseLine?.tax_in_words: {JSON.stringify(existingExpenseLine?.tax_in_words, null, 2)}
        </pre> */}
        {/* <pre>
          existingExpenseLine: {JSON.stringify(existingExpenseLine, null, 2)}
        </pre> */}

        {/* <pre>
          existingExpenseLine.tax_type: {JSON.stringify(existingExpenseLine.tax_type, null, 2)}
        </pre>
        <pre>
          existingExpenseLine.tax_amount: {JSON.stringify(existingExpenseLine.tax_amount, null, 2)}
        </pre> */}
{/* 
        <pre>
          manualTaxNumberWatch: {JSON.stringify(manualTaxNumberWatch, null, 2)}
        </pre>
        <pre>
          taxTypeWatch: {JSON.stringify(taxTypeWatch, null, 2)}
        </pre>
        <pre>
          calcTaxAmount: {JSON.stringify(calcTaxAmount(), null, 2)}
        </pre> */}

        
        
        {taxes &&

          <label htmlFor="" className={`${errors?.expense_lines_attributes?.[index]?.tax ? 'error-label' : ''}`}>

            {(!existingExpenseLine?.has_manual_tax && viewAsUser.roleid !== 5) &&  
              <>
                <select 
                    {...register(`${inputName}.tax`, {
                      validate: v => {
                        if (destroyed) return true;
                        if (!v && v !== 0) return "Select tax.";
                        return true;
                      }
                    })}  
                    name={`${inputName}.tax`}
                    defaultValue={taxDefault()} 
                  className={`auto-width ${(taxDisabled()) && 'disabled-input'}`}
                >
                  <option disabled value="">Select Tax</option>
                  {taxes?.map(tax => {
                    return (
                      <option value={tax.combined_primary_key}>{tax.full_name}</option>
                    )
                  })}
                </select>
              </>
            }
            {(existingExpenseLine?.has_manual_tax && viewAsUser.roleid !== 5) &&  
              <>
                {existingExpenseLine?.tax_in_words && 
                  <>
                  <div className='nowrap bold gray'>
                    <div className="gray bold font-075">CONTROL SELECTED: </div> {existingExpenseLine?.tax_in_words}
                  </div>
                    <input {...register(`${inputName}.manual_tax_number`, {
                      validate: v => {
                        return true;
                      }})}
                      defaultValue={existingExpenseLine?.tax_amount}
                      style={{width: '3.15rem', minWidth: '3.15rem', paddingRight: '.9rem'}} type="text" name={`${inputName}.manual_tax_number`} 
                      className={`${(taxDisabled()) && 'disabled-input'}`}  
                    />
                    <input {...register(`${inputName}.tax_type`, {
                      validate: v => {
                        return true;
                      }})}
                      defaultValue={taxTypeDefault()}
                      style={{width: '3.15rem', minWidth: '3.15rem', paddingRight: '.9rem'}} type="text" name={`${inputName}.tax_type`} 
                      className={`${(taxDisabled()) && 'disabled-input'}`}  
                    />
                  </>
                }
              </>
            }


              

            {(viewAsUser.roleid === 5) &&
              <>
                {!existingExpenseHeader?.view_as_user_can_approve &&
                  <>
                    <div className='padding-left-0 padding-right-half'>{existingExpenseLine?.tax_in_words}</div>
                    <input {...register(`${inputName}.manual_tax_number`, {
                      validate: v => {
                        return true;
                      }})}
                      defaultValue={existingExpenseLine?.tax_amount}
                      style={{width: '3.15rem', minWidth: '3.15rem', paddingRight: '.9rem'}} type="text" name={`${inputName}.manual_tax_number`} 
                      className={`${(taxDisabled()) && 'disabled-input'}`}  
                    />
                    <input {...register(`${inputName}.tax_type`, {
                      validate: v => {
                        return true;
                      }})}
                      defaultValue={taxTypeDefault()}
                      style={{width: '3.15rem', minWidth: '3.15rem', paddingRight: '.9rem'}} type="text" name={`${inputName}.tax_type`} 
                      className={`${(taxDisabled()) && 'disabled-input'}`}  
                    />
                  </>
                }
                {existingExpenseHeader?.view_as_user_can_approve &&
                  <div>
                    <div className='nowrap flex-cont'>
                      <div className="relative ">
                        <input {...register(`${inputName}.manual_tax_number`, {
                          validate: v => {
                            if (destroyed) return true;
                            if (!v) return "Enter a number."
                            if (!isNumeric(v)) return "Enter a number."
                            // if (parseFloat(v) <= 0) return "Enter a number greater than 0."
                            return true;
                          }})}
                          defaultValue={existingExpenseLine?.tax_amount}
                          style={{width: '3.15rem', minWidth: '3.15rem', paddingRight: '.9rem'}} type="text" name={`${inputName}.manual_tax_number`} 
                          className={`${(taxDisabled()) && 'disabled-input'}`}  
                        />
                        {/* <div style={{position: 'absolute', right: '.25rem', top: '.25rem', color: 'rgba(0,0,0,.5)'}}>%</div> */}
                      </div>
                      {errors?.expense_lines_attributes?.[index]?.manual_tax_number &&
                        <div className="nowrap error-cont">{errors?.expense_lines_attributes?.[index]?.manual_tax_number?.message}</div>
                      }     
      
      
                      <select
                        {...register(`${inputName}.tax_type`, {
                          validate: v => {
                            if (destroyed) return true;
                            if (!v && v !== 0) return "Select tax method.";
                            return true;
                          }
                        })}  
                        name={`${inputName}.tax_type`}
                        defaultValue={taxTypeDefault()} 
                        className={`auto-width ${(taxDisabled()) && 'disabled-input'}`}
                      >
                        <option value="pct">%</option>
                        <option value="net">{homeCurrency}</option>
                      </select>
      
                      {/* <div>
                      {existingExpenseLine?.tax} {existingExpenseLine?.tax_amount} {existingExpenseLine?.tax_type}
                      </div> */}
                    
                    </div>
      
                    {!existingExpenseLine?.has_manual_tax && existingExpenseLine?.tax_in_words && 
                      <div className='nowrap bold gray margin-top-half'>
                        <div className="gray bold font-075">EMPLOYEE SELECTED: </div> {existingExpenseLine?.tax_in_words}
                      </div>
                    }
                  </div>
                }
              </>
              
            }
            
            </label>

          }

          

          {errors?.expense_lines_attributes?.[index]?.tax &&
            <div className="nowrap error-cont">{errors?.expense_lines_attributes?.[index]?.tax?.message}</div>
          }
        </td>
        
        <td>
          {/* {/* {currencyWatch} */}
          {parseFloat(formattedAmt).toLocaleString('en-US', {style: 'currency', currency: homeCurrency, minimumFractionDigits: 2,})}
          <input  {...register(`${inputName}.meals`, {
              validate: v => {

                return true;
                if (destroyed) return true;
                if (!v) return "Enter a number."
                if (!isNumeric(v)) return "Enter a number."
                if (parseFloat(v) <= 0) return "Enter a number greater than 0."
                return true;
              }
            })}  
            name={`${inputName}.meals`}
            defaultValue={existingExpenseLine?.meals} 
            type="hidden"  
            placeholder="Reimbursement amt."
            className={`width-money disabled-input`}  
          />
          {errors?.expense_lines_attributes?.[index]?.meals &&
            <div className="nowrap error-cont">{errors?.expense_lines_attributes?.[index]?.meals?.message}</div>
          }
        </td>
        {/* <td style={{width: "4rem"}} className="">
          <div className="flex-cont">
            <div className="flex-1 margin-right-0">
              <input  {...register(`${inputName}.currency`, {
                  validate: v => {
                    return true;
                  }
                })}  
                name={`${inputName}.currency`}
                defaultValue={""} 
                type="text"  
                placeholder="Currency"
                className={`auto-width ${(readOnly) && 'disabled-input'}`}  
              />
            </div>
            <div className="flex-1 margin-right-0">
              <input  {...register(`${inputName}.currencyamount`, {
                  validate: v => {
                    if (!v) return "Enter a number."
                    if (!isNumeric(v)) return "Enter a number."
                    if (parseFloat(v) <= 0) return "Enter a number greater than 0."
                    return true;
                  }
                })}  
                name={`${inputName}.currencyamount`}
                defaultValue={""} 
                type="text"  
                placeholder="Amount"
                className={`auto-width ${(readOnly) && 'disabled-input'}`}  
              />
            </div>
            
          </div>
          

        </td> */}
        {/* <td>
          <input  {...register(`${inputName}.transportation`, {
              validate: v => {
                if (!v) return "Enter a number."
                if (!isNumeric(v)) return "Enter a number."
                if (parseFloat(v) <= 0) return "Enter a number greater than 0."
                return true;
              }
            })}  
            name={`${inputName}.transportation`} 
            defaultValue={""} 
            type="text"  
            placeholder="Transport."
            className={`width-money ${(readOnly) && 'disabled-input'}`}  
          />
        </td>
        <td>
          <input  {...register(`${inputName}.meals`, {
              validate: v => {
                if (!v) return "Enter a number."
                if (!isNumeric(v)) return "Enter a number."
                if (parseFloat(v) <= 0) return "Enter a number greater than 0."
                return true;
              }
            })}  
            name={`${inputName}.meals`}
            defaultValue={""} 
            type="text"  
            placeholder="Meals"
            className={`width-money ${(readOnly) && 'disabled-input'}`}  
          />
        </td>
        <td className='width-money'>
          <input  {...register(`${inputName}.lodging`, {
              validate: v => {
                if (!v) return "Enter a number."
                if (!isNumeric(v)) return "Enter a number."
                if (parseFloat(v) <= 0) return "Enter a number greater than 0."
                return true;
              }
            })}  
            name={`${inputName}.lodging`}
            defaultValue={""} 
            type="text"  
            placeholder="Lodging"
            className={`width-money ${(readOnly) && 'disabled-input'}`}  
          />
        </td> */}
        {/* <td>
          <div className="input-font">
            {sumExpenses().toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
          </div>
          <input  {...register(`totalexpenses.${index}`, {
              validate: v => {
                return true;
              }
            })}  
            name={`${inputName}.totalexpenses`}
            defaultValue={""} 
            type="hidden"  
            placeholder="Total Exp."
            className={`width-money ${(readOnly) && 'disabled-input'}`}  
          />
          
        </td> */}
        
      </tr>
    </>
  )
}

export default ExpenseLine;