import React from 'react';
import Modal from '../../components/Modal';

import BudgetPrintViewer from './BudgetPrintViewer';

import { useParams } from 'react-router-dom';

const BudgetPrint = ({showPricing}) => {

  const { projectid } = useParams();
  const opened = true;

  const toggleModal = () => {

  }

  return (
    <>
      {projectid &&
        <div style={{
          width: '100%',
          height: '8.5in'
        }}>
          <BudgetPrintViewer showPricing={showPricing} toggleModal={toggleModal} opened={opened} projectID={projectid} />
        </div>
      }
    </>
  )
}

export default BudgetPrint;