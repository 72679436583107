const initialTimesheetState = {
  isLoading: false,
  error: null,
  timesheets: null,
  currentTimesheet: null,
  submitted: null,
  pagy: null,
  submittedCount: null,
  approvedCount: null,
  savingEntryIds: [],
  currentSummary: null,
};

const timesheetReducer = ( state = initialTimesheetState, action) => {
  switch(action.type) {

    case "ADD_SAVING_ENTRY_ID":
      return {
        ...state,
        savingEntryIds: [...state.savingEntryIds, action.id.toString()]
      }
    case "REMOVE_SAVING_ENTRY_ID":
      return {
        ...state,
        savingEntryIds: [...state.savingEntryIds.filter(e => e.toString() !== action.id.toString())]
      }
      case "SET_SUBMITTED_TIMESHEET_COUNT":
      return {
        ...state,
        submittedCount: action.submittedCount
      };
    case "SET_APPROVED_TIMESHEET_COUNT":
      return {
        ...state,
        approvedCount: action.approvedCount
      };
    case "CLEAR_TIMESHEETS":
      return initialTimesheetState
    case "CREATE_TIMESHEET_BEGIN":
      return {
        ...state,
        error: null
      };
    case "CREATE_TIMESHEET_SUCCESS":
      return {
        ...state,
        error: null
      };
    case "CREATE_TIMESHEET_ERROR":
      return {
        ...state,
        error: action.error
      };
    case "GET_SUBMIT_TIMESHEET_BEGIN":
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case "GET_SUBMIT_TIMESHEET_SUCCESS":
      return {
        ...state,
        isLoading: false,
        error: null,
        // timesheets: state.timesheets.map(ts => ts.timesh === action.entry.id ? {...action.entry} : entry)
        // currentTimesheet: action.timesheet
      };
    case "GET_SUBMIT_TIMESHEET_ERROR":
      return {
        ...state,
        isLoading: false,
        error: action.error
      };
    case "GET_TIMESHEETS_BEGIN":
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case "GET_TIMESHEETS_SUCCESS":
      return {
        ...state,
        timesheets: action.timesheets,
        isLoading: false
      };
    case "GET_TIMESHEETS_ERROR":
      return {
        ...state,
        isLoading: false,
        error: action.error
      };

    case "GET_SUBMITTED_TIMESHEETS_BEGIN":
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case "GET_SUBMITTED_TIMESHEETS_SUCCESS":
      return {
        ...state,
        submitted: action.timesheets,
        isLoading: false
      };
    case "GET_SUBMITTED_TIMESHEETS_ERROR":
      return {
        ...state,
        isLoading: false,
        error: action.error
      };
    case "SET_CURRENT_TIMESHEET_SUMMARY":
      return {
        ...state,
        currentSummary: action.currentSummary
      };
    case "GET_TIMESHEET_BEGIN":
      return {
        ...state,
        isLoading: true,
        error: null,
        currentTimesheet: null,
      };
    case "GET_TIMESHEET_SUCCESS":
      return {
        ...state,
        currentTimesheet: action.timesheets,
        isLoading: false
      };
    case "GET_TIMESHEET_ERROR":
      return {
        ...state,
        isLoading: false,
        error: action.error
      };
    case "GET_TIMESHEETS_PAGY":
      return {
        ...state,
        pagy: action.pagy
      };
    default :
      return state
  }
}

export default timesheetReducer;