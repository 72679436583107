import { andyFetch, actionReturn } from '../../api';


export const getPeriods = (params) => {

  return async (dispatch, getState) => {

    const url = "weeks/get_periods";

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};