import React, { useEffect, useState } from 'react';
import {
  NavLink,
  useLocation
} from 'react-router-dom';
import { connect } from 'react-redux';
import { useApi } from '../../../api';
import { getReviewCount } from '../../../redux/manager/expenseHeaders/expenseHeaderActions';


const isActive = ({ isCurrent }) => {
  return isCurrent ? { className: "active" } : null
}



const ExpenseNav = ({currentCompany, viewAsUser, children, reviewCount}) => {

  const location = useLocation();

  const [reviewCountResult, reviewCountLoading, reviewCountLoaded, reviewcountError, doReviewCountFetch, setReviewCountResult] = useApi();

  const fetchReviewCount = () => {
    doReviewCountFetch(getReviewCount);
    
  }

  

  useEffect(() => {
    fetchReviewCount();
    // alert(location.key)
  }, [location.key, currentCompany]);




  useEffect(() => {
  }, [reviewCountLoaded]);


  return(
    
    <ul className="sub-nav">
{/* 
      <li>
        <pre>
          {JSON.stringify(reviewCountResult,null,2)}
        </pre>
      </li> */}

      <li>
        <NavLink end to="/expense-reports">My Expense Reports</NavLink>
      </li>
      {(viewAsUser.isexpenseapprover || viewAsUser.can_see_all_expense_reports) &&
        <li>
          <NavLink end to="/expense-reports/review">
            <div className="flex-cont align-center">
              <div className="flex-cont align-center justify-center unread-count">
                <div className="number">
                  {(reviewCountResult?.review_count === 0 || reviewCountResult?.review_count) ? reviewCountResult?.review_count : "-"}
                </div>
              </div>
              <div>Review</div>
            </div>
          </NavLink>
        </li>
      }
      {(viewAsUser.can_see_all_expense_reports) &&
        <li>
          <NavLink  Link end to="/expense-reports/company">All Company Reports</NavLink>
        </li>
      }

      {false && <>

      {((viewAsUser.roleid === 4 || viewAsUser.roleid === 1 || viewAsUser.roleid === 8 || viewAsUser.roleid === 9 || viewAsUser.roleid === 2 || viewAsUser.roleid === 3)) &&
        <li>
             
          <NavLink end to="/expense-reports/review/pm">
            <div className="flex-cont align-center">
              <div className="flex-cont align-center justify-center unread-count">
                <div className="number">
                  {(reviewCountResult?.pm_review_count === 0 || reviewCountResult?.pm_review_count) ? reviewCountResult?.pm_review_count : "-"}
                </div>
              </div>
              <div>PM Review</div>
            </div>
          </NavLink>
        </li>
      }

      {viewAsUser.isexpenseapprover && <>
          <li>
            
            <NavLink end to="/expense-reports/review/office">
              <div className="flex-cont align-center">
                <div className="flex-cont align-center justify-center unread-count">
                  <div className="number">
                    {(reviewCountResult?.office_review_count === 0 || reviewCountResult?.office_review_count) ? reviewCountResult?.office_review_count : "-"}
                  </div>
                </div>
                <div>Office Review</div>
              </div>


            </NavLink>

          </li>
        </>
      }
{/* 
      <li>
        {JSON.stringify(viewAsUser.isfinalexpenseapprover)}
      </li> */}

      {viewAsUser.isfinalexpenseapprover === true && <>
          <li>
            
            <NavLink end to="/expense-reports/review/final">
              <div className="flex-cont align-center">
                <div className="flex-cont align-center justify-center unread-count">
                  <div className="number">
                    {(reviewCountResult?.final_review_count === 0 || reviewCountResult?.final_review_count) ? reviewCountResult?.final_review_count : "-"}
                  </div>
                </div>
                <div>Final Review</div>
              </div>


            </NavLink>

          </li>
        </>
      }

      {(viewAsUser.isexpenseapprover || viewAsUser.isfinalexpenseapprover) && <>
          <li>
            <NavLink end to="/expense-reports/approved">Final Approved</NavLink>
          </li>
        </>
      }

    </>}
    </ul>
  );
}


function mapStateToProps(state) {
  return { 
    // reviewCount: state.budgetHeaderState.reviewCount,
    viewAsUser: state.authState.viewAsUser,
    currentCompany: state.authState.currentCompany,
  };
} 

export default connect(mapStateToProps)(ExpenseNav);
