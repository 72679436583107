import React, { useState, useEffect } from 'react';
import XLSX from 'xlsx';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { getHoursConsumedReport } from '../../redux/admin/timesheetActions';
import { useApi } from '../../api';
import { DateTime } from 'luxon';
import { connect } from 'react-redux';
import { slice } from 'lodash';
import Selector from '../../components/Selector';
import CheckBoxes from '../../components/CheckBoxes';

const Postings = ({items}) => {

  if (items == null || items.length === 0) {
    return null;
  }

  return (
    <table style={{fontSize: '12px'}}>
      <tr>
        <th>ACTIVITY</th>
        <th>KRONOS</th>
        <th>D365</th>
      </tr>
      {items?.map(item => 
        <tr>
          <td>{item.act}</td>
          <td>{item.k}</td>
          <td>{item.d}</td>
        </tr>
      )}
    </table>
    // <div>
    //   <pre>{JSON.stringify(krons, null, 2)}</pre>
    // </div>
  )
}

const TimesheetReport = ({refresh, compOnly, showAct, setIsLoading, currentCompany, kind, discOnly}) => {
  const [data, setData] = useState([]);
  const [openedRow, setOpenedRow] = useState(null);
  const [timesheetLogs, loading, loaded, error, doFetch, setResult] = useApi();

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (refresh !== 0) {
      fetchReport();
    }
    
  }, [refresh, currentCompany]);

  useEffect(() => {

  }, [timesheetLogs]);

  const fetchReport = () => {
    doFetch(getHoursConsumedReport, {query: {comp_only: compOnly, show_act: showAct, disc_only: discOnly, kind: kind, company: currentCompany}});
  }
  

  const previewData = timesheetLogs;

  const openRowClicked = id => {
    setOpenedRow(o => {
      if (o === id) {
        return null;
      }
      else {
        return id
      }
    })
  }

  return (
    <>

{/*       
      <pre>
        {JSON.stringify(timesheetLogs, null, 2)}
      </pre> */}
      {timesheetLogs != null && 
        <>

          <div className='bold'>Total Rows: {timesheetLogs.length - 2}</div>
            <div className="table-responsive">
              
              {/* <div>
                <pre>
                  {JSON.stringify(previewData, null, 2)}
                </pre>
              </div> */}
              <table className="basic table table-striped">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Project ID</th>
                    <th>Kronos Hours</th>
                    <th>D365 Hours</th>
                    <th>Difference</th>
                    <th>By Activity</th>
                  </tr>
                </thead>
                <tbody>
                  {previewData?.map((r,i) => 
                    <tr key={i}>
                      <td style={{verticalAlign: 'top'}}>{i+1}</td>
                      <td style={{verticalAlign: 'top'}} className='fit nowrap'>{ r.projectid }</td>
                      <td style={{verticalAlign: 'top'}} className='fit nowrap'>{ parseFloat(r.kronos_hours).toLocaleString() }</td>
                      <td style={{verticalAlign: 'top'}} className='fit nowrap'>{ parseFloat(r.dynamics_hours).toLocaleString() }</td>
                      <td style={{verticalAlign: 'top'}} className='fit nowrap'>{ parseFloat(r.difference).toLocaleString() }</td>
                      
                      <td className='full'>
                        {/* <div>
                          <pre>
                            {JSON.stringify(r, null, 2)}
                          </pre>
                        </div> */}

                        {r.details != null && r.details?.length !== 0 &&
                          <>
                            <button className='font-075 button ghost' onClick={() => openRowClicked(r.projectid)}>
                              {openedRow === r.projectid ? "HIDE" : "VIEW"}
                            </button>

                            {openedRow === r.projectid &&
                              <Postings items={r.details} />
                            }
                          </>
                          
                        }
                      </td>
                    </tr>)
                  }
                </tbody>
              </table>
              {/* {data && data.length >= 25 && <div className='margin-top-2 gray bold'>Showing first 25 employees. Download Excel file to see full list.</div>} */}
            </div>
        </>
      }
    </>
  )


}

const AdminReportsTimesheets = ({currentCompany, viewAsUser}) => {

  const [kind, setKind] = useState('budgeted');

  const [isLoading, setIsLoading] = useState(false);
  const [onlyDiscrepencies, setOnlyDiscrepencies] = useState(["0"]);
  const [byActivitynumber, setByActivitynumber] = useState(["0"]);
  const [byComp, setByComp] = useState(["0"]);

  const compOnly = byComp.length !== 0;
  const discOnly = onlyDiscrepencies.length !== 0;
  const showAct = byActivitynumber.length !== 0; 
  const [refresh, setRefresh] = useState(0);
    
 
  return(
    <>
      {true &&
      <div className={`full-screen-spinner-cont ${isLoading && "entered"}`}>
        <div className={`spinner-cont-cool ${isLoading && "entered"} flex-cont align-center`}>
          <div className="cool spinner margin-right-1"></div>
          <div>Generating report...</div>
        </div>
      </div>
      }
 
      
       

      <div className="flex-cont align-center">
        <h1 className="flex-cont">
          Consumed Report
        </h1>
      </div> 


      <div className="margin-bottom-3">
      {currentCompany &&
        <div className="inline-flex align-center margin-bottom-3">
          
          <div className={`margin-bottom-2x margin-right-2 check-boxes ${isLoading ? 'disabled' : ''}`}>          
            <CheckBoxes disabled={true} label={`${currentCompany} only`} name="0" boxes={byComp} setBoxes={setByComp} />
          </div>

          <div className={`margin-bottom-2x margin-right-2 check-boxes ${isLoading ? 'disabled' : ''}`}>          
            <CheckBoxes disabled={isLoading} label={"Discrepencies only"} name="0" boxes={onlyDiscrepencies} setBoxes={setOnlyDiscrepencies} />
          </div>
          
          
        </div>
      }
      <div onClick={() => setRefresh(o => o+1)} className="button">Generate Report</div>
      </div>


      <TimesheetReport setIsLoading={setIsLoading} currentCompany={currentCompany}   kind={kind} compOnly={compOnly} showAct={showAct} discOnly={discOnly} refresh={refresh} />


      
    </>
  )
};

const mapState = state => ({
  reviewCount: state.budgetHeaderState.reviewCount,
  currentCompany: state.authState.currentCompany,
  viewAsUser: state.authState.viewAsUser
});

export default connect(mapState)(AdminReportsTimesheets);