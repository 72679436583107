import { andyFetch, actionReturn } from '../api';


export const setReAuth = (reAuth) => {
  return {
    type: 'SET_REAUTH',
    reAuth: reAuth
  }
}


export const clearStore = (store) => {
  return dispatch => {
    dispatch({
      type: `CLEAR_${store}`,
    });
  }
}

export const setTokenLoading = (tokenLoading) => {
  return {
    type: 'SET_TOKEN_LOADING',
    tokenLoading
  }
}

export const setRedirectLoading = (redirectLoading) => {
  return {
    type: 'SET_REDIRECT_LOADING',
    redirectLoading
  }
}

export const setTokenResponse = (tokenResponse) => {




  return {
    type: 'SET_TOKEN_RESPONSE',
    tokenResponse
  }
}

export const unsetTokenResponse = () => {



  return {
    type: 'UNSET_TOKEN_RESPONSE',
  }
}

export const setCurrentAccount = (currentAccount) => {
  return {
    type: 'SET_CURRENT_ACCOUNT',
    currentAccount
  }
}

export const unsetCurrentAccount = () => {
  return {
    type: 'UNSET_CURRENT_ACCOUNT',
  }
}

export const setDynamicsToken = (dynamicsToken) => {
  
  return {
    type: 'SET_DYNAMICS_TOKEN',
    dynamicsToken
  }
}

export const unsetDynamicsToken = () => {
  return {
    type: 'UNSET_DYNAMICS_TOKEN',
  }
}



export const getDynamicsToken = (params) => {

  return async (dispatch, getState) => {

    const url = "get_dynamics_token";

    try {
      const response = await andyFetch(url, {}, "GET");
      
      if (response.ok) {

        
        const json = await response.json();

        


        const { data } = json;
  
        if (data) {
          dispatch({
            type: 'SET_DYNAMICS_TOKEN', 
            dynamicsToken: data,
          });
          // setDynamicsToken(data);

          
        }
        


        return actionReturn(json);
      } else {

        
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};



export const getCurrentUser = (params) => {
  return async (dispatch, getState) => {
    const url = "users/get_current_user";
    try {
      const response = await andyFetch(url, {}, "GET");
      if (response.ok) {
        const json = await response.json();
        const { data } = json;
  
        if (data) {
          dispatch({
            type: 'SET_CURRENT_USER', 
            currentUser: data,
          });
        }
        return actionReturn(json);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};




export const getViewAsUser = (params) => {
  return async (dispatch, getState) => {
    const url = "users/get_view_as_user";
    try {
      const response = await andyFetch(url, {}, "GET");
      if (response.ok) {
        const json = await response.json();
        const { data } = json;
  
        if (data) {
          dispatch({
            type: 'SET_VIEW_AS_USER', 
            viewAsUser: data,
          });

          dispatch({
            type: 'SET_CURRENT_COMPANY', 
            currentCompany: data?.current_company,
          });
          if (data.current_company) {
            localStorage.setItem('currentCompanyID', data?.current_company);
          }
  
          
        }
        return actionReturn(json);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};