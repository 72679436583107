
import { useWatch } from "react-hook-form";
import { isNumeric } from "../../utils";
import { useEffect } from "react";

const BudgetCalcs = ({setHoursBalanceNegative, budgetFeeCategories, budgetHeader, getCurrencySymbol, currency, control}) => {

  const watchedLines = useWatch({
    control,
    name: 'budget_lines',
    defaultValue: budgetHeader.budget_lines
  });

  const watchedFees = useWatch({
    control,
    name: 'budget_fees',
    defaultValue: budgetHeader.budget_fees
  });

  const watchedAvgHourlyRate = useWatch({
    control,
    name: 'avghourlyrate',
    defaultValue: budgetHeader.avghourlyrate
  });

  const watchedProfit = useWatch({
    control,
    name: 'estprofitpct',
    defaultValue: budgetHeader.estprofitpct
  });

  const hoursArray = () => {

    const array = watchedLines;
    if (!array || array.length === 0) return [];

    let valueArray = [];

    array.forEach(line => {

      const tasks = line.budget_line_tasks;

      if (!tasks) return;
      tasks.forEach(task => {
        const hh = task.hoursrequired?.toString().replace(/,/g, '');
        const hours = parseFloat(hh);

        isNumeric(hours) ? valueArray.push(hours) : valueArray.push(0)
      })
    })

    return valueArray;
  }

  const hoursSum = hoursArray()?.reduce((current, previous) => { return (current + previous) }, 0);

  const calculateProfit = () => {
    let c = 0.0;
    if (budgetHeader?.projectFee) {
      c = (budgetHeader.projectFee * (isNaN(parseFloat(watchedProfit)) ? 0.0 : parseFloat(watchedProfit)) * .01);
    }
    return c
  }

  const calculatedProfit = calculateProfit();

  const calcSumOfCosts = () => {
    const feesSum = watchedFees ? parseFloat(watchedFees.reduce((a, c) => (c !== null && c !== undefined && !isNaN(parseFloat(c?.amount))) ? a+parseFloat(c.amount) : 0.0, 0.0)) : 0.0;

    const profit = parseFloat(calculatedProfit);

    const hours = (hoursSum * (isNaN(parseFloat(watchedAvgHourlyRate)) ? 0 : parseFloat(watchedAvgHourlyRate)));

    return feesSum + profit + hours;
  }

  const sumOfCosts = calcSumOfCosts();


  const projectBalance = () => {
    if (!budgetHeader?.projectFee) return "-"

    return (budgetHeader?.projectFee - (sumOfCosts))?.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})
  }

  const projectBalanceVal = () => {
    if (!budgetHeader?.projectFee) return 0

    return parseFloat(budgetHeader?.projectFee - sumOfCosts);
  } 

  const hoursBalance = () => {
    const result = (parseFloat((budgetHeader?.projectFee ? budgetHeader?.projectFee : 0.0) - sumOfCosts) / (isNaN(parseFloat(watchedAvgHourlyRate)) ? 0.0 : parseFloat(watchedAvgHourlyRate))   ).toFixed(2);

    return isFinite(result) ? result : (0).toFixed(2)
  }

  const hoursBalanceVal = () => {
    const result = (parseFloat((budgetHeader?.projectFee ? budgetHeader?.projectFee : 0.0) - sumOfCosts) / (isNaN(parseFloat(watchedAvgHourlyRate)) ? 0.0 : parseFloat(watchedAvgHourlyRate))   );

    return isFinite(result) ? parseFloat(result) : 0
  }

  const andy = hoursBalanceVal();

  useEffect(() => {
    console.log("andy", andy);
    setHoursBalanceNegative(andy < 0);
  }, [andy]);



  const formatProjectFee = () => {
    if (!budgetHeader?.projectFee) return "-"
    return parseFloat(budgetHeader?.projectFee)?.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})
  }
  const getCategoryName = id => {
    if (!budgetFeeCategories) return "";
    const matches = budgetFeeCategories.filter(cat => cat.id == id);
    if (!matches || matches.length === 0) return "";
    return matches[0]?.name;
  }

  return (
    <div className="section flex-1 margin-right-x">

      <div className="bold margin-bottom-1">
        All amounts are in {currency}
      </div>
      <table className="vertical">
        <tr className="gray-bg">
          <th>Project Fee</th>
          <td className="right-text">
            {getCurrencySymbol(currency)} {formatProjectFee()}
          </td>
          <td className="full"></td>
        </tr>
        {true && watchedFees && watchedFees.filter(f => f && f.amount && !f._destroy).map(fee => 
          <tr>
            <th>
              {getCategoryName(fee.budgetfeecategoryid)}
            </th>
            <td className="nowrap right-text">
              {/* ${parseFloat(fee.amount)} */}

              {getCurrencySymbol(currency)} {(parseFloat(fee.amount)).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}

            </td>
            <td className="full"></td>
          </tr>
        )}

        <tr className="gray-bg">
          <th>Project Labor Cost</th>
          <td className="nowrap right-text">
          {getCurrencySymbol(currency)} {parseFloat((hoursSum * (isNaN(parseFloat(watchedAvgHourlyRate)) ? 0 : parseFloat(watchedAvgHourlyRate))))?.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
        
          
          <td className="full"></td>
        </tr>

        <tr className="gray-bg">
          <th>Estimated Profit</th>
          <td className="nowrap right-text">
            {getCurrencySymbol(currency)} {calculatedProfit?.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
          </td>
          <td className="full"></td>
        </tr>
        {/* <tr>
          <th>sumOfCosts</th>
          <td className="right-text">                 
            ${sumOfCosts}
          </td>
          <td className="full"></td>
        </tr> */}
        <tr>
          <th>Project Balance</th>
          <td className={`nowrap right-text ${projectBalanceVal() < 0 ? 'negative-red' : ''}`}>                 
            {getCurrencySymbol(currency)} {projectBalance()}
          </td>
          <td className="full"></td>
        </tr>
        <tr>
          <th>Hours Balance</th>
          <td className={`right-text nowrap ${hoursBalanceVal() < 0 ? 'negative-red' : ''}`}>
            {hoursBalance()}
          </td>
          <td className="full"></td>
        </tr>
        
        {/* <BudgetProjectBalance projectBalance={projectBalance} projectBalanceVal={projectBalanceVal} />
        <BudgetHoursBalance  hoursBalance={hoursBalance}  hoursBalanceVal={hoursBalanceVal} /> */}
      </table>
    </div>
  )

}

export default BudgetCalcs;