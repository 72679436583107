import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { BlobProvider, PDFViewer } from '@react-pdf/renderer';
import Wrapper from './TimesheetPrintScreen';


import { getTimesheet, getTimesheetWeeks, submitTimesheet } from '../redux/timesheets/timesheetActions';
import { getEntries, setEntries } from '../redux/entries/entryActions';
import { useApi } from '../api';

const TimesheetPrintScreen = ({timesheetId}) => {

  const [url, setUrl] = useState(null);


  useEffect(() => {
    // window.location.href = "/";
    // store.dispatch(showFullscreenLoading("Generating PDF..."));
    generatePdf();

    return () => {
      // store.dispatch(hideFullscreenLoading());

      // store.dispatch(clearStore('ENTRIES'));
      // store.dispatch(clearStore('TIMESHEETS'));
      // store.dispatch(clearStore('PROJECTS'));
    }
  });

  useEffect(() => {
    if (url) {
      window.location.href = url;
    }
  }, [url]);

  const generatePdf = async () => {
    
    // const response = await store.dispatch(api('timesheet_entries', null, {timesheetid: timesheetId}, 'GET', ''));
    // const ts = await store.dispatch(api('timesheets/show_timesheet', null, {id: timesheetId}, 'GET', ''));



    // 
    // setEntries(response);
    // setTimesheet(ts);
    fetchTimesheet();
    fetchEntries();
  };

  const [timesheet, timesheetLoading, timesheetLoaded, timesheetError, doTimesheetFetch, setTimesheetResult] = useApi();
  const [entries, entriesLoading, entriesLoaded, entriesError, doEntriesFetch, setEntriesResult] = useApi();

  const fetchTimesheet = () => {
    doTimesheetFetch(getTimesheet, {query: {id: timesheetId}});
  }
  const fetchEntries = () => {
    doEntriesFetch(getEntries, {query: {timesheetid: timesheetId}});
  }


  return (
    <div>
      <h1>PRINT</h1>
      {/* {!url && timesheet && entries &&
        <BlobProvider document={<Wrapper timesheet={timesheet} entries={entries} />}>
          {({ url }) => {

              if (url) {
                
                setUrl(url);
              }

              return (
                <>
                </>
              )
            }

          }
        </BlobProvider>
      } */}
    </div>

  )

  
};





const mapState = state => ({
  // timesheet: state.timesheetState.currentTimesheet,
  // timesheets: state.timesheetState.data,
  // weeks: state.timesheetState.weeks,
  // weekSummary: state.timesheetState.weekSummary,
  // isLoading: state.timesheetState.isLoading,
  // // weekSummaryIsLoading: state.timesheetState.weekSummaryIsLoading,
  // weeksIsLoading: state.timesheetState.weeksIsLoading,
  // // projects: state.projectState.projects,
  // // phases: state.phaseState.phases,
  // // categories: state.categoryState.categories,
  // entries: state.timesheetEntryState.data,
  // fullScreenLoading: state.timesheetState.fullScreenLoading,
  // unsavedEntries: state.timesheetEntryState.unsavedEntries,
});

export default connect(mapState)(TimesheetPrintScreen);
