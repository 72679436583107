import React, { useState, useEffect, useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import { DateTime, Info } from 'luxon';
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import store from '../../redux/store';
// import { updateEntry, addEntry, api, getTimesheet, getProjects, getPhases, getCategories, submitEntry, setUnsavedEntries } from './actions';
import { getTimesheetSummary, setCurrentTimesheetSummary, addSavingEntryId, removeSavingEntryId } from '../../redux/timesheets/timesheetActions';
import { createEntry, addEntry, changeEntry, saveNote } from '../../redux/entries/entryActions';
import { useApi } from '../../api';

import Modal from '../../components/Modal';
import { ErrorMessage } from '@hookform/error-message';
import { useWatch } from "react-hook-form";
import {SubactivitySelect, ActivitySelect, ProjectSelect} from './ActivitySelect';


let count = 0;
// wed: yup.number().transform((cv, ov) => ov === '' ? undefined : cv).max(999).matches().nullable().positive().label('Wednesday hours').typeError(' Wednesday hours must be a number'),

// .test(`${day}-no-spaces`, `Remove spaces from ${day[0].toUpperCase() + day.substring(1)}. hours.`, v => !v.includes(' '))
// .test(`${day}-is-number`, `${day[0].toUpperCase() + day.substring(1)}. hours must be a number.`, value => testIfNumber(value))
// .test(`${day}-is-positive`, `${day[0].toUpperCase() + day.substring(1)}. hours must be greater than or equal to 0.`, val =>  !val || val >= 0)
// .matches(/^(\d+(\.\d{0,2})?|\.?\d{1,2})$/, {excludeEmptyString: true, message: `${day[0].toUpperCase() + day.substring(1)}. hours must not have more than two decimal places.`})
// .test(`${day}-is-less-than-999`, `${day[0].toUpperCase() + day.substring(1)}. must be less than 1000 hours.`, (value => !value || parseFloat(value) < 1000))


const testIfNumber = (value) => {
  // if (!value) return false
  // // alert(value);
  // const s = value.toString();


  // if (s === "" || s.includes(" ")) {
  //   return false;
  // }

  return !isNaN(value);
}

const validPlaces = (value) => {
 
  // const regex = /^(\d+(\.\d{0,2})?|\.?\d{1,2})$/g;
 
  const regex = /^\s*-?(\d+(\.\d{1,2})?|\.\d{1,2})\s*$/;
  const found = value.match(regex);

  return (found && found.length > 0)
}

const dayValidation = (day, hide) =>  {

  // return "Green"

  // return {
  //   andy: "simon"
  // }


      // return (v && true) || `Please enter a number for ${day}`
      // return !v.includes(' ') || `Remove spaces from ${day}`
      // return testIfNumber(v) || `Please enter a number for ${day}`
      // return validPlaces(v) || `${day} should be a number with no more than 2 decimal places`
      // return parseFloat(v) >= 0 || `${day} should be a positive number`
      // return parseFloat(v) < 1000 || 'should be less than 1000'

  if (hide) {
    return null
  } 
  

  return {
    present: v => {

      if (v === null || v === undefined || v === "") {
        return `Please enter a number ${JSON.stringify(v)} for ${day}`
      }

      return true;
      // return (v && true) || `Please enter a number for ${day}`
    },
    noSpaces: v => {
      return !v.includes(' ') || `Remove spaces from ${day}`
    },
    isNumber: v => {
      return testIfNumber(v) || `Please enter a number for ${day}`
    },
    
    places: v => {
      return validPlaces(v) || `${day} should be a number with no more than 2 decimal places`
    },
    
    lessThanThousand: v => parseFloat(v) < 1000 || 'should be less than 1000',
    twentyFive: v => {
      return ((parseFloat(v) * 100) % 25 === 0) || "Please enter a number in 0.25 increments."
    }

    // positive: v => parseFloat(v) >= 0 || `${day} should be a positive number`,

    // if ((parseFloat(vv) * 100) % 25 !== 0) return "Enter a number in 0.25 increments."
  }
}



const NewLine = ({selectedPeriodIndex, unregister, cancelClicked, setError, control, watch, periodIndex, weekInputName, entryIndex, weekIndex,  formState, register, handleSubmit, errors, getValues, trigger, reset, clearErrors, setValue, updateLineError, totalPeriodHours, isLast, setNewLineOpened, readOnly, fetchTimesheetWeeks, fetchEntries, projectsLoading, projects, phases, categories, timesheet, andyEntry, timesheetId, index, selectedTimesheetWeek, toggleRemoveLineModal}) => {

  const inputName = `${weekInputName}.entries.${entryIndex}`
  const myErrors = errors?.['weeks']?.[periodIndex]?.['entries']?.[entryIndex];
  const [sumHours, setSumHours] = useState(0.0);

  // useEffect(() => {
  //   trigger(`weeks.${periodIndex}.entries.${entryIndex}`)
  // }, [])

  // const {isValid} = formState;


  const [saveDisabled, setSaveDisabled] = useState(false);
  



  const errorArray = () => {

    const myErrors = errors?.['weeks']?.[periodIndex]?.['entries']?.[entryIndex];

    if (!myErrors) return [];


    const {hours, ...notHours} = myErrors;
    let hourErrors = [];

    if (hours) {
      hourErrors = Object.values(hours).map(v => {
        return (v.message);
      });
    }

    let others = [];

    if (notHours) {
      others = Object.values(notHours).map(v => {
        return (v.message);
      });
    }


    return [...hourErrors, ...others];
 
  }


  const periodDays = () => {
    let days = [];
    let count = 1;
    let weekStart = DateTime.fromISO(selectedTimesheetWeek.weekStart);
    let weekEnd = DateTime.fromISO(selectedTimesheetWeek.weekEnd)
    let currentDay = weekStart;

    // if (weekStart.weekday === 7) {
    //   // days.push([`${weekStart.toFormat('EEE').toUpperCase()}`, currentDay, count]);

    //   days.push([`FIRST SUN`, currentDay, count]);
    //   currentDay = currentDay.plus({day: 1}); 
    //   count += 1;
    // }
    // else {

    // }
    
    while (currentDay <= weekEnd) {
      days.push([`${currentDay.toFormat('EEE').toUpperCase()}`, currentDay, count]);
      currentDay = currentDay.plus({day: 1});
      count += 1;
    }

    return days;
  }
  const [subactivities, setSubactivities] = useState(null);
  const [activities, setActivities] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [selectedSubactivity, setSelectedSubactivity] = useState(null);
  const [existingEntry, setExistingEntry] = useState(andyEntry);



  useEffect(() => {
    if (selectedTimesheetWeek && selectedTimesheetWeek.timesheetEntries) {
      setExistingEntry(selectedTimesheetWeek.timesheetEntries[index]);
    }
  }, [selectedTimesheetWeek]);

  // useEffect(() => {
  //   if (activities && existingEntry && existingEntry.activityNumber) {
  //     const activity = activities.find(p => p.number === existingEntry.activityNumber);
  //     if (activity) {
  //       setSelectedActivity(activity);
  //     }
  //     else {
  //       setSelectedActivity(null);
  //     }
  //   }
  //   else {
  //     setSelectedActivity(null);
  //   }
  // }, [activities]);






  const [responseText, setResponseText] = useState("");





  const [entryCreateResult, entryCreateLoading, entryCreateLoaded, entryCreateError, doEntryCreateFetch, setEntryCreateResult, pagy] = useApi();


  const [summaryResult, summaryLoading, summaryLoaded, summaryError, doSummaryFetch, setSummaryResult] = useApi();  
  const fetchSummary = () => {
    doSummaryFetch(getTimesheetSummary, {query: {id: timesheetId}});
  }
  useEffect(() => {
    if (summaryLoaded && summaryResult) {
      store.dispatch(setCurrentTimesheetSummary(summaryResult));
    }
  }, [summaryLoaded]);

  // useEffect(() => {
  //   if (entryCreateResult) {
  //     setExistingEntry(entryCreateResult.timesheetEntry);
  //   }
  // }, [entryCreateResult]);

  useEffect(() => {
    if (entryCreateLoaded) {
      // reset(inputName);

      // fetchSummary();

      // setValue(`${inputName}._destroy`, "1")
      
      setNoteOpened(false);

      

      if (entryCreateResult) {
        if (!existingEntry) {
          store.dispatch(addEntry(entryCreateResult.timesheetEntries));
          if (setNewLineOpened) {
            // reset(inputName)
            unregister(inputName)
            setNewLineOpened(false)
          }
        }
        // else {
        //   store.dispatch(changeEntry(entryCreateResult.timesheetEntry));
        //   setExistingEntry(entryCreateResult.timesheetEntry);

        //   store.dispatch(removeSavingEntryId(existingEntry.id))
        // }
      }


    }
  }, [entryCreateLoaded]);
  

  useEffect(() => {
    if (!existingEntry) return;

    if (entryCreateLoading) {
      store.dispatch(addSavingEntryId(existingEntry.id))
    }
  }, [entryCreateLoading]);





  const [expanded, setExpanded] = useState(!existingEntry);

  const statusText = () => {
    let r = "";
    if (!existingEntry) {
      r = "";
    }
    else if (existingEntry.statusid === 0) {
      r = "DRAFT";
    }
    
    else if (existingEntry.statusid === 1) {
      r = "SUBMITTED";
    }
    else if (existingEntry.statusid === 2) {
      r = "APPROVED";
    }
    else if (existingEntry.statusid === 3) {
      r = "RETURNED";
    }
    else if (existingEntry.statusid === 4) {
      r = "COPIED";
    }

    return r;
  }

  const rowClassName = () => {
    if (!existingEntry) {
      return "row entry-cont new"
    }

    if (existingEntry.statusid === 0) {
      return "row entry-cont created"
    }
    if (existingEntry.statusid === 1) {
      return "row entry-cont created"
    }
    if (existingEntry.statusid === 2) {
      return "row entry-cont submitted"
    }
    if (existingEntry.statusid === 3) {
      return "row entry-cont returned"
    }

    if (existingEntry.statusid === 5) {
      return "row entry-cont posted"
    }

    if (existingEntry.statusid === 6) {
      return "row entry-cont posting failed"
    }


  }

  const isDisabled = () => {
    if (readOnly) {
      return true;
    }
    if (entryCreateLoading) {
      return true;
    }
    if (!existingEntry) {
      return false;
    }
    if (existingEntry.statusid === 1 || existingEntry.statusid === 2 || existingEntry.statusid === 5 || existingEntry.statusid === 6) {
      return true;
    }
  }

  const selectsDisabled = () => {
    if (!existingEntry) {
      return false;
    }

    if (isDisabled()) {
      return true;
    }

    // if (existingEntry.statusid === 1 || existingEntry.statusid === 2 || existingEntry.statusid === 0 || existingEntry.statusid === 2 || existingEntry.statusid === 3) {
    //   return true;
    // }
  }

  const subSelectsDisabled = () => {

    if (!existingEntry) {
      return false;
    }
    if (isDisabled()) {
      return true;
    }
  }

  const [noteSaveResult, noteSaveLoading, noteSaveLoaded, noteSaveError, doNoteSaveFetch, setNoteSaveResult] = useApi();
  const [note, setNote] = useState(null);
  const [noteOpened, setNoteOpened] = useState(false);
  const [noteLabel, setNoteLabel] = useState('');

  const pencilClicked = (i) => {
    setNoteLabel(`day${i+1}note`);
    setNoteOpened(true);
    setNote(existingEntry ? existingEntry[`day${i+1}note`] : getValues()[`day${i+1}note`])
  }

  const toggleNoteModal = () => {
    setNoteOpened(o => !o);
  }

  const trySaveNote = () => {

    // handleSubmit(onSubmit)();
    // 
    if (existingEntry) {
      const payload = {
        id: existingEntry?.id,
        timesheet_entry: {
          [noteLabel]: note
        }
      }
      doNoteSaveFetch(saveNote, {payload: payload});

      // alert(JSON.stringify(getValues(`${inputName}.${noteLabel}`)));
      setValue(`${inputName}.${noteLabel}`, note);
      // alert(JSON.stringify(getValues(`${inputName}.${noteLabel}`)));
    }
    else {
      // alert(JSON.stringify(getValues('day1note')));
      // alert(JSON.stringify(getValues(`${inputName}.${noteLabel}`)));
      setValue(`${inputName}.${noteLabel}`, note);
      // alert(JSON.stringify(getValues(`${inputName}.${noteLabel}`)));
      // alert(JSON.stringify(getValues('day1note')));
      // setNote(null);
      toggleNoteModal();
    }
  }

  useEffect(() => {
    if (noteSaveLoaded) {
      if (noteSaveResult) {

        store.dispatch(changeEntry(noteSaveResult.timesheetEntry));
        setExistingEntry(noteSaveResult.timesheetEntry);
        setNote(null);
        toggleNoteModal();
        

        
      }
    }
  }, [noteSaveLoaded]);



  

  // const www = useWatch({
  //   control,
  //   name: `${inputName}.hours.WED`, // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
  //   defaultValue: hoursHash // default value before the render
  // });

  const totalWatchedHours = (hourObj) => {
    if (!hourObj) return 0.0;

    const h = Object.values(hourObj);
    
    let total = 0.0;
    if (!h) {
      total = 0.0;
    }
    else {
      h.forEach(hour => {
        // total = total + parseFloat(hour)
        const pf = parseFloat(hour) ;
        total = total + (!pf || isNaN(pf) ? 0 : pf)
        
      })
    }

    if (isNaN(total)) {
      total = 0
    }

    return total;
  }


  // useEffect(() => {
  //   const s = totalWatchedHours(www);
  //   setSumHours(s);
  //   setValue(`${inputName}.th`, s, { shouldValidate: true });
  //   
  // }, [www])

  // const hourOnChange = (name) => {
  //   trigger(`${inputName}.th`, {shouldFocus: false})
  //   
  // }


  const hoursHash = () => {
    let h = {}

    periodDays().forEach((a, i) => {
      h[a[0]] = existingEntry ? existingEntry[`day${i+1}hour`] : 0.0
      return true
    })

    return h
  }

  const hoursArray = () => {
    let h = []

    periodDays().forEach((a, i) => {
      const v = existingEntry ? existingEntry[`day${i+1}hour`] : 0.0;
      h.push(v)
    })

    return h
  }






  // useEffect(() => {
  //   const s = totalWatchedHours(www);
  //   setSumHours(s);
  //   setValue(`${inputName}.th`, s, { shouldValidate: true });
  //   
  // }, [www])


  // const ppp = useWatch({
  //   control,
  //   name: `${inputName}.selectedProject`, // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
  //   defaultValue: existingEntry ? existingEntry.projectId : "" // default value before the render
  // });
  // useEffect(() => {
  //   const project = projects?.find(p => p.id === ppp);

  //   
  //   if (project) {
  //     setSelectedProject(project);
  //   }
  //   else {
  //     setSelectedProject("");
  //   }
  // }, [ppp]);
  // const aaa = useWatch({
  //   control,
  //   name: `${inputName}.selectedActivity`, // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
  //   defaultValue: existingEntry ? existingEntry.activityNumber : ""  // default value before the render
  // });
  
  // useEffect(() => {
  //   
  //   if (selectedProject) {
  //     const activity = selectedProject?.activities?.find(p => p.number === aaa);
      
  //     if (activity) {
  //       setSelectedActivity(activity);
  //     }
  //     else {
  //       setSelectedActivity("");
  //     }
  //   }
  //   else {
  //     setSelectedActivity("");
  //   }
    
  // }, [aaa, selectedProject]);

  // useEffect(() => {
  //   if (activities && existingEntry && existingEntry.activityNumber) {
  //     const activity = activities.find(p => p.number === existingEntry.activityNumber);
  //     if (activity) {
  //       setSelectedActivity(activity);
  //     }
  //     else {
  //       setSelectedActivity("");
  //     }
  //   }
  //   else {
  //     setSelectedActivity("");
  //   }
  // }, [activities]);

  // useEffect(() => {
  //   if (subactivities && existingEntry && existingEntry.subactivitynum) {
  //     const subactivity = subactivities.find(p => p.number === existingEntry.subactivitynum);
  //     if (subactivity) {
  //       setSelectedSubactivity(subactivity);
  //     }
  //     else {
  //       setSelectedSubactivity("");
  //     }
  //   }
  //   else {
  //     setSelectedSubactivity("");
  //   }
  // }, [subactivities]);






  // useEffect(() => {
  //   if (existingEntry?.projectId && projects) {
  //     const project = projects?.find(p => p.id === existingEntry?.projectId);
  //     setSelectedProject(existingEntry.project);


  //     

  //     if (project) {
  //       setActivities(project.activities)

  //       if (project.activities) {
  //         if (existingEntry.activityNumber) {

  //           const activity = project.activities.find(p => p.number === existingEntry.activityNumber);
  //           setSelectedActivity(activity);

  //           if (activity) {
  //             setSubactivities(activity.subactivities)

  //             if (activity.subactivities) {
  //               if (existingEntry.subactivitynum) {
  //                 const subactivity = activity.subactivities.find(p => p.subactivitynum === existingEntry.subactivitynum);
  //                 setSelectedActivity(subactivity);
  //               }
  //             }
  //           }

  //         }
  //       }
  //     }
  //   }
  //   else {

  //   }

  // }, [existingEntry, projects]);


  // const ppp = useWatch({
  //   control,
  //   name: `${inputName}.selectedProject`, // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
  //   defaultValue: "" // default value before the render
  // });



  // 
  // 


  const selectProjectChange = (e) => {
    const project = projects?.find(p => p.id === e.target.value);

    
    if (project) {
      setSelectedProject(project);
    }
    else {
      setSelectedProject("");
    }

    clearErrors(`${inputName}`)

    // trigger(`${inputName}.selectedProject`);
  }

  const selectActivityChange = async (e) => {
    // 
    // alert('selectActivityChange')
    // alert('here 2');
    const activity = selectedProject?.activities?.find(p => p.number === e.target.value);

    
    if (activity) {
      setSelectedActivity(activity);
    }
    else {
      setSelectedActivity("");
    }
  }

  const selectSubactivityChange = async (e) => {
    const subactivity = subactivities?.find(p => p.id === e.target.value);
    
    if (subactivity) {
      setSelectedSubactivity(subactivity);
    }
    else {
      setSelectedSubactivity("");
    }
  }


  useEffect(() => {
    if (activities && existingEntry && existingEntry.activityNumber) {
      const activity = activities.find(p => p.number === existingEntry.activityNumber);
      if (activity) {
        setSelectedActivity(activity);
      }
      else {
        setSelectedActivity(null);
      }
    }
    else {
      setSelectedActivity(null);
    }
  }, [activities]);

  useEffect(() => {
    // clearError("selectedSubactivity");

    if (selectedProject && selectedProject.activities && selectedProject.activities.length > 0) {
      setActivities(selectedProject.activities);

      
      // setSelectedActivity(selectedProject.activities[0]);
    }
    else {
      setActivities(null);
      setSelectedActivity(null);
    }

  }, [selectedProject]);

  useEffect(() => {
    // alert(JSON.stringify(selectedActivity, null, 2))
    // alert(JSON.stringify(selectedActivity, null, 2))

    // const t = await trigger([`weeks.${periodIndex}.entries.0.selectedProject`, `weeks.${periodIndex}.entries.0.selectedActivity`, `weeks.${periodIndex}.entries.0.selectedSubactivity`]);

    if (selectedActivity) {
      setValue(`${inputName}.selectedActivity`, selectedActivity?.number)
    }
    // else {
    //   setValue(`${inputName}.selectedActivity`, "")
    // }

    
    if (selectedActivity && selectedActivity.subactivities && selectedActivity.subactivities.length > 0) {
      setSubactivities(selectedActivity.subactivities);
      setSelectedSubactivity(selectedActivity?.subactivities[0]);
      
      
    }
    else {
      setSubactivities(null);
      setSelectedSubactivity(null);
    }
    // setSubactivities(null);
    // setSelectedSubactivity(null);
    
    

  }, [selectedActivity]);

  useEffect(() => {
    
    // alert(JSON.stringify(selectedSubactivity, null, 2))
    setValue(`${inputName}.selectedSubactivity`, selectedSubactivity?.id)
  }, [selectedSubactivity]);

  useEffect(() => {
    if (existingEntry) {


      setValue(`${inputName}.th`, existingEntry.totalPeriodHours, {shouldValidate: false});

      // const t = trigger(`${inputName}.th`)

      
      // setSelectedProject(existingEntry.project);
      // 
    }
    else {
      // TODO
      // store.dispatch(setUnsavedEntries(true));
    }
    // reset();
    // calcHours();

  }, [existingEntry]);

  useEffect(() => {
    if (existingEntry && existingEntry.projectId && projects) {
      const found = projects.find(project => project.id === existingEntry.projectId)

      if (found) {
        setSelectedProject(found);
      }
      
      
      // 
    }
    else {
      // TODO
      // store.dispatch(setUnsavedEntries(true));
    }
    // reset();
    // calcHours();

  }, [existingEntry, projects]);


  // useEffect(() => {
  //   // clearErrors("selectedSubactivity");
  //   
  //   // trigger(`${inputName}.selectedProject`);

  //   if (selectedProject && selectedProject.activities && selectedProject.activities.length > 0) {
  //     setActivities(selectedProject.activities);
  //   }
  //   else {
  //     setActivities(null);
  //     setSelectedActivity("");
  //   }

  //   
  //   if (!existingEntry) {
  //     // trigger(`${inputName}.selectedProject`)
  //   }

  // }, [selectedProject]);

  // useEffect(() => {
  //   // trigger(`${inputName}.selectedActivity`);
  //   // alert(JSON.stringify(selectedActivity, null, 2))

  //   
  //   if (selectedActivity && selectedActivity.subactivities && selectedActivity.subactivities.length > 0) {
  //     setSubactivities(selectedActivity.subactivities);
      
  //   }
  //   else {
  //     setSubactivities(null);
  //     setSelectedSubactivity("");
      
  //   }
  // }, [selectedActivity]);

  // useEffect(() => {
  //   if (existingEntry && projects) {
  //     const project = projects?.find(p => p.id === existingEntry.projectId);
  //     setSelectedProject(project)
  //   }
  // }, [existingEntry, projects])

  // const www = useWatch({
  //   control,
  //   name: [`${inputName}.hours.FIRST SUN`, `${inputName}.hours.MON`, `${inputName}.hours.TUE`, `${inputName}.hours.WED`, `${inputName}.hours.THU`, `${inputName}.hours.FRI`, `${inputName}.hours.SAT`, `${inputName}.hours.SUN`],
  //   defaultValue: hoursArray,
  //   // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
  // });

  const www = useWatch({
    control,
    name: [`${inputName}.hours.MON`, `${inputName}.hours.TUE`, `${inputName}.hours.WED`, `${inputName}.hours.THU`, `${inputName}.hours.FRI`, `${inputName}.hours.SAT`, `${inputName}.hours.SUN`],
    defaultValue: hoursArray,
    // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
  });

  const hasValues = () => {
    if (!www) return false
    if (www.length === 0) return false
    if (www.filter(e => e !== undefined).length === 0) return false

    return true
  }

  const sum = () => {
    // if (!www) return undefined
    // if (www.length === 0) return undefined
    // if (www.filter(e => e !== undefined).length === 0) return undefined

    if (!hasValues()) return false;

    let total = 0;

    www.forEach(n => {
      if (n) {
        const pf = parseFloat(n) ;
        total = total + (!pf || isNaN(pf) ? 0 : pf)
      }
    })

    return total;
  }

  // const output = sum(www);
  // 
  

  // useEffect(() => {
  //   const s = sum(www);
  //   
  //   if (hasValues() !== false) {
  //     setValue(`${inputName}.th`, s);
  //     // trigger(`${inputName}.th`);
  //     if (s > 0) {
  //       clearErrors(`${inputName}.th`)
  //     }
  //     // else {
  //     //   setError(`${inputName}.andy`, {type: 'manual', message: "Please enter hours for this week or remove it."})
  //     // }
  //   }



    
  // }, [www])



  const [hide, setHide] = useState(false);
  const watchDestroy = useWatch({
    control,
    name: `${inputName}._destroy`,
    defaultValue: "0"
  })

  useEffect(() => {
    if (watchDestroy === "1") {
      setHide(true)
    }
  }, [watchDestroy]);



  const checkDupes = () => {
    const values = getValues();
    if (!values?.weeks) return false;

    const week = values.weeks[selectedPeriodIndex];
    let found = false;
    const entries = week.entries;

    if (!entries) {
      return false;
    }

    const newEntry = values.weeks[selectedPeriodIndex].entries[0];
    const myString = `${newEntry.selectedProject} ${newEntry.selectedActivity} ${newEntry.selectedSubactivity}`;


    
    const array = entries.filter(v => v._destroy !== "1")
      .map(v => `${v.selectedProject} ${v.selectedActivity} ${v.selectedSubactivity}`)
      .filter(v => (v._destroy !== "1" && v === myString));


    // const uniqueValues = new Set(entries.map(v => `${v.selectedProject} ${v.selectedActivity} ${v.selectedSubactivity}`));
    if (array.length > 1) {
      
      
      
      found = true;
      return true;
    }
    
    

    return found;
  }

  const [dupesFound, setDupesFound] = useState(false);


  const saveNewClicked = async (override) => {

    // setCancelled(false);
    // setValue(`weeks.${periodIndex}.entries.0.th`, sum(www))
    // alert(override)
    if (checkDupes() && !override) {

      
      setDupesFound(true);
    }
    else {
      setDupesFound(false);
      const t = await trigger([`weeks.${periodIndex}.entries.0.selectedProject`, `weeks.${periodIndex}.entries.0.selectedActivity`, `weeks.${periodIndex}.entries.0.selectedSubactivity`]);

      // alert('here')

        if (!t) return
        // let e = formState.errors?.['weeks']?.[periodIndex]?.['entries']?.[entryIndex];



        // // alert(JSON.stringify(Object.keys(e)?.length, null, 2))
        // if (e && Object.keys(e)?.length !== 0) {
        //   return
        // }

        const data = getValues(`weeks.${periodIndex}.entries.${entryIndex}`);


        
        const payload = {
          timesheet_entry: {
            ...data
          }
        }

        doEntryCreateFetch(createEntry, {payload: payload});
    }



    
  };

  const toggleOverrideModal = () => {
    setDupesFound(false);
  }



  return (
    <div className={`inline-blockx ${hide && 'line-hidden'}`} key={existingEntry?.id || inputName}>
      {/* <div>{existingEntry?.id}</div> */}
      {/* <div>COUNT: {count}</div> */}
      {/* <div onClick={() => alert(JSON.stringify(getValues(inputName), null, 2))} className="button"></div> */}
      {/* <div className="code">
        <pre>
          
          {JSON.stringify(selectedProject, null, 2)}

          {JSON.stringify(projects, null, 2)}
        </pre>
      </div> */}

      {/* <div className="code">
        <pre>
          {JSON.stringify(watchDestroy, null, 2)}
        </pre>
        <pre>
          {JSON.stringify(hide, null, 2)}
        </pre>
      </div> */}

      {dupesFound &&
        <Modal focusTrap={false} size="small" toggleModal={toggleOverrideModal}>
          <div className='modal-header flex-cont align-center'>
            {/* <h1 className="flex-1">Submit Timesheet</h1> */}
            <div className="flex-1"></div>
            <div onClick={toggleOverrideModal} className="ghost button close">
              <i className="fas fa-times"></i>
            </div>
          </div>

          <div className="padding-1">


            <div>
              This project/milestone/task already exists for this week. Are you sure you want to continue?
            </div>
            

          </div>

          <div className='modal-footer flex-cont align-center'>
            <div onClick={() => saveNewClicked(true)} className="button">Yes, continue</div>
            <div className="flex-1"></div>
            <div onClick={toggleOverrideModal} className="ghost button">Cancel</div>
            
            
          </div>
        </Modal>
      }


      {noteOpened &&
        <Modal focusTrap={!isDisabled()} toggleModal={toggleNoteModal}>
          <div className='modal-header flex-cont align-center'>
            <div className="flex-1">
              <h1 className="margin-bottom-1">Note</h1>
            </div>
            <div onClick={toggleNoteModal} className="button close ghost">
              <i className="fas fa-times"></i>
            </div>
          </div>
          <div className="padding-1">
            

            {isDisabled() && 
              <div>
                <textarea disabled={isDisabled()} value={existingEntry ? existingEntry[noteLabel] : getValues()[noteLabel]} />
              </div>
            }
            {!isDisabled() && 
              <textarea value={note} onChange={e => setNote(e.target.value)} defaultValue={existingEntry ? existingEntry[noteLabel] : getValues()[noteLabel]} />
            }
          </div>

          <div className="modal-footer align-center flex-cont">
            <div onClick={toggleNoteModal} className="ghost button margin-right-1">Close</div>

            {!isDisabled() && 
              <div onClick={trySaveNote} className={`button margin-right-1 ${noteSaveLoading ? 'disabled' : ''}`}>Save</div>
            }
            {noteSaveLoading &&
              <div className="spinner"></div>
            }
          </div>
        </Modal>

        
      }

    <div className={rowClassName()}>


        <div className="flex-cont align-items-end">
          {/* <div>
            {existingEntry?.id}
          </div> */}

          {existingEntry && 
            <div style={{marginBottom: '.5em'}} className={`flex-cont align-center margin-right-half font-075 status-badge-cont ${existingEntry?.status?.toLowerCase()}`}>
              <div className="status-badge-shell">
                <div className="status-badge">
                  {existingEntry?.status?.toUpperCase()}
                </div>
              </div>
            </div>
          }
          {!existingEntry &&
            <div style={{marginBottom: '.5em'}} className={`flex-cont align-center margin-right-half font-075 status-badge-cont ${existingEntry?.status?.toLowerCase()}`}>
              <div className="status-badge-shell">
                <div className="status-badge">
                  NEW
                </div>
              </div>
            </div>
          }


           {/* {
 
            <label className={myErrors?.selectedProject ? "margin-right-4px error-label" : "margin-right-4px"}>  
              <div className="label-text">Project</div>
              {(projects && projects.length > 0) ? (
                  <select tabIndex={0} defaultValue={existingEntry ? existingEntry.projectId : ""} {...register(`${inputName}.selectedProject`, {
                    required: "Select a project"
                  })} name={`${inputName}.selectedProject`}  id={`${inputName}.selectedProject`} >
                    <option value="">Select project:</option>
                    {projects?.map((project, key) => {
                      return <option  key={key} value={project.id}>{`${project.id} - ${project.name}`}</option>
                    })}
                  </select>
                ) : (
                  <select tabIndex={0} disabled id={`${inputName}.selectedProject`}>
                    <option value="" disabled>---</option>
                  </select>
                )
              }
            </label>

           } */}
            {/* <div>
              {existingEntry?.projectId}
            </div> */}

            <ProjectSelect hide={hide} disabled={selectsDisabled()} myErrors={myErrors} selectedProject={selectedProject} projects={projects} selectProjectChange={selectProjectChange} register={register} inputName={inputName} existingEntry={existingEntry}   /> 
            
            <ActivitySelect setValue={setValue} getValues={getValues} selectedProjectID={selectedProject?.id} projects={projects} hide={hide} disabled={subSelectsDisabled()} myErrors={myErrors} selectedActivity={selectedActivity} activities={activities} selectActivityChange={selectActivityChange} register={register} inputName={inputName} existingEntry={existingEntry}   />
            
            <SubactivitySelect projects={projects} hide={hide} disabled={subSelectsDisabled()} myErrors={myErrors} selectedSubactivity={selectedSubactivity} subactivities={subactivities} selectSubactivityChange={selectSubactivityChange} register={register} inputName={inputName} existingEntry={existingEntry}   />

          <input  {...register(`${inputName}.id`)} type="hidden" name={`${inputName}.id`} defaultValue={existingEntry ? existingEntry.id : null} />
          <input  {...register(`${inputName}._destroy`)} type="hidden" name={`${inputName}._destroy`} defaultValue={"0"} />
          <input  {...register(`${inputName}.timesheetid`)} type="hidden" name={`${inputName}.timesheetid`} defaultValue={timesheetId} />
          <input  {...register(`${inputName}.weekStart`)} type="hidden" name={`${inputName}.weekStart`} defaultValue={selectedTimesheetWeek.weekStart} />
          <input  {...register(`${inputName}.weekEnd`)} type="hidden" name={`${inputName}.weekEnd`} defaultValue={selectedTimesheetWeek.weekEnd} />

          {existingEntry && 
            <>
              <input  {...register(`${inputName}.day1note`)} type="hidden" name={`${inputName}.day1note`} defaultValue={existingEntry?.day1note} />
              <input  {...register(`${inputName}.day2note`)} type="hidden" name={`${inputName}.day2note`} defaultValue={existingEntry?.day2note} />
              <input  {...register(`${inputName}.day3note`)} type="hidden" name={`${inputName}.day3note`} defaultValue={existingEntry?.day3note} />
              <input  {...register(`${inputName}.day4note`)} type="hidden" name={`${inputName}.day4note`} defaultValue={existingEntry?.day4note} />
              <input  {...register(`${inputName}.day5note`)} type="hidden" name={`${inputName}.day5note`} defaultValue={existingEntry?.day5note} />
              <input  {...register(`${inputName}.day6note`)} type="hidden" name={`${inputName}.day6note`} defaultValue={existingEntry?.day6note} />
              <input  {...register(`${inputName}.day7note`)} type="hidden" name={`${inputName}.day7note`} defaultValue={existingEntry?.day7note} />
              <input  {...register(`${inputName}.day8note`)} type="hidden" name={`${inputName}.day8note`} defaultValue={existingEntry?.day8note} />
            </>
          }
          {!existingEntry &&
            <>
              <input  {...register(`${inputName}.day1note`)} type="hidden" name={`${inputName}.day1note`}  />
              <input  {...register(`${inputName}.day2note`)} type="hidden" name={`${inputName}.day2note`}  />
              <input  {...register(`${inputName}.day3note`)} type="hidden" name={`${inputName}.day3note`}  />
              <input  {...register(`${inputName}.day4note`)} type="hidden" name={`${inputName}.day4note`}  />
              <input  {...register(`${inputName}.day5note`)} type="hidden" name={`${inputName}.day5note`}  />
              <input  {...register(`${inputName}.day6note`)} type="hidden" name={`${inputName}.day6note`}  />
              <input  {...register(`${inputName}.day6note`)} type="hidden" name={`${inputName}.day6note`}  />
              <input  {...register(`${inputName}.day8note`)} type="hidden" name={`${inputName}.day8note`}  />
            </>
          }




            <div className="flex-cont align-centerx">
              {periodDays() && periodDays().map((a, i) => {

                const day = a[1];
                const label = a[0];

                if (!day) {
                  return(
                    <>
                      INVALID
                    </>
                  )
                }
                return (
                  <>
                  {existingEntry ? (
                      <label key={i} className={myErrors?.hours?.[label] ? 'error-label margin-right-4px' : 'margin-right-4px'}>
                        {/* <div  onClick={() => pencilClicked(i)} className="flex-cont align-center">
                          <div className="flex-1 label-text">{`${day.toFormat('EEE')}`}</div>
                          <i className="pencil fas fa-pencil-alt"></i>
                        </div> */}

                        {/* <div>{JSON.stringify(myErrors?.hours?.[label], null, 2)}</div> */}
                        {/* <div>{entryIndex}</div> */}
                        {entryIndex === 1 &&
                          <div style={{
                            color: 'gray',
                            marginBottom: '1em',
                            fontWeight: 'bold',
                            width: '5px'
                          }}>
                            {day?.toLocaleString({month: '2-digit', day: '2-digit', year: '2-digit' })} 
                          </div> 
                        }


                        <div className="relative flex-cont align-centerx">

                          
                          <div className="flex-1 label-text">{`${day.toFormat('EEE')}`}</div>
                          {/* <div>{`Test: ${existingEntry[`day${i+1}note`] ? 'note-present' : ''}`}</div> */}
              
                          <i onClick={() => pencilClicked(i)} className={`${existingEntry[`day${i+1}note`] ? 'note-present' : ''} pencil fas fa-pencil-alt`}></i>
                  
                        </div>

      
      
                        

                        {/* <div>{existingEntry ? existingEntry[`day${day.weekday}hour`] : 99}</div> */}
                        <input tabIndex={i+1}  defaultValue={existingEntry ? existingEntry[`day${i+1}hour`] : 0.0}  
                        {...register(`${inputName}.hours.${label}`, {
                          validate: dayValidation(label, hide)
                        })} name={`${inputName}.hours.${label}`} className={`hour-input ${isDisabled() ? 'disabled-input' : ''}`} type="text" />

                        <input  {...register(`${inputName}.dates[${i}]`)} type="hidden" name={`${inputName}.dates[${i}]`} value={day.toISODate()} />
                        
                      </label>
                    ) : (
                      <label  key={i} className={errors && errors.hours && errors.hours[`${label}`] ? 'error-label margin-right-4px' : 'margin-right-4px'}>

                        <div onClick={() => pencilClicked(i)} className="relative flex-cont align-centerx">
                          <div className="flex-1 label-text">{`${day.toFormat('EEE')}`}</div>
                          <i className="pencil fas fa-pencil-alt"></i>
                        </div>
                        
                        
         

                        {/* <div>{existingEntry ? existingEntry[`day${day.weekday}hour`] : 99}</div> */}
                        <input   defaultValue={0.0}  {...register(`${inputName}.hours.${label}`, {
                          validate: dayValidation(label, hide)
                          
                        })} name={`${inputName}.hours.${label}`} className="hour-input" type="text" />
                        <input  {...register(`${inputName}.dates[${i}]`)} type="hidden" name={`${inputName}.dates[${i}]`} value={day.toISODate()} />
                        
                      </label>
                    )
                    }

                  </>
                );
              })}
            </div>
            <label className="center">
              <div style={{marginBottom: '4px'}} className="label-text">Hours</div>
              <div className="dummy-input bold">
                {/* {sum(www) || (existingEntry ? existingEntry.totalPeriodHours : 0)} */}

                {hasValues() && sum(www)}
                {!hasValues() && (existingEntry ? existingEntry.totalPeriodHours : 0)}
                
                {/* {sum(www) && sum(www)} */}
                {/* {www && sum(www)}
                {!www && (existingEntry ? existingEntry.totalPeriodHours : 'help')} */}
              </div>   
              <input name={`${inputName}.th`} {...register(`${inputName}.th`)} type="hidden" defaultValue={existingEntry ? existingEntry.totalPeriodHours : 0} 
              />
              
            </label>

            {/* <div className="flex-1"></div> */}
          <div className="flex-cont align-centerx">
            
          
            {entryCreateLoading ? (
              <div style={{fontSize: '.75em'}} className="entry-button margin-left-1 margin-right-1 spinner"></div>
            ) : (
              <div>
                
              </div>
            )
                
            }
            {!isDisabled() && !readOnly &&
            <>

              
              {/* <div>
                {existingEntry?.createdDate}
              </div> */}
              {false && <button tabIndex={20} disabled={entryCreateLoading} type="submit" className={`${(entryCreateLoading || (errorArray() && errorArray().length !== 0)) ? "disabled ghost button margin-right-4px margin-left-4px" : "button ghost margin-right-4px margin-left-4px"}`} >
                {/* <i className="no-margin big fas fa-save"></i> */}
                {/* {existingEntry ? "UPDATE ENTRY" : "SAVE NEW ENTRY"} */}
                SAVE
              </button>}

              {existingEntry &&
                <div onClick={() => toggleRemoveLineModal(existingEntry.id, inputName) } className={`entry-button circle button margin-left-1 ${entryCreateLoading ? 'disabled' : ''}`}>
                  <i className="no-margin big fas fa-times"></i>
                  {/* DELETE */}
                </div>
              }
              {!existingEntry &&
                <>
                <div className="flex-cont align-center">
                
                <div onClick={() => saveNewClicked(false)} tabIndex={20} disabled={entryCreateLoading} className={`${(saveDisabled || entryCreateLoading || (errorArray() && errorArray().length !== 0)) ? "entry-buttonx disabled button margin-right-0 margin-left-1" : "margin-left-1 button margin-right-0"}`} >
                  {/* <i className="no-margin big fas fa-save"></i> */}
                  {/* {existingEntry ? "UPDATE ENTRY" : "SAVE NEW ENTRY"} */}
                  ADD
                </div>
                <div onClick={cancelClicked} className={`ghost entry-buttonx margin-top-1x margin-left-05 button circlex ${entryCreateLoading ? 'disabled' : ''}`}>
                  {/* <i className="no-margin big fas fa-times"></i> */}
                  {/* DELETE */}
                  CANCEL
                </div>
                </div>
                </>
              }
              </>
            }
          </div>
            
        
        </div>



      {existingEntry && existingEntry.returnnote && existingEntry.statusid !== 2 &&
        <div style={{color: 'darkred'}}>
          <strong>MANAGER'S NOTE: </strong>
          {existingEntry.returnnote}
        </div>
      }

      
      

      
    </div>

    {/* <div>Errors: {errors}</div> */}
    {/* <div>{JSON.stringify(errorArray())}</div> */}
{/* 
    <ErrorMessage
      errors={errors}
      name="singleErrorInput"
      render={({ message }) => <p>{message}</p>}
    />
   */}
    {errorArray() && 
      <ol className="error-cont">
        {errorArray().map(e => 
          <li>{e}</li>
        
        )}
      </ol>
    }
    </div>
  );
};

const mapState = state => ({
});

export default connect(mapState)(NewLine);
