import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link, NavLink, Outlet, useLocation
} from 'react-router-dom';
import { connect } from 'react-redux';


const isActive = ({ isCurrent }) => {
  return isCurrent ? { className: "active" } : null
}



const AdminLogsNav = ({children, count}) => {


  return(
    <ul className="sub-nav">
      <li>
        <NavLink end to="/logs">
          Timesheets
        </NavLink>
      </li>

      <li>
        <NavLink end to="/logs/expense-reports">
          Expense Reports
        </NavLink>
      </li>

      <li>
        <NavLink to="/logs/unposted-timesheets">
          Unposted Timesheets (temp tool)
        </NavLink>
      </li>

    </ul>
  );
}


function mapStateToProps(state) {
  return { 
    // currentUser: state.sessionState.currentUser,
  };
} 

export default connect(mapStateToProps)(AdminLogsNav);
