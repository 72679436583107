import React, { useEffect, useState, useRef } from 'react';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link, Outlet, useLocation
} from 'react-router-dom';

import * as yup from "yup";
import { useForm, useWatch } from 'react-hook-form';

import * as msal from "@azure/msal-browser";
import store from '../../redux/store';
import { connect } from 'react-redux';
import { setTokenResponse } from '../../redux/authActions';

import Modal from '../../components/Modal';
import { useApi } from '../../api';
import { createUser, updateUser, getUsers, deleteUser } from '../../redux/admin/userActions';
import { getCompanies } from '../../redux/admin/companyActions';
import { getRoles} from '../../redux/admin/roleActions';
import Pagination from '../../components/Pagination';

import SubNav from '../../components/SubNav';

const CompanyAccessFields = ({viewOnly=true, companyAccess,  index, formUser, control, errors, register, companiesLoading, companies}) => {

  const sub = null;
  // companies.filter(company => );


  const ttt = [
    {legalentityid: "1"},
    {legalentityid: "2"},
    {legalentityid: "3"},
    {legalentityid: "4"},
  ]


  return (
    <div>
      {true &&
        <>
          {!viewOnly && companiesLoading &&
            <div className="editable-cont loading">
              <div className="absolute spinner"></div>
            </div>
          }
          {viewOnly &&
            <div className="dummy-select">
              {companyAccess ? companyAccess.company : ""} 
            </div>
          }
          {!viewOnly && <>
            {!companiesLoading && companies &&

              <div className="flex-cont align-center">
                <select className={`${errors?.user_company_accesses_attributes?.[index] ? 'has-errors' : ''}`}  
                defaultValue={companyAccess ? companyAccess.company : ""} 
                {...register(`user_company_accesses_attributes.${index}.company`, {
                  required: "Select a company."
                })} name={`user_company_accesses_attributes.${index}.company`}>
                  <option className="gray" disabled value="">Select company:</option>
                  {companies?.map((company, index) => <option key={company?.legalentityid} value={company?.legalentityid}>{company?.legalentityid}</option>)}
                </select>

                {formUser?.roleid?.toString() !== "0" &&

                  <label className="margin-left-2 margin-right-half">
                    <input type="checkbox" {...register(`user_company_accesses_attributes.${index}.isexpenseapprover`)} name={`user_company_accesses_attributes.${index}.isexpenseapprover`}  value={true} defaultChecked={companyAccess ? companyAccess.isexpenseapprover : false}  />

                    <span className='gray bold font-075 uppercase margin-left-half'>EXPENSE APPROVER</span>
                  </label>
                }

                

                
              </div>
            }
          </>}
          {errors?.user_company_accesses_attributes?.[index] && 
            <div className="field-error">
              {errors?.user_company_accesses_attributes?.[index]?.message}
            </div>
          }

          
          
          
        </>
      }
      

    </div>
  )
}

 
const UserForm = ({viewAsUser, toggleModal, formUser}) => {

  const { control, watch, register, formState: {errors}, handleSubmit, getValues, setValue } = useForm({
    // validationSchema: UserSchema
  });

  const watchedAllCompanies = useWatch({
    control,
    name: 'allcompanies', // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
    defaultValue: "0" // default value before the render
  });

  const [result, loading, loaded, error, doFetch, setResult] = useApi();
  
  const [roles, rolesLoading, rolesLoaded, rolesError, doRolesFetch, setRolesResult] = useApi();

  const fetchRoles = () => {
    doRolesFetch(getRoles);
  }

  const [companies, companiesLoading, companiesLoaded, companiesError, doCompaniesFetch, setCompaniesResult] = useApi();
  const fetchCompanies = () => {
    doCompaniesFetch(getCompanies);
  }


  useEffect(() => {
    fetchCompanies();
    fetchRoles();
  }, []);


  const onSubmit = async data => {
    let d = {
      user: {
        ...data
      }
    }

    if (formUser && formUser.email) {
      doFetch(updateUser, {payload: d});
    }
    else {
      doFetch(createUser, {payload: d});
    }

  }

  useEffect(() => {
    if (loaded) {
      toggleModal(true);
    }
  }, [loaded]);

  const [deleteModalOpened, setDeleteModalOpened] = useState(false);

  const toggleDeleteModal = () => {
    setDeleteModalOpened(o => !o);
  }

  const [deleteResult, deleteLoading, deleteLoaded, deleteError, doDeleteFetch, setDeleteResult] = useApi();
  const tryDeleteUser = () => {

    alert('To be implemented.');

    return 
    if (formUser) {
      const payload = {
        user: {
          workerrecid: formUser.workerrecid
        }
      }
      doDeleteFetch(deleteUser, {payload: payload});
    }
  }
  useEffect(() => {
    if (deleteLoaded) {
      toggleDeleteModal();
      toggleModal();
    }
  }, [deleteLoaded]);


  const [companyCount, setCompanyCount] = useState(formUser?.user_company_accesses ? formUser.user_company_accesses.length : 0);
  const [rows, setRows] = useState([]);
  const [removedIndexes, setRemovedIndexes] = useState([]);

  const addRowClicked = () => {
    setRows(o => [...o, companyCount+1]);
    setCompanyCount(o => o+1);    
  }

  const removeRowClicked = (row, index) => {
    setRows(o => [...o.filter(e => e !== row)] );
    setValue(`user_company_accesses_attributes.${row}`, null)

  }

  const removeExistingRowClicked = index => {
    setRemovedIndexes(prevRemovedIndexes => [...prevRemovedIndexes, index]);
    const fn = `user_company_accesses_attributes.${index}._destroy`;
    setValue(fn, true);
  }



  const remainingCompanies = (index) => {

    return companies;
    // if (companiesLoading || !companies || !companies) return null;

    // 

    // let selectedCompanyIds = getValues('user_company_accesses_attributes')?.map((caa, i) => {
    //   if (caa?.company && i !== index && !caa?._destroy) {
    //     return (caa.company.toString());
    //   }
    // })
    

    // if (selectedCompanyIds) {
    //   
    //   
    //   
    //   return companies.filter(company =>  !selectedCompanyIds.includes(company.toString()) )
    // }
    // else {
    //   return companies
    // }
    


  }

  
  const viewOnly = viewAsUser?.roleid?.toString() !== "2";


  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="modal-header flex-cont align-center">
        <h2 className="flex-1">{formUser ? "Edit" : "New"} User</h2>
        <div onClick={() => toggleModal(false)} className="ghost button close">
          <i className="fas fa-times"></i>
        </div>
      </div>

      <div className="padding-1">

        {formUser &&
          <div className="bold">
            {formUser.fname} {formUser.lname} - {formUser.workerrecid}
          </div>
        }
        {/* 
        <div className="code">
          <pre>
            {JSON.stringify(formUser, null, 2)}
          </pre>
        </div> */}



        <div className="flex-cont margin-top-1">

          <input {...register("workerrecid")} type="hidden" name="workerrecid" value={formUser?.workerrecid} />

          

          <label htmlFor="roleid"  className="margin-right-1"> 
            <div style={{marginBottom: '4px'}} className="gray bold font-075 uppercase">ROLE</div>
            
            {/* <pre>
              {JSON.stringify(formUser?.roleid?.toString())}
            </pre> */}

            {(viewAsUser.roleid === 7 && viewAsUser.workerrecid === formUser.workerrecid) ? (
              <div className='bold'>
                {viewAsUser.rolename}
              </div>
            ) : (
              <>
                {rolesLoading &&
                  <div className="editable-cont loading">
                    <div className="spinner"></div>
                  </div>
                }
                {!rolesLoading && roles &&
                  <select className={`${errors?.roleid ? 'has-errors' : ''}`}   {...register("roleid", {
                    required: "Select a role."
                  })} defaultValue={formUser ? formUser.roleid : "0"} name="roleid">
                    {roles?.map((role, index) => {

                        return (<option value={role?.roleid}>{role?.rolename}</option>)
                      
                      
                    })}
                  </select>
                }
                {errors?.roleid && <div className="field-error">{errors?.roleid?.message}</div>}
              </>

            )
            
            }
          
          </label>

          <label htmlFor="email"  className="margin-right-half"> 
            <div style={{marginBottom: '4px'}} className="gray bold font-075 uppercase">AAD Login Email</div>
            <input 
              type="text" 
              {...register("email", {
                required: "Enter an email."
              })}
              placeholder="AAD Login Email"
              name="email"
              defaultValue={formUser?.email}
              className={`${errors?.email ? 'has-errors' : ''}`}
              style={{minWidth: '20em', }}
            />
            {errors?.email && <div className="field-error">{errors?.email?.message}</div>}
          </label>

          <label htmlFor="enabled"  className="margin-right-half"> 
            <div style={{marginBottom: '4px'}} className="gray bold font-075 uppercase">ENABLED?</div>

            <div className="flex-cont align-center">
              <label className="margin-right-half">
                <input type="radio" {...register("enabled")} name='enabled'  value={1} defaultChecked={formUser?.enabled}  />
                <span>Yes</span>
              </label>
              <br />
              <label>
                <input type="radio" {...register("enabled")} name="enabled" value={0} defaultChecked={!formUser?.enabled}   />
                <span>No</span>
              </label>
            </div>
            
            {errors?.enabled && <div className="field-error">{errors?.enabled?.message}</div>}
          </label>
        </div>



        

        {/* <div>{JSON.stringify(errors)}</div> */}
        {/* <div className="button" onClick={() => alert(JSON.stringify(getValues(), null, 2))}>

        </div>
         */}

         {/* <div>
           viewAs roleid: {JSON.stringify(viewAsUser?.roleid?.toString(), null, 2)}
         </div> */}

        <div className="margin-top-2 margin-bottom-4">

          <h4>Company Access</h4>

          <div className="flex-cont">
          <label htmlFor="company"  className="margin-right-1 margin-bottom-half"> 

            <div style={{marginBottom: '4px'}} className="gray bold font-075 uppercase">HOME COMPANY</div>
            {companiesLoading &&
              <div className="dummy-select editable-cont loading">
                <div className="absolute spinner"></div>
              </div>
            }
            {!companiesLoading && companies &&
              <div className="align-center flex-cont">
                <select className={`${errors?.company ? 'has-errors' : ''}`}  defaultValue={formUser?.company} {...register("company", {
                  required: "Select a company."
                })} name="company">
                  <option className="gray" disabled value="">Select company:</option>
                  {companies?.map((company, index) => <option key={company?.legalentityid} value={company?.legalentityid}>{company?.legalentityid}</option>)}
                </select>
                {formUser?.roleid?.toString() !== "0" &&
                  <label className="margin-left-2 margin-right-half"> 
                    <input type="checkbox" {...register("isexpenseapprover")} name='isexpenseapprover'  value={true} defaultChecked={formUser?.isexpenseapprover}  />

                    <span className='gray bold font-075 uppercase margin-left-half'>EXPENSE APPROVER</span>
                  </label>
                }

                {/* <div className="code">
                  <pre>
                    {JSON.stringify(formUser, null, 2)}
                  </pre>
                </div> */}

                
              </div>
             
            }
            {errors?.company && <div className="field-error">{errors?.company.message}</div>}
            </label>

            {/* <label htmlFor="isexpenseapprover"  className="margin-right-half margin-bottom-1"> 
            <div style={{marginBottom: '4px'}} className="gray bold font-075 uppercase"></div>

            <div className="flex-cont align-center">
              <label className="margin-right-half">
                <input type="checkbox" {...register("isexpenseapprover")} name='isexpenseapprover'  value={1} defaultChecked={formUser?.isexpenseapprover}  />
              </label>
            </div>

            {errors?.isexpenseapprover && <div className="field-error">{errors?.isexpenseapprover?.message}</div>}
            </label> */}
          </div>

          


          {/* <label htmlFor="enabled"  className="margin-right-half margin-bottom-1"> 
            <div style={{marginBottom: '4px'}} className="gray bold font-075 uppercase">ACCESS TO ALL COMPANIES?</div>

            <div className="flex-cont align-center">
              <label className="margin-right-half">
                <input type="radio" {...register("allcompanies")} name='allcompanies'  value={1} defaultChecked={formUser?.allcompanies}  />
                <span>Yes</span>
              </label>
              <br />
              <label>
                <input type="radio" {...register("allcompanies")} name="allcompanies" value={0} defaultChecked={!formUser?.allcompanies}   />
                <span>No</span>
              </label>
            </div>
            
            {errors?.allcompanies && <div className="field-error">{errors?.allcompanies?.message}</div>}
          </label> */}

            {/* <div className="dummy-select">
              <div>{formUser?.company}</div>
            </div> */}

          {/* {formUser? */}
          {/* <div>
            {formUser?.roleid}
          </div> */}


          {formUser?.roleid?.toString() === "2" && <>
            <div className="margin-top-1">
              <em>Adminstrators have access to all companies.</em>
            </div>
          </>}

          {/* formUser?.roleid?.toString() === "3" */}
          {formUser?.roleid?.toString() !== "2" && 
            <>

              {formUser?.user_company_accesses?.length !== 0 &&
                <div style={{marginTop: '1em', marginBottom: '4px'}} className="gray bold font-075 uppercase">OTHER COMPANIES</div>
              }

          {formUser?.user_company_accesses?.map((companyAccess, index) => {

            const hide = removedIndexes.includes(index);
            


            return (
              <div key={`company_row.${index}`} className={`${hide ? 'hidden' : '' } align-center flex-cont margin-bottom-half`}>
                <div>
                  
                  <label htmlFor={`user_company_accesses_attributes.${index}.company`}  className="flex-cont margin-right-1"> 

                      

                      <CompanyAccessFields 
                        viewOnly={viewOnly}
                        key={`user_company_accesses_attributes.${index}`}
                        name={`user_company_accesses_attributes.${index}`} 
                        formUser={formUser}
                        companiesLoading={companiesLoading} 
                        companies={remainingCompanies(index)} 
                        control={control} 
                        register={register} 
                        errors={errors}
                        setValue={setValue}
                        index={index}
                        companyAccess={companyAccess}
                      />

                  
                      
                      {!viewOnly &&
                        <>
                          <div onClick={() => removeExistingRowClicked(index)} className="ghost button margin-left-half">
                            <i className="fas fa-times"></i>
                          </div>
                        </>
                      }
                      
     
                    {/* {errors?.team_users_attributes?.[index]?.userid && <div className="field-error">{errors?.team_users_attributes?.[index]?.userid?.message}</div>} */}
                  </label>
                </div>
                <input defaultValue={companyAccess.id} {...register(`user_company_accesses_attributes.${index}.id`)} type="hidden" />
                <input {...register(`user_company_accesses_attributes.${index}._destroy`)} type="hidden" />
                {/* <div onClick={() => removeExistingRowClicked(index)} className="ghost button">
                  <i className="fas fa-times"></i>
                </div> */}
              </div>
            )
            })}

          {watchedAllCompanies?.toString() === "0" &&
            <>
              {rows && rows.map((row, i) => {
                  const index = row;
                  

                  return(
                    
                    <div key={`comapany_row.${index}`} className="flex-cont margin-bottom-half margin-right-half">

                        {/* <pre>
                          {`comapany_row.${index}`}
                        </pre> */}


                      

                      {/* <UserAutocomplete
                        setValue={setValue}
                        users={remainingUsers(index)} 
                        register={register} 
                        defaultValue={null} 
                        fullName={null}
                        errors={errors?.team_users_attributes?.[index]?.userid}
                        name={`user_company_accesses_attributes.${index}`} 
                      /> */}

                      <CompanyAccessFields 
                        viewOnly={viewOnly}
                        key={`user_company_accesses_attributes.${index}`}
                        name={`user_company_accesses_attributes.${index}`} 
                        formUser={formUser}
                        companiesLoading={companiesLoading} 
                        companies={remainingCompanies(index)} 
                        control={control} 
                        register={register} 
                        errors={errors}
                        index={index}
                        
                      />
                      {!viewOnly &&
                        <>    
                          <div onClick={() => removeRowClicked(row, index)} className="ghost button margin-left-half">
                            <i className="fas fa-times"></i>
                          </div>
                        </>
                      }
                    </div>
                    
                  );
                }
              )}
              {!viewOnly &&
                <>   
                  <div onClick={addRowClicked} className="font-075 ghost button margin-top-half"><i className="fas fa-plus margin-right-half"></i>Add Company</div>
                </>
              }
            </>
          }

          </>}
        </div>
      </div>

      <div className="modal-footer align-center flex-cont">
        
        <input type="submit" value="Save" className={`${loading ? 'disabled' : ''} button`} />

        {loading &&
          <div className="margin-left-1 spinner"></div>
        }

        <div className="flex-1"></div>
        {/* <div onClick={toggleDeleteModal} className="button danger">Delete</div> */}
      </div>

      {deleteModalOpened &&
        <Modal size="small" toggleModal={toggleDeleteModal} focusTrap={false}>
          <div className="modal-header flex-cont align-center">
            <h2 className="flex-1">Delete User</h2>
            <div onClick={toggleDeleteModal} className="ghost button close">
              <i className="fas fa-times"></i>
            </div>
          </div>

          <div className="padding-1">
            Are you sure you want to delete <span className="bold"> {formUser?.fname} {formUser?.lname}</span>?
          </div>

          <div className="modal-footer align-center flex-cont">
            <div onClick={toggleDeleteModal} className="ghost button margin-right-1">Cancel</div>
            <div onClick={tryDeleteUser} className="danger button">Delete</div>
          </div>
        </Modal>
      }

    </form>


  )
}






function useInterval(callback, timeout, search) {
  const timeoutId = useRef();

  // Remember the latest callback.
  useEffect(() => {
    timeoutId.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {

    clearTimeout(timeoutId.current);
    timeoutId.current = setTimeout(callback, timeout);

    return () => clearTimeout(timeoutId.current);

    // 
    // function tick() {
    //   savedCallback.current();
    // }
    // if (search !== null) {
    //   let id = setTimeout(tick, delay);
    //   return () => clearTimeout(id);
    // }
  }, [search]);
}



const AdminUsers = ({viewAsUser, currentCompany}) => {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState(null);
  const [modalOpened, setModalOpened] = useState(false);
  const [editingUser, setEditingUser] = useState(null);
  
  const [onlyInCompanyChecked, setOnlyInCompanyChecked] = useState(true);

  const [result, loading, loaded, error, doFetch, setResult, pagy, apiRef] = useApi();
  
  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = () => {
    doFetch(getUsers, {query: {all_companies: onlyInCompanyChecked ? null : true, search: search, page: page}});
  }

  useEffect(() => {
    fetchUsers();
    setEditingUser(null);
    setModalOpened(false);
  }, [onlyInCompanyChecked, page, currentCompany]);

  useEffect(() => {
    setOnlyInCompanyChecked(true);
  }, [currentCompany]);



  const toggleModal = (reload = false) => {
    // alert('here');
    setModalOpened(old => !old);

    if (reload) {
      fetchUsers();
    }
  }

  const editClicked = user => {
    setEditingUser(user);
    toggleModal(false);
  }

  useEffect(() => {
    if (!modalOpened) {
      setEditingUser(null);
    }
  }, [modalOpened])


  const [count, setCount] = useState(0);



  const handlePageChange = (p) => {
    setPage(p);
  };

  const searchInputChanged = e => {
    const value = e.target.value;
    setSearch(value);
  }

  const [delay, setDelay] = useState(null)

  useInterval(() => {  
    doFetch(getUsers, {query: {all_companies: onlyInCompanyChecked ? null : true, search: search, page: 1}});
    
    
    setCount(o => o+1);
  }, 500  , search);

  // useEffect(() => {
  //   setDelay(3000);
  // }, [search])

  




  return (
    <>
      {modalOpened &&
        <Modal toggleModal={toggleModal}>
          <UserForm viewAsUser={viewAsUser} formUser={editingUser} toggleModal={toggleModal}/>
        </Modal>
      }

        <div className="flex-cont">
          <h1 className="flex-cont">
            <div className="medgray margin-right-half">{currentCompany}</div>
              Users
          </h1>
        </div>

        {/* <div className="code">
            <pre>
              {JSON.stringify(pagy)}
            </pre>
          </div>   */}
          
          {/* <div className="code">
            {count}
          </div> */}
          {/* <div className="code">
            {count}
          </div>

          <div className="code">
            <pre>
              {JSON.stringify(pagy)}
            </pre>
          </div> */}

          {/* <SubNav count={result?.new_count} /> */}

          {/* <div className="button" onClick={() => toggleModal(false)}>
            <i className="fas fa-plus margin-right-half"></i>
            <span>Add User</span>  
          </div> */}
{/* 
          <div>
            {JSON.stringify(onlyInCompanyChecked, null, 2)}
          </div> */}

          <div className="seearch-cont margin-bottom-1 flex-cont align-center">

            {viewAsUser?.roleid?.toString() === "2" &&
              <div className="margin-right-1 flex-cont align center">
                <input checked={onlyInCompanyChecked} onChange={e => setOnlyInCompanyChecked(e.target.checked)}  type="checkbox" name="" id="" className="margin-right-half" />
                <div className="bold gray uppercase font-075 nowrap">Only in {currentCompany}</div>
              </div>
            }
            <input onChange={e => searchInputChanged(e)} placeholder="Search users..." type="text" name="" id=""/>
          </div>
          <Pagination currentPage={page} pagy={pagy} handlePageChangeFn={handlePageChange} />

          

          <div className="margin-top-2">

            {loading &&
              <div className="margin-left-1 spinner"></div>
            }

           


            {!loading &&
              <table className="basic">
                <tbody>
                <tr>
                  <th className="left-text ">First</th>
                  <th>Last</th>
                  
                  
                  <th>Email</th>

                  <th>D365 Employee #</th>
                  <th>Company</th>
                  <th className="center-text">Expense</th>
                  <th className="center-text">Enabled</th>
                  <th>Role</th>
                  <th></th>
                  {/* <th></th> */}
                </tr>
  
                {result?.users && result?.users?.map(user => {
                  return (
                    <tr key={user.workerrecid}>
                      <td className="left-text  fit">{user.fname}</td>
                      <td>{user.lname}</td>
                     
                      
                      <td>{user.email}</td>
                      <td>{user.personnelnumber}</td>
                      <td>{user.company}</td>
                      <td className="center-text">{user.iscurrentcompanyexpenseapprover ? "Yes" : "No"}</td>
                      <td className="center-text">{user.enabled ? "Yes" : "No"}</td>
                      <td className="uppercase bold font-075">{user.rolename}</td>
                      
                      {/* <td>
                        <pre>
                          {JSON.stringify(user.employee, null, 2)}
                        </pre>
                      </td> */}

                      
                      <td>
                        <i className="ghost button fas fa-pencil-alt" onClick={() => editClicked(user)}></i>
                      </td>
                    </tr>
                  )
                })}
                </tbody>
              </table>
            }
          </div>


          <div className="margin-top-2">
            <Pagination  currentPage={page} pagy={pagy} handlePageChangeFn={handlePageChange} />
          </div>



      
    </>
  );
}

function mapState(state) {
  return { 
    // tokenResponse: state.authState.tokenResponse,
    currentCompany: state.authState.currentCompany,
    viewAsUser: state.authState.viewAsUser,
  };
} 

export default connect(mapState)(AdminUsers);
