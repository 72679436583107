
const initialPeriodState = {
  isLoading: false,
  error: null,
  data: null,
  pagy: null,
};

const periodReducer = ( state = initialPeriodState, action) => {
  switch(action.type) {
    case "CLEAR_PERIODS":
      return initialPeriodState
    case "GET_PERIODS_BEGIN":
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case "GET_PERIODS_SUCCESS":
      return {
        ...state,
        data: action.data,
        isLoading: false
      };
    case "GET_PERIODS_ERROR":
      return {
        ...state,
        isLoading: false,
        error: action.error
      };
    case "GET_PERIODS_PAGY":
      return {
        ...state,
        pagy: action.pagy
      };
    default :
      return state
  }
}

export default periodReducer;