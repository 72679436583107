import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link, NavLink, Outlet, useLocation
} from 'react-router-dom';
import { connect } from 'react-redux';


const isActive = ({ isCurrent }) => {
  return isCurrent ? { className: "active" } : null
}



const TimesheetsNav = ({viewAsUser, children, submittedCount}) => {

  const total = submittedCount?.total;


  return(
    <ul className="sub-nav">
      {/* <li>
        <div className="code">
          <pre>
          {JSON.stringify(viewAsUser, null, 2)}
          </pre>
        </div>
        
      </li> */}
      <li>
        <NavLink end to="/timesheets">My Timesheets</NavLink>
      </li>
      {/* <li>
        <NavLink end to="/timesheets/list/delegated">Delegated</NavLink>
      </li> */}
      {viewAsUser && (viewAsUser.roleid === 6) && <>
        <li>
          <NavLink end to="/timesheets/list/created-by-me">Created for Others</NavLink>
        </li>
        <li>
          <NavLink end to="/timesheets/list/all">All Timesheets</NavLink>
        </li>
      </>}
      {viewAsUser && (viewAsUser.roleid === 6 || viewAsUser.roleid === 4 || viewAsUser.roleid === 1 || viewAsUser.roleid === 8 || viewAsUser.roleid === 9 || viewAsUser.roleid === 2 || viewAsUser.roleid === 3) &&
        <>
        <li>
          <NavLink to="/timesheets/review">

            <div className="flex-cont align-center">
              <div className="flex-cont align-center justify-center unread-count">
                <div className="number">
                  {(total === 0 || total) ? total : "-"}
                </div>
              </div>
              <div>Review</div>
            </div>
          
            

            {/* <div className="pulser stopped"></div> */}
          </NavLink>
        </li>
        <li>
          <NavLink end to="/timesheets/approved">

            <div className="flex-cont align-center">
              <div>My Approved</div>
            </div>
          
            

            {/* <div className="pulser stopped"></div> */}
          </NavLink>


        </li>
        </>
      }
      {viewAsUser && (viewAsUser.roleid === 4 || viewAsUser.roleid === 2 || viewAsUser.roleid === 6) &&
        <>
          <li>
            <NavLink end to="/timesheets/approved/all">
              <div className="flex-cont align-center">
                <div>All Approved</div>
              </div>
            </NavLink>
          </li>
        </>
      }
      {viewAsUser && (viewAsUser.roleid === 2 || viewAsUser.roleid === 3 || viewAsUser.roleid === 6) &&
        <>
          <li>
            <NavLink to="/timesheets/report">
              <div className="flex-cont align-center">
                <div>Timesheet Reports</div>
              </div>
            </NavLink>
          </li>
        </>
      }
      {viewAsUser && (viewAsUser.roleid === 2) &&
        <>
          {/* <li>
            <NavLink to="/timesheets/posting-logs">
              <div className="flex-cont align-center">
                <div>Posting Logs</div>
              </div>
            </NavLink>
          </li>

          <li>
            <NavLink to="/timesheets/unposted">
              <div className="flex-cont align-center">
                <div>Unposted (temporary tool)</div>
              </div>
            </NavLink>
          </li> */}
        </>
      }
    </ul>
  );
}


function mapStateToProps(state) {
  return { 
    submittedCount: state.timesheetState.submittedCount,
    viewAsUser: state.authState.viewAsUser,
  };
} 

export default connect(mapStateToProps)(TimesheetsNav);
