import React, { useEffect, useState, useRef } from 'react';
import { DateTime, Info } from 'luxon';

import { getRevisions } from '../../redux/manager/budgetHeaders/budgetHeaderActions';
import { useApi } from '../../api';
import { connect } from 'react-redux';

import {
  Link
} from 'react-router-dom';


const BudgetRevisions = ({projectid, toggleModal, budgetHeader}) => {
  const [revisions, revisionsLoading, revisionsLoaded, revisionsError, doRevisionFetch, setRevisionResult] = useApi();

  useEffect(() => {
    if (budgetHeader) {
      fetchRevisions();
    }
  }, [budgetHeader]);

  const fetchRevisions = () => {
    const query = {
      project_id: projectid,
      id: budgetHeader.id
    }
    doRevisionFetch(getRevisions, {query: query})
  }

  return (
    <>
      <div className="modal-header flex-cont align-center">
        <h2 className="flex-1">Revision History</h2>
        <div onClick={toggleModal} className="ghost button close">
          <i className="fas fa-times"></i>
        </div>
      </div>
      
      

      <div className="padding-1">

        {revisionsLoading && <div className="spinner"></div>}

        {!revisionsLoading &&
          <>
            <Link onClick={toggleModal} className="button margin-bottom-1" to={`/budgeting/projects/${projectid}`}>
              Current Budget
            </Link>

            <table className="basic">
              {!revisionsLoading && revisions && revisions.map((revision, index) => {
                return(
                  <tr>
                    <td className="fit nowrap">
                      <Link className="text-link" onClick={toggleModal} to={`/budgeting/projects/${projectid}/revision/${revision.id}`}>Revision #{index+1}</Link>
                    </td>
                    <td>
                      {revision.approveddate &&
                        <div>{DateTime.fromISO(revision.approveddate).toLocaleString()}</div>
                      }
                    </td>
                  </tr>
                )
              })}

            </table>
          </>
        }

        
      </div>

      <div className="modal-footer align-center flex-cont">
      </div>
    </>
  );
}

export default BudgetRevisions;