const initialState = {
  data: [],
  pagy: null,
};

const entryReducer = ( state = initialState, action) => {
  switch(action.type) {
    case "CLEAR_ENTRIES":
      return initialState
    case "SET_ENTRIES":
      return {
        ...state,
        data: action.entries
      }
    case "ADD_ENTRY":
      return {
        ...state,
        data: [...state.data, ...action.entries]
      }
    case "REMOVE_ENTRY":
      return {
        ...state,
        data: [...state.data.filter(e => e.id.toString() !== action.entryId.toString())]
      }
    case "CHANGE_ENTRY":
      return {
        ...state,
        data: state.data.map(entry => entry.id === action.entry.id ? {...action.entry} : entry)
      }
    case "DESTROY_ENTRY":

      return {
        ...state,
        // data: state.data.map(entry => entry.id === action.entryId ? {...entry, _destroy: "1" } : entry)
        data: state.data.map(entry => {

          if (entry.id?.toString() === action.entryId.toString()) {
            // alert("FOUND");
            return {...entry, _destroy: "1"}
          }
          else {
            // alert("NOT FOUND");
            return entry
          }
        })
        
        
        // state.data.map(entry => {

        //   if (entry.id?.toString() === action.entryId?.toString()) {
        //     alert("found");
        //     return {found: true}
        //   }
        //   alert("not found");
        //   // alert(JSON.stringify(entry.id?.toString() === action.entryId.toString(), null, 2));

        //   return entry
        // })
        
        
        
        // (entry => entry.id?.toString() === action.entryId.toString() ? {_destroy: "1"} : {_destroy: "HELP"})
      }
    default :
      return state
  }
}

export default entryReducer;