import React, { useEffect, useState } from 'react';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link, Outlet, useLocation
} from 'react-router-dom';

import * as yup from "yup";
import { useForm } from 'react-hook-form';

import * as msal from "@azure/msal-browser";
import store from '../../redux/store';
import { connect } from 'react-redux';
import { setTokenResponse } from '../../redux/authActions';

import Modal from '../../components/Modal';
import { useApi } from '../../api';
import { createUser, updateUser, getUsers } from '../../redux/admin/userActions';
import { getCompanies } from '../../redux/admin/companyActions';
import { getRoles} from '../../redux/admin/roleActions';


import AdminLogsNav from './AdminLogsNav';


const AdminLogsLayout = ({children}) => {

  return (
    <>
      <AdminLogsNav />
      {children}
    </>
  )
}

export default AdminLogsLayout;