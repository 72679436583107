import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { EmailClient, KnownEmailSendStatus } from '@azure/communication-email'
import { getSupportTicket, createSupportTicket, updateSupportTicket } from './redux/supportTickets/supportTicketActions';
import { useApi } from './api';
import { DateTime } from 'luxon';

const toHTML = currentSupportTicket => {
  if (!currentSupportTicket) return "";

  return(`
    <a href="${process.env.REACT_APP_FRONTEND_URL}/support-tickets/${currentSupportTicket.id}">${process.env.REACT_APP_FRONTEND_URL}/support-tickets/${currentSupportTicket.id}</a>
    <br />
    <br />
    <table className="margin-top-2 vertical font-1-25">
      <tr>
        <th style="text-align: right;">ID</th>
        <td>#${currentSupportTicket.id}</td>
      </tr>
      <tr>
        <th style="text-align: right;">Submitted by</th>
        <td>
          ${currentSupportTicket.submittingfname} ${currentSupportTicket.submittinglname} <span className="gray">${currentSupportTicket.submittingemail}</span>
          ${currentSupportTicket.has_acting_as ? (`
              <div className="font-09">
                acting as ${currentSupportTicket.actingasfname} ${currentSupportTicket.actingaslname}
              </div>
            `) : ""
          }
        
        </td>
      </tr>
      <tr>
        <th  style="text-align: right;">Date</th>
        <td>${currentSupportTicket.createdat ? DateTime.fromISO(currentSupportTicket.createdat).toLocaleString(DateTime.DATETIME_SHORT) : '-'}</td>
      </tr>
      <tr>
        <th style="text-align: right;">Company</th>
        <td>${currentSupportTicket.company}</td>
      </tr>
      <tr>
        <th style="text-align: right;">Submitted from</th>
        <td>${currentSupportTicket.screen}</td>
      </tr>
      <tr>
        <th style="text-align: right;">Code</th>
        <td>${currentSupportTicket.code}</td>
      </tr>
      <tr>
        <th style="text-align: right;">Description</th>
        <td>${currentSupportTicket.description}</td>
      </tr>
      
      
    </table>
  `)
}


const SupportButton = ({viewAsUser, currentUser}) => {

  const location = useLocation();
  const navigate = useNavigate();

  const [opened, setOpened] = useState(false);
  const [description, setDescription] = useState("");

  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);


  const [supportTicketResult, supportTicketLoading, supportTicketLoaded, supportTicketError, doSupportTicketFetch, setSupportTicketResult, pagy, apiRef, resetApi] = useApi();

  const tryCreateSupportTicket = () => {

    const payload = {
      support_ticket: {
        description: description,
        screen: location?.pathname
      }
      
    }

    doSupportTicketFetch(createSupportTicket, {payload: payload})
  }

  const submitButtonClicked = async () => {

    if (!description || description.length === 0) {
      setError("Issue description cannot be blank.");
      return;
    }

    if (description && description.length > 1000) {
      setError("Issue description cannot contain more than 1000 character.");
      return;
    }

    setError(null);
    setLoading(true);

    tryCreateSupportTicket();



    

    // let poller;

    // try {
    //   poller = await client.beginSend(emailMessage);
    // } catch (error) {
    //   setError("An error occurred. Please try again.");
    //   // alert("An error occurred. Please try again.")
    // }

    // console.log('poller', poller)

    // let result;

    // try {
    //   result = await poller.pollUntilDone();
    // } catch (error) {
    //   // alert("An error occurred. Please try again.")
    //   setError("An error occurred. Please try again.");
    // }


    // console.log('email result', result)
    // setError(null);
    // setLoading(false);

    // const content = "/vsK9rhTNvy1nkNE58uZJaMX7nnsvfmaw65tvRTKYCHV3IkgcK/sBYfw63cWYwlVrjMGNtk9NVY2uBbIqGbH/g=="

    // const myHeaders = new Headers({
    //   "Content-Type": "text/plain",
    //   "Content-Length": content.length.toString(),
    //   "Authorization": "ProcessThisImmediately",
    // });

    // const data = {
    //   "senderAddress": "supprt@hba"
    // }

    // const response = await fetch("https://kronoscommunicationservice.unitedstates.communication.azure.com/emails:send?api-version=2023-03-31", {
    //   method: "POST",
    //   headers: {
    //     "Authorization": content,
    //   },
    //   body: {

    //   }
    // });
    // const movies = await response.json();
    // console.log(movies);
    // setLoaded(true);
  }

  useEffect(() => {

    const trySendEmail = async (supportTicket) => {
      const connectionString = "endpoint=https://kronoscommunicationservice.unitedstates.communication.azure.com/;accesskey=/vsK9rhTNvy1nkNE58uZJaMX7nnsvfmaw65tvRTKYCHV3IkgcK/sBYfw63cWYwlVrjMGNtk9NVY2uBbIqGbH/g==";
  
      const client = new EmailClient(connectionString);
  
      const emailMessage = {
          senderAddress: "DoNotReply-KronosSupportTicket@873963c4-576a-45ca-be3d-72bb48dd1f40.azurecomm.net",
          content: {
              subject: `Ticket #${supportTicket.id} - ${supportTicket.submittingfname} ${supportTicket.submittinglname} has submitted a support ticket`,
              plainText: `${description}`,
              html: toHTML(supportTicket)
          },
          recipients: {
              to: [
                { address: "kronos.support@axseva.com" },
              ],
              bcc: [
                { address: "asimon@axseva.com" },
              ]
          },
      };
      
  
      let poller;
  
      try {
        poller = await client.beginSend(emailMessage);
      } catch (error) {
        setError("An error occurred. Please try again.");
        // alert("An error occurred. Please try again.")
      }
  
      console.log('poller', poller)
  
      let result;
  
      try {
        result = await poller.pollUntilDone();
      } catch (error) {
        // alert("An error occurred. Please try again.")
        setError("An error occurred. Please try again.");
      }
  
  
      console.log('email result', result)
      setError(null);
      setLoading(false);
      setLoaded(true);
  
    }
  
    


    if (supportTicketLoaded && supportTicketResult) {
      // alert(JSON.stringify(supportTicketResult, null, 2))
      trySendEmail(supportTicketResult);
    }

  }, [supportTicketLoaded, supportTicketResult]);


  const shouldDisable = supportTicketLoading === true;
  
    const doneClicked = () => {
      setOpened(false);
      setDescription("");
      setLoaded(false);
      setLoading(false);
      setError(null);
  
      resetApi();
    }





  return (
    <div className='support-cont'>

      {opened &&
        <>
          <div onClick={() => setOpened(o => !o)} className="support-bg"></div>
          <div className="support-dialog">
            <div className="bold font-1-5 margin-bottom-1">Kronos Support</div>

            <div className="margin-bottom-2">
              <div>
                {currentUser?.fname} {currentUser?.lname} <span className="gray">{currentUser.email}</span>
              </div>
              {viewAsUser !== currentUser &&
                <div className='font-075'>
                  Acting as {viewAsUser?.fname} {viewAsUser?.lname} 
                </div>
              }
            </div>

            {/* {loading &&
              <div className="spinner"></div>
            } */}

            {supportTicketLoaded && 
              <>

                <div className="margin-bottom-1">
                  <i className="fas fa-check-circle font-3 gray"></i>
                </div>
                <div className="grayx">
                  This issue has been sent to the support team. They will contact with you shortly. 
                </div>

                <div className="margin-top-2">
                  <button onClick={doneClicked} className="full">Done</button>
                </div>
              </>
            }
            {!supportTicketLoaded && 
              <>
               <div className="gray font-08 margin-bottom-1">
                  Please describe the issue including relevant details like project and timesheet IDs:
                </div>

                <textarea 
                  disabled={shouldDisable} 
                  onChange={e => setDescription(e.target.value)} 
                  placeholder='Issue description' 
                  name="" 
                  id="" 
                >



                </textarea>


                {error &&
                  <div className="error-cont margin-top-1">
                    {error}
                  </div>
                }
                

                <button onClick={() => submitButtonClicked()} className={`${shouldDisable ? 'disabled' : ''} margin-top-1 button full`}>
                  Submit
                  {supportTicketLoading &&
                    <div className="spinner button-spinner"></div>
                  }
                </button>
              </>
            }
           



            
          </div>
        </>
        
      }


      <div onClick={() => setOpened(o => !o)} className='support-button'>
        <i className={`fas fa-${opened ? 'times' : 'question-circle'}`}></i>
      </div>

      
    </div>
  )

}

function mapState(state) {
  return { 
    viewAsUser: state.authState.viewAsUser,
    currentUser: state.authState.currentUser,
  };
} 

export default connect(mapState)(SupportButton);