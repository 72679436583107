import React, { useState, useEffect } from 'react';
import { useApi } from '../../api';
import { getInterProjects } from '../../redux/manager/budgetHeaders/budgetHeaderActions';

const InterProjects = ({projectid}) => {

  const [projects, projectsLoading, projectsLoaded, projectsError, doProjectsFetch, setProjectsResult] = useApi();

  useEffect(() => {

    // alert(JSON.stringify(projectid, null, 2))

    if (projectid) {
      doProjectsFetch(getInterProjects, {query: {projectid: projectid}})
    }
    
  }, [projectid]);

  return (
    <div className="sectionx">

      {projectsLoading && <div className="spinner"></div>}


      {projects &&
        <table className="basic budget-worksheetx">
          <tr>
            <th>CO</th>
            <th>Project</th>
            <th>Pending Hours</th>
            <th>Posted Hours</th>
            <th>Total Hours</th>
            <th></th>
          </tr>

          {projects.map(project => 
            <tr>
              <td className="fit">
                {project.coy}
              </td>
              <td className="nowrap fit">
                <span className="gray bold">{project.projectid.toString().toUpperCase()} - </span>
                <span className="boldx">{project.projectname}</span>
              </td>
              <td className="fit nowrap">{project.pending * 1}</td>
              <td className="fit nowrap">{project.posted * 1}</td>
              <td className="fit nowrap">{(parseFloat(project.posted) + parseFloat(project.pending))*1}</td>
              <td></td>
              
            </tr>
          
          )

          }
        </table>
      }
    </div>
  )
}

export default InterProjects;