import React from 'react';

const TH = ({classNames=[], column, sortedColumn, label, columnClicked, direction, columnToSort, sortAssociation}) => {
  return (
    <th  
      className={`th-clicker ${sortedColumn === column ? "current" : ""} ${classNames.join(' ')}`}
      onClick={() => columnClicked(column, sortAssociation)}
    >
      {label}

      {sortedColumn === column &&
        <>
          {direction === -1 && <i className="margin-left-half fas fa-arrow-up"></i>}
          {direction === 1 && <i className="margin-left-half fas fa-arrow-down"></i>}
        </>
      }
      
    </th>
  )
}

export default TH;