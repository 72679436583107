import React from 'react';
import { useWatch } from 'react-hook-form';

const TimesheetWeekTotal = ({control, weekIndex}) => {

  const weekWatch = useWatch({
    control,
    name: `week_summary_hours.${weekIndex}`,
    defaultValue: 0
  });



  return (
    <div className="flex-cont  alpha-entry-row total">
      <div className="full alpha-entry-cell"></div>

      <div  className="alpha-entry-cell">
        <div className="bold editable-cont entry-total-hours">
          WEEK TOTAL:
        </div>
      </div>
      <div  className="alpha-entry-cell">
        <div style={{marginRight: '8px'}}  className="bold editable-cont entry-total-hours">
          {parseFloat(weekWatch)?.toLocaleString()}
        </div>
      </div>

        
  
    </div>
  )
}

export default TimesheetWeekTotal;
