import React, { useLayoutEffect, useEffect, useState, useRef } from 'react';

import { useForm } from 'react-hook-form';

import Modal from '../../../components/Modal';
import { useApi } from '../../../api';
import { getDelegateUsers } from '../../../redux/manager/users/userActions';
import { deleteProjectDelegate, createProjectDelegate, updateProjectDelegate, getMyManagedProjects } from '../../../redux/manager/projectDelegates/projectDelegateActions';

import { UserAutocomplete } from '../../admin/AdminTeamsForm'; 

import AutoComplete from '../../../components/AutoComplete';


const Row = ({item}) => {

  return (
    <div className="flex-cont">
      <div className='flex-1'>{item.projectid} - {item.projectname}</div>

    </div>
  )
}



const ProjectDelegateForm = ({setReload, toggleModal, formProjectDelegate}) => {

  const { trigger, reset, watch, register, formState: { errors }, handleSubmit, getValues, setValue } = useForm({
    // resolver: yupResolver(TeamSchema),
  });

  const [result, loading, loaded, error, doFetch, setResult] = useApi();
  const [users, usersLoading, usersLoaded, usersError, doUsersFetch, setUsersResult] = useApi();
  const [projects, projectsLoading, projectsLoaded, projectsError, doProjectsFetch, setProjectsResult] = useApi();
  const [deleteResult, deleteLoading, deleteLoaded, deleteError, doDeleteFetch, setDeleteResult] = useApi();


  const fetchUsers = () => {
    doUsersFetch(getDelegateUsers, {query: {no_pagy: true}});
  }

  const fetchProjects = () => {
    doProjectsFetch(getMyManagedProjects);
  }

  useEffect(() => {
    // fetchManagerUsers();
    fetchUsers();
    fetchProjects();

    // if (formProjectDelegate) {

    // }
  }, []);

  useEffect(() => {
    if (loaded) {
      setReload(o => o+1);
      toggleModal();
    }
  }, [loaded]);

  
  useEffect(() => {
    if (deleteLoaded) {
      toggleDeleteModal();
      setReload(o => o+1);
      toggleModal();
    }
  }, [deleteLoaded]);


  const onSubmit = async data => {
    const t = await trigger();

    if (!t) return;


    const formValues = getValues();

    
    let d = {
      id: formProjectDelegate?.id,
      project_delegate: {
        ...formValues,
      }
    }


    if (formProjectDelegate && formProjectDelegate.id) {
      doFetch(updateProjectDelegate, {payload: d});
    }
    else {
      doFetch(createProjectDelegate, {payload: d});
    }


  }

  const checkKeyDown = (e) => { 
    const keyCode = e.keyCode ? e.keyCode : e.which; 

    if (keyCode === 13) { 
      e.preventDefault() 
    }; 
  };

  const renderRow = item => <Row item={item} />
  const [deleteModalOpened, setDeleteModalOpened] = useState(false);

  const toggleDeleteModal = () => {
    setDeleteModalOpened(o => !o);
  }
  
  const tryDelete = () => {
    if (formProjectDelegate) {
      const payload = {
        project_delegate: {
          id: formProjectDelegate.id
        }
      }
      doDeleteFetch(deleteProjectDelegate, {payload: payload});
    }
  }



  const somethingLoading = deleteLoading || usersLoading || projectsLoading || loading;

  const isAssignedProjects = !projectsLoading && projects?.length !== 0;

  return (
    <Modal allowOverflow={true} focusTrap={false} size="medium" toggleModal={toggleModal}>

      <div className='modal-header flex-cont align-center'>
        <h1 className="flex-1">{formProjectDelegate ? 'Edit' : 'Add'} Project Delegate</h1>
        <div onClick={toggleModal} className="button close ghost">
          <i className="fas fa-times"></i>
        </div>
      </div>

      <div className="padding-1" style={{marginBottom: '0px'}}>

        {/* <pre>
          {JSON.stringify(formProjectDelegate, null, 2)}
        </pre> */}

        {!usersLoading && !projectsLoading && !isAssignedProjects && !formProjectDelegate &&
          <em>You are not assigned any projects. Please try again later.</em>
        }
        {(usersLoading || projectsLoading) && <div className="spinner"></div> }

        {((isAssignedProjects && !formProjectDelegate) || (formProjectDelegate)) && <>
          

        
        {!usersLoading && !projectsLoading && 
          <form onKeyDown={(e) => checkKeyDown(e)} onSubmit={handleSubmit(onSubmit)}>
            {/* <input {...register("id")} type="hidden" name="id" value={formProjectDelegate?.id} /> */}
            

            <label htmlFor="delegateworkerrecid"  className="margin-right-1 margin-bottom-2"> 
              <div style={{marginBottom: '4px'}} className="gray bold font-075 uppercase">DELEGATE</div>
              {usersLoading &&
                <div className="editable-cont loading">
                  <div className="absolute spinner"></div>
                </div>
              }
              {!usersLoading && users &&

                <UserAutocomplete 
                  fixedPosition={true}
                  setValue={setValue}
                  users={users} 
                  register={register} 
                  fullName={formProjectDelegate?.delegate_user}
                  defaultValue={formProjectDelegate?.delegateworkerrecid || ""} 
                  errors={errors?.delegateworkerrecid}
                  name={`delegateworkerrecid`} 
                />
              }
              
              {errors.delegateworkerrecid && <div className="field-error">{errors.delegateworkerrecid.message}</div>}
            </label>

            {!projectsLoading && projects &&
            
              <AutoComplete 
                setValue={setValue}
                defaultValue={formProjectDelegate?.projectid}
                defaultSearch={formProjectDelegate ? `${formProjectDelegate.projectid} - ${formProjectDelegate.projectname}` : ""}
                name={"projectid"}
                items={projects}
                fullName={"FULL NAME"}
                register={register}
                errors={errors?.projectid}
                label="Project"
                labelFn={(item) => `${item.projectid} - ${item.projectname}`}
                labelComponent={renderRow}
                valueFn={(item) => item.projectid }
                searchableAttributes={["projectname", "projectid"]}
              />
            }

            {errors.projectid && <div className="field-error">{errors.projectid.message}</div>}

            {/* <pre>
              {JSON.stringify(projects, null, 2)}
            </pre> */}

            {deleteModalOpened &&
              <Modal size="small" toggleModal={toggleDeleteModal} focusTrap={false}>
                <div className="modal-header flex-cont align-center">
                  <h2 className="flex-1">Remove Delegate</h2>
                  <div onClick={toggleDeleteModal} className="ghost button close">
                    <i className="fas fa-times"></i>
                  </div>
                </div>

                <div className="padding-1">
                  Are you sure you want to remove this delegate?
                </div>

                <div className="modal-footer align-center flex-cont">
                  <div onClick={toggleDeleteModal} className="ghost button margin-right-1">Cancel</div>
                  <div onClick={tryDelete} className={`${somethingLoading ? 'disabled' : ''} danger button`}>
                    {deleteLoading && <div className="spinner button-spinner"></div> }
                    Remove
                  </div>
                </div>
              </Modal>
            }

          </form>
        }
        </>}
      </div>

      <div className="modal-footer">
        <div className="flex-cont align-center">
          <div onClick={toggleModal} className="margin-right-1 ghost button">Cancel</div>
          {formProjectDelegate &&
            <div onClick={toggleDeleteModal} className={`button danger ${somethingLoading ? 'disabled' : ''}`}>
              Remove
            </div>
          }
          <div className="flex-1"></div>
          {/* <div onClick={onSubmit} className="button">Save</div> */}
          {formProjectDelegate && 
            <div onClick={onSubmit} className={`button ${somethingLoading ? 'disabled' : ''}`}>
              Save
              {loading &&
                <div className="spinner button-spinner"></div>
              }
            </div>
          }

          {!formProjectDelegate && !somethingLoading && isAssignedProjects &&
            <div onClick={onSubmit} className={`button ${somethingLoading ? 'disabled' : ''}`}>
              Save
              {loading &&
                <div className="spinner button-spinner"></div>
              }
            </div>
          }
        </div>
      </div>


      

    </Modal>
  )
}

export default ProjectDelegateForm;