import React, { useState, useEffect } from 'react';
import { useApi } from '../../api';
import { DateTime } from 'luxon';
import { connect } from 'react-redux';

import { submitOneUnposted, submitAllUnposted,  getTimesheetUnposted } from '../../redux/timesheets/timesheetActions';

import Modal from '../../components/Modal';

const TimesheetUnposted = ({currentCompany}) => {

  // const [tab, setTab] = useState("failed");

  const [unposteds, loading, loaded, error, doFetch, setResult] = useApi();
  const [submitAllResult, submitAllLoading, submitAllLoaded, submitAllError, doSubmitAllFetch, setSubmitAllResult] = useApi();
  const [submitOneResult, submitOneLoading, submitOneLoaded, submitOneError, doSubmitOneFetch, setSubmitOneResult] = useApi();


  const [submitAllModalOpened, setSubmitAllModalOpened] = useState(false);
  const [submitOneModalOpened, setSubmitOneModalOpened] = useState(false);

  const [selectedTimesheetid, setSelectedTimesheetid] = useState(null);
  
  useEffect(() => {
    fetchUnposteds();
  }, [])

  const fetchUnposteds = () => {
    doFetch(getTimesheetUnposted)
  }

  const submitAllClicked = () => {
    setSubmitAllModalOpened(true);

    doSubmitAllFetch(submitAllUnposted);
  }

  const submitOneClicked = (timesheetid) => {
    setSelectedTimesheetid(timesheetid);
    setSubmitOneModalOpened(true);
    // doSubmitOneFetch(submitOneUnposted);
  }

  const toggleSubmitAllModal = () => {
    setSubmitAllModalOpened(o => !o)
  }
  
  const dismissModal = () => {
    setSubmitAllModalOpened(false);
    fetchUnposteds();
    
  }

  const dismissOneModal = () => {
    setSubmitOneModalOpened(false);
    fetchUnposteds();
    
  }
  


  return(
    <>
      {submitAllModalOpened &&
        <Modal focusTrap={false} toggleModal={dismissModal} size="small">
          <div className="modal-header flex-cont align-center">
            <h2 className="flex-1">Post All with status=0</h2>
            <div onClick={dismissModal} className="ghost button close">
              <i className="fas fa-times"></i>
            </div>
          </div>

          <div className="padding-1">
            Timesheets are being posted in the background. Refresh the list to see the progress.
          </div>

          <div className="modal-footer align-center flex-cont">
            <div onClick={dismissModal} className="button margin-right-1">Refresh</div>
          </div>
        </Modal>
      }

      {submitOneModalOpened &&
        <Modal focusTrap={false} toggleModal={dismissOneModal} size="small">
          <div className="modal-header flex-cont align-center">
            <h2 className="flex-1">Post {selectedTimesheetid}</h2>
            <div onClick={dismissOneModal} className="ghost button close">
              <i className="fas fa-times"></i>
            </div>
          </div>

          <div className="padding-1">
            Timesheet {selectedTimesheetid} is being posted in the background. Refresh the list to see the progress.
          </div>

          <div className="modal-footer align-center flex-cont">
            <div onClick={dismissOneModal} className="button margin-right-1">Refresh</div>
          </div>
        </Modal>
      }

      <div className="flex-cont align-center">
        <h1 className="flex-cont">
          <div className="margin-right-half medgray">{currentCompany}</div>
            Unposted Timesheets
        </h1>
      </div> 

      <div onClick={submitAllClicked} className="button">
        Post All With Status=0
      </div>

      <div className="margin-top-1 margin-bottom-1">
        {loading && <div className="spinner"></div> }
      </div>

      {loaded && (!unposteds || unposteds.length === 0) && <em>Nothing found.</em> }

      {unposteds &&
        <table className="basic">
          <tr>
            <th>Timesheet ID</th>
            <th>Worker Rec ID</th>
            <th>Status</th>
            <th>Response</th>
            <th></th>
          </tr>
          {unposteds?.map(unposted => 
            <tr>
              <td className="fit nowrap">{unposted.timesheetid}</td>
              <td className="fit nowrap">{unposted.workerrecid}</td>
              <td className="fit nowrap">{unposted.status}</td>
              <td>{unposted.response}</td>
              <td  className="fit nowrap">

                {unposted.status === 0 &&
                  <div onClick={() => submitOneClicked(unposted.timesheetid)} className="button">
                    Post
                  </div>
                }
              </td>
            </tr>  
          )}
        </table>
      }
    
      

      <pre>
        {JSON.stringify(unposteds, null, 2)}
      </pre>
      
      {/* <div className="flex-cont">
        <div style={{background: tab === 'failed' ? '#f6c346' : 'initial'}} onClick={() => setTab("failed")} className="button outline margin-right-1">Failed</div>
        <div  style={{background: tab === 'succeeded' ? '#f6c346' : 'initial'}} onClick={() => setTab("succeeded")} className="button  outline margin-right-1">Succeeded</div>
        <div  style={{background: tab === 'outbound' ? '#f6c346' : 'initial'}} onClick={() => setTab("outbound")} className="button  outline margin-right-1">Outbound Queue</div>
      </div> */}

      {/* <div>
        {tab}
      </div> */}


    </>
  )
};

const mapState = state => ({
  reviewCount: state.budgetHeaderState.reviewCount,
  currentCompany: state.authState.currentCompany,
});

export default connect(mapState)(TimesheetUnposted);