import React, { useEffect, useState } from 'react';
import { Document, Page, Text, View, StyleSheet, Font, Image } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import { connect } from 'react-redux';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link, Outlet, useLocation, useParams, useNavigate,
} from 'react-router-dom';
import { useApi } from '../api';
import { getPrintTimesheet } from '../redux/timesheets/timesheetActions';
import { getEntries } from '../redux/entries/entryActions';
import { entries } from 'lodash';
import { DateTime } from 'luxon';

import Modal from '../components/Modal';
import logo from "../images/Unscripted.png"
import { getLogoName } from '../utils';



// Font.register({
//   family: 'Open Sans',
//   fonts: [
//     { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
//     { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf', fontWeight: 600 }
//   ]
// });

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: 'white',
    padding: 20,
    width: '100%',
    height: '100%',
  },
  defaultText: {
    fontSize: 8,
    color: 'black'
  },
  tableVertical: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1
  },
  tableColumn: {
    display: 'flex',
    flexDirection: 'column',
    // border: '1 solid cyan',
  },
  tableColumnFlex1: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    // border: '1 solid red',
  },
  thVerticalText: {
    // fontWeight: 600,
    paddingRight: 12,
    // fontFamily: 'Helvetica-Bold',
    minHeight: 10,
  },
  tdVerticalText: {
    // fontFamily: 'Helvetica',
    minHeight: 10,
  },
  table: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  tr: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%'
  },
  td: {
    minHeight: 14,
    borderBottom: '1 solid lightgray',
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  noBorder: {
    borderBottom: '0 solid gray',
  },
  thNum: {
    width: 30,
    flex: '0 1 auto',
    // fontWeight: '600'
  },
  tdNum: {
    width: 30,
    flex: '0 1 auto'
  },
  th: {
    minHeight: 12,
    borderBottom: '2px solid lightgray',
    flex: 1,
  },
  thText: {
    // fontFamily: 'Helvetica-Bold'
  },
  tdText: {

  },
  nowrap: {
    whiteSpace: 'nowrap'
  },
  image: {
    maxHeight: '30mm',
    maxWidth: '30mm'
  },
  logoCont: {
    alignItems: 'flex-start'
  },  
  tdNarrow: {
    minHeight: 10,
  },
  bold: {
    // fontFamily: 'Helvetica-Bold'
  },
  gray: {
    color: 'gray'
  }
});



// Create Document Component
const Print3 = ({pageSize, currentUser, timesheet, entries, weeks}) => {

  const [isReady, setIsReady] = useState(true);

  useEffect(() => {
    if (timesheet && entries) {
      setIsReady(true);
    }
    
  }, [timesheet, entries]);

  const periodDays = (week) => {
    let days = [];
    let count = 1;
    let weekStart = DateTime.fromISO(week?.dates[0]);
    let weekEnd = DateTime.fromISO(week?.dates[1])
    let currentDay = weekStart;

    // if (weekStart.weekday === 7) {
    //   // days.push([`${weekStart.toFormat('EEE').toUpperCase()}`, currentDay, count]);

    //   days.push([`FIRST SUN`, currentDay, count]);
    //   currentDay = currentDay.plus({day: 1}); 
    //   count += 1;
    // }
    // else {

    // }
    
    while (currentDay <= weekEnd) {
      days.push([`${currentDay.toFormat('EEE').toUpperCase()}`, currentDay, count]);
      currentDay = currentDay.plus({day: 1});
      count += 1;
    }

    return days;
  }

  if (!isReady) return (
    <Document>
      <Page orientation="landscape" size={pageSize} style={styles.page}>
        <View>
          <Text>Loading...</Text>
        </View>
      </Page>
    </Document>
  );

  return (
    <>
    {isReady &&
      <Document>
        <Page orientation="landscape" size={pageSize} style={styles.page}>


          {true && 
          <>
          <View  style={{display: 'flex', flexDirection: 'column', paddingBottom: 10}}>
            <View style={{ width: '100%', display: 'flex', flexDirection: 'row'}}>
              <View style={{ flex: 1}}>

                <View style={{...styles.tableVertical}}>
                  <View style={{...styles.tableColumn}}>
                    <View style={{...styles.td, ...styles.tdNarrow, ...styles.noBorder, width: 100}}>
                      <Text style={{...styles.defaultText, ...styles.thVerticalText}}>Employee Name</Text>
                    </View>
                    <View style={{...styles.td, ...styles.noBorder,  ...styles.tdNarrow}}>
                      <Text style={{...styles.defaultText, ...styles.thVerticalText}}></Text>
                    </View>
                    <View style={{...styles.td, ...styles.noBorder,  ...styles.tdNarrow}}>
                      <Text style={{...styles.defaultText, ...styles.thVerticalText}}>Approval Status</Text>
                    </View>
                    <View style={{...styles.td, ...styles.noBorder,  ...styles.tdNarrow}}>
                      <Text style={{...styles.defaultText, ...styles.thVerticalText}}>Employee ID</Text>
                    </View>
                    <View style={{...styles.td, ...styles.noBorder, ...styles.tdNarrow,}}>
                      <Text style={{...styles.defaultText, ...styles.thVerticalText}}>Timesheet Number</Text>
                    </View>
                    <View style={{...styles.td, ...styles.noBorder, ...styles.tdNarrow,}}>
                      <Text style={{...styles.defaultText, ...styles.thVerticalText}}>Period Date Range</Text>
                    </View>
                  </View>

                  <View style={styles.tableColumn}>
                  <View style={{...styles.td, ...styles.noBorder, ...styles.tdNarrow}}>
                      <Text style={{...styles.defaultText, ...styles.tdVerticalText}}>{timesheet.employeeName}</Text>
                    </View>
                    <View style={{...styles.td, ...styles.noBorder, ...styles.tdNarrow,}}>
                      <Text style={{...styles.defaultText, ...styles.tdVerticalText}}></Text>
                    </View>
                    <View style={{...styles.td, ...styles.noBorder, ...styles.tdNarrow,}}>
                      <Text style={{...styles.defaultText, ...styles.tdVerticalText}}>{timesheet.status}</Text>
                    </View>
                    <View style={{...styles.td, ...styles.noBorder, ...styles.tdNarrow,}}>
                      <Text style={{...styles.defaultText, ...styles.tdVerticalText}}>{timesheet.workerrecid}</Text>
                    </View>
                    <View style={{...styles.td, ...styles.noBorder, ...styles.tdNarrow,}}>
                      <Text style={{...styles.defaultText, ...styles.tdVerticalText}}>{timesheet.number}</Text>
                    </View>
                    <View style={{...styles.td, ...styles.noBorder, ...styles.tdNarrow,}}>
                      <Text style={{...styles.defaultText, ...styles.tdVerticalText}}>{DateTime.fromISO(timesheet.weekStart).toLocaleString()} - {DateTime.fromISO(timesheet.weekEnd).toLocaleString()}</Text>
                    </View>
                  </View>
                </View>
              </View>
              {/* <Text>{process.env.PUBLIC_URL + `/public/images/${timesheet.company}.png`}</Text> */}
              <View style={{...styles.logoCont}}>
                {/* <Text>{window.location.origin + `/public/images/${timesheet.company}.png`}</Text> */}
                {/* <Image style={{...styles.image}} src={logo}  /> */}
                {/* <Image style={{...styles.image}} src={process.env.PUBLIC_URL + `/public/images/${timesheet.company}.png`}  /> */}
                {getLogoName(timesheet.company) &&
                  <Image style={{...styles.image}} src={`/${getLogoName(timesheet.company)}.png`}  />
                }
                {/* <img src={require(`../../folder-path/${dynamic-filename}.png`).default} /> */}
              </View>
            </View>

            <View style={{paddingTop: 50, width: '100%', display: 'flex', flexDirection: 'column'}}>
              <View style={{...styles.table}}>

                <View style={{...styles.tr, paddingBottom: 16}}>
                    <View style={{...styles.th}}><Text style={{...styles.defaultText, ...styles.thText}}>Project Name</Text></View>
                    <View style={{...styles.th}}><Text style={{...styles.defaultText, ...styles.thText}}>Activity</Text></View>
                    <View style={{...styles.th}}><Text style={{...styles.defaultText, ...styles.thText}}>Description</Text></View>
                    <View style={{...styles.th}}><Text style={{...styles.defaultText, ...styles.thText}}>Sub Activity</Text></View>
                    {/* <View style={{...styles.th}}><Text style={{...styles.defaultText, ...styles.thText}}>Sun</Text></View> */}
                    <View style={{...styles.th, ...styles.thNum}}><Text style={{...styles.defaultText, ...styles.thText}}>Mon</Text></View>
                    <View style={{...styles.th, ...styles.thNum}}><Text style={{...styles.defaultText, ...styles.thText}}>Tue</Text></View>
                    <View style={{...styles.th, ...styles.thNum}}><Text style={{...styles.defaultText, ...styles.thText}}>Wed</Text></View>
                    <View style={{...styles.th, ...styles.thNum}}><Text style={{...styles.defaultText, ...styles.thText}}>Thu</Text></View>
                    <View style={{...styles.th, ...styles.thNum}}><Text style={{...styles.defaultText, ...styles.thText}}>Fri</Text></View>
                    <View style={{...styles.th, ...styles.thNum}}><Text style={{...styles.defaultText, ...styles.thText}}>Sat</Text></View>
                    <View style={{...styles.th, ...styles.thNum}}><Text style={{...styles.defaultText, ...styles.thText}}>Sun</Text></View>
                    <View style={{...styles.th, ...styles.thNum}}><Text style={{...styles.defaultText, ...styles.thText}}>Hours</Text></View>
                  </View>

                
                  {/* <Text style={{fontSize: 6}}>
                    {JSON.stringify(weeks)}
                  </Text> */}
                  
            
                {weeks?.map(week => {
                  return (
                    <>
              
                    <View> 

                      {/* <View style={{paddingBottom: 8}}>
                        <Text style={{fontSize: 8}}>
                          {DateTime.fromISO(week.dates[0]).toLocaleString()} - {DateTime.fromISO(week.dates[1]).toLocaleString()}
                        </Text>
                      </View> */}
                    
                  
                      <View>
                        <View style={{paddingBottom: 8}}>
                          <Text style={{...styles.defaultText, ...styles.nowrap, ...styles.bold, paddingTop: 16}}>
                            {week.dates?.[0] && 
                              <>
                                {DateTime.fromISO(week?.dates[0]).toLocaleString()} -
                              </>
                            }
                            {week.dates?.[1] && 
                              <>
                                {DateTime.fromISO(week?.dates[1]).toLocaleString()}
                              </>
                            }

                          </Text>
                        </View>

                        {true && week?.entries?.map(entry => {
                            return (
                              <>
                              <View style={{...styles.tr}}> 
                                {/* <View style={{...styles.td}}><Text style={{...styles.defaultText}}>{JSON.stringify(entry.daysTest, null, 2)}</Text></View> */}
                                <View style={{...styles.td}}><Text style={{...styles.defaultText, ...styles.nowrap}}>{entry.projectId} {entry.projectName}</Text></View>
                                <View style={{...styles.td}}><Text style={{...styles.defaultText}}>{entry.activityNumber}</Text></View>
                                <View style={{...styles.td}}><Text style={{...styles.defaultText}}>{entry.activityName}</Text></View>
                                <View style={{...styles.td}}><Text style={{...styles.defaultText}}>{entry.subcategoryName}</Text></View>
                                <View style={{...styles.td, ...styles.tdNum}}><Text style={{...styles.defaultText}}>{entry.daysTest?.MON?.hour}</Text></View>
                                <View style={{...styles.td, ...styles.tdNum}}><Text style={{...styles.defaultText}}>{entry.daysTest?.TUE?.hour}</Text></View>
                                <View style={{...styles.td, ...styles.tdNum}}><Text style={{...styles.defaultText}}>{entry.daysTest?.WED?.hour}</Text></View>
                                <View style={{...styles.td, ...styles.tdNum}}><Text style={{...styles.defaultText}}>{entry.daysTest?.THU?.hour}</Text></View>
                                <View style={{...styles.td, ...styles.tdNum}}><Text style={{...styles.defaultText}}>{entry.daysTest?.FRI?.hour}</Text></View>
                                <View style={{...styles.td, ...styles.tdNum}}><Text style={{...styles.defaultText}}>{entry.daysTest?.SAT?.hour}</Text></View>
                                <View style={{...styles.td, ...styles.tdNum}}><Text style={{...styles.defaultText}}>{entry.daysTest?.SUN?.hour}</Text></View>
                                <View style={{...styles.td, ...styles.tdNum}}><Text style={{...styles.defaultText}}>{entry.totalPeriodHours}</Text></View>
                              </View>
                              
                              </>
                    
                            )
                          })}


                          <View style={{...styles.tr, backgroundColor: 'lightgray'}}>
                            <View style={{...styles.td}}><Text style={{...styles.defaultText, ...styles.nowrap}}></Text></View>
                            <View style={{...styles.td}}><Text style={{...styles.defaultText}}></Text></View>
                            <View style={{...styles.td}}><Text style={{...styles.defaultText}}></Text></View>
                            <View style={{...styles.td}}><Text style={{...styles.defaultText, textAlign: 'right', marginRight: 10}}>TOTAL:</Text></View>
                            <View style={{...styles.td, ...styles.tdNum}}><Text style={{...styles.defaultText}}>{week.andy?.MON?.hour}</Text></View>
                            <View style={{...styles.td, ...styles.tdNum}}><Text style={{...styles.defaultText}}>{week.andy?.TUE?.hour}</Text></View>
                            <View style={{...styles.td, ...styles.tdNum}}><Text style={{...styles.defaultText}}>{week.andy?.WED?.hour}</Text></View>
                            <View style={{...styles.td, ...styles.tdNum}}><Text style={{...styles.defaultText}}>{week.andy?.THU?.hour}</Text></View>
                            <View style={{...styles.td, ...styles.tdNum}}><Text style={{...styles.defaultText}}>{week.andy?.FRI?.hour}</Text></View>
                            <View style={{...styles.td, ...styles.tdNum}}><Text style={{...styles.defaultText}}>{week.andy?.SAT?.hour}</Text></View>
                            <View style={{...styles.td, ...styles.tdNum}}><Text style={{...styles.defaultText}}>{week.andy?.SUN?.hour}</Text></View>
                            <View style={{...styles.td, ...styles.tdNum}}><Text style={{...styles.defaultText}}>{week.totals.totalHours}</Text></View>
                          </View>
                      </View>
                    </View>
                    
                  </>)
                })}

                
                
              </View>

              <View style={{alignItems: 'flex-end', backgroundColor: 'white'}}>
                <Text style={{...styles.defaultText, ...styles.bold}}>TOTAL HOURS: {timesheet.totalHours}</Text>
              </View>
            </View>
          </View>

          {true &&
          <View style={{paddingTop: 20, backgroundColor: 'white', minHeight: 30, display: 'flex', flexDirection: 'row'}}>


            {/* <View style={{flex: 1, backgroundColor: 'white', display: 'flex', flexDirection: 'column'}}>

            </View> */}

              <View style={{flex: 1, backgroundColor: 'white', display: 'flex', flexDirection: 'column'}}>
                {/* <Text  style={{...styles.bold, fontSize: 10}}>Summary</Text> */}
                <View style={{display: 'flex', backgroundColor: 'white', flex: 1, flexDirection: 'row'}}>

                  <View style={{flex: 1}}><Text  style={{...styles.defaultText, ...styles.bold, fontSize: 10}}>Summary</Text></View>
                  
                  <View style={{flex: 3}}><Text  style={{...styles.defaultText, ...styles.gray}}>-</Text></View>
                  <View style={{flex: 1}}><Text  style={{...styles.defaultText, ...styles.gray}}>Total Hours</Text></View>
                </View>
                  
                  
                <View style={{display: 'flex', flexDirection: 'column', paddingTop: 16}}>
                
                  {timesheet.projectSummary?.map(s => (
                    <View style={{display: 'flex', flexDirection: 'row', borderBottomColor: 'lightgray', borderBottomWidth: 1}}>
                      <Text style={{...styles.defaultText, flex: 1}}>{s[0]}</Text> 
                      <Text style={{...styles.defaultText, flex: 3 }}>{s[2]}</Text> 
                      <Text style={{...styles.defaultText,...styles.gray, flex: 1}}>{s[1]}</Text>
                    </View>
                  ))}
                </View>

              </View>

              <View style={{flex: 1, backgroundColor: 'white', display: 'flex', flexDirection: 'column'}}>
                <View style={{display: 'flex', flexDirection: 'row', paddingBottom: 16}}>
                  <Text  style={{...styles.defaultText, ...styles.bold, fontSize: 10, flex: 1}}>Comments</Text>
                </View>
                  
                  
                {/* <View style={{display: 'flex', flexDirection: 'column'}}>
                
                  <Text style={{...styles.defaultText}}>1) Integer sollicitudin id massa at maximus. Proin et gravida lorem hendrerit.</Text>
                  <Text style={{...styles.defaultText}}>2) Integer sollicitudin id massa at maximus. Proin et gravida lorem hendrerit.</Text>
                  <Text style={{...styles.defaultText}}>3) Integer sollicitudin id massa at maximus. Proin et gravida lorem hendrerit.</Text>
                </View> */}

              </View>

              
              

          </View>
          }

          {true &&
            <View wrap={false} style={{backgroundColor: 'white', justifyContent: 'flex-end', flex: 1, minHeight: 30, display: 'flex', flexDirection: 'column'}}>

              <View style={{paddingBottom: 50}}>
                <Text style={{marginRight: 20, paddingTop: 5, width: 200, fontSize: 8, borderTop: '1 solid black'}}>Employee Signature</Text>
              </View>
              
              <View style={{display: 'flex', flexDirection: 'row'}}>
                <Text style={{marginRight: 20, paddingTop: 5, width: 200, fontSize: 8, borderTop: '1 solid black'}}>Manager Signature</Text>
                <Text style={{marginRight: 20, paddingTop: 5, width: 200, fontSize: 8, borderTop: '1 solid black'}}>Manager Signature</Text>
                <Text style={{marginRight: 0, paddingTop: 5, width: 200, fontSize: 8, borderTop: '1 solid black'}}>Manager Signature</Text>
              </View>

            </View>
          }
   

        </>}
        </Page>
      </Document>
    }
    </>
  );
}

const TimesheetPrintViewer = ({toggleModal, opened, timesheet, currentUser}) => {

  const [timesheetResult, timesheetLoading, timesheetLoaded, timesheetError, doTimesheetFetch, setTimesheetResult] = useApi();
  const [isReady, setIsReady] = useState(false);
  const [pageSize, setPageSize] = useState("A4");

  useEffect(() => {
    if (timesheet) {
      fetchTimesheet();
      // fetchEntries();
    }
  }, [timesheet]);


  const fetchTimesheet = () => {
    doTimesheetFetch(getPrintTimesheet, {query: {print: '1', id: timesheet?.id}});
  }

  const [entriesResult, entriesLoading, entriesLoaded, entriesError, doEntriesFetch, setEntriesResult] = useApi();

  const fetchEntries = () => {
    doEntriesFetch(getEntries, {query: {timesheetid: timesheet?.id}});
  }

  // useEffect(() => {
  //   if (timesheetResult && entriesResult) {
  //     setTimeout(()=>{
  //       setIsReady(true);
  //     }, 100)
  //   }
  // }, [timesheetResult, entriesResult]);

  useEffect(() => {
    if (timesheetResult) {
      setTimeout(()=>{
        setIsReady(true);
      }, 100)
    }
  }, [timesheetResult]);

  // useEffect(() => {
  //   setOpened(pageSize)
  // }, [pageSize]);

  // const [opened, setOpened] = useState(true);

  // const toggleModal = () => {
  //   setOpened(o => !o);
  // }



  return (
    <>
    {!isReady &&
      <div className="full center">
        <div className="margin-4">
          <div className="spinner"></div>
        </div>
        
      </div>
      
    }
    {isReady && 
      <>

        {pageSize && opened &&
          <>

            
            <div className='modal-header flex-cont align-center'>
              <h1 className="flex-1">Print</h1>

              <div>
                <div onClick={() => setPageSize("A4")} className={`margin-right-1 button ${pageSize !== 'A4' ? 'ghost' : ''}`}>A4</div>
                <div onClick={() => setPageSize("Letter")} className={`button ${pageSize !== 'Letter' ? 'ghost' : ''}`}>Letter</div>
              </div>

              <div onClick={toggleModal} className="ghost button close">
                <i className="fas fa-times"></i>
              </div>
            </div>

            <div style={{width: '100%', height: '100%'}}>
              {/* <div>
                {JSON.stringify(timesheetResult)}
              </div> */}
              <PDFViewer width='100%' height='100%' >
                <Print3  weeks={timesheetResult?.weeks} pageSize={pageSize} entries={entriesResult} timesheet={timesheetResult?.timesheet} currentUser={currentUser} />

                {/* <Print4 /> */}
              </PDFViewer>
            </div>
            
          </>
        }
      </>
    }
    </>
  );
};

const Print4 = () => {

  return (
    <Document>
        <Page orientation="landscape">
          <View>
            <Text>Test</Text>
          </View>
        </Page>
    </Document>
  )
}


function mapState(state) {
  return { 
    currentUser: state.authState.currentUser,
  };
} 

export default connect(mapState)(TimesheetPrintViewer);