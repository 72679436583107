import React, { useMemo } from "react";
import { useWatch } from 'react-hook-form';

const SummaryTotal = ({control, totalHours}) => {

  const entriesWatch = useWatch({
    control,
    name: `week_summary_hours`,
    defaultValue: [totalHours]
  });

  const sum = entriesWatch?.reduce((current, previous) => { return parseFloat(current)+parseFloat(previous)}, 0);

  return (
    <>
      {parseFloat(sum).toLocaleString()}
    </>
  )
}

export default SummaryTotal;