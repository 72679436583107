import React, { useEffect } from 'react';

import ExpenseNav from './ExpenseNav';
import { connect } from 'react-redux';
import { getApprovalSteps } from '../../../redux/manager/expenseHeaders/expenseHeaderActions';
import { useApi } from '../../../api';

const ExpenseLayout = ({currentCompany, children, viewAsUser}) => {




  // const [steps, stepsLoading, stepsLoaded, stepsError, doStepsFetch, setStepsResult] = useApi();

  // const fetchSteps = () => {
  //   doStepsFetch(getApprovalSteps);
  // }

  // useEffect(() => {
  //   fetchSteps();
  // }, []);

  if (process.env.REACT_APP_DEPLOYMENT_SLOT == "prod") {
    return <></>
  }
  
  return (
    <>
      {/* <ViewAsBanner /> */}

      {/* <pre>
        {JSON.stringify(viewAsUser, null, 2)}
      </pre> */}

      <ExpenseNav />

      {/* {steps &&
        <div className="padding-1 expense-steps-cont">
          
          <div className="bold gray font-075 margin-bottom-1">APPROVAL WORKFLOW</div>

          <div className="flex-cont align-center">
            {steps.map((step, index) => 
              <div className="expense-step flex-cont align-center">
                <div className="expense-step-number margin-right-half">{index+1}</div>
                <div className="bold">{step.rolename} Approval</div>
                {index !== steps.length-1 &&
                  <div className='expense-step-arrow'><i className="fas fa-arrow-right"></i></div>
                } 
              </div>

            )}
          </div>
          
        </div>
      } */}
      

      {children}
    </>
  )
}



function mapState(state) {
  return { 
    // tokenResponse: state.authState.tokenResponse,
    viewAsUser: state.authState.viewAsUser,
    currentCompany: state.authState.currentCompany
  };
} 

export default connect(mapState)(ExpenseLayout);
