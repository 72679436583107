import React, { useEffect, useState } from 'react';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link, Outlet, useLocation
} from 'react-router-dom';

import * as yup from "yup";
import { useForm } from 'react-hook-form';

import * as msal from "@azure/msal-browser";
import store from '../../redux/store';
import { connect } from 'react-redux';
import { setTokenResponse } from '../../redux/authActions';

import Modal from '../../components/Modal';
import { useApi } from '../../api';
import { createUser, updateUser, getUsers } from '../../redux/admin/userActions';

import { getRoles} from '../../redux/admin/roleActions';


import { getBudgetFeeCategories } from '../../redux/admin/budgetFeeCategoryActions';
import { getBudgetPhases } from '../../redux/admin/budgetPhaseActions';


import SubNav from '../../components/SubNav';
import AdminPhases from './AdminPhases';
import AdminBudgetCategories from './AdminBudgetCategories';
import AdminExpenseLineCategories from './AdminExpenseLineCategories';


const AdminSettingsLayout = ({children}) => {


  // const [phases, phasesLoading, phasesLoaded, phasesError, doPhasesFetch, setPhasesResult] = useApi();
  
  // useEffect(() => {
  //   fetchPhases();
  // }, []);

  // const fetchPhases = () => {
  //   doPhasesFetch(getBudgetPhases);
  // }


  // const [budgetFeeCategories, budgetFeeCategoriesLoading, budgetFeeCategoriesLoaded, budgetFeeCategoriesError, doBudgetFeeCategoriesFetch, setBudgetFeeCategoriesResult] = useApi();
  
  // useEffect(() => {
  //   fetchBudgetFeeCategories();
  // }, []);

  // const fetchBudgetFeeCategories = () => {
  //   doBudgetFeeCategoriesFetch(getBudgetFeeCategories);
  // }



  return (
    <>
      <div className="margin padding-2x">
        <h1>Settings</h1>

        <AdminExpenseLineCategories />
        <AdminBudgetCategories />
        <AdminPhases />
        
        
      </div>
    </>
  )
}

export default AdminSettingsLayout;