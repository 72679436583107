import React from 'react';

import TimesheetsNav from './TimesheetsNav';
import { connect } from 'react-redux';


const ManagerTimesheetsLayout = ({children}) => {
  
  return (
    <>
      {/* <ViewAsBanner /> */}

      
      <TimesheetsNav />
      {children}
    </>
  )
}



const mapState = state => ({
  
});

export default connect(mapState)(ManagerTimesheetsLayout);
