import React, { useEffect } from "react";
import { isNumeric } from "../../utils";

const TimesheetEntryTotal = ({setValue, name, register, totalHours}) => {

  const parsedHours = isNumeric(totalHours) ? parseFloat(totalHours) : 0;


  useEffect(() => {
    setValue(name, parseFloat(parsedHours), {  });
  }, [totalHours])


  // if (totalHours == null) return null;
  
  
  return (
    <div className="editable-cont entry-total-hours">

      
      {parsedHours.toLocaleString()}

      
      <input  
        {...register(name)} 
        type="hidden" 
        name={name} 
        defaultValue={parsedHours}
      />
    </div>
  )

}

export default TimesheetEntryTotal;