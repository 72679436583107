import React, {useEffect} from 'react';

const shouldShowProjectSelect = (projects, existingEntry) => {
  if (!projects || projects.length === 0) return false;
  if (!existingEntry?.projectId) return true;

  const projectIDs = projects.map(project => project.id?.toString());

  return projectIDs.includes(existingEntry?.projectId?.toString());
}

export const ProjectSelect = ({hide, disabled, myErrors, selectedProject, selectProjectChange, register, inputName, existingEntry, projects}) => {

  const final = () => {
    // if (selectedProject) return selectedProject.number;
    return (existingEntry?.projectId ? existingEntry?.projectId : "")
  }

  const shouldShowSelect = () => {
    return (shouldShowProjectSelect(projects, existingEntry))
  }

  const reg = register(`${inputName}.selectedProject`, {

    validate: v => {
      if (hide) return true;

      if (v) {
        return true
      }
      return "Select a project"
    }
  })

  // if (!projects) {
  //   return (
  //     <label className={myErrors?.selectedProject ? "margin-right-4px error-label" : "margin-right-4px"}>  
  //       <div className="label-text">Project</div>
  //       <div className="dummy-select wide">Loading...</div>
  //     </label>
  //   )
  //   // <div className="spinner"></div>
  // }

  return (
    <>
      <label className={myErrors?.selectedProject ? "margin-right-4px error-label" : "margin-right-4px"}>  
        <div className="label-text">Project</div>

        
        {!disabled && shouldShowSelect() ? (
            <select disabled={disabled}  tabIndex={0} 
                defaultValue={final()}
                className="wide"
               onBlur={reg.onBlur}
               ref={reg.ref} 
               onChange={(e) => {
                  reg.onChange(e); // method from hook form register
                  selectProjectChange(e)
                }}
                 name={`${inputName}.selectedProject`}  id={`${inputName}.selectedProject`}>
              <option value="">Select project:</option>
              {projects?.map((project, key) => {
                return <option  key={key} value={project.id}>{`${project.id} - ${project.name}`}</option>
              })}
            </select>
          ) : (
            <>
              {existingEntry?.projectName &&
                <>
                  <div className="dummy-select wide">
                    {existingEntry?.projectId} - {existingEntry?.projectName}
                  </div>
                  <input type="hidden" name={`${inputName}.selectedProject`}  ref={reg.ref} defaultValue={final()} />
                </>
              }
              {!existingEntry?.projectName &&
                <div className="dummy-select wide">
                  Project not found.
                </div>
              }
              {/* <select tabIndex={0} disabled id={`${inputName}.selectedProject`}>
                <option value="" disabled>---</option>
              </select> */}

            </>
          )
        }
      </label>

    </>

  )
}

export const ActivitySelect = ({setValue, getValues, selectedProjectID, hide, disabled, myErrors, selectedActivity, selectActivityChange, register, inputName, existingEntry, activities, projects}) => {

  const final = () => {
    if (selectedActivity) return selectedActivity.number;
    return (existingEntry?.activityNumber ? existingEntry?.activityNumber : "")
  }

  // useEffect(() => {
  //   setValue(`${inputName}.selectedActivity`, final());
  // }, [existingEntry])
  

  const shouldShowSelect = () => {

    return shouldShowProjectSelect(projects, existingEntry);
  }

  const shouldDisplayActivities = () => {
    if (selectedProjectID && selectedProjectID?.toString()?.charAt(0) === "X") {
      return false;
    }

    return true;
  }

  const reg = register(`${inputName}.selectedActivity`, {
    // required: "Select a phase"

    validate: v => {
      
      // alert('here')
      if (hide) return true;
      if (v === undefined) return true;
      

      if (!shouldDisplayActivities()) {
        // alert(selectedProjectID)
        return true;
      }
      else {

      }



      if (v) {
        return true
      }
      return "Select a milestone"
    }
  })

  return (
    <>
      <label className={myErrors?.selectedActivity ? "margin-right-4px error-label" : "margin-right-4px"}>  
        <div className="label-text">Milestone</div>
        {/* <div>
          {JSON.stringify(selectedProjectID?.toString()?.charAt(0), null, 2)}
        </div> */}
        {/* <div>
          {JSON.stringify(final())}
        </div> */}
        {/* <div className="button" onClick={() => {
          alert(JSON.stringify(getValues(`${inputName}.selectedActivity`)))
        }}></div> */}
        {(shouldShowSelect()) ? (
          <>

            {disabled &&
              <>
                {/* <select tabIndex={0} disabled id={`${inputName}.selectedActivity`}>
                  <option value="" disabled>---</option>
                </select> */}
                {existingEntry?.activityName &&
                  <>
                    <div className="dummy-select wrap">
                      {existingEntry?.activityName}
                    </div>
                    <input type="hidden" name={`${inputName}.selectedActivity`}  ref={reg.ref} defaultValue={final()} />
                  </>
                }
                {!existingEntry?.activityName &&
                  <div className="dummy-select wrap">
                    -
                  </div>
                }
              </>
            }
            {!disabled &&
              <select disabled={disabled || !shouldDisplayActivities()}  tabIndex={0} defaultValue={final()} 
                onBlur={reg.onBlur}
                ref={reg.ref} 
                onChange={(e) => {
                  reg.onChange(e); // method from hook form register
                
                  selectActivityChange(e)
                }}
                className="wrap"
              
              
              name={`${inputName}.selectedActivity`}  id={`${inputName}.selectedActivity`}  >
                  
                  {shouldDisplayActivities() &&
                    <option value="">Select milestone:</option>
                  }
                  {!shouldDisplayActivities() &&
                    <option value="">---</option>
                  }
                  {activities?.map((activity, key) => {
                    return <option selected={activity.number === final()}  key={key} value={activity.number}>{`${activity.number} - ${activity.name}`}</option>
                  })}
      
                </select>
              }
            </>
          ) : (
            

            <>
              {existingEntry?.activityName &&
                <>
                  <div className="dummy-select wrap">
                    {existingEntry?.activityName}
                  </div>
                  <input type="hidden" name={`${inputName}.selectedActivity`}  ref={reg.ref} defaultValue={final()} />
                </>
              }
              {!existingEntry?.activityName &&
                <div className="dummy-select wrap">
                  -
                </div>
              }
              {/* <select tabIndex={0} disabled id={`${inputName}.selectedProject`}>
                <option value="" disabled>---</option>
              </select> */}
                              {/* <select className="wrap" tabIndex={0} disabled id={`${inputName}.selectedActivity`}>
                  <option value="" disabled>---</option>
                </select> */}

            </>
          )
        }
      </label>

    </>

  )
}
export const SubactivitySelect = ({disabled, myErrors, selectedSubactivity, selectSubactivityChange, register, inputName, existingEntry, subactivities, projects}) => {

  const final = () => {
    if (selectedSubactivity) return selectedSubactivity.id;
    return (existingEntry?.subactivitynum ? existingEntry?.subactivitynum : "")
  }

  const shouldShowSelect = () => {
    return shouldShowProjectSelect(projects, existingEntry);
    // if (!subactivities || subactivities.length === 0) return false
    // if (!existingEntry?.subactivitynum) return true

    // const subactivityIDs = subactivities.map(subactivity => subactivity.id?.toString());
    
    // return subactivityIDs.includes(existingEntry?.subactivitynum?.toString()) && shouldShowProjectSelect(projects, existingEntry);
  }

  const reg = register(`${inputName}.selectedSubactivity`)


  return (
    <>
      <label className={myErrors?.selectedSubactivity ? "margin-right-4px error-label" : "margin-right-4px"}>
        <div className="label-text">Task</div>



        {(!disabled && shouldShowSelect()) ? 
          (

            <>

            {!subactivities &&
              <select tabIndex={0} disabled id={`${inputName}.selectedSubactivity`}>
                <option value="" disabled>---</option>
              </select>
            }
            {subactivities &&
              <select disabled={disabled}  tabIndex={0} defaultValue={final()} {...register(`${inputName}.selectedSubactivity`, {

                  })} 
                  
                  onBlur={reg.onBlur}
                  ref={reg.ref} 
                  onChange={(e) => {
                    reg.onChange(e); // method from hook form register
                    selectSubactivityChange(e)
                  }}
                  
                  name={`${inputName}.selectedSubactivity`}  id={`${inputName}.selectedSubactivity`}>
                <option value="">Select task:</option>
                {subactivities?.map((subactivity, key) => {
                  return <option  key={key} value={subactivity.id}>{`${subactivity.name}`}</option>
                })}
              </select>
            }
            </>
          ) : (
            <>
              {existingEntry?.subactivityname &&
                <>
                  <div className="dummy-select">
                    {existingEntry?.subactivityname}
                  </div>
                  <input type="hidden" name={`${inputName}.selectedSubactivity`}  ref={reg.ref} defaultValue={final()} />
                </>
              }
              {!existingEntry?.subactivityname &&
                <select tabIndex={0} disabled id={`${inputName}.selectedSubactivity`}>
                  <option value="" disabled>---</option>
                </select>
              }
              {/* <select tabIndex={0} disabled id={`${inputName}.selectedSubactivity`}>
                <option value="" disabled>---</option>
              </select> */}
            </>
          )
        }
        
      </label>
    </>

  )
}

