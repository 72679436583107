import React, {useLayoutEffect, useState } from 'react';

import { connect } from 'react-redux';
import { removeSuccess, addSuccess } from './redux/ui/uiActions';

function useInterval(callback, delay) {
  const intervalRef = React.useRef(null);
  const savedCallback = React.useRef(callback);
  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);
  React.useEffect(() => {
    const tick = () => savedCallback.current();
    if (typeof delay === 'number') {
      intervalRef.current = window.setInterval(tick, delay);
      return () => window.clearInterval(intervalRef.current);
    }
  }, [delay]);
  return intervalRef;
}





function useTimeout(callback, delay) {
  const timeoutRef = React.useRef(null);
  const savedCallback = React.useRef(callback);
  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);
  React.useEffect(() => {
    const tick = () => savedCallback.current();
    if (typeof delay === 'number') {
      timeoutRef.current = window.setTimeout(tick, delay);
      return () => window.clearTimeout(timeoutRef.current);
    }
  }, [delay]);
  return timeoutRef;
};

const Suc = ({suc, i}) => {
  const [shouldStartToFade, setShouldStartToFade] = useState(false);
  const [hasFaded, setHasFaded] = useState(false);
  const [opened, setOpened] = useState(false);

  useLayoutEffect(() => {
    setOpened(true);
  }, [])

  useTimeout(() => {
    setShouldStartToFade(true);
  }, 5000);

  useTimeout(() => {
    setHasFaded(true);
    removeSuccess(i);
  }, 6000);

  return (
    <div className={`${opened ? 'opened' : ''} ${shouldStartToFade ? 'fading' : ''} global-success flex-cont align-center`}>
      {/* <i onClick={() => store.dispatch(removeSuccess(i))} className="ghost button margin-right-half fas fa-times">

      </i> */}

      <div className="i-cont margin-right-half">
        <i className='fas fa-check'></i>
      </div>

      
      {suc}
    </div>
  )
}

const GlobalSuccesses = ({globalSuccesses}) => {

  // const [delay, setDelay] = useState(Math.random()*1000);

  // useTimeout(() => {
  //   const r = (Math.random()*1000);
  //   store.dispatch(addSuccess(r.toString()));

  //   // alert('here')

  //   setDelay(r);
  // }, delay);

  return(
    <>
      {true && //globalSuccesses && globalSuccesses.length !== 0 &&
        <div className="global-success-cont ">
          {/* <Suc i={0} key={`${"Entry added."} ${0}`} suc={"Entry added."} /> */}
          {globalSuccesses.map((success, i) => 
            <Suc i={i} key={`${success} ${i}`} suc={success} />
          )}

        </div>
      }
    </>
  );
}




function mapState(state) {
  return { 
    globalSuccesses: state.uiState.globalSuccesses,
  };
} 

export default connect(mapState)(GlobalSuccesses);

