import React, { useState, useEffect } from 'react';
import XLSX from 'xlsx';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { getBudgetReport } from '../../redux/manager/budgetHeaders/budgetHeaderActions';
import { useApi } from '../../api';
import { DateTime } from 'luxon';
import { connect } from 'react-redux';
import { slice } from 'lodash';
import Selector from '../../components/Selector';
import CheckBoxes from '../../components/CheckBoxes';

const initialData = {
  cols: [{ name: "A", key: 0 }, { name: "B", key: 1 }, { name: "C", key: 2 }, { name: "D", key: 3 }, { name: "E", key: 4 }, { name: "F", key: 5 }, { name: "G", key: 6 }, { name: "H", key: 7 } ],
  data: [
    [ "ActivityNumber", "TransType", "CategoryID", "BudgetLineType", "CurrentBudget", "Description", "CompanyId", "ProjId"],
    [    1, "sheetjs",    7262 ],
    [    2, "js-xlsx",    6969 ]
  ]
};


// ActivityNumber	TransType	CategoryID	BudgetLineType	CurrentBudget	Description	CompanyId	ProjId
const initialApproverData = {
  cols: [{ name: "A", key: 0 }, { name: "B", key: 1 }, { name: "C", key: 2 }, { name: "D", key: 3 }, { name: "E", key: 4 }, { name: "F", key: 5 }, { name: "G", key: 6 }, ],
  data: [
    [ "Period End Date", "Manager Name", "Employee Name", "Timesheet Number", "Company", "Approval Status", "Hours"],
    [    1, "sheetjs",    7262 ],
    [    2, "js-xlsx",    6969 ]
  ]
};

const BudgetedReport = ({refresh, compOnly, showAct, setIsLoading, currentCompany, kind, discOnly}) => {
  const [data, setData] = useState([]);
	const [cols, setCols] = useState([]);
  const [budgetHeaders, loading, loaded, error, doFetch, setResult] = useApi();

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  useEffect(() => {

    if (refresh !== 0) {
      fetchReport();
    }
    
    
  }, [refresh]); // compOnly, currentCompany, kind, discOnly, showAct, 

  useEffect(() => {
    if (budgetHeaders) {
      setData(budgetHeaders.slice(2));

      if (kind === 'budgeted') {
        setCols(initialData.cols);
      }
      else {
        setCols(initialApproverData.cols);
      }
      
    }
  }, [budgetHeaders]);

  const fetchReport = () => {
    doFetch(getBudgetReport, {query: {comp_only: compOnly, show_act: showAct, disc_only: discOnly, kind: kind, company: currentCompany}});
  }
  

  const exportFile = () => {
		/* convert state to workbook */

    const companies = budgetHeaders[0];

    // alert(JSON.stringify(companies, null, 2))

      

    let zip = new JSZip();

    companies.forEach(company => {

      let compData = data.filter(d => d[6] == company);

      if (compData && compData.length !== 0) {

        compData.unshift(budgetHeaders[1]); // add headers back in
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet(compData);
        XLSX.utils.book_append_sheet(wb, ws, company);

  
        let wbout = XLSX.write(wb, {bookType: 'xlsx', bookSST: true, type: 'binary'});
    
        zip.file(`${company}.xlsx`, wbout, {binary: true});
      }

    })

    zip.generateAsync({type:"blob"})
    .then(function(content) {
        // see FileSaver.js
        saveAs(content, `BudgetReports_${DateTime.fromJSDate(new Date()).toISODate()}_${DateTime.fromJSDate(new Date()).toISOTime()}.zip`);
    });

    console.log("ANDY X", zip);
		
		
		/* generate XLSX file and send to client */
		
	};

  const onFilterClick = () => {
    fetchReport();
  }

  // const [, ...previewData] = data ? data.slice(0, 25) : null;
  const previewData = data ? data.slice(0, 100) : null;

  

  return (
    <>
            {/* <div className="code"> 
              <pre>
              previewData: {JSON.stringify(previewData, null, 2)}
              </pre>
            </div> 

            <div className="code"> 
              <pre>
              data: {JSON.stringify(data, null, 2)}
              </pre>
            </div> 

            <div className="code"> 
              <pre>
                {JSON.stringify(budgetHeaders, null, 2)}
              </pre>
            </div>  */}

            {/* <div>
              {JSON.stringify(`${DateTime.fromJSDate(new Date()).toISODate()} ${DateTime.fromJSDate(new Date()).toISOTime()}`)}
            </div> */}
      {budgetHeaders && !loading && data &&

        <>

          {data.length === 0 &&
            <div className='margin-top-1'>
              <em>No results for these filters.</em>
            </div>
          }
          {data.length !== 0 &&
            <>
              <div className="row">
                <div className="col-xs-12">
                  <div className="button" disabled={!data.length || loading}  onClick={exportFile}>
                    <i className="fas fa-file-excel margin-right-half"></i>
                    Export to Excel
                  </div>
                </div>
              </div>
              

            
              <div className='margin-bottom-1 margin-top-2 font-1-5 bold'>Preview </div>
              {previewData && previewData.length >= 100 && <div className='margin-bottom-2 gray bold'>Showing first 100 of {budgetHeaders.length - 2} rows. Export Excel file to see full list.</div>}

              {/* {data && data.length &&
                div.gray
              } */}
              
              <div className='bold'>Total Rows: {budgetHeaders.length - 2}</div>
              <div className="table-responsive">
                <table className="basic table table-striped">
                  <thead>
                    {/* <tr>{cols?.map((c) => <th key={c.key}>{c.name}</th>)}</tr> */}
                    <tr>
                      <th>#</th>
                      {cols?.map(c => <th key={0}>{ budgetHeaders[1][c.key] }</th>)}
                      {/* <th>COMPANY</th> */}
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {previewData?.map((r,i) => 
                      <tr key={i}>
                        <td>{i+1}</td>
                        {cols?.map(c => <td className='fit nowrap' key={c.key}>{ r[c.key] }</td>)}
                        {/* <td>{r.company}</td> */}
                        <td className='full'></td>
                      </tr>)
                    }
                  </tbody>
                </table>
                {/* {data && data.length >= 25 && <div className='margin-top-2 gray bold'>Showing first 25 employees. Download Excel file to see full list.</div>} */}
              </div>
            </>
          }
          
        </>
      }
    </>
  )


}

const BudgetingReports = ({currentCompany, viewAsUser}) => {

  const [kind, setKind] = useState('budgeted');

  const [isLoading, setIsLoading] = useState(false);
  const [onlyDiscrepencies, setOnlyDiscrepencies] = useState(["0"]);
  const [byActivitynumber, setByActivitynumber] = useState(["0"]);
  const [byComp, setByComp] = useState(["0"]);

  const compOnly = byComp.length !== 0;
  const discOnly = onlyDiscrepencies.length !== 0;
  const showAct = byActivitynumber.length !== 0;
  const [refresh, setRefresh] = useState(0);


  return(
    <div>

      <div className={`spinner-cont-cool ${isLoading && "entered"} flex-cont align-center`}>
        <div className="cool spinner margin-right-1"></div>
        <div>Generating report...</div>
        
      </div>
       

      <div className="flex-cont align-center">
        <h1 className="flex-cont">
          {/* <div className="margin-right-half medgray">{currentCompany}</div> */}
            Budget Reports
        </h1>
      </div> 


      {/* <div className="margin-top-1 margin-bottom-3">


        <div className="flex-cont align-center">
          <div onClick={() => setKind('employee')} className="pointer margin-right-2 flex-cont align-center">
            <div className={`check-box margin-right-1 ${kind === 'employee' ? 'selected' : ''}`}>
              <div className="check"></div>
            </div>
            <div className="gray bold">Employee Report</div>
          </div>

          <div onClick={() => setKind('approver')} className="pointer margin-right-2 flex-cont align-center">
            <div className={`check-box margin-right-1 ${kind === 'approver' ? 'selected' : ''}`}>
              <div className="check"></div>
            </div>
            <div className="gray bold">Approver Report</div>
          </div>


        </div>
      </div> */}

      

      {/* <div className="margin-bottom-3">
        <div className={`selector-cont ${isLoading ? 'disabled' : ''}`}>          
          <Selector label={"Budgeted Report"} name="budgeted" setTab={setKind} tab={kind} count={null} />
        </div>
      </div> */}




      {/* <div onClick={() => setRefresh(o => o+1)} className='button margin-bottom-1 margin-top-1'>Refresh {refresh}</div> */}

      <div className="margin-bottom-3">
      {currentCompany &&
        <div className="inline-flex align-center ">

          <div className={`margin-bottom-2x margin-right-2 check-boxes ${isLoading ? 'disabled' : ''}`}>          
            <CheckBoxes disabled={isLoading} label={`${currentCompany} only`} name="0" boxes={byComp} setBoxes={setByComp} />
          </div>

          <div className={`margin-bottom-2x margin-right-2 check-boxes ${isLoading ? 'disabled' : ''}`}>          
            <CheckBoxes disabled={isLoading} label={"Discrepencies only"} name="0" boxes={onlyDiscrepencies} setBoxes={setOnlyDiscrepencies} />
          </div>
          

          <div className={`margin-bottom-2x margin-right-2 check-boxes ${isLoading ? 'disabled' : ''}`}>          
            <CheckBoxes disabled={isLoading} label={"By activity number"} name="0" boxes={byActivitynumber} setBoxes={setByActivitynumber} />
          </div>
        </div>
      }

      <div onClick={() => setRefresh(o => o+1)} className="button">Generate Report</div>
      </div>
      
      {kind == "budgeted" &&
        <BudgetedReport setIsLoading={setIsLoading} currentCompany={currentCompany}   kind={kind} compOnly={compOnly} showAct={showAct} discOnly={discOnly} refresh={refresh} />
      }
      {kind == "consumed" &&
        <BudgetedReport setIsLoading={setIsLoading} currentCompany={currentCompany}   kind={kind} showAct={showAct} discOnly={discOnly} />
      }

      
    </div>
  )
};

const mapState = state => ({
  reviewCount: state.budgetHeaderState.reviewCount,
  currentCompany: state.authState.currentCompany,
  viewAsUser: state.authState.viewAsUser
});

export default connect(mapState)(BudgetingReports);