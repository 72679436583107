import React, { useState } from 'react';
import { useApi } from '../../api';
import { approveLine } from '../../redux/entries/entryActions';

import { DateTime, Info } from 'luxon'; 
import { connect } from 'react-redux';
import Modal from '../../components/Modal';

const HourCell = ({line, i, periodDays}) => {
  const [noteOpened, setNoteOpened] = useState(false);


  const addNoteClicked = () => {
    setNoteOpened(o => !o);
  }

  const getHour = i => {
    if (!line?.daysTest?.[Info.weekdays('short')[i]?.toUpperCase()]?.hour) {
      return "0.0"
    }
    return line?.daysTest[Info.weekdays('short')[i]?.toUpperCase()]["hour"]
  }

  const getNote = i => {
    if (!line?.daysTest?.[Info.weekdays('short')[i]?.toUpperCase()]?.note) {
      return ""
    }
    return line?.daysTest[Info.weekdays('short')[i]?.toUpperCase()]["note"]
  }


  return (
    <td className="hour">
      <div className="flex-cont align-center relative">

      
      <div className={`${getHour(i).toString() !== '0.0' ? 'bold' : 'gray'}`}>
        {getHour(i)}
        {/* {getNote(i)} */}
      </div>

      {/* <div onClick={() => pencilClicked(i)}>
        <i className={`${existingEntry[`day${i+1}note`] ? 'note-present' : ''} pencil fas fa-pencil-alt`}></i>
      </div> */}

        {getNote(i) &&
          <i onClick={addNoteClicked} className={`${getNote(i) ? 'note-present' : ''} margin-left-half pencil fas fa-pencil-alt`}></i>
        }
      </div>

      {noteOpened &&
          <Modal size="small" toggleModal={addNoteClicked} focusTrap={false}>
            <div className="modal-header flex-cont align-center">
              <h2 className="flex-1">Note</h2>
              <div onClick={addNoteClicked} className="ghost button close">
                <i className="fas fa-times"></i>
              </div>
            </div>

            <div className="padding-1">
              {getNote(i)}
            </div>

            <div className="modal-footer align-center flex-cont">
              <div className="flex-1"></div>
              <div onClick={addNoteClicked} className="button margin-right-1">Close</div>
              
              {/* <div onClick={addNoteClicked} className={`button margin-right-1`}>Save</div> */}
            </div>
          </Modal>
        }
    </td>
  )
}



const EntryReview = ({tab, line, selectLine, lineIsSelected, lineReturnClicked}) => {

  const [hide, setHide] = useState(false);

  const [approveResponse, approveLoading, approveLoaded, approveError, doApproveFetch, setApproveResult] = useApi();
  const lineApproveClicked = async (line) => {
    doApproveFetch(approveLine, {payload: {id: line.id}});
    setHide(true);
    // store.dispatch(removeEntry(line.id))
  };

  // useEffect(() => {
  //   if (approveLoaded) {
  //     store.dispatch(removeEntry(line.id))
  //   }
  // }, [approveLoaded]);






  return(
    <>
      {!hide &&
        <tr>
          <td>
            <div onClick={() => selectLine(line.id)} className={lineIsSelected(line.id) ? "selected check-box" : "check-box"}>
              <div className="check"></div>
            </div>
          </td>
          <td className="fit nowrap">
            {/* <div>{JSON.stringify(line?.id)}</div> */}
            {/* <strong>{line.status.toUpperCase()}</strong> */}
            {/* || returnLoading */}
            {(false && approveLoading) ? (
              <div className="full center">
                <div className="absolute spinner font-05"></div>
              </div>
              
            ) : (
              <>
                <button onClick={() => lineApproveClicked(line)} className="outline pill first button">APPROVE</button>
                <button onClick={() => lineReturnClicked(line)} className="outline pill last button">RETURN</button>
              </>
            )}

            
          </td>
          {tab === "delegated" &&
            <td>{line.pmname}</td>
          }
          <td className="fit nowrap">{line.projectId}<br />{line.projectName}</td>
          <td className="fit">{line.activityNumber}<br />{line.activityName}</td>
          <td className="fit">{line.phaseDesc}</td>
          <td className="fit nowrap">{line.category}</td>
          <td className="fit nowrap">{DateTime.fromISO(line.weekStart).toLocaleString()}</td>
          <td className="fit nowrap">{DateTime.fromISO(line.weekEnd).toLocaleString()}</td>
          <td className="fit nowrap">{line.totalPeriodHours}</td>
          {[...Array(7)].map((day, i) => {
            return (
              <HourCell line={line} i={i} />
            );
          })}
          <td>
            {/* <div onClick={() => toggleLineTooltip(line.id)} className="tooltip-cont chevron-cont">
              <i className="fas fa-chevron-down"></i>
              <div className={lineTooltipIsOpen(line.id) ? "open line tooltip" : "line tooltip"}>
                <ul>
                  <li onClick={() => lineApproveClicked(line)}><a className="nowrap">Approve</a></li>
                  <li onClick={() => lineReturnClicked(line)}><a className="nowrap">Return</a></li>
                </ul>
              </div>

            </div> */}

          </td>
        </tr>
      }
    </>
  );


}



const mapState = state => ({
  entries: state.entryState.data,
});

export default connect(mapState)(EntryReview);