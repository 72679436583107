import React from 'react';
import Modal from '../../components/Modal';

import BudgetPrintViewer from './BudgetPrintViewer';

const BudgetPrintModal = ({opened, toggleModal, project}) => {

  return (
    <Modal size="large" toggleModal={toggleModal} focusTrap={false}>
      <BudgetPrintViewer toggleModal={toggleModal} opened={opened} project={project} />
    </Modal>
  )
}

export default BudgetPrintModal;