import { andyFetch, actionReturn } from '../../../api';

export const getDelegateUsers = (params) => {
  return async (dispatch, getState) => {
    const url = "manager/users/delegate_users";
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};


export const getMyProjectDelegates = (params) => {
  return async (dispatch, getState) => {
    const url = "manager/users/my_project_delegates";
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};


export const getMyTimesheetDelegates = (params) => {
  return async (dispatch, getState) => {
    const url = "manager/users/my_timesheet_delegates";
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};



export const getDelegateCount = (params) => {
  return async (dispatch, getState) => {
    const url = "manager/users/my_delegate_count";
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};


export const getUsers = (params) => {
  return async (dispatch, getState) => {
    const url = "manager/users";
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};


export const getUsersFromCompany = (params) => {
  return async (dispatch, getState) => {
    const url = "manager/users/from_company";
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};
