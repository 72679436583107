import React, { useLayoutEffect, useEffect, useState, useRef } from 'react';
import { useClickOutside } from '../hooks/useClickOutside';

const Row = ({selectedValue, labelComponent, listRef, index, item, labelFn, valueFn, highlightIndex, itemClicked}) => {
  const itemRef = useRef(null);
  const isHighlighted = () => {
    return highlightIndex !== null && index !== null && highlightIndex === index
  }

  useEffect(() => {

    if (itemRef && itemRef.current && isHighlighted()) {
      // 
      // itemRef.current.scrollIntoView()
    }
    
  }, [highlightIndex]);

  const value = valueFn(item);
  const isSelected = value === selectedValue;
  

  return (
    <div 
      ref={itemRef} 
      key={value} 
      className={`item ${highlightIndex === index ? 'highlighted' : ''} ${isSelected ? 'existing-item' : ''}`} 
      onClick={() => itemClicked(item)}
    >
      {labelComponent !== undefined &&
        <>
          {labelComponent(item)}
        </>
      }
      
      
    </div>  
  )
}


const AutoComplete = ({onSetValue=null, hide=false, disabled, validation=null, resetField, trigger, required=true, resetDependency=[], getValues, blankMessage="No results found.", selectedItemHash=null, itemsLoading=false, fetchItems, canReset=false, onSelect=null, labelComponent, searchableAttributes, label, labelFn, valueFn, setValue, defaultSearch, defaultValue, name, items, register, errors}) => {

  const wrapperRef = useRef(null);
  const inputRef = useRef(null);

  const initialDepVal = useRef(resetDependency);
  const firstUpdate = useRef(true);

  const [search, setSearch] = useState(defaultSearch ? defaultSearch : "");
  const [filtered, setFiltered] = useState(items);
  const [listOpened, setListOpened] = useState(false);
  const [selectedItem, setSelectedItem] = useState(selectedItemHash);
  

  const handleClickOutside = event => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setListOpened(false);
    }
  }; 

  // useEffect(() => {
  //   alert(`AutoComplete: ${JSON.stringify(setValue, null, 2)}`);
  // }, [setValue]);

  useEffect(() => {

    // alert(JSON.stringify(items, null, 2));

    if (firstUpdate.current) {
      firstUpdate.current = false;
    }

    document.addEventListener("mousedown", handleClickOutside, false);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside, false);
    };
  }, []);

  const formattedSearchableAttributes = (item) => {
    if (!searchableAttributes) return null;

    let array = searchableAttributes.map(a => item[a]?.toString().toUpperCase().replace(/ /g, ''));
    if (searchableAttributes.length > 1) {
      array.push(
        labelFn(item)?.toString().toUpperCase().replace(/ /g, '')
      );
    }
    return array
  }


  useEffect(() => {

    // if (items && !selectedItem) {
    //   items.forEach(item => {
    //     const value = valueFn(item);

    //     if (value === defaultValue) {
    //       setSelectedItem(item);
      
    //     }
    //   })
    // }

  }, [selectedItemHash]);

  useEffect(() => {
    if (search) {
      const formattedSearch = search.toUpperCase().replace(/ /g, '');
      const f = items?.filter(item => 
        {
          const attrs = formattedSearchableAttributes(item);

          if (!attrs) return false;
          return attrs.some(attr => attr.includes(formattedSearch)) || attrs.join('').includes(formattedSearch); 
        }
      )
      setFiltered(f)
    }
    else {
      setFiltered(items)
    }
  }, [search, items, listOpened]);

  const orderedFiltered = () => {

    return filtered;


    if (!filtered) return null;

    if (selectedItem) {
      const value = valueFn(selectedItem)
      return filtered.filter(item => valueFn(item) != value);
    }
    else {
      return filtered;
    }
    


  }


  useEffect(() => {
    // alert(JSON.stringify(selectedItem, null, 2));
    // setSearch(defaultSearch ? defaultSearch : "");
    // alert(firstUpdate.current);
    // if (firstUpdate && firstUpdate.current) {
    //   alert(firstUpdate.current);
    // }

    // if (items?.blank === true) {

    // }


    if (canReset === true) {
      // alert('reset')
      setSearch("");
      setListOpened(false);
      setSelectedItem(null);
    }
      
    
    
  }, [items]);

  useEffect(() => {

    if (JSON.stringify(resetDependency) !== JSON.stringify(initialDepVal.current)) {
      doReset();
    }
    
  }, [...resetDependency])

  const doReset = () => {
    // alert('here')


    setSearch("");
    // resetField(name);
    setListOpened(false);
    setSelectedItem(null);
    // alert(JSON.stringify(validation, null, 2))
    // register(name, validation ? validation : {required: `${label} is required.`})
    setValue(name, "", { shouldDirty: true, shouldValidate: false });
    if (onSetValue) {
      onSetValue(null);
    }
  }




  useEffect(() => {
    if (selectedItem) {

      setSearch(labelFn(selectedItem));
      if (inputRef?.current) {
        inputRef.current.blur();
      }
    }    
  }, [selectedItem]);

  const itemClicked = (item) => {
    setListOpened(false);
    setSelectedItem(item);
    setSearch(`${labelFn(item)}`);
    // alert(`${name} ${valueFn(item)}`);

    setValue(name, valueFn(item), { shouldDirty: true, shouldValidate: true });

    if (onSetValue) {
      onSetValue(item);
    }
    

    if (onSelect) {
      onSelect(item);
    }
    
    
  }

  useEffect(() => {
    if (!listOpened) {
      if (selectedItem) {
        setSearch(labelFn(selectedItem));
      }
    }
    else {
      if (fetchItems) {
        fetchItems();
      }
      
    }
  }, [listOpened])

  const inputFocused = () => {
    // alert('gere')

    if (!listOpened) {
      setListOpened(true);
    }
    
  }

  const [highlightIndex, setHighlightIndex] = useState(0);

  const checkKeyDown = (e) => { 
    if (!filtered || !listOpened) return;
    const keyCode = e.keyCode ? e.keyCode : e.which; 
    
    if (keyCode === 40) { 
      setHighlightIndex(o => Math.min(filtered.length-1, o+1));
    }
    else if (keyCode === 38) {
      setHighlightIndex(o => Math.max(0, o-1));
    } 
    else if (keyCode === 13) {
      setListOpened(false);
      const item = filtered[highlightIndex];
      setSelectedItem(item);
      setSearch(`${labelFn(item)}`);
      setValue(name, valueFn(item), { shouldDirty: true, shouldValidate: true });
      
    } 
  };

  // useEffect(() => {
  //   alert(search);
  // }, [search])

  const selectedValue = selectedItem ? valueFn(selectedItem) : null;

  const hasValue = (val) => {
    if (val === null || val === undefined || val.length === 0) return false;

    return true;
  }

  const finalItems = () => {
    const shouldShowAll = (selectedItem && labelFn(selectedItem) === search);
    
    // JSON.stringify(selectedItemHash) === JSON.stringify(selectedItem);

    if (shouldShowAll) {
      return items;
    }

    return orderedFiltered();
  }

  

  return (
    <div className={`${disabled ? 'autocomplete-disabled' : ''}`}  >
      <div className={`${errors ? 'has-errors' : ''} entry-cell-label gray bold font-075 uppercase`}>{label}</div>

      {/* <div className="button" onClick={() => setValue(name, "ANDY")}>Set</div>
      <div className="button" onClick={() => alert(JSON.stringify(getValues(), null, 2))}>Get</div> */}
    <div key={name} ref={wrapperRef} className="autocomplete">


      <div className="menu-cont">

        <div className="flex-cont align-center">


{/* <pre>{JSON.stringify(resetDependency, null, 2)}</pre> */}
          {/* <pre>
            selectedItem: {JSON.stringify(selectedItem,null,2)}
            <br />
            selectedItemHash: {JSON.stringify(selectedItemHash,null,2)}
          </pre> */}
{/* 
          <pre>
            {JSON.stringify(selectedValue)}
          </pre> */}

          {/* <div className="button" onClick={() => trigger()}></div> */}


          <input 
            onFocus={inputFocused} 
            ref={inputRef}
            type="text" 
            name="item_search" 
            id="" 
            autoComplete="off"
            onKeyDown={(e) => checkKeyDown(e)} 
            defaultValue={defaultSearch}
            placeholder={`${disabled ? '' : 'Search...'}`}
            value={search} 
            className={`${errors ? 'has-errors' : ''}`}  
            onChange={e => setSearch(e.target.value)} 
          />

          <input
            style={{display: 'none'}}
            type="text"
            name={name} 
            id={name} 
            defaultValue={defaultValue} 
            // ref={register({
            //   required: "Required."
            // })}
            {...register(name, validation ? validation : {required: `${label} is required.`})}
          />
        </div>

        {listOpened &&
          <div className="menu">

            {/* {selectedItem && hasValue(selectedValue) &&
              <Row 
                isSelected={true}
                valueFn={valueFn} 
                labelFn={labelFn} 
                key={`item-row-${valueFn(selectedItem)}`} 
                index={0} 
                item={selectedItem} 
                highlightIndex={highlightIndex} 
                itemClicked={itemClicked} 
                labelComponent={labelComponent}
              />
            } */}

            {itemsLoading && 
              <div className="margin-1 flex-cont align-center">
                <div className="font-05 spinner"></div>
                {/* <div className="margin-left-half bold spinner-text">Loading...</div> */}
              </div>
              
            }
            {!itemsLoading && 
              <>
                
                

                  {/* <Row 
                    isSelected={true}
                    valueFn={valueFn} 
                    labelFn={labelFn} 
                    key={`item-row-${valueFn(selectedItem)}`} 
                    index={0} 
                    item={selectedItem} 
                    highlightIndex={highlightIndex} 
                    itemClicked={itemClicked} 
                    labelComponent={labelComponent}
                  /> */}
                
                {finalItems()?.map((item, index) =>
                    <Row 
                      valueFn={valueFn} 
                      labelFn={labelFn} 
                      key={`item-row-${valueFn(item)}`} 
                      index={index} 
                      item={item} 
                      selectedValue={selectedValue}
                      highlightIndex={highlightIndex} 
                      itemClicked={itemClicked} 
                      labelComponent={labelComponent}
                    />
                )}



                {!itemsLoading && !!!items?.length &&
                  <div className="gray item" style={{cursor: 'default', pointerEvents: 'none'}}>
                    {blankMessage}
                  </div>
                }
                {!itemsLoading && !!items?.length && !!!finalItems()?.length &&
                  <div className="gray item" style={{cursor: 'default', pointerEvents: 'none'}}>
                    No results.
                  </div>
                }

                {/* {!itemsLoading && !!!filtered?.length && !hasValue(selectedValue) && 
                  <div className="gray item" style={{cursor: 'default', pointerEvents: 'none'}}>
                    {blankMessage}
                  </div>
                } */}
              </>
            }
          </div>
        }
      </div>
    </div>
    </div>
  )
}

export default AutoComplete;
