import { andyFetch, actionReturn } from '../../api';

export const changeViewAsUser = (params) => {

  return async (dispatch, getState) => {

    const url = `admin/users/change_view_as_user`;

    try {
      localStorage.setItem('viewAsUserID', params?.query?.workerrecid?.toString())
      const response = await andyFetch(url, params, "POST");

      if (response.ok) {
        const json = await response.json();

        const { data } = json;
  
        if (data) {
          dispatch({
            type: 'SET_VIEW_AS_USER', 
            viewAsUser: data,
          });

          dispatch({
            type: 'SET_CURRENT_COMPANY', 
            currentCompany: data?.current_company,
          });
          if (data.current_company) {
            localStorage.setItem('currentCompanyID', data?.current_company);
          }
        }


        
        return actionReturn(json);

      } else {
        throw new Error('changeViewAsUser error.');
      }
    }
    catch (e) {
      
    }
  }
};


export const getNewUsers = (params) => {

  return async (dispatch, getState) => {

    const url = "admin/users/new_users";

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};


export const getManagerUsers = (params) => {
  return async (dispatch, getState) => {
    const url = "admin/users/manager_users";
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};

export const getAutoCompleteUsers = (params) => {
  return async (dispatch, getState) => {
    const url = "admin/users/auto_complete_users";
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};


export const getSupportTicketUsers = (params) => {
  return async (dispatch, getState) => {
    const url = "admin/users/support_ticket_users";
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};


export const getUsers = (params) => {

  return async (dispatch, getState) => {

    const url = "admin/users";

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return actionReturn(json, response);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};

export const deleteUser = (params) => {
  return async (dispatch, getState) => {
    const url = "admin/users/delete";
    try {
      const response = await andyFetch(url, params, "POST");
      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);
      } else {
        throw new Error('createUser error.');
      }
    }
    catch (e) {
    }
  }
};



export const createUser = (params) => {

  return async (dispatch, getState) => {

    const url = "admin/users";

    try {

      const response = await andyFetch(url, params, "POST");

      if (response.ok) {
        const json = await response.json();
       

        return actionReturn(json);

      } else {
        throw new Error('createUser error.');
      }
    }
    catch (e) {
    }
  }
};

export const updateUser = (params) => {

  return async (dispatch, getState) => {

    const url = `admin/users/update_user`;

    try {

      const response = await andyFetch(url, params, "PATCH");

      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);

      } else {
        throw new Error('updateUser error.');
      }
    }
    catch (e) {
      
    }
  }
};

