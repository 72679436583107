import { andyFetch, actionReturn } from '../../api';

export const getExpenseLineCategories = (params) => {

  return async (dispatch, getState) => {

    const url = "admin/expense_line_categories";

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};

export const deleteExpenseLineCategory = (params) => {
  return async (dispatch, getState) => {
    const url = "admin/expense_line_categories/delete";
    try {
      const response = await andyFetch(url, params, "POST");
      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);
      } else {
        throw new Error('createExpenseLineCategory error.');
      }
    }
    catch (e) {
    }
  }
};



export const createExpenseLineCategory = (params) => {

  return async (dispatch, getState) => {

    const url = "admin/expense_line_categories";

    try {

      const response = await andyFetch(url, params, "POST");

      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);

      } else {
        throw new Error('createExpenseLineCategory error.');
      }
    }
    catch (e) {
    }
  }
};

export const updateExpenseLineCategory = (params) => {

  return async (dispatch, getState) => {

    const url = `admin/expense_line_categories/${params?.payload?.expense_line_category?.id}`;

    try {

      const response = await andyFetch(url, params, "PATCH");

      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);

      } else {
        throw new Error('updateExpenseLineCategory error.');
      }
    }
    catch (e) {
      
    }
  }
};