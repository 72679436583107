import React from "react";
import { Link } from "react-router-dom";


const AdminTeamsHome = () => {

  return (
    <>
      <h1>Teams</h1>
      

      <div className="flex-wrap">

        <Link to="project-manager">
          <div className="card outline">
            <div className="title flex-cont align-center">
              <i className="fas fa-money-check-alt margin-right-1 gray font-1-5"></i>
              <div>Project Manager Teams</div>
              
            </div>

            <div className="button ghost margin-top-1">
              VIEW
              <i className="fas fa-chevron-right margin-left-1 margin-top-2px font-075"></i>
            </div>
          </div>
        </Link>

        {process.env.REACT_APP_DEPLOYMENT_SLOT !== "prod" &&

          <Link to="office-manager">
            <div className="card outline">
              <div className="title flex-cont align-center">
                <i className="fas fa-building margin-right-1 gray font-1-5"></i>
                <div>Office Manager Teams</div>
                
              </div>

              <div className="button ghost margin-top-1">
                VIEW
                <i className="fas fa-chevron-right margin-left-1 margin-top-2px font-075"></i>
              </div>
            </div>
          </Link>
      }
        
      </div>
    </>
  )
}

export default AdminTeamsHome