import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link, NavLink, Outlet, useLocation
} from 'react-router-dom';
import { connect } from 'react-redux';


const isActive = ({ isCurrent }) => {
  return isCurrent ? { className: "active" } : null
}



const AdminTeamsNav = ({children, count}) => {


  return(
    <ul className="sub-nav">
      <li>
        <NavLink end to="/teams">
          Home
        </NavLink>
      </li>

      <li>
        <NavLink to="/teams/project-manager">
          Project Manager
        </NavLink>
      </li>

      {process.env.REACT_APP_DEPLOYMENT_SLOT !== "prod" &&

        <li>
          <NavLink to="/teams/office-manager">
            Office Manager
          </NavLink>
        </li>
      }
{/* 
      <li>
        <NavLink to="/reports/unposted-timesheets">
          Unposted Timesheets (temp tool)
        </NavLink>
      </li> */}

    </ul>
  );
}


function mapStateToProps(state) {
  return { 
    // currentUser: state.sessionState.currentUser,
  };
} 

export default connect(mapStateToProps)(AdminTeamsNav);
