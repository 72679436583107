import React, { useEffect, useState } from 'react';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link, Outlet, useLocation, useParams
} from 'react-router-dom';

import * as yup from "yup";
import { useForm } from 'react-hook-form';

import * as msal from "@azure/msal-browser";
import store from '../../redux/store';
import { connect } from 'react-redux';

import AdminTeamsForm from './AdminTeamsForm';

import { getTeam } from '../../redux/admin/teamActions';
import { useApi } from '../../api';


const AdminTeamsEdit = ({formTeam, currentCompany}) => {
  
  
  const { id } = useParams();

  const [result, loading, loaded, error, doFetch, setResult, pagy] = useApi();
  
  useEffect(() => {
    fetchTeam();
  }, []);

  const fetchTeam = () => {
    doFetch(getTeam, {query: {id: id}});
  }
  
  return (
    <>
      <div className="xxx margin padding-2x">

        <h1 className="flex-cont">
          <div className="medgray margin-right-half">{currentCompany}</div>
          Edit Project Manager Team
        </h1>

        {loading && <div className="spinner"></div>  }
        {result &&
          <AdminTeamsForm type="Project" formTeam={result} />
        }

        

      </div>
    
    </>
  );
}

function mapState(state) {
  return { 
    // tokenResponse: state.authState.tokenResponse,
    formTeam: state.teamState.formTeam,
    currentCompany: state.authState.currentCompany
  };
} 

export default connect(mapState)(AdminTeamsEdit);
