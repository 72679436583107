
const initialState = {
  tokenLoading: false,
  redirectLoading: false,
  tokenResponse: null,
  dynamicsToken: null,
  currentAccount: null,
  currentUser: null,
  viewAsUser: null,
  reAuth: false,
  currentCompany: null,
};

const authReducer = ( state = initialState, action) => {


  switch(action.type) {
    case "SET_REAUTH":
      return {
        ...state,
        reAuth: action.reAuth,
      }
    case "SET_TOKEN_LOADING":
      return {
        ...state,
        tokenLoading: action.tokenLoading,
      }
    case "SET_REDIRECT_LOADING":
      return {
        ...state,
        redirectLoading: action.redirectLoading,
      }
    case "SET_TOKEN_RESPONSE":
      return {
        ...state,
        tokenResponse: action.tokenResponse,
        tokenLoading: false,
      }
    case "UNSET_TOKEN_RESPONSE":
      return {
        ...state,
        tokenResponse: null,
        dynamicsToken: null,
        tokenLoading: false,
      }
      case "SET_DYNAMICS_TOKEN":
        return {
          ...state,
          dynamicsToken: action.dynamicsToken,
        }
      case "UNSET_DYNAMICS_TOKEN":
        return {
          ...state,
          dynamicsToken: null,
        }
    case "SET_CURRENT_ACCOUNT":
      return {
        ...state,
        currentAccount: action.currentAccount,
      }
    case "UNSET_CURRENT_ACCOUNT":
      return {
        ...state,
        currentAccount: null,
      }
    case "SET_CURRENT_USER":
      return {
        ...state,
        currentUser: action.currentUser,
      }
    case "UNSET_CURRENT_USER":
      return {
        ...state,
        currentUser: null,
      }
    case "SET_VIEW_AS_USER":
      return {
        ...state,
        viewAsUser: action.viewAsUser,
      }
    case "UNSET_VIEW_AS_USER":
      return {
        ...state,
        viewAsUser: null,
      }
    case "SET_CURRENT_COMPANY":
      return {
        ...state,
        currentCompany: action.currentCompany,
      }
    case "UNSET_CURRENT_COMPANY":
      return {
        ...state,
        currentCompany: null,
      }
    default :
      return state
  }
}

export default authReducer;