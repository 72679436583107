const initialState = {
  data: null,

  reviewCount: null,
  pagy: null,
};

const budgetHeaderReducer = ( state = initialState, action) => {
  switch(action.type) {
    case "CLEAR_BUDGET_HEADER":
      return initialState
    case "SET_BUDGET_HEADER_REVIEW_COUNT":
      return {
        ...state,
        reviewCount: action.reviewCount
      }
    case "SET_BUDGET_HEADER":
      return {
        ...state,
        data: action.budgetHeader
      }
    case "ADD_BUDGET_LINE_TASK":
      return {
        ...state,
        data: {
          ...state.data,
          budgetLineTasks: [...state.data.budgetLineTasks, action.budgetLineTask]
        }
      }

    case "REMOVE_BUDGET_LINE_TASK":
      return {
        ...state,
        data: {
          ...state.data,
          budgetLineTasks: [...state?.data?.budgetLineTasks?.filter(e => e?.id?.toString() !== action?.budgetLineTaskId?.toString())]
        }
      }
    case "ADD_BUDGET_FEE":
      return {
        ...state,
        data: {
          ...state.data,
          budgetFees: [...state.data.budgetFees, action.budgetFee]
        }
      }

    case "REMOVE_BUDGET_FEE":
      return {
        ...state,
        data: {
          ...state.data,
          budgetFees: [...state?.data?.budgetFees?.filter(e => e?.id?.toString() !== action?.budgetFeeId?.toString())]
        }
      }
    case "ADD_BUDGET_HEADER":
      return {
        ...state,
        data: [...state.data, action.budgetHeader]
      }
    case "REMOVE_BUDGET_HEADER":
      return {
        ...state,
        data: [...state.data.filter(e => e.id.toString() !== action.budgetHeaderId.toString())]
      }
    case "CHANGE_BUDGET_HEADER":
      return {
        ...state,
        data: state.data.map(budgetHeader => budgetHeader.id === action.budgetHeader.id ? {...action.budgetHeader} : budgetHeader)
      }
    default :
      return state
  }
}

export default budgetHeaderReducer;