import React from 'react';

import AdminReportsNav from './AdminReportsNav';


const AdminReportsLayout = ({children}) => {

  return (
    <>
      <AdminReportsNav />
      {children}
    </>
  )
}

export default AdminReportsLayout;