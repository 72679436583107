import React, { useState, useEffect, useRef } from 'react';
// import { Link, navigate } from "@reach/router"


import {
  BrowserRouter as Router,
  Routes,
  Route,
  useParams,
  Link, Outlet, useLocation, useNavigate
} from 'react-router-dom';


import { DateTime } from 'luxon';
import store from '../../redux/store';
// import { getTimesheets, createTimesheet, api, showFullscreenLoading, hideFullscreenLoading } from './actions';
import { connect } from 'react-redux';


import Pagination from '../../components/Pagination';

import { getTimesheetsIApproved, getMyTimesheets, postTimesheet, createTimesheet, updateTimesheet, getTimesheets, deleteTimesheet } from '../../redux/timesheets/timesheetActions';
import { useApi } from '../../api';
import { clearStore } from '../../redux/authActions';
import TimesheetPrintModal from '../TimesheetPrintModal';

import TH from './TH';
import { useSortTimesheets, toNum, isNumber } from './useSortTimesheets';
import Selector from '../../components/Selector';


const TimesheetRow = ({tag, printClicked, selectTimesheet, timesheetIsSelected, timesheetSubmitClicked, timesheetDeleteClicked, timesheet}) => {

  

  const [timesheetTooltipIsOpen, setTimesheetTooltipIsOpen] = useState(false);

  

  const showTooltip = () => {
    document.addEventListener("mousedown", handleClickOutside, false);
    setTimesheetTooltipIsOpen(true);
  }
  const hideTooltip = () => {
    document.removeEventListener("mousedown", handleClickOutside, false);
    setTimesheetTooltipIsOpen(false);
  }

  const toggleTooltip = () => {
    if (!timesheetTooltipIsOpen) {
      showTooltip();
    }
    else {
      hideTooltip();
    }
  }



  const statusBadge = (statusText) => {
    const s = statusText?.toUpperCase();

    if (s === "SUBMITTED" || s === "DRAFT") {
      return (
        <div className="bold font-075">
          {statusText?.toUpperCase()}
        </div>
      )
      
    }
    else if (s === "RETURNED") {
      return (
        <div className={`status-badge-cont ${statusText?.toLowerCase()} font-075`}>
          <div className="status-badge">
            {/* fas fa-exclamation-triangle  */}
            <i className={`${s === "RETURNED" ? 'fas fa-exclamation-triangle' : 'fas fa-check-circle'} margin-right-half font-1`}></i>
            <div className="font-1">{statusText?.toUpperCase()} </div>
          </div>
        </div>
      )
    }
    else {
      return (
        <div className={`status-badge-cont ${statusText?.toLowerCase()} font-075`}>
          <div className="status-badge">
            {/* fas fa-exclamation-triangle  */}
            {s === "POSTED" &&
              <i className={`fas fa-check-circle margin-right-half font-1`}></i>
            }
            <div className="font-1">{statusText?.toUpperCase()} </div>
          </div>
        </div>
      )
    }
  }

  const wrapperRef = useRef(null);

  useEffect(() => {
    // document.addEventListener("mousedown", handleClickOutside, false);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = event => {
    // alert(timesheetTooltipIsOpen)
    // hideTooltip();
    // // alert(timesheetTooltipIsOpen);
    // 
    // 

    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      hideTooltip();
    }
  };



  
  return (
    <tr>
      {/* <td>
        <div onClick={() => selectTimesheet(timesheet.id)} className={timesheetIsSelected(timesheet.id) ? "selected check-box" : "check-box"}>
          <div className="check"></div>
        </div>
      </td> */}
      <td className="center fit nowrap bold">
        <Link className="text-link" to={`/timesheets/${timesheet.id}`}>
          {statusBadge(timesheet.status)}
        </Link>
      </td>

      
      <td className="fit nowrap">
        {timesheet.employeeName}
      </td>
      <td className="fit nowrap">
        <Link className="text-link" to={`/timesheets/${timesheet.id}`}>
          {timesheet.number}
        </Link>
      </td>
      


        <td  className="fit nowrap">
          {timesheet.pmname}
        </td>
      
      
      <td className="fit nowrap">{DateTime.fromISO(timesheet.weekStart).toLocaleString()}</td>
      <td className="fit nowrap">{DateTime.fromISO(timesheet.weekEnd).toLocaleString()}</td>
      <td className="fit nowrap">{timesheet.totalHours}</td>
      <td>
        <div ref={wrapperRef}>
        
        <div onClick={() => toggleTooltip(timesheet.id)} className="tooltip-cont chevron-cont">
          <i className="fas fa-chevron-down"></i>
          <div className={timesheetTooltipIsOpen ? "open timesheet tooltip" : "timesheet tooltip"}>
            <ul>
              <li><Link to={`/timesheets/${timesheet.id}`}>Open</Link></li>
              {/* {timesheet.statusid === 0 &&
                <li onClick={() => timesheetSubmitClicked(timesheet)}><a>Submit</a></li>
              } */}
              
              <li>

                <div onClick={() => printClicked(timesheet)}><a>Print</a></div>



              </li>
              {timesheet.statusid === 0 &&
                <li onClick={() => timesheetDeleteClicked(timesheet)}><a>Delete</a></li>
              }
            </ul>
          </div>

        </div>
        </div>
        
      </td>
    </tr>
  )
}


const TimesheetsApproved = ({approvedCount, currentCompany, isLoading, fullScreenLoading}) => {

  const { tag } = useParams();

  const navigate = useNavigate("/");

  const [timesheets, loading, loaded, error, doFetch, setResult, pagy] = useApi();
  const [periods, periodsLoading, periodsLoaded, periodsError, doPeriodsFetch, setPeriodsResult] = useApi();
  const [tab, setTab] = useState("managed");


  const [selectedTimesheets, setSelectedTimesheets] = useState([]);
  const [actionsOpen, setActionsOpen] = useState(false);
  const [openTimesheetTooltip, setOpenTimesheetTooltip] = useState(null);

  const [dateTooltipOpened, setDateTooltipOpened] = useState(false);
  const [newTimesheetOpened, setNewTimesheetOpened] = useState(false);

  const [selectedPeriod, setSelectedPeriod] = useState(null);

  const periodSelectRef = useRef(null);

  const periodRows = useRef({});

  const [pastPeriods, setPastPeriods] = useState(null);
  const [futurePeriods, setFuturePeriods] = useState(null);

  const [page, setPage] = useState(1);


  const fetchTimesheets = () => {
    doFetch(getTimesheetsIApproved, {query: {page: page, tab: tab, all: (tag === "all")}});
  }

  useEffect(() => {
    store.dispatch(clearStore('ENTRIES'));
    fetchTimesheets();
  }, [currentCompany, tag, tab, page]);




  // useEffect(() => {
  //   // store.dispatch(getTimesheets());
  //   if (periods) {

  //       setPastPeriods(periods.past);
  //       setFuturePeriods(periods.future);

  //       if (periods.future) {
  //         setSelectedPeriod(periods.future[0]);
  //       }
        

        
  //   }
  // }, [periods]);

  // useEffect(() => {
  //   if (selectedPeriod) {
  //     periodRows && periodRows.current && periodRows.current[selectedPeriod.periodStart] && periodRows.current[selectedPeriod.periodStart].scrollIntoView({ behavior: 'smooth' });
  //   }
  // }, [periodRows])

  const timesheetIsSelected = id => {
    return selectedTimesheets.includes(id);
  };

  const selectTimesheet = id => {
    // setSelectedTimesheets(oldArray => [...oldArray, id]);
    // setSelectedTimesheets(oldArray => [...oldArray, id]);

    if (timesheetIsSelected(id)) {
      setSelectedTimesheets(oldArray => oldArray.filter( elem => elem !== id));
    }
    else {
      setSelectedTimesheets(oldArray => [...oldArray, id]);
    }
  }

  const toggleActionsTooltip = () => {
    setOpenTimesheetTooltip(null);
    setActionsOpen(oldValue => !oldValue);
  };

  
  const timesheetTooltipIsOpen = id => {
    return openTimesheetTooltip === id;
  };

  const toggleTimesheetTooltip = id => {
    setActionsOpen(false);
    timesheetTooltipIsOpen(id) ? setOpenTimesheetTooltip(null) : setOpenTimesheetTooltip(id)
  };

  const toggleAllTimesheets = () => {
    if (!timesheets) return;

    if (allTimesheetsSelected()) {
      setSelectedTimesheets([]);
    }
    else {
      setSelectedTimesheets(timesheets.map(t => t.id));
    }
  }

  const allTimesheetsSelected = () => {
    if (!timesheets) return;

    return selectedTimesheets.length === Object.keys(timesheets).length;
  }

  const wrapperRef = useRef(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside, false);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = event => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setActionsOpen(false);
    }
  };

  const toggleDateTooltipOpened = () => {
    setDateTooltipOpened(old => !old);
    if (periodSelectRef && periodSelectRef.current) {
      periodSelectRef.current.scrollTo(0, 1000);
      
    }
  } 

  const toggleNewTimesheetOpened = () => {
    setNewTimesheetOpened(old => !old);
  }

  const getCurrentPeriod = () => {
    const day = DateTime.local().toObject().day;

    if (day <= 14) {
      return DateTime.local().startOf("month");
    }
    else {
      return DateTime.local().startOf("month").plus({days: 15});
    }
  }

  const timesheetSubmitClicked = async (timesheet) => {

    alert('To be implemented.');
    // try {
    //   // const response = await store.dispatch(submitTimesheet(json));

    //   store.dispatch(showFullscreenLoading("Submitting timesheet..."));
    //   const response = await store.dispatch(api('timesheets/submit', {id: timesheet.id}, null, 'POST', 'SUBMIT_TIMESHEET'));
    //   const r = await store.dispatch(api('timesheets', null, {page: ""}, 'GET', 'TIMESHEETS'));
    //   store.dispatch(hideFullscreenLoading());
    //   

    // }
    // catch (e) {
    //   store.dispatch(hideFullscreenLoading());
    //   alert(e);

    //   // setIsLoading(false);
    // }
  }

  const [delModalOpened, setDelModalOpened] = useState(false);
  const [delTimesheet, setDelTimesheet] = useState(null);

  const toggleDelModal = (reload=false) => {
    setDelModalOpened(o => !o);

    if (reload) {
      fetchTimesheets();
    }
    
  }

  const timesheetDeleteClicked = async (timesheet) => {
    setDelTimesheet(timesheet);
    toggleDelModal();

    // alert('To be implemented.');
    // try {
    //   store.dispatch(showFullscreenLoading("Deleting timesheet..."));
    //   const response = await store.dispatch(api('timesheets/delete', {id: timesheet.id}, null, 'DELETE', 'DELETE_TIMESHEET'));
    //   const r = await store.dispatch(api('timesheets', null, {page: ""}, 'GET', 'TIMESHEETS'));
    //   store.dispatch(hideFullscreenLoading());
    //   

    // }
    // catch (e) {
    //   store.dispatch(hideFullscreenLoading());
    //   alert(e);

    //   // setIsLoading(false);
    // }
  }


  const [deleteResult, deleteLoading, deleteLoaded, deleteError, doDeleteFetch, setDeleteResult] = useApi();
  const tryDeleteTimesheet = () => {
    if (delTimesheet) {
      const payload = {
        timesheet: {
          id: delTimesheet.id
        }
      }
      doDeleteFetch(deleteTimesheet, {payload: payload});
    }
  }
  useEffect(() => {
    if (deleteLoaded) {
      toggleDelModal(true);
    }
  }, [deleteLoaded]);

  const bulkSubmitClicked = async () => {

    alert('To be implemented.');
    setActionsOpen(false);
    // try {
    //   // const response = await store.dispatch(submitTimesheet(json));

    //   store.dispatch(showFullscreenLoading("Submitting timesheet..."));
    //   const response = await store.dispatch(api('timesheets/submit', {id: selectedTimesheets}, null, 'POST', 'SUBMIT_TIMESHEET'));
    //   const r = await store.dispatch(api('timesheets', null, {page: ""}, 'GET', 'TIMESHEETS'));
    //   store.dispatch(hideFullscreenLoading());
    //   
    //   setActionsOpen(false);

    // }
    // catch (e) {
    //   store.dispatch(hideFullscreenLoading());
    //   alert(e);

    //   setActionsOpen(false);

    //   // setIsLoading(false);
    // }

  }

  const {sorted, sortByColumn, setSorted, sortedColumn, sortDirection, columnClicked} = useSortTimesheets();

  useEffect(() => {
    if (timesheets) {
      setSorted(sortByColumn([...timesheets], sortedColumn, sortDirection));
    }
  }, [sortedColumn, timesheets, sortDirection]);

  useEffect(() => {
  }, [sorted]);

  

  const renderContent = () => {

    if (loading) {
      return <div className="spinner"></div>
    }

    if (!sorted || sorted.length === 0) {
      return(
        <div className="margin-top-0">
          No results.
        </div>
      );
    }
   
    return(

      <table className="basic">
        <thead>
          <tr>
            {/* <th className="fit nowrap">
              <div onClick={toggleAllTimesheets} className={allTimesheetsSelected() ? "selected check-box" : "check-box"}>
                <div className="check"></div>
              </div>
            </th> */}
            {/* <th className="fit nowrap">USER ID</th> */}
            <TH direction={sortDirection} classNames={["fit", "nowrap"]} column="status" sortedColumn={sortedColumn} label="Status" columnClicked={columnClicked} />
            <TH direction={sortDirection} classNames={["fit", "nowrap"]} column="employeeName" sortedColumn={sortedColumn} label="Employee" columnClicked={columnClicked} />
            <TH direction={sortDirection} classNames={[]} column="number" sortedColumn={sortedColumn} label="Timesheet" columnClicked={columnClicked} />


              <TH direction={sortDirection} classNames={[]} column="pmname" sortedColumn={sortedColumn} label="PM" columnClicked={columnClicked} />
            
            <TH direction={sortDirection} classNames={["fit", "nowrap"]} column="weekStart" sortedColumn={sortedColumn} label="Start Date" columnClicked={columnClicked} />
            <TH direction={sortDirection} classNames={["fit", "nowrap"]} column="weekEnd" sortedColumn={sortedColumn} label="End Date" columnClicked={columnClicked} />
            <TH direction={sortDirection} classNames={[]} column="totalHours" sortedColumn={sortedColumn} label="Hours" columnClicked={columnClicked} />

            <th></th>
          </tr>
        </thead>
        <tbody>
          {sorted && sorted.map( timesheet => 
            <TimesheetRow tag={tag} printClicked={printClicked} key={timesheet.id} toggleTimesheetTooltip={toggleTimesheetTooltip} selectTimesheet={selectTimesheet} timesheetIsSelected={timesheetIsSelected} timesheetSubmitClicked={timesheetSubmitClicked} timesheetDeleteClicked={timesheetDeleteClicked} timesheet={timesheet} timesheetTooltipIsOpen={timesheetTooltipIsOpen} />
          )}
        </tbody>
      </table>
    );
    
  }

  const renderPeriodSelect = () => {
    return (
      <div className="tooltip-cont">
        <div className="select-cont">
          <select className="ghost button flex-cont align-center font-1-75">
            
            {Array(10).fill().map((v, i) => {
              let start = DateTime.local().startOf('month').plus({months: i});
              let end = DateTime.local().startOf('month').plus({months: i}).plus({weeks: 2}).minus({day: 1});
              


              if (i & 1) {
                start = end.plus({day: 1});
                end = start.endOf('month');
              }
              else {
                
              }
              
              return(
                <>
                  <option key={i}>{`${start.toLocaleString()} - ${end.toLocaleString()}`}</option>
                  {/* <li key={i+'b'}>{`${start2.toLocaleString()} - ${end2.toLocaleString()}`}</li> */}
                </>
              );


            })}
            
          </select>
            <i className="fas fa-chevron-down"></i>
        </div>
      </div> 
    );
  }

  // const [newTimesheetModalOpened, setNewTimesheetOpened] = useState(false);

  const newTimesheetClicked = async () => {
    // TO-DO CREATE TIMESHEET

    // toggleNewTimesheetOpened();

    doTimesheetCreateFetch(createTimesheet);

    // const r = await store.dispatch(createTimesheet());
    // 

    // if (r && r.id) {
    //   navigate(`/timesheets/${r.id}`);
    // }
    
  };

  useEffect(() => {
    executeScroll();
  }, [newTimesheetOpened, periods]);

  const setRowRef = (el, date) => {
    periodRows.current[date] = el
  };

  const currentRef = useRef(null)
  const parentRef = useRef(null)


  const executeScroll = () => {

    if (currentRef && currentRef.current && parentRef && parentRef.current) {
      scrollParentToChild(parentRef.current, currentRef.current)
    }
  } 
  // const scrollToRef = (ref) => scrollParentToChild(parentRef, ref);


  function scrollParentToChild(parent, child) {

    // Where is the parent on page
    var parentRect = parent.getBoundingClientRect();
    // What can you see?
    var parentViewableArea = {
      height: parent.clientHeight,
      width: parent.clientWidth
    };
  
    // Where is the child
    var childRect = child.getBoundingClientRect();
    // Is the child viewable?
    var isViewable = (childRect.top >= parentRect.top) && (childRect.bottom <= parentRect.top + parentViewableArea.height);
  
    // if you can't see the child try to scroll parent
    if (!isViewable) {
      // scroll by offset relative to parent
      parent.scrollTop = (childRect.top + parent.scrollTop) - parentRect.top
    }
  
  
  }


  const [timesheetCreateResult, timesheetCreateLoading, timesheetCreateLoaded, timesheetCreateError, doTimesheetCreateFetch, setTimesheetCreateResult] = useApi();


  const handleCreateTimesheet = async () => {
    const payload = {...selectedPeriod, prev: prevTs, copy: copyFromPrevChecked};
    doTimesheetCreateFetch(createTimesheet, {payload: payload});
    // try {
    //   store.dispatch(showFullscreenLoading("Creating timesheet..."));
    //   const payload = {...selectedPeriod, prev: prevTs, copy: copyFromPrevChecked};
    //   const response = await store.dispatch(createTimesheet(payload));
    //   store.dispatch(hideFullscreenLoading());
    //   
    //   // alert(JSON.stringify(response));
    //   navigate(`/timesheets/${response.data.id}`);
    // }
    // catch (e) {
    //   store.dispatch(hideFullscreenLoading());
    // }
    
  }

  useEffect(() => {
    if (timesheetCreateLoaded && timesheetCreateResult) {
      // alert(JSON.stringify(timesheetCreateResult));
      navigate(`/timesheets/${timesheetCreateResult.id}`);
    }
  }, [timesheetCreateLoaded, timesheetCreateResult]);









  const [copyFromPrevChecked, setCopyFromPrevChecked] = useState(false);

  const toggleCopyFromPrev = () => {

    setCopyFromPrevChecked(old => !old);
  }

  const [prevTs, setPrevTs] = useState(null);

  useEffect(() => {
    if (timesheets && timesheets.length > 0) {
      setPrevTs([...timesheets].reverse()[0].id);
    }
  }, [timesheets]);





  const bulkOpenClicked = () => {
    if (selectedTimesheets) {
      selectedTimesheets.forEach((timesheet, index) => {
        setTimeout(() => {
          window.open(`timesheets/${timesheet}`, "Print", "noopener,noreferrer");
        }, 1000 * (index-1));
      })
    }     
  }

  const handlePageChange = (p) => {
    setPage(p);
  };


  const [printModalOpened, setPrintModalOpened] = useState(false);
  const [printTimesheet, setPrintTimesheet] = useState(null);

  const printClicked = (timesheet) => {
    setPrintModalOpened(true);
    setPrintTimesheet(timesheet);
  }
  const togglePrintModal = () => {
    setPrintModalOpened(o => !o);
  }

  const newButtonDisabled = timesheetCreateLoading;

  const tabCount = (name) => {
    // approvedCount?.hash?.mine
    
  }


  return (
    <div>
      {/* {fullScreenLoading &&
        <FullScreenSpinner message="Creating timesheet..." />
      } */}



      {printModalOpened &&
        <TimesheetPrintModal opened={printModalOpened} timesheet={printTimesheet} toggleModal={togglePrintModal} />
      }


      {/* <div className="flex-cont align-center">
        <h1>Approved Timesheets</h1>
      </div> */}

      <div className="flex-cont align-center">
        <h1 className="flex-cont">
          <div className="margin-right-half medgray">{currentCompany}</div>
          {tag === "all" ? "All" : "My"} Approved Timesheets
        </h1>
      </div> 

      {tag !== "all" &&
        <div className="margin-bottom-3">
          <div className={`selector-cont ${loading ? 'disabled' : ''}`}>          
            <Selector label={"My Projects"} name="managed" setTab={setTab} tab={tab} count={approvedCount?.hash?.mine} />
            <Selector label={"Delegated Projects"} name="delegated" setTab={setTab} tab={tab} count={approvedCount?.hash?.delegated} />
          </div>
        </div>
      }


      

      <div className="content margin-top-1">

        <Pagination currentPage={page} pagy={pagy} handlePageChangeFn={handlePageChange} />

        {/* <pre>
          {JSON.stringify(pagy, null, 2)}
        </pre> */}

        <div className="margin-top-1 margin-bottom-1">
          {renderContent()}
        </div>

        <Pagination currentPage={page} pagy={pagy} handlePageChangeFn={handlePageChange} />
        
        
      </div>
      
    </div>
  );
};

const mapState = state => ({
  timesheets: state.timesheetState.data,
  periods: [],
  isLoading: state.timesheetState.isLoading,
  fullScreenLoading: state.timesheetState.fullScreenLoading,
  // error: state.timesheetState.error,
  // pagy: state.timesheetState.pagy,
  currentCompany: state.authState.currentCompany,
  approvedCount: state.timesheetState.approvedCount,
});

export default connect(mapState)(TimesheetsApproved);
