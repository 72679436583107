import React, { useState, useEffect, useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import { DateTime, Info } from 'luxon';
import store from '../../redux/store';
// import { updateEntry, addEntry, api, getTimesheet, getProjects, getPhases, getCategories, submitEntry, setUnsavedEntries } from './actions';






// wed: yup.number().transform((cv, ov) => ov === '' ? undefined : cv).max(999).matches().nullable().positive().label('Wednesday hours').typeError(' Wednesday hours must be a number'),
const Entry = ({projectsLoading, projects, phases, categories, addLine, timesheet, andyEntry, timesheetId, index, selectedTimesheetWeek, toggleRemoveLineModal}) => {


  const [sumHours, setSumHours] = useState(0.0);


  const [subactivities, setSubactivities] = useState(null);
  const [activities, setActivities] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [existingEntry, setExistingEntry] = useState(andyEntry);

  const selectProjectChange = async (e) => {
    // alert("selectProjectChange");
    const project = projects.find(p => p.id === e.target.value);
    setSelectedProject(project);
    
  }

  const selectActivityChange = async (e) => {
    const activity = activities.find(p => p.number === e.target.value);

    if (activity) {
      setSelectedActivity(activity);
      
    }
    
    // 
  }

  useEffect(() => {
    if (selectedTimesheetWeek && selectedTimesheetWeek.timesheetEntries) {
      setExistingEntry(selectedTimesheetWeek.timesheetEntries[index]);
    }

  }, [selectedTimesheetWeek]);




  useEffect(() => {

    if (selectedProject && selectedProject.activities && selectedProject.activities.length > 0) {
      // alert('here');
      setActivities(selectedProject.activities);
      setSelectedActivity(selectedProject.activities[0]);
    }
    else {
      setActivities(null);
      setSelectedActivity(null);
    }

  }, [selectedProject]);

  useEffect(() => {
    // alert('here');
    
    if (selectedActivity && selectedActivity.subactivities && selectedActivity.subactivities.length > 0) {
      setSubactivities(selectedActivity.subactivities);
      // setSelectedSubactivity(selectedProject.subactivities[0]);
      
    }
    else {
      // alert('here');
      
      setSubactivities(null);
    }
  }, [selectedActivity]);





  useEffect(() => {
    if (existingEntry) {
      setSelectedProject(existingEntry.project);
    }
    else {
      // store.dispatch(setUnsavedEntries(true));
    }
  }, [existingEntry]);


  const [isLoading, setIsLoading] = useState(false);
  const [responseText, setResponseText] = useState("");
  const [expanded, setExpanded] = useState(!existingEntry);

  const statusText = () => {
    let r = "";
    if (!existingEntry) {
      r = "";
    }
    else if (existingEntry.status === "draft") {
      r = "";
    }
    else if (existingEntry.status === "copied") {
      r = "COPIED";
    }
    else if (existingEntry.status === "submitted") {
      r = "SUBMITTED";
    }
    else if (existingEntry.status === "returned") {
      r = "RETURNED";
    }
    else if (existingEntry.status === "approved") {
      r = "APPROVED";
    }

    return r;
  }

  const rowClassName = () => {
    if (!existingEntry) {
      return "row entry-cont new"
    }

    if (existingEntry.status === "draft") {
      return "row entry-cont created"
    }
    if (existingEntry.status === "copied") {
      return "row entry-cont created"
    }
    if (existingEntry.status === "submitted") {
      return "row entry-cont submitted"
    }
    if (existingEntry.status === "returned") {
      return "row entry-cont returned"
    }
  }

  const isDisabled = () => {
    if (!existingEntry) {
      return false;
    }
//Test 
    if (existingEntry.status === "submitted" || existingEntry.status === "approved") {
      return true;
    }
  }



  const periodDays = () => {
    let days = [];
    let count = 1;
    let weekStart = DateTime.fromISO(selectedTimesheetWeek.weekStart);
    let weekEnd = DateTime.fromISO(selectedTimesheetWeek.weekEnd)
    let currentDay = weekStart;

    if (weekStart.weekday === 7) {
      days.push([`First ${weekStart.toFormat('EEE').toLowerCase()}`, currentDay, count]);
      currentDay = currentDay.plus({day: 1}); 
      count += 1;
    }
    else {

    }
    

    while (currentDay <= weekEnd) {
      
      days.push([`${currentDay.toFormat('EEE').toLowerCase()}`, currentDay, count]);
      currentDay = currentDay.plus({day: 1});
      count += 1;
    }

    return days;
  }


  return (
    <div>
      <div className={rowClassName()}>
        {/* style={{marginBottom: '8px', paddingBottom: '8px', borderBottom: "2px solid rgba(0,0,0,.05)"}} */}
        <div>
          {/* <pre>
            {JSON.stringify(existingEntry, null, 2)}
          </pre> */}
          
          <div className="flex-cont align-items-end">
            <div className={`entry-status margin-right-4px center  block-badge ${existingEntry ? existingEntry.status : "new"}`}>
              {statusText()}
            </div>

            <label className={"margin-right-4px"}>
              <div className="label-text">Project</div>
              <div className="dummy-selectx editable-cont">
                {`${existingEntry.projectId} - ${existingEntry.projectName}` || "---"}
              </div>
            </label>

            <label className={"margin-right-4px"}>
              <div className="label-text">Milestone</div>
              <div className="dummy-select">
                {`${existingEntry.activityNumber} - ${existingEntry.activityName}` || "---"}
              </div>
            </label>

            <label className={"margin-right-4px"}>
              <div className="label-text">Task</div>
              <div className="dummy-select">
                {existingEntry.subactivityname || "---"}
              </div>
            </label>


              <div className="flex-cont align-center">
                {periodDays() && periodDays().map((a, i) => {

                  const day = a[1];
                  const label = a[0];

                  if (!day) {
                    return(
                      <>
                        INVALID
                      </>
                    )
                  }
                  return (
                    <label className={"margin-right-4px"}>
                      <div className="label-text">{`${day.toFormat('EEE')}`}</div>
                      <div className="dummy-input-bg hour-input">
                        {existingEntry ? existingEntry[`day${i+1}hour`] : 0.0}
                      </div>
                    </label>
                    
                  );
                })}
              </div>
          </div>  
        </div>

        {existingEntry && existingEntry.description && existingEntry.status === "returned" &&
          <div style={{color: 'darkred'}}>
            <strong>MANAGER'S NOTE: </strong>
            {existingEntry.description}
          </div>
        }

        
      </div>

    </div>
  );
};

const mapState = state => ({
  projects: [], // state.projectState.data,
  phases: [], // state.phaseState.data,
  categories: [], // state.categoryState.data,
});

export default connect(mapState)(Entry);
