import { andyFetch, actionReturn } from '../../api';

export const getBudgetFeeCategories = (params) => {

  return async (dispatch, getState) => {

    const url = "admin/cats";

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};

export const deleteBudgetFeeCategory = (params) => {
  return async (dispatch, getState) => {
    const url = "admin/cats/delete";
    try {
      const response = await andyFetch(url, params, "POST");
      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);
      } else {
        throw new Error('createBudgetFeeCategory error.');
      }
    }
    catch (e) {
    }
  }
};



export const createBudgetFeeCategory = (params) => {

  return async (dispatch, getState) => {

    const url = "admin/cats";

    try {

      const response = await andyFetch(url, params, "POST");

      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);

      } else {
        throw new Error('createBudgetFeeCategory error.');
      }
    }
    catch (e) {
    }
  }
};

export const updateBudgetFeeCategory = (params) => {

  return async (dispatch, getState) => {

    const url = `admin/cats/${params?.payload?.budget_phase?.id}`;

    try {

      const response = await andyFetch(url, params, "PATCH");

      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);

      } else {
        throw new Error('updateBudgetFeeCategory error.');
      }
    }
    catch (e) {
      
    }
  }
};