
import React, {useState, useEffect} from 'react';
import BudgetFeeRow from './BudgetFeeRow';




const BudgetFeeRows = ({currencySymbol, budgetPhases, getValues, reset, register, budgetFeeCategories, budgetHeader, saveBudgetHeaderLoaded, setValue, trigger, readOnly, watch, errors}) => {

 
  const [userCount, setUserCount] = useState(budgetHeader?.budget_fees ? budgetHeader?.budget_fees?.length + 0 : 0);
  const [rows, setRows] = useState([]);
  const [removedIndexes, setRemovedIndexes] = useState([]);

  // const budgetFees = budgetHeader?.budgetFees;

  // useEffect(() => {
  //   // alert('budgetHeader')
  //   // if (budgetHeader) {
  //   //   setUserCount(budgetHeader?.budgetFees?.length);

  //   //   const idx = budgetHeader?.budgetFees?.map((fee, i) => i);

  //   //   setRows(idx)
  //   // }
  // }, [budgetHeader])

  useEffect(() => {
    if (saveBudgetHeaderLoaded && budgetHeader) {
      // alert(JSON.stringify(saveBudgetHeaderLoaded));
      // setUserCount(budgetHeader?.budgetFees ? budgetHeader?.budgetFees?.length + 0 : 0)


      // setValue('budget_fees', budgetHeader?.budgetFees)
      // reset({
      //   ...getValues(),
      //   budget_fees: budgetHeader?.budgetFees
      // })
      
      setUserCount(budgetHeader?.budget_fees ? budgetHeader?.budget_fees?.length + 0 : 0)
      setRows([]);
      setRemovedIndexes([]);
    }
  }, [budgetHeader, saveBudgetHeaderLoaded])

  const addRowClicked = () => {
    setRows(o => [...o, userCount]);
    setUserCount(o => o+1);    
  }

  const removeRowClicked = row => {
    setRows(o => [...o.filter(e => e !== row)] );
  }

  const removeExistingRowClicked = index => {
    setRemovedIndexes(prevRemovedIndexes => [...prevRemovedIndexes, index]);
    const fn = `team_users_attributes.${index}._destroy`;
    setValue(fn, true);
  }





  

  return (<>



    <table className="basic budget-worksheet">
      <tr>
        <th>Phase</th>
        <th>Category</th>
        <th>Description</th>
        <th>Amount</th>
      </tr>

      {budgetHeader?.budget_fees && budgetHeader?.budget_fees.map((fee, index) => 
        <tr className={`${removedIndexes.includes(index) ? 'hidden' : ''}`} key={fee.id}>
        {/* <td>
              {JSON.stringify(fee, null, 2)}
            </td>

            <td>
              FEE: {JSON.stringify(fee, null, 2)}
            </td> */}
          <BudgetFeeRow currencySymbol={currencySymbol} budgetPhases={budgetPhases} watch={watch}  getValues={getValues} reset={reset} budgetHeader={budgetHeader} trigger={trigger} rrr={() => true} watch={watch} errors={errors} setValue={setValue} setRemovedIndexes={setRemovedIndexes} removedIndexes={removedIndexes}  readOnly={readOnly} budgetFeeCategories={budgetFeeCategories} register={register} index={index} key={fee.id} fee={fee} />

        </tr>
      
      )}


      {rows && rows.map((row, i) => {
        const ii = row;
        
        const feeFieldName = `budget_fees.${ii}`;
        const feeErrors = errors?.budget_fees?.[ii];

        return (
          <tr key={row} className={`${removedIndexes.includes(ii) ? 'hidden' : ''}`}>

            {/* <td>ROW</td> */}
           
            <BudgetFeeRow currencySymbol={currencySymbol} budgetPhases={budgetPhases} watch={watch} getValues={getValues} reset={reset} budgetHeader={budgetHeader}  trigger={trigger} rrr={() => true} watch={watch} errors={errors} setValue={setValue} setRemovedIndexes={setRemovedIndexes} removedIndexes={removedIndexes}  readOnly={readOnly} budgetFeeCategories={budgetFeeCategories} register={register} index={ii} key={feeFieldName} fee={null} />
            
          </tr>
        )
      })}



      {!readOnly &&
        <tr>
          <td  colSpan={4}>
            <div tabIndex={0} onKeyPress={addRowClicked} onClick={addRowClicked} className="button ghost font-075">
              <i className="margin-right-half fas fa-plus font-075"></i>
              <div>Add Fee</div>
            </div>
          </td>
        </tr>
      }
    </table>
  </>)

}

export default BudgetFeeRows;