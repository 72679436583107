import React, {useEffect} from 'react';
import '../styles/Modal.scss';
import FocusTrap from 'focus-trap-react';

const Modal = ({canClose=true, size="medium", focusTrap=true, toggleModal, children}) => {

  const ttt = () => {

    if (canClose === true) {
      toggleModal();
    }
    
  };

  useEffect(() => {
    // alert(JSON.stringify(toggleModal))
  }, [])

  const Wrapper = focusTrap ? FocusTrap : React.Fragment
  
  return(
    <>
      <Wrapper>
        <div>
          <div onClick={ttt} className="modal-bg"></div>
          <div className="modal-cont">
            <div className={`modal-content ${size}`}>
              <div className="modal-inner-content">

                <div style={{width: '100%', height: '100%'}}>
                  {children}
                </div>
                
                {/* {focusTrap &&
                  <FocusTrap>
                    <div style={{width: '100%', height: '100%'}}>
                      {children}
                    </div>
                  </FocusTrap>
                }
                {!focusTrap &&
                  <div style={{width: '100%', height: '100%'}}>
                    {children}
                  </div>
                } */}
              
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    </>
  )
}

export default Modal;