import React, { useEffect, useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import Modal from '../../components/Modal';
import { useApi } from '../../api';


import { getManagerUsers, getAutoCompleteUsers } from '../../redux/admin/userActions'; 
import { updateTeam, createTeam, deleteTeam } from '../../redux/admin/teamActions'; 
import { updateOfficeManagerTeam, createOfficeManagerTeam, deleteOfficeManagerTeam } from '../../redux/admin/officeManagerTeamActions'; 
import { useNavigate } from 'react-router';
import { useIsFirstRender } from '../../hooks/useIsFirstRender';


export const UserRow = ({listRef, index, user, highlightIndex, userClicked}) => {
  
  const itemRef = useRef(null);

  const isHighlighted = () => {
    // return true
    return highlightIndex !== null && index !== null && highlightIndex === index
  }

  useEffect(() => {

    if (itemRef && itemRef.current && isHighlighted()) {
      
      // itemRef.current.scrollIntoView()
    }
    
  }, [highlightIndex])

  return (
    <div ref={itemRef} key={user?.workerrecid} className={`item ${highlightIndex === index ? 'highlighted' : ''}`} onClick={() => userClicked(user)}>
      {user.fname} {user.lname}
    </div>  
  )
}
export const UserAutocomplete = ({fixedPosition=false, setValue, defaultValue, name, users, fullName, register, errors, teamUser}) => {

  const wrapperRef = useRef(null);
  const inputRef = useRef(null);

  const [search, setSearch] = useState(fullName ? fullName : "");
  const [filtered, setFiltered] = useState(users);
  const [listOpened, setListOpened] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const handleClickOutside = event => {
        // alert(timesheetTooltipIsOpen)
    // hideTooltip();
    // // alert(timesheetTooltipIsOpen);
    // 
    // 
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setListOpened(false);
      
    }
  }; 

  // useEffect(() => {
  //   setSearch(fullName)
  // }, [fullName])
  
  
  useEffect(() => {

    document.addEventListener("mousedown", handleClickOutside, false);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside, false);
    };
  }, []);


  useEffect(() => {
    if (search) {
      // setListOpened(true);

      

      const formattedSearch = search.toUpperCase().replace(/ /g, '');
      const f = users?.filter(u => 

        {
          

          const fname = u.fname.toString().toUpperCase().replace(/ /g, '');
          const lname = u.lname.toString().toUpperCase().replace(/ /g, '');
          // 
          return (
            fname.includes(formattedSearch) || 
            lname.includes(formattedSearch) ||
            (`${fname}${lname}`).includes(formattedSearch) 
          )
        }
       

       
      )
      // 
      setFiltered(f)
    }
    else {
      setFiltered(users)
    }
  }, [search, users, listOpened]);

  useEffect(() => {
    // setListOpened(false);

    if (selectedUser) {
      setSearch(`${selectedUser.fname} ${selectedUser.lname}`);
      if (inputRef?.current) {
        inputRef.current.blur();
      }
    }

    // setValue(name, selectedUser?.workerrecid, { shouldValidate: true })
    
  }, [selectedUser]);

  const userClicked = (user) => {
    setSelectedUser(user);
    setSearch(`${user.fname} ${user.lname}`);
    setValue(name, user?.workerrecid, { shouldValidate: true })
    setListOpened(false);
  }

  useEffect(() => {
    if (!listOpened) {
      if (selectedUser) {
        setSearch(`${selectedUser.fname} ${selectedUser.lname}`);
      }
    }
  }, [listOpened])

  const inputFocused = () => {
    // alert('focuses');
    
    setListOpened(true);
    // setSearch()
  }

  const [highlightIndex, setHighlightIndex] = useState(0);

  const checkKeyDown = (e) => { 

    if (!filtered || !listOpened) return;


    const keyCode = e.keyCode ? e.keyCode : e.which; 
    




    if (keyCode === 40) { 
      setHighlightIndex(o => Math.min(filtered.length-1, o+1));
    }
    else if (keyCode === 38) {
      setHighlightIndex(o => Math.max(0, o-1));
    } 
    else if (keyCode === 13) {

      const user = filtered[highlightIndex];
      
      setSelectedUser(user);
      setSearch(`${user.fname} ${user.lname}`);
      setValue(name, user?.workerrecid, { shouldValidate: true })
      setListOpened(false);
    } 
  };



  

 
  const itemsArePresent = (filtered != null && filtered.length !== 0);

  return (
    <div key={name} ref={wrapperRef} className="autocomplete">


      <div className="menu-cont">

        <div className="flex-cont align-center">
          <input 
            onFocus={inputFocused} 
            // onBlur={(e) => handleBlur(e)}
            ref={inputRef}
            type="text" 
            name="user_search" 
            id="" 
            autoComplete="off"
            onKeyDown={(e) => checkKeyDown(e)} 
            // defaultValue={fullName}
            placeholder="Search..."
            value={search} 
            className={`${errors ? 'has-errors' : ''}`}  
            onChange={e => setSearch(e.target.value)} 
          />

          <input
            type="hidden"
            name={name} 
            id={name} 
            
            defaultValue={defaultValue} 
            {...register(name, {
              required: "Select a user."
            })}
          />
          {/* {selectedUser &&
            <i className="margin-left-half font-075 lightgray fas fa-user-check"></i>
          } */}
        </div>
        {/* <div className="code">
          <pre>
            {JSON.stringify(search, null, 2)}
          </pre>
        </div> */}

       
        {/* <div className="code">
          <pre>
            {JSON.stringify(listOpened, null, 2)}
            <br />
            {JSON.stringify(search, null, 2)}
            <br />
            {JSON.stringify(selectedUser, null, 2)}
          </pre>
        </div> */}

        {listOpened && 

          <>
            {!itemsArePresent &&
              <div className={`menu ${fixedPosition === true ? 'auto-min-width fixed' : ''}`}>
                <div className="padding-1 gray">
                  <em>No results.</em>
                </div>
              </div>            
            }
            {itemsArePresent &&
              <div className={`menu ${fixedPosition === true ? 'auto-min-width fixed' : ''}`}>
                {filtered?.map((user, index) =>
                  <UserRow key={`user-row-${user.workerrecid}`} index={index} user={user} highlightIndex={highlightIndex} userClicked={userClicked} />
                )}
              </div>
            }
          
          </>
          
        }
      </div>



    </div>
  )
}

 


const AdminTeamsForm = ({type="Project", formTeam, currentCompany}) => {

  const teamUserAttributeName = type === "Project" ? "team_users" : "office_manager_team_users";
  const fieldName = type === "Project" ? `${teamUserAttributeName}_attributes` : `${teamUserAttributeName}_attributes`;
  const userIDColumnName = type === "Project" ? "userid" : "workerrecid";

  const isFirstRender = useIsFirstRender();

  const navigate = useNavigate("/");

  const { reset, watch, register, formState: { errors }, handleSubmit, getValues, setValue } = useForm({
    // resolver: yupResolver(TeamSchema),
  });

  const [result, loading, loaded, error, doFetch, setResult] = useApi();


  const [managerUsers, managerUsersLoading, managerUsersLoaded, managerUsersError, doManagerUsersFetch, setManagerUsersResult] = useApi();
  const fetchManagerUsers = () => {
    doManagerUsersFetch(getManagerUsers, {query: {type: type, for_auto_complete: true, no_pagy: true}});
  }

  const [users, usersLoading, usersLoaded, usersError, doUsersFetch, setUsersResult] = useApi();
  const fetchUsers = () => {
    doUsersFetch(getAutoCompleteUsers, {query: { no_pagy: true, for_auto_complete: true, roleid: (type==="Project" ? null : 0)}});
  }

  useEffect(() => {
    fetchManagerUsers();
    fetchUsers();

    if (formTeam) {

    }
  }, []);



  useEffect(() => {
    if (usersLoaded) {
      reset();
    }
  }, [usersLoaded])


  const onSubmit = async data => {

    const param = type === "Project" ? "team" : "office_manager_team"
    let d = {
      id: formTeam?.teamid,
      [param]: {
        ...data,
      }
    }

    if (formTeam && formTeam.teamid) {

      if (type === "Project") {
        doFetch(updateTeam, {payload: d});
      }
      else if (type === "Office") {
        doFetch(updateOfficeManagerTeam, {payload: d});
      }
      
    }
    else {
      if (type === "Project") {
        doFetch(createTeam, {payload: d});
      }
      else if (type === "Office") {
        doFetch(createOfficeManagerTeam, {payload: d});
      }
    }

  }

  useEffect(() => {
    if (loaded) {
      const path = type === "Project" ? "/teams/project-manager" : "/teams/office-manager";
      navigate(path);
    }
  }, [loaded]);

  const [deleteModalOpened, setDeleteModalOpened] = useState(false);

  const toggleDeleteModal = () => {
    setDeleteModalOpened(o => !o);
  }

  const [deleteResult, deleteLoading, deleteLoaded, deleteError, doDeleteFetch, setDeleteResult] = useApi();
  const tryDeleteTeam = () => {
    if (formTeam) {

      if (type === "Project") {
        const payload = {
          team: {
            teamid: formTeam.teamid
          }
        }
        doDeleteFetch(deleteTeam, {payload: payload});
      }
      else if (type === "Office") {
        const payload = {
          office_manager_team: {
            id: formTeam.id
          }
        }
        doDeleteFetch(deleteOfficeManagerTeam, {payload: payload});
      }
      
    }
  }
  useEffect(() => {
    if (deleteLoaded) {
      toggleDeleteModal(true);
      const path = type === "Project" ? "/teams/project-manager" : "/teams/office-manager";
      navigate(path);
    }
  }, [deleteLoaded]);

  const [userCount, setUserCount] = useState(formTeam?.[teamUserAttributeName] ? formTeam?.[teamUserAttributeName].length : 0);
  const [rows, setRows] = useState([]);
  const [removedIndexes, setRemovedIndexes] = useState([]);

  const addRowClicked = () => {
    setRows(o => [...o, userCount+1]);
    setUserCount(o => o+1);    
  }

  const removeRowClicked = (row, index) => {
    setRows(o => [...o.filter(e => e !== row)] );
    setValue(`${fieldName}.${row}`, null)

  }

  const removeExistingRowClicked = index => {
    setRemovedIndexes(prevRemovedIndexes => [...prevRemovedIndexes, index]);
    const fn = `${fieldName}.${index}._destroy`;
    setValue(fn, true);
  }

  const remainingUsers = (index) => {
    if (usersLoading || !users) return null;

    let selectedUserIds = getValues(fieldName)?.map((tua, i) => {
      if (tua?.userid && i !== index && !tua?._destroy) {
        return (tua.userid.toString());
      }
    })

    if (!selectedUserIds) {
      selectedUserIds = [];
    }

    const managerUserID = getValues('manageruserid');

    if (managerUserID) {
      selectedUserIds.push(managerUserID);
    }

    

    if (selectedUserIds) {
      // 
      // 
      // 
      return users.filter(user =>  !selectedUserIds.includes(user.workerrecid.toString()) )
    }
    else {
      return users
    }
    


  }

  const checkKeyDown = (e) => { 
    const keyCode = e.keyCode ? e.keyCode : e.which; 

    // alert(JSON.stringify(keyCode, null, 2));


    if (keyCode === 13) { 
      e.preventDefault() 
    }; 
  };



  return (
    <form onKeyDown={(e) => checkKeyDown(e)} onSubmit={handleSubmit(onSubmit)}>


      <div className="padding-1">

        {/* <div className="code">
          <pre>
            {JSON.stringify(formTeam, null, 2)}
          </pre>
        </div> */}

        {/* <div className="button" onClick={() => alert(JSON.stringify(getValues(), null, 2))}></div> */}



        <div className="flex-cont margin-top-half">

          {type === "Project" && <input {...register("teamid")} type="hidden" name="teamid" value={formTeam?.teamid} />}
          {type === "Office" && <input {...register("id")} type="hidden" name="id" value={formTeam?.id} />}

          <label htmlFor="manageruserid"  className="margin-right-1"> 
            <div style={{marginBottom: '4px'}} className="gray bold font-075 uppercase">{type.toUpperCase()} MANAGER</div>
            {managerUsersLoading &&
              <div className="editable-cont loading">
                <div className="absolute spinner"></div>
              </div>
            }

            {/* <div className="code">
              <pre>
                {JSON.stringify(managerUsers, null, 2)}
              </pre>
            </div> */}
            {!managerUsersLoading && managerUsers &&
              // <select className={`${errors?.manageruserid ? 'has-errors' : ''}`}  defaultValue={formTeam?.manageruserid || ""} {...register("manageruserid", {
              //             required: "Select a manager."
              //           })}>
              //   <option className="gray" disabled selected value="">Select manager:</option>
              //   {managerUsers?.map((user, index) => <option value={user?.workerrecid}>{user?.fname} {user?.lname} </option>)}
              // </select>
              <UserAutocomplete 
                setValue={setValue}
                users={managerUsers} 
                register={register} 
                fullName={formTeam?.manager_full_name}
                defaultValue={formTeam?.manageruserid || ""} 
                errors={errors?.manageruserid}
                name={`manageruserid`} 
              />
            }
            {errors.manageruserid && <div className="field-error">{errors.manageruserid.message}</div>}
          </label>

         
        </div>


        <div className="sectionx margin-top-2 margin-bottom-2">

          <div style={{marginBottom: '4px'}} className="gray bold font-075 uppercase">MEMBERS</div>
          {formTeam?.[teamUserAttributeName]?.map((teamUser, index) => {

            const hide = removedIndexes.includes(index);


            return (
              <div key={teamUser.userid} className={`${hide ? 'hidden' : '' } align-center flex-cont margin-bottom-half`}>
                <div>
                  
                  <label htmlFor={`${fieldName}.${index}.userid`}  className="margin-right-1"> 
                    {/* <div style={{marginBottom: '4px'}} className="gray bold font-075 uppercase">MEMBER</div> */}
                    {usersLoading &&
                      <div className="editable-cont loading">
                        <div className="absolute spinner"></div>
                      </div>
                    }
  
                    {remainingUsers(index) &&

                      <UserAutocomplete
                        setValue={setValue}
                        users={remainingUsers(index)} 
                        register={register} 
                        defaultValue={teamUser?.userid} 
                        fullName={teamUser?.full_name}
                        errors={errors?.[fieldName]?.[index]?.userid}
                        name={`${fieldName}.${index}.userid`} 
                      />
                    }
                    {errors?.[fieldName]?.[index]?.userid && <div className="field-error">{errors?.[fieldName]?.[index]?.userid?.message}</div>}
                  </label>
                </div>
                <input defaultValue={currentCompany} {...register(`${fieldName}.${index}.company`)} type="hidden" />
                <input defaultValue={teamUser.id} {...register(`${fieldName}.${index}.id`)} type="hidden" />
                <input {...register(`${fieldName}.${index}._destroy`)} type="hidden" />
                <div onClick={() => removeExistingRowClicked(index)} className="ghost button">
                  <i className="fas fa-times"></i>
                </div>
              </div>
            )
          })}
          {rows && rows.map((row, i) => {
            const index = row;
            


            return (
              <div key={`index-${index}`} className="align-center flex-cont margin-bottom-half">
                <div>
                  <label htmlFor={`${fieldName}.${index}.userid`}  className="margin-right-1"> 
                    {/* <div style={{marginBottom: '4px'}} className="gray bold font-075 uppercase">MEMBER</div> */}
                    {usersLoading &&
                      <div className="editable-cont loading">
                        <div className="absolute spinner"></div> 
                      </div>
                    }
                    {remainingUsers(index) &&
                      <UserAutocomplete
                        setValue={setValue}
                        users={remainingUsers(index)} 
                        register={register} 
                        defaultValue={null} 
                        fullName={null}
                        errors={errors?.[fieldName]?.[index]?.userid}
                        name={`${fieldName}.${index}.userid`} 
                      />
                    }
                    {errors?.[fieldName]?.[index]?.userid && <div className="field-error">{errors?.[fieldName]?.[index]?.userid?.message}</div>}
                  </label>
                </div>
                <input defaultValue={currentCompany} {...register(`${fieldName}.${index}.company`)} type="hidden" />
                <div onClick={() => removeRowClicked(row, index)} className="ghost button">
                  <i className="fas fa-times"></i>
                </div>
              </div>
            )

          })}

          <div onClick={addRowClicked} className="margin-top-1 ghost button">
            <i className="font-075 fas fa-plus margin-right-half"></i>
            <div>Add Member</div>
          </div>
        </div>
      </div>

      <div className="align-center flex-cont">
        
        <input type="submit" value="Save" className={`${loading ? 'disabled' : ''} button`} />

        {loading &&
          <div className="margin-left-1 spinner"></div>
        }

        <div className="flex-1"></div>

        {formTeam &&
          <div onClick={toggleDeleteModal} className="button danger">Delete Team</div>
        }
      </div>

      {deleteModalOpened &&
        <Modal size="small" toggleModal={toggleDeleteModal} focusTrap={false}>
          <div className="modal-header flex-cont align-center">
            <h2 className="flex-1">Delete Team</h2>
            <div onClick={toggleDeleteModal} className="ghost button close">
              <i className="fas fa-times"></i>
            </div>
          </div>

          <div className="padding-1">
            Are you sure you want to delete <span className="bold"> {formTeam?.teamname}'s team</span>?
          </div>

          <div className="modal-footer align-center flex-cont">
            <div onClick={toggleDeleteModal} className="ghost button margin-right-1">Cancel</div>
            <div onClick={tryDeleteTeam} className={`${deleteLoading ? 'disabled' : ''} danger button`}>
              {deleteLoading && <div className="spinner button-spinner"></div> }
              Delete Team
            </div>
          </div>
        </Modal>
      }

    </form>


  )
}

function mapState(state) {
  return { 
    // tokenResponse: state.authState.tokenResponse,
    currentCompany: state.authState.currentCompany
  };
} 

export default connect(mapState)(AdminTeamsForm);
