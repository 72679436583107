import React, { useState, useEffect, useRef } from 'react';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  useParams,
  Link, Outlet, useLocation
} from 'react-router-dom';


import { DateTime, Info } from 'luxon';
import store from '../../redux/store';
import { connect } from 'react-redux';
import Modal from '../../components/Modal';
import Pagination from '../../components/Pagination';
import { getPeriods } from '../../redux/periods/periodActions';
import { getSubmittedCount, postTimesheet, createTimesheet, updateTimesheet, getTimesheets, getSubmittedTimesheets } from '../../redux/timesheets/timesheetActions';
import { clearStore } from '../../redux/authActions';
import { getManagedEntries, setEntries, clearEntries, getEntries, approveLine, returnLine, removeEntry } from '../../redux/entries/entryActions';
import { useApi } from '../../api';
import * as yup from "yup";
import { useForm } from 'react-hook-form';
import EntryReview from './EntryReview';




const schema = yup.object().shape({
  notes: yup.array().of(yup.object().shape({
    note: yup.string(),
    entryId: yup.string(),
  })),
});



const TimesheetsReview = ({submittedCount, currentCompany, fullScreenLoading, isLoading, error, entries}) => {


  const [selectedTimesheets, setSelectedTimesheets] = useState([]);
  const [selectedLines, setSelectedLines] = useState([]);
  const [actionsOpen, setActionsOpen] = useState(false);
  const [openTimesheetTooltip, setOpenTimesheetTooltip] = useState(null);
  const [openLineTooltip, setOpenLineTooltip] = useState(null);

  const { register, handleSubmit, formState: {errors}, getValues, reset } = useForm({
    // validationSchema: schema,
    mode: 'onChange'
  });

  const [postResult, postLoading, postLoaded, postError, doPostFetch, setPostResult] = useApi();
  const [countResult, countLoading, countLoaded, countError, doCountFetch, setCountResult] = useApi();

  const fetchSubmittedCount = () => {
    // doCountFetch(getSubmittedCount);
  }
  

  const test4 = () => {

    doPostFetch(postTimesheet);

  }



  const timesheetIsSelected = id => {
    return selectedTimesheets.includes(id);
  };
  const lineIsSelected = id => {
    return selectedLines.includes(id);
  };

  const selectTimesheet = id => {
    if (timesheetIsSelected(id)) {
      setSelectedTimesheets(oldArray => oldArray.filter( elem => elem !== id));
    }
    else {
      setSelectedTimesheets(oldArray => [...oldArray, id]);
    }
  }

  const selectLine = id => {
    if (lineIsSelected(id)) {
      setSelectedLines(oldArray => oldArray.filter( elem => elem !== id));
    }
    else {
      setSelectedLines(oldArray => [...oldArray, id]);
    }
  }

  const toggleActionsTooltip = () => {
    setOpenTimesheetTooltip(null);
    setActionsOpen(oldValue => !oldValue);
  };


  const timesheetTooltipIsOpen = id => {
    return openTimesheetTooltip === id;
  };
  const lineTooltipIsOpen = id => {
    return openLineTooltip === id;
  };

  const toggleTimesheetTooltip = id => {
    setActionsOpen(false);
    timesheetTooltipIsOpen(id) ? setOpenTimesheetTooltip(null) : setOpenTimesheetTooltip(id)
  };

  const toggleLineTooltip = id => {
    setActionsOpen(false);
    lineTooltipIsOpen(id) ? setOpenLineTooltip(null) : setOpenLineTooltip(id)
  };

  const toggleAllTimesheets = () => {
    if (!timesheets) return;

    if (allTimesheetsSelected()) {
      setSelectedTimesheets([]);
    }
    else {
      setSelectedTimesheets(Object.keys(timesheets));
    }
  }

  const allTimesheetsSelected = () => {
    if (!selectedTimesheets || !timesheets) {
      return false;
    }
    return selectedTimesheets.length === Object.keys(timesheets).length;
  }


  const toggleAllLines = () => {
    if (!entries) return;

    if (allLinesSelected()) {
      setSelectedLines([]);
    }
    else {
      setSelectedLines(entries.map(t => t.id));
    }
  }

  const allLinesSelected = () => {
    if (!selectedLines || selectedLines.length === 0 || !entries) {
      return false;
    }
    return selectedLines.length === Object.keys(entries).length;
  }




  const wrapperRef = useRef(null);

  const [tab, setTab] = useState("mine");

  const [timesheets, timesheetsLoading, timesheetsLoaded, timesheetsError, doTimesheetsFetch, setTimesheetsResult] = useApi();


  useEffect(() => {
    fetchSubmittedCount();
    doTimesheetsFetch(getSubmittedTimesheets, {query: {tab: tab}});
  }, [currentCompany, tab]);

  useEffect(() => {

    


    // store.dispatch(api('timesheets/submitted', null, {page: ""}, 'GET', 'SUBMITTED_TIMESHEETS'));

    document.addEventListener("mousedown", handleClickOutside, false);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside, false);
      setActionsOpen(false);
      setOpenLineTooltip(null);
      setSelectedLines([]);

      // store.dispatch(clearStore('ENTRIES'));
      // store.dispatch(clearStore('TIMESHEETS'));
      // store.dispatch(clearStore('PROJECTS'));

    };
  }, []);


  const [selectedTimesheetIndex, setSelectedTimesheetIndex] = useState(0);
  const [selectedTimesheet, setSelectedTimesheet] = useState(null);

  const selectTimesheetIndex = (i, p) => {
    setSelectedTimesheetIndex(i);
    setSelectedTimesheet(p);
  }

  useEffect(() => {
    
    if (timesheets) {
      
      setSelectedTimesheet(timesheets[0]);
    }

    setActionsOpen(false);
    setOpenLineTooltip(null);
    setSelectedLines([]);
  }, [timesheets]);


  const [localEntries, entriesLoading, entriesLoaded, entriesError, doEntriesFetch, setEntriesResult] = useApi();

  useEffect(() => {
    store.dispatch(setEntries(localEntries));
  }, [localEntries]);


  useEffect(() => {
    store.dispatch(clearStore('ENTRIES'));
    
    if (selectedTimesheet && selectedTimesheet !== undefined) {

      // alert('here');
      // store.dispatch(api('timesheet_entries', null, {status: "submitted", timesheetid: selectedTimesheet.number}, 'GET', 'ENTRIES'));


      doEntriesFetch(getManagedEntries, {query: {tab: tab, statusid: 1, timesheetid: selectedTimesheet.id}})

    }

    setActionsOpen(false);
    setOpenLineTooltip(null);
    setSelectedLines([]);

  }, [selectedTimesheet])

  

  const handleClickOutside = event => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setActionsOpen(false);
    }
  };

  const [returnModalOpened, setReturnModalOpened] = useState(false);

  const [bulkReturnModalOpened, setBulkReturnModalOpened] = useState(false);
  const [submitNoteOpen, setSubmitNoteOpen] = useState(false);
  const [submitNote, setSubmitNote] = useState(null);

  const subNoteClicked = (note="") => {

    setSubmitNote(note);
    toggleSubmissionNote();
  }
  const toggleSubmissionNote = () => {

    // if (submitNoteOpen) {
    //   setSubmitNote(note)
    // }
    // else {
    //   setSubmitNote(note)
    // }
    setSubmitNoteOpen(o=> !o)
  }

  const renderContent = () => {
    if (isLoading) {
      return <div className="margin-2 spinner"></div>
    }
    if (error) {
      return <div>There was an error fetching timesheets. Please try again later.</div>
    }
    if (!timesheets || timesheets.length === 0) {
      return <div>No timesheets found.</div>
    }
   
    return(

      <>
      {submitNoteOpen &&
        <Modal focusTrap={false} size="medium" toggleModal={toggleSubmissionNote}>
            <div className='modal-header flex-cont align-center'>
              <h1 className="flex-1">Submission Note</h1>
              <div className="flex-1"></div>
              <div onClick={toggleSubmissionNote} className="ghost button close">
                <i className="fas fa-times"></i>
              </div>
            </div>

            <div className="padding-1">

              {submitNote}

            </div>

            <div className='modal-footer flex-cont align-center'>

            </div>
          </Modal>
      }
      <table className="basic no-stripes">
        <thead>
          <tr>
            <th>Employee</th>
            <th>Timesheet</th>
            <th className="fit nowrap">Start Date</th>
            <th className="fit nowrap">End Date</th>
            <th className="fit nowrap">
              PM Hours
            </th>
            <th className="fit nowrap">
              Period Hours
            </th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        {timesheets && timesheets.map( (timesheet, i) => 
          <tr onClick={() => selectTimesheetIndex(i, timesheet)} className={selectedTimesheetIndex === i ? "selectable selected" : "selectable"}>            
            <td className="fit nowrap">{timesheet.employeeName}</td>
            <td className="fit nowrap">
              {timesheet.number}
            </td>
            <td className="fit nowrap">{DateTime.fromISO(timesheet.weekStart).toLocaleString()}</td>
            <td className="fit nowrap">{DateTime.fromISO(timesheet.weekEnd).toLocaleString()}</td>

            <td className="fit nowrap">
              {timesheet.pmHours}
            </td>

            <td className="fit nowrap">
              {timesheet.totalHours}
            </td>


            <td className="fit nowrap">
            
              {timesheet.submissionnote &&
                <div className="font-075 button ghost">
                  <div onClick={() => subNoteClicked(timesheet.submissionnote)} className="margin-right-half ">SUBMISSION NOTE</div>
                  <i class="fas fa-pencil-alt"></i>
                </div>
              }
            </td>
            <td>
              <a rel="noreferrer" target="_blank" className="ghost button font-075" href={`/timesheets/${timesheet.id}/view`}>
                <div className="margin-right-half ">OPEN</div>
                <i class="fas fa-external-link-alt"></i>
              </a>
            </td>

            
            <td>
              {/* <div onClick={() => toggleTimesheetTooltip(timesheet.id)} className="tooltip-cont chevron-cont">
                <i className="fas fa-chevron-down"></i>
                <div className={timesheetTooltipIsOpen(timesheet.id) ? "open timesheet tooltip" : "timesheet tooltip"}>
                  <ul>
                    <li className="nowrap"><Link to={`/timesheets/${timesheet.id}`}>View Timesheet</Link></li>
                    <li><a className="nowrap">Approve All Timesheet's Lines</a></li>
                    <li onClick={() => returnClicked(timesheet)}><a className="nowrap">Return All Timesheet's Lines</a></li>
                  </ul>
                </div>

              </div> */}
              
            </td>
          </tr>
        )}
      </table>
      </>
    );
    
  }


  const [modalLine, setModalLine] = useState(null);

  const lineReturnClicked = (line) => {
    // setModalLine(line);
    // setReturnModalOpened(true);
    // alert(JSON.stringify(line));

    setSelectedLines([line.id]);
    setBulkReturnModalOpened(true);
  };
  
  const toggleReturnModalOpened = () => {
    setReturnModalOpened(old => !old);
    setModalLine(null);
  }

  const toggleBulkReturnModalOpened = () => {
    setBulkReturnModalOpened(old => !old);


    // setSelectedLines([]);
  }

  useEffect(() => {
    if (!bulkReturnModalOpened) {
      setSelectedLines([]);
    }
  }, [bulkReturnModalOpened])

  const [returnComment, setReturnComment] = useState(null);

  const [returnResponse, returnLoading, returnLoaded, returnError, doReturnFetch, setReturnResult] = useApi();

  const returnClicked = async () => {
    toggleReturnModalOpened();
    // doReturnFetch(returnLine, {payload: {id: line.id}});
    // try {

    //   // store.dispatch(showFullscreenLoading("Returning timesheet..."));
    //   // const response = await store.dispatch(api('timesheet_entries/reject', null, {description: returnComment, id: modalLine.id}, 'POST', 'RETURN_ENTRY'));


    //   // store.dispatch(hideFullscreenLoading());
    //   // 
    //   // store.dispatch(api('timesheet_entries', null, {status: "submitted", timesheetid: selectedTimesheet.number}, 'GET', 'ENTRIES'));
    //   toggleReturnModalOpened();
    //   // alert("Successfully rejected.")
    // }
    // catch (e) {
    //   // store.dispatch(hideFullscreenLoading());
    //   alert(e);
    // }
  }




  
  const [approveResponse, approveLoading, approveLoaded, approveError, doApproveFetch, setApproveResult] = useApi();
  const bulkApproveClicked = async () => {
    doApproveFetch(approveLine, {payload: {id: selectedLines}});
  };
  useEffect(() => {
    if (approveLoaded) {

      selectedLines.forEach(lineID => {
        store.dispatch(removeEntry(lineID))
      });


      toggleReturnModalOpened();
      setActionsOpen(false);
      setOpenLineTooltip(null);
      setSelectedLines([]);
    }
  }, [approveLoaded]);


  const getCurrentLine = (id) => {
    if (!entries || !id) {
      return null;
    }
    return entries.find(e => e.id === id); 
  }

  const onSubmit = async (data) => {
    

    // 
    // try {
    //   // store.dispatch(showFullscreenLoading("Returning timesheets..."));
    //   // const response = await store.dispatch(api('timesheet_entries/reject', data, null, 'POST', 'RETURN_ENTRY'));
    //   // store.dispatch(hideFullscreenLoading());
    //   // 
    //   // store.dispatch(api('timesheet_entries', null, {status: "submitted", timesheetid: selectedTimesheet.number}, 'GET', 'ENTRIES'));
    //   toggleReturnModalOpened();
    //   // alert("Successfully rejected.")
    // }
    // catch (e) {
    //   // store.dispatch(hideFullscreenLoading());
    //   alert(e);
    // }

    doReturnFetch(returnLine, {payload: data});

  }

  useEffect(() => {

    if (returnLoaded) {

      // TODO
      // store.dispatch(removeEntry(line.id))
      selectedLines.forEach(lineID => {
        store.dispatch(removeEntry(lineID))
      });

      reset();
      toggleBulkReturnModalOpened();
      setActionsOpen(false);
      setOpenLineTooltip(null);
      setSelectedLines([]);
    }
    
  }, [returnLoaded]);

  // lineApprovedClicked


  const periodDays = () => {
    let days = [];
    let count = 1;
    let weekStart = DateTime.fromISO(selectedTimesheet.weekStart);
    let weekEnd = DateTime.fromISO(selectedTimesheet.weekEnd)
    let currentDay = weekStart;

    if (weekStart.weekday === 7) {
      days.push([` ${weekStart.toFormat('EEE').toUpperCase()}`, currentDay, count]);
      currentDay = currentDay.plus({day: 1}); 
      count += 1;
    }
    else {

    }
    

    while (currentDay <= weekEnd) {
      
      days.push([`${currentDay.toFormat('EEE').toUpperCase()}`, currentDay, count]);
      currentDay = currentDay.plus({day: 1});
      count += 1;
    }

    return days;
  }

  





  return (
    <div>
      {/* {selectedTimesheet &&
        <div>{JSON.stringify(periodDays())}</div>
      } */}
      {/* {fullScreenLoading  &&
        <FullScreenSpinner message="Returning timesheet..." />
      } */}
      {/* <div className="flex-cont align-center">
        <h1 className="flex-1">Review Timesheets</h1>
      </div> */}

      <div className="flex-cont align-center">
        <h1 className="flex-cont">
          <div className="margin-right-half medgray">{currentCompany}</div>
            Review Timesheets
        </h1>
      </div> 

      <div className="margin-bottom-3">
        <div className={`selector-cont ${timesheetsLoading ? 'disabled' : ''}`}>
          <div onClick={() => setTab("mine")}  className={`${tab === 'mine' ? 'selected' : ''} selector`}>

            

              <div className="flex-cont align-center">
                
                <div>My Projects</div>

                {(!!submittedCount?.hash?.mine) &&
                  <div className="unread-count margin-left-half">
                    <div className="number">{submittedCount?.hash?.mine ? submittedCount?.hash?.mine : "-"} </div>
                  </div>
                }
              </div>
            
            
          </div>
          <div onClick={() => setTab("delegated")}  className={`${tab === 'delegated' ? 'selected' : ''} selector`}>
          <div className="flex-cont align-center">

          
              <div>Delegated Projects</div>

              {(!!submittedCount?.hash?.delegated) &&
                <div className="unread-count margin-left-half">
                  <div className="number">{submittedCount?.hash?.delegated ? submittedCount?.hash?.delegated : "-"} </div>
                </div>
              } 
            </div>
          </div>
        </div>
      </div>

    

      


      {timesheetsLoading &&
        <div className="spinner"></div>
      }
      {(!timesheetsLoading && timesheetsLoaded && timesheets && timesheets.length === 0) &&
        <>
          No {tab === "delegated" ? "delegated" : ""} timesheets to be reviewed.
        </>
      }
      {(!timesheetsLoading && timesheetsLoaded && timesheets && timesheets.length !== 0) &&
        <>


      

      <div className="content">
      {false && modalLine && returnModalOpened === true && 
        <Modal toggleModal={toggleReturnModalOpened}>
          <div className='modal-header flex-cont align-center'>
            <h1 className="flex-1">Return</h1>
            <div onClick={toggleReturnModalOpened} className="button close">
              <i className="fas fa-times"></i>
            </div>
          </div>

          <div className="padding-1">
          {/* {JSON.stringify(modalLine)} */}
          <table className="basic vertical">

              <tbody>
                  <tr>
                    <td className="fit nowrap">Employee</td>
                    <td>{selectedTimesheet.employeeName}</td>
                  </tr>
                  <tr>
                    <td className="fit nowrap">Timesheet</td>
                    <td>{modalLine.timesheetId}</td>
                  </tr>
                  <tr>
                    <td className="fit nowrap">Start</td>
                    <td className="nowrap">{DateTime.fromISO(modalLine.weekStart).toLocaleString()}</td>
                  </tr>
                  <tr>
                    <td className="fit nowrap">End</td>
                    <td className="nowrap">{DateTime.fromISO(modalLine.weekEnd).toLocaleString()}</td>
                  </tr>
                  <tr>
                    <td className="fit nowrap">Project</td>
                    <td>{modalLine.projectId}<br />{modalLine.projectName}</td>
                  </tr>
                  <tr>
                    <td className="fit nowrap">Activity</td>
                    <td>{modalLine.activityNumber}<br />{modalLine.activityName}</td>
                  </tr>
                  <tr>
                    <td className="fit nowrap">Category</td>
                    <td className="nowrap">{modalLine.category}</td>
                  </tr>
                  <tr>
                    <td className="fit nowrap">Total Hours</td>
                    <td className="nowrap">{modalLine.totalPeriodHours}</td>
                  </tr>
              </tbody>
            </table>
            <div className="margin-top-1"></div>
              <table className="basic no-stripes">
                <thead>
                  <tr>
                  <th>Hours</th>
                  <th>SUN</th>
                  {Info.weekdays('short').map(day => {
                    return (
                      <th>{day}</th>
                    );
                  })}
                  <th></th>
                  </tr>
                </thead>
                <tbody>
                    <tr>
                      <td className="fit nowrap">{modalLine.hours}</td>
                      {[...Array(8)].map((day, i) => {
                        return (
                          <td className="hdour">
                            <strong>{modalLine[`day${i+1}hour`]}</strong>
                          </td>
                        );
                      })}

                    </tr>
  
              </tbody>
            </table>
            <div className="margin-bottom-3"></div>
            <textarea placeholder="Notes" onChange={(e) => setReturnComment(e.target.value)} />
            
          </div>

          <div className='modal-footer flex-cont align-center'>
            <input onClick={returnClicked} type="submit" value="Submit" className="margin-right-1 button" />
            <div onClick={toggleReturnModalOpened}  className="button ghost">Cancel</div>
          </div>
        </Modal>
      }
      {bulkReturnModalOpened && 
        <Modal toggleModal={toggleBulkReturnModalOpened}>
          <form  onSubmit={handleSubmit(onSubmit)}>
          <div className='modal-header flex-cont align-center'>
            <h1 className="flex-1">Return</h1>
            <div onClick={toggleBulkReturnModalOpened} className="ghost button close">
              <i className="fas fa-times"></i>
            </div>
          </div>

          <div className="padding-1">

          {/* <div className="code">
            <pre>
              {JSON.stringify(selectedLines)}
            </pre>
          </div> */}

         
          

          {selectedLines && selectedLines.map((lineId, index) => {

            const line = getCurrentLine(lineId);

            if (!line) {
              return <></>
            }

            return(
              <div className="section margin-bottom-1">
                <input  {...register(`notes[${index}].entryId`)} type="hidden" name={`notes[${index}].entryId`} defaultValue={lineId} />
                <table className="basic vertical no-stripes">
                  <tbody>
                      <tr>
                        <td className="fit nowrap">Employee</td>
                        <td>{selectedTimesheet.employeeName}</td>
                      </tr>
                      <tr>
                        <td className="fit nowrap">Timesheet</td>
                        <td>{line.timesheetId}</td>
                      </tr>
                      <tr>
                        <td className="fit nowrap">Start</td>
                        <td className="nowrap">{DateTime.fromISO(line.weekStart).toLocaleString()}</td>
                      </tr>
                      <tr>
                        <td className="fit nowrap">End</td>
                        <td className="nowrap">{DateTime.fromISO(line.weekEnd).toLocaleString()}</td>
                      </tr>
                      <tr>
                        <td className="fit nowrap">Project</td>
                        <td>{line.projectId}<br />{line.projectName}</td>
                      </tr>
                      <tr>
                        <td className="fit nowrap">Activity</td>
                        <td>{line.activityNumber}<br />{line.activityName}</td>
                      </tr>
                      <tr>
                        <td className="fit nowrap">Category</td>
                        <td className="nowrap">{line.category}</td>
                      </tr>
                      <tr>
                        <td className="fit nowrap">Total Hours</td>
                        <td className="nowrap">{line.totalPeriodHours}</td>
                      </tr>
                  </tbody>
                  </table>
                  <div className="margin-top-1"></div>
                  <table className="basic no-stripes">
                    <thead>
                      <tr>
                      <th>Hours</th>
                      {/* <th>SUN</th> */}
                      {Info.weekdays('short').map(day => {
                        return (
                          <th>{day}</th>
                        );
                      })}
                      <th></th>
                      </tr>
                    </thead>
                    <tbody>
                        <tr>
                          <td className="fit nowrap">{line.totalPeriodHours}</td>
                          {[...Array(7)].map((day, i) => {
                            return (
                              <td className="hdour">
                                
                                <strong>
                                  {line?.daysTest?.[Info.weekdays('short')[i]?.toUpperCase()]?.hour}
                                  
                                </strong>
                              </td>
                            );
                          })}

                        </tr>

                  </tbody>
                  </table>
                  <div className="margin-bottom-3"></div>
                  <textarea {...register(`notes[${index}].note`)} name={`notes[${index}].note`} placeholder="Notes" />
              </div>
            )
          })}
         

          </div>
          <div>
            {/* {JSON.stringify(errors)} */}
          </div>
          <div className='modal-footer flex-cont align-center'>
            {returnLoading &&
              <div className="spinner"></div>
            }
            <input disabled={returnLoading} type="submit" value="Submit" className="margin-right-1 button" />
            <div onClick={toggleBulkReturnModalOpened}  className="button ghost">Cancel</div>
          </div>
          </form>
        </Modal>
      }

      

      
        <div className="content">

          
            <div className="section">
              <>
                {renderContent()}
              </>
            </div>
          
            <div className="margin-bottom-3">
            </div>

            <div className="margin-bottom-1 flex-cont align-center">
              <div ref={wrapperRef} className="tooltip-cont margin-right-1">
                <div onClick={toggleActionsTooltip} className="outline button">
                  <i className="fas fa-chevron-down margin-right-half"></i>
                  Actions
                  
                </div>
                <div className={actionsOpen ? "open actions tooltip" : "actions tooltip"}>
                  <ul>
                    {selectedLines && selectedLines.length !== 0 ? (
                      <>
                        <li onClick={bulkApproveClicked}><a className="nowrap">Approve</a></li>
                        <li onClick={toggleBulkReturnModalOpened}><a className="nowrap">Return</a></li>
                      </>
                    ) : (
                      <>
                        <li><a className="disabled nowrap">Approve</a></li>
                        <li><a className="disabled nowrap">Return</a></li>
                      </>
                    )
                    }
                  </ul>
                </div>
              </div>
            </div>



            <div className="better section">
              {/* {JSON.stringify(selectedLines)} */}
              <h2 className="flex-contz">
                
                <div className="margin-right-1">Entries</div>
                <div className="font-075 flex-cont align-center margin-bottom-2">
                  <div className="margin-right-1" style={{fontWeight: 'normal'}}>{selectedTimesheet && `${selectedTimesheet.number}:`}</div>
                  {/* <i className="gray font-0-5 margin-h-1 fas fa-circle"></i> */}
                  <div style={{fontWeight: 'normal'}} className="margin-right-half">
                    {/* {JSON.stringify(selectedTimesheet)} */}
                    {selectedTimesheet && selectedTimesheet.weekStart && DateTime.fromISO(selectedTimesheet.weekStart).toLocaleString()} - {selectedTimesheet && selectedTimesheet.weekEnd && DateTime.fromISO(selectedTimesheet.weekEnd).toLocaleString()}
                  </div>
                </div>
              </h2>
              {/* <em className="font-1">(WIP) Render timesheet lines here.</em> */}
              {(entriesLoading || approveLoading) &&
                <div className="margin-2 spinner"></div>
              }
              {/* <div className="code">
                <pre>
                  {JSON.stringify(entries, null, 2)}
                </pre>
              </div> */}
              {!entriesLoading && entries && entries.length === 0 &&
                <div>
                  No entries found.
                </div>
              }
              {!entriesLoading && entries && entries.length !== 0 &&
                <table className="basic">
                  <thead>
                    <tr>
                      <th className="fit nowrap">
                        <div onClick={toggleAllLines} className={allLinesSelected() ? "selected check-box" : "check-box"}>
                          <div className="check"></div>
                        </div>
                      </th>
                      <th></th>
                      {tab === "delegated" &&
                        <th>Delegated By</th>
                      }
                      <th>Project</th>
                      <th>Activity</th>
                      <th></th>
                      <th>Cat.</th>
                      <th className="nowrap">Start</th>
                      <th className="nowrap">End</th>
                      <th>Hrs.</th>
                      {/* <th>SUN</th> */}
                      {Info.weekdays('short').map(day => {
                        return (
                          <th>{day}</th>
                        );
                      })}
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {entries && entries.map((line, i) => {
                      return (
                        <EntryReview tab={tab} periodDays={periodDays} selectLine={selectLine} line={line} lineIsSelected={lineIsSelected} lineReturnClicked={lineReturnClicked} />
                      );
                    })}
                  </tbody>
                </table>
              }
            </div>
        </div>
      </div>

      </>
    }
      
    </div>
  );

  
};

const mapState = state => ({
  // timesheets: state.timesheetState.submitted,
  isLoading: state.timesheetState.isLoading,
  error: state.timesheetState.error,
  entries: state.entryState.data,
  // entriesLoading: false, //state.timesheetEntryState.isLoading,
  fullScreenLoading: state.uiState.fullScreenLoading,
  currentCompany: state.authState.currentCompany,
  submittedCount: state.timesheetState.submittedCount,
});

export default connect(mapState)(TimesheetsReview);
