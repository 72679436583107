

import React, { useEffect } from 'react';
import { NavLink, Link, Outlet, useLocation } from 'react-router-dom';
import Dashboard from '../Dashboard';
const AdminHome = ({children}) => {

  return (
    <>
     <Dashboard />
     
    </>
  );
}

export default AdminHome;