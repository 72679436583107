


import React, { useState, useEffect } from 'react';
import uploadFileToBlob, { isStorageConfigured, getBlobsInContainer } from './azure-storage-blob';
import DisplayImagesFromContainer from './ContainerImages';
const storageConfigured = isStorageConfigured();

const Upload = ({inputsDisabled, blobs, currentExpenseLineID, toggleUploadModal, currentLineIndex, addBlob, removeBlob}) => {
  // all blobs in container
  const [blobList, setBlobList] = useState([]);

  // current file to upload into container

  const currentBlob = blobs?.[currentLineIndex];

  const initialURL = () => {
    if (blobs && blobs[currentLineIndex] && blobs[currentLineIndex].blobname) {
      const containerName = `expenselinefiles`;
      const sasToken = process.env.REACT_APP_AZURE_STORAGE_SAS_TOKEN_READ_ONLY;
      const storageAccountName = process.env.REACT_APP_AZURE_STORAGE_RESOURCE_NAME;
      return `https://${storageAccountName}.blob.core.windows.net/${containerName}/${blobs[currentLineIndex].blobname}?${sasToken}`;
    }
  }

  const [existingFileURL, setExistingFileUrl] = useState(initialURL());
  const [fileSelected, setFileSelected] = useState();
  const [fileUploaded, setFileUploaded] = useState('');

  useEffect(() => {
    if (existingFileURL) {

    }
  }, [existingFileURL]);

  // UI/form management
  const [uploading, setUploading] = useState(false);
  const [inputKey, setInputKey] = useState(Math.random().toString(36));

  // const [reader, setReader] = useState(new FileReader());

  const [previewSrc, setPreviewSrc] = useState(null);

  const [errors, setErrors] = useState(null);

  // *** GET FILES IN CONTAINER ***
  useEffect(() => {

    const test = async () => {
      const list = await getBlobsInContainer();
      setBlobList(list);
    }

    test();

  }, []);




  useEffect(() => {

    console.log('fileSelected', fileSelected)

    if (fileSelected && (fileSelected.type === "image/jpeg" || fileSelected.type === "image/png")) {
      
      const reader = new FileReader();
      reader.addEventListener(
        "load",
        () => {
          // convert image file to base64 string
          // preview.src = reader.result;
          setPreviewSrc(reader.result);
        },
        false
      );

      reader.readAsDataURL(fileSelected);
    }
    else {
      setPreviewSrc(null);
    }

  }, [fileSelected]); 

  const onFileChange = (event) => {
    // capture file into state

    setErrors(null);
    const file = event.target.files[0];
    

    

    if (file) {

      // alert(file.type === "image/jpeg")

      let errorArray = [];

      if (file.size >= 5600000) {
        errorArray.push("Please attach an image that is less than 5MB.");
      }
      if (file.type === "image/jpeg" || file.type === "image/png" || file.type === "application/pdf") {

      } 
      else {
        errorArray.push("Please attach an image with file types .pdf, .jpeg, .jpg, or .png");
      }
      if (errorArray.length !== 0) {
        setErrors(o => errorArray);
      }
      else {
        setFileSelected(file);
      }

      
      
    }
  };



  const onFileUpload = async () => {


    
    if(fileSelected && fileSelected?.name) {
      console.log(fileSelected);


      const nameSplit = fileSelected.name.split('.');
      let nameWithoutExtension = nameSplit[0];

      if (!nameWithoutExtension) {
        nameWithoutExtension = "untitled" 
      }

      const extension = nameSplit[1] ? nameSplit[1] : "";
      const formattedName = `${nameWithoutExtension.replace(/\W/g, '')}.${extension}`;

    
      console.log('nameWithoutExtension', nameWithoutExtension)
      console.log('formattedName', formattedName)

      const name = `${(+new Date * Math.random()).toString(36).substring(0,5)}_${(new Date()).getTime().toString()}_${formattedName}`;

      // reader.readAsDataURL(fileSelected);
      const hash = {
        file: fileSelected,
        blobname: name,
        filetype: fileSelected.type,
        expenselineid: currentExpenseLineID,
        blobPreview: finalImageURL(),
      }

      console.log("hash", hash); 
    
      addBlob(hash, currentLineIndex);

      toggleUploadModal();
      
      return true;
      // prepare UI
      setUploading(true);

      // *** UPLOAD TO AZURE STORAGE ***
      await uploadFileToBlob(fileSelected);

      // reset state/form
      setFileSelected(null);
      setFileUploaded(fileSelected.name);
      setUploading(false);
      setInputKey(Math.random().toString(36));

    }

  };


  const removeClicked = () => {
    setPreviewSrc(null);
    setFileSelected(null);
    setErrors(null);
    setFileUploaded('');
    setUploading(false);
    setInputKey(Math.random().toString(36));
    removeBlob(currentLineIndex);
    toggleUploadModal();
  }


  const finalImageURL = () => {
    return previewSrc || currentBlob?.blobPreview || existingFileURL;
  }

  const finalFileType = () => {
    if (fileSelected?.type) return fileSelected?.type;

    if (currentBlob?.filetype) return currentBlob?.filetype;




  }



  // display form
  const DisplayForm = () => (
    <div className="flex-contx align-center">
      <div className='margin-bottom-2'>
        <input accept="image/png, image/jpeg, application/pdf" type="file" onChange={onFileChange} key={inputKey || ''} />
      </div>
      <hr />
      <div className="margin-top-2">
        
          <div className='margin-bottom-2'>
            {finalFileType() &&
              <div onClick={removeClicked} className="button outline margin-right-1">Remove</div>
            }

            {fileSelected &&
              <button className={`${!fileSelected ? 'disabled' : ''}`} disabled={!fileSelected} onClick={onFileUpload}>
                Save
              </button>
            }
          </div>
          
      </div>
      

      
    </div>
  )

  const isImage = () => {
    return (finalFileType() === "image/png" || finalFileType() === "image/jpeg" || finalFileType() === "image/jpg")
  }
  const isPDF = () => {
    return (finalFileType() === "application/pdf")
  }


  return (

    
    <div>
      {/* <h3>C</h3> */}
      {/* <pre>
        REACT_APP_AZURE_STORAGE_SAS_TOKEN: {process.env.REACT_APP_AZURE_STORAGE_SAS_TOKEN}
        REACT_APP_AZURE_STORAGE_RESOURCE_NAME: {process.env.REACT_APP_AZURE_STORAGE_RESOURCE_NAME}
      </pre> */}
      
      {/* <div>
        <pre>
          {JSON.stringify(blobList, null, 2)}
        </pre>
      </div> */}

      {inputsDisabled && finalImageURL() && isImage() &&
        <div className="margin-bottom-2">
          <a href={finalImageURL()} target="_blank" className="button">
            View Full Image
          </a>
        </div>
      }

      {/* <div>
        <pre>
          {JSON.stringify(inputsDisabled, null, 2)}
        </pre>
      </div>
      <div>
        <pre>
          {JSON.stringify(finalImageURL(), null, 2)}
          
        </pre>
      </div>
      <div>
        <pre>
          {JSON.stringify(isImage())}
        </pre>
      </div>
      <div>
        <pre>
          {JSON.stringify(finalFileType())}
        </pre>
      </div> */}
      {inputsDisabled && finalImageURL() && isPDF() &&
        <div className="margin-bottom-2">
          <a href={finalImageURL()} target="_blank" className="button">
            View PDF
          </a>
        </div>
      }

      {!inputsDisabled && <>

        <ul className='gray'>
          <li>Types accepted: .pdf, .jpeg, .jpg, .png</li>
          <li>Max file size: 5MB</li>
        </ul>
        
        {storageConfigured && !uploading && DisplayForm()}
        {storageConfigured && uploading && <div className="spinner"></div>}
        
        {/* {storageConfigured && blobList.length > 0 && <DisplayImagesFromContainer blobList={blobList}/>} */}
        {/* {!storageConfigured && <div>Storage is not configured.</div>} */}

        {!storageConfigured &&
          <div className="danger section margin-bottom-1">
            There is a problem with uploading files. Please try again later.
          </div>
        }

        {storageConfigured && errors && errors.length &&
          <div className="danger section margin-bottom-1">
            <ul>
              {errors.map(error => 
                <li>
                  {error}
                </li>
              )}
            </ul>
          </div>
        }
      </>}


      {finalFileType() === "application/pdf" &&
        <div className="font-1">
          <i className="fas fa-file-pdf margin-right-half font-1-5"></i>
          <span className='bold'>PDF Attached: </span>
          {finalImageURL() &&
            <a style={{color: '#f6c346'}} className='text-link' target="_blank" href={finalImageURL()}>{fileSelected?.name || currentBlob?.blobname}</a>
          }
          {!finalImageURL() &&
            <>{fileSelected?.name || currentBlob?.blobname}</>
          }
          
        </div>
      }
      {(finalFileType() === "image/png" || finalFileType() === "image/jpeg" || finalFileType() === "image/jpg")  &&
        <>
          {finalImageURL() &&
            <img style={{maxWidth: '100%'}} src={finalImageURL()} />
          }
        </>
      }
      
    </div>
  );
};

export default Upload;