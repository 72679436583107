import React, { useEffect, useState } from 'react';

import {
  BrowserRouter as Router,
  Link, 
} from 'react-router-dom';

import * as yup from "yup";
import { connect } from 'react-redux';

import { useApi } from '../../api';

import { getTeams} from '../../redux/admin/teamActions'; 
import Pagination from '../../components/Pagination';

const AdminProjectTeams = ({currentCompany}) => {
  const [page, setPage] = useState(1);
  const [result, loading, loaded, error, doFetch, setResult, pagy] = useApi();
  
  useEffect(() => {
    fetchTeams();
  }, [currentCompany, page]);


  const fetchTeams = () => {
    doFetch(getTeams, {query: {page: page}});
  }

  const handlePageChange = (p) => {
    setPage(p);
  };

  return (
    <>

      <div className="xxx margin padding-2x">

        <h1 className="flex-cont">
          <div className="medgray margin-right-half">{currentCompany}</div>
          Project Manager Teams
        </h1>


        <Link to={`/teams/project-manager/new`} className="button margin-bottom-2">
          <i className="fas fa-plus margin-right-half"></i>
          <span>Add Team</span>
        </Link>

        
        <Pagination disabled={loading} currentPage={page} pagy={pagy} handlePageChangeFn={handlePageChange} />
        <div className="margin-top-2 margin-bottom-2">

          {loading &&
            <div className="margin-left-1 spinner"></div>
          }

          


          {!loading &&
            <table className="basic">
              <tr>
                <th>Manager</th>
                <th className="fit nowrap"># Members</th>
                <th className="fit nowrap"># Projects</th>
                <th className="fit nowrap">Company</th>
                <th></th>
                <th></th>
              </tr>
              {result && result.map(team => {
                return (
                  <tr>
                    <td className="fit nowrap">{team.teamname}</td>
                    <td  className="center fit nowrap">{team.usercount}</td>
                    <td  className="center fit nowrap">{team.project_count}</td>
                    <td  className="center fit nowrap">{team.company}</td>
                    <td className="full"></td>
                    <td>
                      <Link to={`edit/${team.teamid}`}>
                        <i className="ghost button fas fa-pencil-alt"></i>
                      </Link>
                      
                    </td>
                  </tr>
                )
              })}
            </table>
          }
        </div>
        <Pagination disabled={loading} currentPage={page} pagy={pagy} handlePageChangeFn={handlePageChange} />

      </div>

      
    </>
  );
}

function mapState(state) {
  return { 
    currentCompany: state.authState.currentCompany
  };
} 

export default connect(mapState)(AdminProjectTeams);
