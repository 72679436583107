import React, { useMemo} from "react";
import { DateTime } from 'luxon';
import { useWatch } from 'react-hook-form';
import SummaryDay from "./SummaryDay";
import SummaryWeekTotal from "./SummaryWeekTotal";

const SummaryWeek = ({errors, setValue, register, setSumArray, control, setSelectedWeekIndex, selectedWeekIndex, week, index}) => {

  const getMyErrors = () => {
    return errors?.['week']?.[index]?.['entries']?.filter(e => true && e)?.length || 0
  }

  const entriesWatch = useWatch({
    control,
    name: `week.${index}.entries`,
    defaultValue: week.entries
  });


  const sumFields = () => {

    const array = entriesWatch;
    if (!array || array.length === 0) return [];

    const dayNames = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"];
    let valueArray = [];

    

    dayNames.forEach((dayName, index) => {
      const sum = array.reduce((previous, current) => {
        if (current === null || current === undefined) {
          return previous;
        }

        const num = parseFloat(current[`day${index+1}hour`]);
        if (num === null || num === undefined) return 0.0;
        if (isNaN(num) || isNaN(num)) {
          return previous;
        }
        return num + previous;
      }, 0);
  
      valueArray.push(parseFloat(sum));

    })

    return valueArray;
  };

  const sum = sumFields();


  
  const weekSum = sum?.reduce((current, previous) => { return (current + previous) }, 0);

  // const test = sumFields();


  if (!week || week.length === 0) {
    return null;
  }

  return (
    <tr onClick={() => setSelectedWeekIndex(index)} className={selectedWeekIndex === index ? "selectable selected" : "selectable"}>
      <td className="center fit nowrap">   

       {getMyErrors() !== 0 &&
          <div className="force-no-margin alpha-error-cont inline-flex font-08">

              <div className="margin-right-half">
                {getMyErrors()}
              </div>
              <div className="normalx">
                {`ISSUE${getMyErrors() !== 1 ? "S" : ""}`}
              </div>
          </div>
        } 
        {week.returned_count !== 0 &&

            <div className="force-no-margin alpha-error-cont inline-flex font-08">
              <div className="margin-right-half">
                {week.returned_count}
              </div>
              <div className="not-bold">
                RETURNED
              </div>
            </div>

        }
      </td>
      <td className="center fit nowrap">
        <strong>{index+1}</strong>
      </td>
      <td className="fit nowrap">{DateTime.fromISO(week.week_start).toLocaleString()}</td>
      <td className="fit nowrap">{DateTime.fromISO(week.week_end).toLocaleString()}</td>
      



      {week?.test?.slice(1).map((day, index) => {
        return (
          <SummaryDay hours={sum[index]} day={day} />
        )
      })}

      {/* Sunday: */}
      <SummaryDay hours={sum[6]} day={week.test[0]} />



      <td className="fit nowrap center">
        
        <div className="clock-in align-center">

        </div>
        <div className="bold"> 
          {/* {w && sum()}
          {!w && p.totalHours} */}

          <SummaryWeekTotal  setValue={setValue} register={register} weekIndex={index} totalHours={weekSum} />
          
        </div>

        
      </td>
      <td></td>
    </tr>
  )
}



export default SummaryWeek;