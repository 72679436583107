
export const truncateString = (string = '', maxLength = 20) => {
  if (string == null || string.toString().length <= 0) {
    return '';
  }

  return (
    string.length > maxLength 
    ? `${string.substring(0, maxLength)}…`
    : string
  )
}

export const isNumeric = (str) => {

  // 

  if ((typeof str != "string") && (typeof str != "number")) return false // we only process strings!  


  

  return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
         !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

const LOGO_NAMES = {
  UNSC: "UNSC",
  BYKK: "BYKK",
  CHID: "COOPERSHILL",
  CHBK: "COOPERSHILL",
  CHSG: "COOPERSHILL",
  CHMY: "COOPERSHILL",
  CHPR: "COOPERSHILL",
  ILLA: "ILLA",
  STCA: "STCA",
  ICA: "CANVAS",
  CAHK: "CANVAS",
  CALA: "CANVAS",
  RDLN: "RDLN",
  PSLA: "STUDIO", 
  STIN: "STUDIO", 
  STMI: "STUDIO", 
  STSE: "STUDIO",
  HBCH: "STUDIO",
} 


export const getLogoName = (company) => {
  if (!company) return "HBA";

  if (company === "HBCH" || !company.startsWith("HB")) {
    const name = LOGO_NAMES[company];

    if (!name) {
      return "HBA"
    }

    return name;
  }
  else {
    return "HBA"
  }


  return "HBA";

}