import React, { useEffect, useState } from 'react';

// import TimesheetsNav from './TimesheetsNav';
import { useApi } from '../../api';
import { connect } from 'react-redux';

import { getMyAdminProjects, getAllProjects, getProjects } from '../../redux/projects/projectActions';
import { getReviewCount } from '../../redux/manager/budgetHeaders/budgetHeaderActions';
import { DateTime } from 'luxon';
import {
  Link, useNavigate,
} from 'react-router-dom';

import { usePrevious } from '../../hooks/usePrevious';

import BudgetPrintModal from './BudgetPrintModal';

import TH from './TH';
import { useSortBudgets, toNum, isNumber } from './useSortBudgets';
import Selector from '../../components/Selector';
import Pagination from '../../components/Pagination';


const BudgetingAll = ({currentCompany, children}) => {

  const navigate = useNavigate('/');
  const prevCompany = usePrevious(currentCompany);

  const [tab, setTab] = useState("all");
  const [filtered, setFiltered] = useState(null);
  const [reviewCountResult, reviewCountLoading, reviewcountLoaded, reviewcountError, doReviewCountFetch, setReviewCountResult] = useApi();
  const [projects, projectsLoading, projectsLoaded, projectsError, doProjectsFetch, setProjectsResult, pagy, apiRef] = useApi();

  const [search, setSearch] = useState("");

  const onSearchClicked = () => {
    fetchProjects();
  }

  const [page, setPage] = useState(1);
  const handlePageChange = (p) => {
    setPage(p);
  };


  useEffect(() => {
    // if (prevCompany && currentCompany) {
    //   if (prevCompany !== currentCompany) {
    //     fetchProjects();
    //   }
    // } 
    fetchProjects();   
  }, [currentCompany, page, tab]);
  

  useEffect(() => {
    setPage(1);  
    // setSearch("");
    // alert('here')
  }, [tab]);

  // useEffect(() => {
    
  //   if (tab) {

  //     fetchProjects()

  //     // if (tab === "all"){
  //     //   setFiltered(projects);
  //     // }
  //     // else {
  //     //   const f = projects.filter(p => p.budgetHeaderStatus?.toString()?.toLowerCase() === tab);
  //     //   setFiltered(f);
  //     // }

      

  //   }

  // }, [tab]);


  const fetchProjects = () => {
    doProjectsFetch(getAllProjects, {query: {search: search, sort_ass: sortAssociation, sort_col: sortedColumn, sort_dir: sortDirection, tab: tab, page: page}});
  }

  const {sorted, sortByColumn, setSorted, sortedColumn, sortDirection, columnClicked, sortAssociation} = useSortBudgets();

  useEffect(() => {
    if (projects) {
      // setSorted(sortByColumn([...filtered], sortedColumn, sortDirection));
      fetchProjects()
    }
  }, [sortedColumn, sortDirection]);

  useEffect(() => {
  }, [sorted]);

  const [printModalOpened, setPrintModalOpened] = useState(false);
  const [printProject, setPrintProject] = useState(null);

  const printClicked = (project) => {
    setPrintModalOpened(true);
    setPrintProject(project);
  }
  const togglePrintModal = () => {
    setPrintModalOpened(o => !o);
  }


  const tabCount = (name) => {

    return ""

    if (projects == null) return 0;

    if (true || name === "all"){
      return projects.length;
    }
    else {
      const f = projects.filter(p => p.budgetHeaderStatus?.toString()?.toLowerCase() === name);
      return f.length;
    }
  }

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      onSearchClicked();
    }
  }
  

  
  return (
    <>
      {/* <div className="flex-cont align-center">
        <h1>My Budgets</h1>
      </div> */}

      {printModalOpened &&
        <BudgetPrintModal opened={printModalOpened} project={printProject} toggleModal={togglePrintModal} />
      }

      <div className="flex-cont align-center">
        <h1 className="flex-cont">
          <div className="margin-right-half medgray">{currentCompany}</div>
            All Budgets
        </h1>
      </div>  

      <div className="margin-bottom-3">
        <div className="flex-cont align-center">

          <div className="padding-right-2">
            <div className="inline-flex align-center">
              <input onKeyDown={handleKeyDown} value={search} placeholder="Search Project ID or name..." onChange={e => setSearch(e.target.value)} type="text" name="" id="" disabled={projectsLoading == true} className={`${projectsLoading ? 'disabled' : '' } `} />
              <div onClick={onSearchClicked} className={`${projectsLoading ? 'disabled' : '' } margin-left-halfx ghost button`}>Search</div>
            </div>
          </div>
         
          <div className={`margin-left-x selector-cont ${projectsLoading ? 'disabled' : ''}`}>          
            <Selector label={"All"} name="all" setTab={setTab} tab={tab} count={tabCount("all")} />
            {/* <Selector label={"Returned"} name="returned" setTab={setTab} tab={tab} count={tabCount("returned")} /> */}
            <Selector label={"Needs Budget"} name="needs budget" setTab={setTab} tab={tab} count={tabCount("needs budget")} />
            <Selector label={"New"} name="new" setTab={setTab} tab={tab} count={tabCount("new")} />
            <Selector label={"Revised"} name="revised" setTab={setTab} tab={tab} count={tabCount("revised")} />
            <Selector label={"Pending"} name="pending" setTab={setTab} tab={tab} count={tabCount("pending")} />
            <Selector label={"Approved"} name="approved" setTab={setTab} tab={tab} count={tabCount("approved")} />
          </div>
          
        </div>
        
      </div>
      

      <div className="content margin-top-1">

        {/* <pre>
          pagy: {JSON.stringify(projects, null, 2)}
        </pre> */}

      


      <div className="flex-cont align-center">
        <div className="margin-right-1">
          <Pagination disabled={projectsLoading} currentPage={page} pagy={pagy} handlePageChangeFn={handlePageChange} />  
        </div>
        {/* {projectsLoading && <div className="spinner"></div> } */}
      </div>

         
          

      
      {/* {projectsError &&
        <div className='margin-top-4'>
          There was an error fetching your projects.
        </div>
      }
 */}


      {!projectsLoading && (projects && projects.length === 0) &&
        <div className='margin-top-4'>
          No results.
        </div>
      }

      {/* {projectsLoading &&
        <div className="spinner"></div>
      } */}

      <div className={`spinner-cont-cool ${projectsLoading && "entered"}`}>
        <div className="cool spinner"></div>
      </div>
     
      {projects && projects.length !== 0 &&
        <table className={`basic ${projectsLoading ? 'disabled-table disabled' : ''}`}>
          <thead>
            <tr>
              <TH direction={sortDirection} classNames={["fit", "nowrap"]} column="projectid" sortedColumn={sortedColumn} label="Project ID" columnClicked={columnClicked} />

              <TH direction={sortDirection} classNames={["fit", "nowrap"]} sortAssociation="budget_header" columnToSort="budgetheaderstatusid" column="budgetheaderstatusid" sortedColumn={sortedColumn} label="Status" columnClicked={columnClicked} />

              <TH direction={sortDirection} classNames={[]} column="projectname" sortedColumn={sortedColumn} label="Name" columnClicked={columnClicked} />

              <TH direction={sortDirection} classNames={[]} column="firstname" sortedColumn={sortedColumn} label="Project Manager" columnClicked={columnClicked} />

              <TH direction={sortDirection} classNames={[]} sortAssociation="budget_header"  column="approveddate" sortedColumn={sortedColumn} label="Budget Approved Date" columnClicked={columnClicked} />

              {/* <TH direction={sortDirection} classNames={["fit"]} column={null} sortedColumn={sortedColumn} label="Budgeted Hours" columnClicked={columnClicked} /> */}

              <th>
                Budgeted Hours
              </th>
              <th>
                Hours Remaining
              </th>
              <th>
                % Billing
              </th>

              {/* <TH direction={sortDirection} classNames={["fit"]} column="hoursRemaining" sortedColumn={sortedColumn} label="Remaining Hours" columnClicked={columnClicked} />

              <TH direction={sortDirection} classNames={["fit"]} column="billing" sortedColumn={sortedColumn} label="% Billing" columnClicked={columnClicked} /> */}
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {projects && projects.map( project => 
              <tr>
                
                
                <td className="fit nowrap">
                  <Link className="text-link" to={`/budgeting/projects/${project.projectid}`}>{project.projectid}</Link>
                </td>

                <td className="fit nowrap">
                  {/* <div>
                    Status: {project?.budgetHeaderStatus?.toUpperCase()}
                  </div> */}
                  <Link className="text-link" to={`/budgeting/projects/${project.projectid}`}>
                    <>
                  
                    {project.budgetHeaderStatus &&
                      <div className="status-badge-cont">
                        <div className={`budget status-badge ${project.budgetHeaderStatus.toLowerCase()}`}>
                          {project.budgetHeaderStatus.toUpperCase() }
                        </div>
                      </div>
                    }
                    {/* {!project.budgetHeaderStatus &&
                      <div className="bold font-075 gray">NEEDS BUDGET</div>
                    } */}
                  </>
                  </Link>
                </td>
                <td className="nowrapx">

                  {project.projectname}
                </td>
                <td className="fit nowrap">
                  {project.firstname} {project.lastname}
                </td>
                <td className="fit nowrap">
                  {project.approveddate ? DateTime.fromISO(project.approveddate).toLocaleString() : ""}
                </td>
                <td className="fit">
                  {project.hoursBudgeted}
                </td>
                <td className="fit">
                  {project.hoursBudgeted !== null &&
                    <>{+(toNum(project.hoursBudgeted) - toNum(project.hoursRemaining)).toFixed(2)}</>
                  }
                  {/* {
                    toNum(project.hoursRemaining)
                  } */}
                </td>
                <td className="fit">
                  {project.billing}%
                </td>
                <td  className="fit nowrap">

                    <Link to={`/budgeting/print/${project.projectid}`}>
                      <i  className="fas fa-print ghost button"></i>
                      {/* onClick={() => printClicked(project)} */}
                    </Link>
                
                </td>
                <td></td>
              </tr>
            )}
          </tbody>
        </table>
      }

      </div>
    </>
  )
}



const mapState = state => ({
  reviewCount: state.budgetHeaderState.reviewCount,
  currentCompany: state.authState.currentCompany,
});

export default connect(mapState)(BudgetingAll);
