import React from 'react';




const Notifications = ({children}) => {


  return (
    <>
      <div className="margin padding-2x">
        <h1>Notifications</h1>

        <div>To be implemented.</div>
      </div>
    </>
  )
}

export default Notifications;