import { andyFetch, actionReturn } from '../../api';



export const deleteSupportTicket = (params) => {
  return async (dispatch, getState) => {
    const url = "support_tickets/delete";
    try {
      const response = await andyFetch(url, params, "POST");
      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);
      } else {
        throw new Error('deleteSupportTicket error.');
      }
    }
    catch (e) {
    }
  }
};




export const getSupportTicket = (params) => {
  // aler t(params?.query?.id)
  return async (dispatch, getState) => {
    const url = `support_tickets/${params?.query?.id}`;
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};



export const getSupportTickets = (params) => {

  return async (dispatch, getState) => {

    
    const url = "support_tickets";

    try {
      const response = await andyFetch(url, params, "GET");

      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};


export const createSupportTicket = (params) => {

  return async (dispatch, getState) => {

    const url = "support_tickets";

    try {

      const response = await andyFetch(url, params, "POST");

      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);

      } else {
        throw new Error('createSupportTicket error.');
      }
    }
    catch (e) {
    }
  }
};

export const updateSupportTicket = (params) => {

  return async (dispatch, getState) => {

    const url = `support_tickets/${params?.payload?.support_ticket?.id}`;

    try {

      const response = await andyFetch(url, params, "PATCH");

      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);

      } else {
        throw new Error('updateSupportTicket error.');
      }
    }
    catch (e) {
      
    }
  }
};