import React, { useEffect, useState } from 'react';

// import TimesheetsNav from './TimesheetsNav';
import { useApi } from '../../../api';
import { connect } from 'react-redux';

import { DateTime } from 'luxon';
import {
  Link, useNavigate, useParams,
} from 'react-router-dom';

import { usePrevious } from '../../../hooks/usePrevious';

import { useForm, useWatch } from 'react-hook-form';
import TH from '../TH';
import { useSortBudgets, toNum, isNumber } from '../useSortBudgets';

import { getExpenseHeader, createExpenseHeader, getApprovals } from '../../../redux/manager/expenseHeaders/expenseHeaderActions';
import { isNumeric } from '../../../utils';
import ExpenseLine from './ExpenseLine';
import { getMyBudgetHeadersForExpenseReports } from '../../../redux/projects/projectActions';
import Modal from '../../../components/Modal';
import ExpenseForm from './ExpenseForm';
import { getLogoName } from '../../../utils';

const ExpenseReport = ({currentUser, currentCompany}) => {
  const navigate = useNavigate('/');
  const prevCompany = usePrevious(currentCompany);
  const { expense_report_id } = useParams();


  const [stepsDetailsShown, setStepsDetailsShown] = useState(false);

  const toggleStepsDetails = () => {
    setStepsDetailsShown(o => !o);
  }
  const [expenseHeader, expenseHeaderLoading, expenseHeaderLoaded, expenseHeaderError, doExpenseHeaderFetch, setExpenseHeaderResult] = useApi();

  // const [steps, stepsLoading, stepsLoaded, stepsError, doStepsFetch, setStepsResult] = useApi();

  // const fetchSteps = () => {
  //   doStepsFetch(getApprovals);
  // }

  
  const fetchExpenseHeader = () => {
    doExpenseHeaderFetch(getExpenseHeader, {query: {id: expense_report_id}});
  }

  useEffect(() => {
    if (expense_report_id !== null && expense_report_id !== undefined && expense_report_id.length !== 0) {
      fetchExpenseHeader();
    }
  }, [expense_report_id]);

  
  useEffect(() => {
    if (prevCompany && currentCompany) {
      if (prevCompany !== currentCompany) {
        navigate("/expense-reports")
      }
    }    
  }, [currentCompany]);


  useEffect(() => {
    if (expenseHeader && expenseHeader.company) {
      if (expenseHeader.company != currentCompany) {
        navigate("/expense-reports")
      }
    } 
  }, [expenseHeader, currentCompany]);

  const statusBadgeClass = () => {

    if (!expenseHeaderLoading && !expenseHeader) return "new";


    return expenseHeader?.statusname?.toLowerCase();
  }

  const steps = expenseHeader?.mapped_approvals;

  return (
    <>
      {true && steps &&
        <div className={`${expenseHeaderLoading ? 'loading-gradient' : ''} padding-1 expense-steps-cont`}>
          
          {/* <div className="bold gray font-075 margin-bottom-1">APPROVAL WORKFLOW</div> */}

          <div className="flex-cont align-center flex-wrap">
            <div className="margin-right-1 flex-1 flex-cont align-center flex-wrap">
            {steps.map((step, index) => 
              <div className="margin-bottom-2px expense-step flex-cont align-center">
                {step.expense_approval &&
                  <div className="checked expense-step-number margin-right-half">
                    <i className="no-hover fas fa-check"></i>
                  </div>
                }
                {!step.expense_approval &&
                  <div className="expense-step-number margin-right-half">
                    {index+1}
                  </div>
                }

                
                {/* <div>
                  <pre>
                  view_as_user_can_approve: {JSON.stringify(expenseHeader?.view_as_user_can_approve, null, 2)}
                  <br />
                  needs_approval: {JSON.stringify(expenseHeader?.needs_approval, null, 2)}
                  </pre>
                </div> */}
                <div className="bold">{step.expense_header_status?.approval_name} Approval</div>
                {index !== steps.length-1 &&
                  <div className='expense-step-arrow'><i className="fas fa-arrow-right"></i></div>
                } 
              </div>

            )}
            </div>
            <div>

              {expenseHeader?.current_approvals?.length !== 0 &&
                <div className="button ghost" onClick={toggleStepsDetails}>
                  
                  {stepsDetailsShown ? 'Hide' : "Show"} Details

                  <div className="margin-left-half">
                    <i className={`fas fa-chevron-${stepsDetailsShown ? 'up' : "down"}`}></i>
                  </div>

                  
                </div>
              }
            </div>
          </div>

          {stepsDetailsShown && expenseHeader?.current_approvals && 
            <ul>
              {expenseHeader?.current_approvals?.map((approval, index) => 
                <li>{approval?.statusname} Approval {DateTime.fromISO(approval.approvaldate)?.toLocaleString()} by <span className="bold">{approval?.approvingname}</span></li>
                // <li>
                //   <div className="bold">{step.expense_header_status?.approval_name} Approval by </div>
                // </li>
              )}
            </ul>
          
          }
          
        </div>
      }
      <div className="margin-bottom-2">

      {/* <div className="code">
        <pre>
          {JSON.stringify(expenseHeader?.is_on_last_step)}
        </pre>
      </div> */}

      <div className="flex-cont align-center margin-top-1">
        <div className="flex-1">
          {expenseHeaderLoading &&
            <div style={{marginBottom: '2px', width: '200px', height: '1.75em'}} className="loading-gradient"></div>
          }
          <div className="bold font-1-75">{currentCompany}</div>
          <div className="bold font-1-25">Travel & Expense Report</div>

        </div>
        <div>
          <img src={`/${getLogoName(expenseHeader?.company)}.png`} style={{height: '2em'}} />
        </div>
      </div>
      <div className="flex-cont align-center margin-top-1">
        <div className="flex-1">
          {expenseHeaderLoading &&
            <div style={{marginBottom: '2px', width: '100px', height: '1.25em'}} className="loading-gradient"></div>
          }
          {!expenseHeaderLoading && expenseHeader &&
            <>
              <div className="font-1-25">#{expenseHeader?.number}</div>

              {(expenseHeader.saved_bu != null && expenseHeader.saved_cc != null) &&
                <div className="margin-top-half font-08">
                  <div>BU: {expenseHeader.saved_bu}</div>
                  <div>CC: {expenseHeader.saved_cc}</div>

                </div>
              }
              
            </>

          }
          {/* {!expenseHeaderLoading && !expenseHeader &&
            <div className="font-1-25">#{expenseHeader?.number}</div>
          } */}

        </div>
        <div>
          <div className="status-badge-cont inline-block">
            {!expenseHeaderLoading &&
              <div className={`${statusBadgeClass()} budget status-badge`}>
                {expenseHeader?.statusname || "NEW"} 
                {expenseHeader?.returneddate &&
                  <>
                    {expenseHeader?.expenseheaderstatusid?.toString() === "6" &&
                      <>
                        <> {DateTime.fromISO(expenseHeader.returneddate).toLocaleString()} </>
                        {expenseHeader?.returnedbyuser && 
                          <> by {expenseHeader?.returnedbyuser} </>
                        }
                      </>
                    }
                  </>
                }
              </div>
            }
          </div>
        </div>
      </div>

      {(false) &&
        <ul style={{margin: 0, marginTop: '2em', paddingLeft: '1em'}} className='gray'>
          {(true) &&
            <li>PM Approval by <span className="bold">{expenseHeader?.approvedbyuser}</span></li>
          }
          {(true) &&
            <li>Office Approval  by <span className="bold">{expenseHeader?.oexapprovedbyuser}</span></li>
          }
          {(true) &&
            <li>Final Approval by <span className="bold">{expenseHeader?.finalapprovedbyuser}</span></li>
          }
        </ul>
      }
      </div>

      
{(false && (expenseHeader?.approveddate || expenseHeader?.oexapproveddate || expenseHeader?.finalapproveddate)) &&  
      <div className="section margin-bottom-1">
          <div className="margin-bottom-half bold font-075">HISTORY</div>
          <ul style={{margin: 0, marginTop: '0', paddingLeft: '1em'}}  className='gray'>
            {(expenseHeader?.approveddate) &&
              <li>PM Approval {DateTime.fromISO(expenseHeader.approveddate).toLocaleString()} by <span className="bold">{expenseHeader?.approvedbyuser}</span></li>
            }
            {(expenseHeader?.oexapproveddate) &&
              <li>Office Approval {DateTime.fromISO(expenseHeader.oexapproveddate)?.toLocaleString()} by <span className="bold">{expenseHeader?.oexapprovedbyuser}</span></li>
            }
            {(expenseHeader?.finalapproveddate) &&
              <li>Final Approval {DateTime.fromISO(expenseHeader.finalapproveddate)?.toLocaleString()} by <span className="bold">{expenseHeader?.finalapprovedbyuser}</span></li>
            }
          </ul>
        
      </div>
}
      
        
      {expenseHeaderLoading &&
        <div className="spinner margin-top-2"></div>
      }
      {!expenseHeaderLoading && 
        <ExpenseForm existingExpenseHeader={expenseHeader} />
      }
      
    </>
  )
}


function mapState(state) {
  return { 
    // tokenResponse: state.authState.tokenResponse,
    currentCompany: state.authState.currentCompany,
    currentUser: state.authState.currentUser,
  };
} 

export default connect(mapState)(ExpenseReport);