import React from "react";
import { Info } from 'luxon';
import SummaryWeek from './SummaryWeek';
import SummaryTotal from './SummaryTotal';

const Summary = ({errors, timesheetResult, setValue, register, setSumArray, control, setSelectedWeekIndex, selectedWeekIndex}) => {

  return (<>
    {/* <div>
      <pre>
        {JSON.stringify(Object.keys(errors))}
      </pre>
    </div> */}
    <table className="basic no-stripes">
      <thead>
        <tr>
          <th></th>
          <th className="fit nowrap">WEEK</th>
          <th className="fit nowrap">Start</th>
          <th className="fit nowrap">End</th>
          
          {Info.weekdays('short').map(day => {
            return (
              <th  className="fit nowrap" key={day}>{day}</th>
            );
          })}
          <th>Hours</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {timesheetResult.summary?.map((week, index) => {
          return (
            <SummaryWeek errors={errors} setValue={setValue} register={register} setSumArray={setSumArray} control={control} setSelectedWeekIndex={setSelectedWeekIndex} selectedWeekIndex={selectedWeekIndex} week={week} index={index} />
          )
        })}
      </tbody>


      
      <thead>
        <tr className="total">
          <th colSpan={10}></th>
          <th className="text-right">TOTAL</th>
          <th className="text-center">
            <SummaryTotal control={control} totalHours={timesheetResult.total_hours} />
          </th>
          <th colSpan={1}></th>
        </tr>
      </thead> 
    </table>
  </>)
}

export default Summary;