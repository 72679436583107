import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link, NavLink, Outlet, useLocation
} from 'react-router-dom';
import { connect } from 'react-redux';


const isActive = ({ isCurrent }) => {
  return isCurrent ? { className: "active" } : null
}



const AdminReportsNav = ({children, count}) => {


  return(
    <ul className="sub-nav">
      <li>
        <NavLink end to="/reports">
          Home
        </NavLink>
      </li>

      <li>
        <NavLink end to="/reports/budgeted">
          Budgeted
        </NavLink>
      </li>

      <li>
        <NavLink end to="/reports/consumed">
          Consumed
        </NavLink>
      </li>
{/* 
      <li>
        <NavLink to="/reports/unposted-timesheets">
          Unposted Timesheets (temp tool)
        </NavLink>
      </li> */}

    </ul>
  );
}


function mapStateToProps(state) {
  return { 
    // currentUser: state.sessionState.currentUser,
  };
} 

export default connect(mapStateToProps)(AdminReportsNav);
