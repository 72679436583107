import { andyFetch, actionReturn } from '../../api';

export const setFormTeam = (team) => {
  return dispatch => {
    dispatch({
      type: "SET_FORM_TEAM",
      team: team
    });
  }
}

export const getTeam = (params) => {
  // aler t(params?.query?.id)
  return async (dispatch, getState) => {
    const url = `admin/teams/${params?.query?.id}`;
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};


export const getTeams = (params) => {
  return async (dispatch, getState) => {
    const url = "admin/teams";
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();

        const ar = actionReturn(json, response);
        console.log("actionReturn", ar);
        return ar;
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};




export const deleteTeam = (params) => {
  return async (dispatch, getState) => {
    const url = "admin/teams/delete";
    try {
      const response = await andyFetch(url, params, "POST");
      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);
      } else {
        throw new Error('createTeam error.');
      }
    }
    catch (e) {
    }
  }
};



export const createTeam = (params) => {

  return async (dispatch, getState) => {

    const url = "admin/teams";

    try {

      const response = await andyFetch(url, params, "POST");

      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);

      } else {
        throw new Error('createTeam error.');
      }
    }
    catch (e) {
    }
  }
};

export const updateTeam = (params) => {

  return async (dispatch, getState) => {

    const url = `admin/teams/${params?.payload?.id}`;

    try {

      const response = await andyFetch(url, params, "PATCH");

      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);

      } else {
        throw new Error('updateTeam error.');
      }
    }
    catch (e) {
      
    }
  }
};

