
import React, { useState, useEffect } from 'react';
import { useApi } from '../../api';
import { DateTime } from 'luxon';
import { connect } from 'react-redux';

import { repostTimesheet, getTimesheetLogsDynamics, getTimesheetLogsOutbounds, getTimesheetLogsSuccesses, getTimesheetLogsFailures,  } from '../../redux/timesheets/timesheetActions';
import { Link } from 'react-router-dom';
import Modal from '../../components/Modal';
import Pagination from '../../components/Pagination';
import CheckBoxes from '../../components/CheckBoxes';
import { truncateString } from '../../utils';



const TimesheetPostingLogsQueue = () => {

  const [result, loading, loaded, error, doFetch, setResult, pagy] = useApi();

  useEffect(() => {
    fetchOutbounds();
  }, [])

  const fetchOutbounds = () => {
    doFetch(getTimesheetLogsOutbounds);
  }

  return (
    <div>
      {loading && <div className="spinner"></div> }

      {result &&
        <table className="margin-top-3x basic">
        <tr>
          {/* <th>Status</th> */}
          <th>Timesheet</th>
          <th>Comp.</th>
          <th>Employee</th>
          <th>Created At</th>
          <th className='fit nowrap'>Posted At</th>
          <th></th>
        </tr>
        {result?.map(log => 
          <tr>
            {/* <td className="fit nowrap bold">
              {log.status_text?.toUpperCase()}
            </td> */}
            <td className="fit nowrap">
              <Link className="text-link" target="_blank" to={`/timesheets/${log.timesheetid}`}>
                TM{log.timesheetid}
              </Link>
            </td>
            <td className="fit nowrap">
              {log.company}
            </td>
            <td className="fit nowrap">
              {log.first_name} {log.last_name}
            </td>
            <td className="fit nowrap">
              {log.createdate}
            </td>
            <td className="fit nowrap">
              {log.posteddate}
            </td>
            <td></td>
          </tr>

          
        )}
      </table>
}

      {/* <pre>{JSON.stringify(result, null, 2)}</pre> */}
    </div>
  )
}



export default TimesheetPostingLogsQueue;