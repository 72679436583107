import React, { useEffect } from 'react';
import {
  NavLink,
  useLocation
} from 'react-router-dom';
import { connect } from 'react-redux';
import { useApi } from '../../../api';
import { getDelegateCount } from '../../../redux/manager/users/userActions';


const isActive = ({ isCurrent }) => {
  return isCurrent ? { className: "active" } : null
}



const DelegateNav = ({viewAsUser}) => {
  const location = useLocation();

  const [delegateCountResult, delegateCountLoading, delegateCountLoaded, delegateCountError, doDelegateCountFetch, setDelegateCountResult] = useApi();

  useEffect(() => {
    // doDelegateCountFetch(getDelegateCount);
  }, [location]);




  useEffect(() => {
    // alert(delegateCountResult)
  }, [delegateCountLoaded]);


  return(
    <ul className="sub-nav">

      
      {process.env.REACT_APP_DEPLOYMENT_SLOT !== "prod" &&
        <li>
          <NavLink end to="/delegate-timesheets">Timesheet Delegates</NavLink>
        </li>
      }

      {viewAsUser && (viewAsUser.roleid === 1 || viewAsUser.roleid === 8 || viewAsUser.roleid === 9 || viewAsUser.roleid === 2 || viewAsUser.roleid === 4) &&
        <li>
          <NavLink end to="/delegate-projects">Project Delegates</NavLink>
        </li>
      }

      

      {/* {viewAsUser.isexpenseapprover && <>
          <li>
            
            <NavLink end to="/expense-reports/review">
              <div className="flex-cont align-center">
                <div className="flex-cont align-center justify-center unread-count">
                  <div className="number">
                    {(delegateCountResult === 0 || delegateCountResult) ? delegateCountResult : "-"}
                  </div>
                </div>
                <div>Review</div>
              </div>


            </NavLink>

          </li>
          <li>
            <NavLink end to="/expense-reports/approved">Approved</NavLink>
          </li>
        </>
      } */}
    </ul>
  );
}


function mapStateToProps(state) {
  return { 
    // reviewCount: state.budgetHeaderState.reviewCount,
    viewAsUser: state.authState.viewAsUser,
  };
} 

export default connect(mapStateToProps)(DelegateNav);
