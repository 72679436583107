import React, { useState, useEffect, useRef } from 'react';
import { useApi } from '../../api';
import { DateTime } from 'luxon';
import { connect } from 'react-redux';

import { repostTimesheet, getTimesheetLogsDynamics, getTimesheetLogsOutbounds, getTimesheetLogsSuccesses, getTimesheetLogsFailures,  } from '../../redux/timesheets/timesheetActions';
import { Link } from 'react-router-dom';
import Modal from '../../components/Modal';
import Pagination from '../../components/Pagination';
import CheckBoxes from '../../components/CheckBoxes';
import { truncateString } from '../../utils';

function useInterval(callback, timeout, search) {
  const timeoutId = useRef();

  // Remember the latest callback.
  useEffect(() => {
    timeoutId.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {

    clearTimeout(timeoutId.current);
    timeoutId.current = setTimeout(callback, timeout);

    return () => clearTimeout(timeoutId.current);

    // 
    // function tick() {
    //   savedCallback.current();
    // }
    // if (search !== null) {
    //   let id = setTimeout(tick, delay);
    //   return () => clearTimeout(id);
    // }
  }, [search]);
}

const TimesheetPostingLogsFailures = ({currentCompany}) => {

  const [result, loading, loaded, error, doFetch, setResult, pagy] = useApi();
  const [reportResult, repostLoading, repostLoaded, repostError, doRepostFetch, setRepostResult, repostPagy] = useApi();
  const [onlyInCompanyChecked, setOnlyInCompanyChecked] = useState(false);
  const [search, setSearch] = useState(null);
  const [repostID, setRepostID] = useState(null);
  const [currentLog, setCurrentLog] = useState(null);
  const [page, setPage] = useState(1);
  const [selectedStatuses, setSelectedStatuses] = useState([]);


  useInterval(() => { 
    setPage(1); 
    doFetch(getTimesheetLogsFailures, {query: {all_companies: onlyInCompanyChecked ? null : true, search: search, page: 1}});
    
    // setCount(o => o+1);
  }, 500  , search);

  const handlePageChange = (p) => {
    setPage(p);
  };

  const searchInputChanged = e => {
    const value = e.target.value;
    setSearch(value);
  }


  useEffect(() => {
    fetchFailures();
  }, [])

  useEffect(() => {
    fetchFailures();
    setCurrentLog(null);
    
  }, [onlyInCompanyChecked, page, currentCompany, selectedStatuses]);

  useEffect(() => {
    setPage(1);
  }, [onlyInCompanyChecked, currentCompany, selectedStatuses]);

  const fetchFailures = () => {
    
    doFetch(getTimesheetLogsFailures, {query: {all_companies: onlyInCompanyChecked ? null : true, search: search, page: page}});
  }

  const repostClicked = timesheetid => {
    setRepostID(timesheetid);
    doRepostFetch(repostTimesheet, {payload: {timesheetid: timesheetid}});
  }

  useEffect(() => {
    // setRepostID(null);

    // alert(repostLoaded);

    if (repostLoaded) {
      // alert("here")
      fetchFailures();
    }
  }, [repostLoaded]);

  

  const viewErrorClicked = msg => {
    setCurrentLog(msg);

  }
  const toggleModal = () => {
    setCurrentLog(null);
  }

  const tabCount = () => {
    return 0;
  }

  return (
    <div>

      <div className="seearch-cont margin-bottom-3 flex-cont align-center">
        {true &&
          <div className="margin-right-1 flex-cont align center">
            <input checked={onlyInCompanyChecked} onChange={e => setOnlyInCompanyChecked(e.target.checked)}  type="checkbox" name="" id="" className="margin-right-half" />
            <div className="bold gray uppercase font-075 nowrap">Only in {currentCompany}</div>
          </div>
        }
        <input onChange={e => searchInputChanged(e)} placeholder="Search timesheets..." type="text" name="" id=""/>
      </div>

      {/* <div className={`margin-bottom-2 margin-right-2 check-boxes ${loading ? 'disabled' : ''}`}>          
        <CheckBoxes disabled={loading} label={"Draft"} name="0" boxes={selectedStatuses} setBoxes={setSelectedStatuses}  count={tabCount("new")} />
        <CheckBoxes disabled={loading} label={"Submitted"} name="1" boxes={selectedStatuses} setBoxes={setSelectedStatuses}  count={tabCount("new")} />
        <CheckBoxes disabled={loading} label={"Approved"} name="2" boxes={selectedStatuses} setBoxes={setSelectedStatuses}  count={tabCount("doing")} />
        <CheckBoxes disabled={loading} label={"Returned"} name="3" boxes={selectedStatuses} setBoxes={setSelectedStatuses}  count={tabCount("doing")} />
        <CheckBoxes disabled={loading} label={"Posted"} name="5" boxes={selectedStatuses} setBoxes={setSelectedStatuses}  count={tabCount("doing")} />
        <CheckBoxes disabled={loading} label={"Posting Failed"} name="6" boxes={selectedStatuses} setBoxes={setSelectedStatuses}  count={tabCount("doing")} />
      </div> */}


      <Pagination currentPage={page} pagy={pagy} handlePageChangeFn={handlePageChange} />

      {currentLog && 
        <Modal toggleModal={toggleModal} focusTrap={false}>

          <div className="modal-header flex-cont align-center">
            <h2 className="flex-1">TM{currentLog?.timesheetid}</h2>
            <div onClick={toggleModal} className="ghost button close">
              <i className="fas fa-times"></i>
            </div>
          </div>

          <div className="padding-1">
            <div>
              {currentLog?.message}
            </div>
          </div>
          <div className="modal-footer"></div>
        </Modal>
      }
      <div className="relative">
      {(repostLoading || loading)  && <div className="absolute spinner"></div> }

      {result &&
      
        <table className={`${(repostLoading || loading) ? 'table-loading' : ''} margin-top-3x basic`}>
          <tr>
            <th></th>
            <th>Timesheet</th>
            <th>Comp.</th>
            <th>Employee</th>
            <th>Error</th>
            <th></th>
            <th>Timestamp</th>
          </tr>
          {result?.map(log => 
            <tr>
              <td className="fit nowrap">
                <div onClick={() => repostClicked(log.timesheetid)} className={`${(false && true) ? 'disabled' : ''} font-075 button`}>
                  REPOST
                  {/* {(repostLoading && repostID === log.timesheetid) &&
                    <div className="spinner button-spinner"></div>
                  } */}
                </div>
              </td>
              <td className="fit nowrap">

                <Link className="text-link" target="_blank" to={`/timesheets/${log.timesheetid}`}>
                  TM{log.timesheetid}
                </Link>
                
              </td>
              <td className="fit nowrap">
                {log.company}
              </td>
              <td className="fit nowrap">
                {log.first_name} {log.last_name}
              </td>
              <td className="">
                {truncateString(log?.message, 100)}
                
              </td>
              <td className="fit nowrap">
                {log.message != null &&
                  <div onClick={() => viewErrorClicked(log)} className="margin-left-0 button ghost font-08">
                    VIEW
                  </div>
                }
              </td>
              <td className="fit nowrap">
                {log.createddate}
              </td>
            </tr>

            
          )}
        </table>
      }
      </div>
      {/* <pre>{JSON.stringify(result, null, 2)}</pre> */}
    </div>
  )
}

const mapState = state => ({
  currentCompany: state.authState.currentCompany,
});

export default connect(mapState)(TimesheetPostingLogsFailures);