import React, { useEffect, useState, useLayoutEffect } from 'react';
import logo from './logo.svg';
import './styles/App.scss';
import logo7 from './images/logo1.png';
import logo8 from './images/logo_big_2.png';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link, Outlet, useLocation, useNavigate
} from 'react-router-dom';

import store from './redux/store';
import { connect } from 'react-redux';
import AlphaTimesheet from './screens/alpha_timesheets/Timesheet';
import HomeScreen from './screens/HomeScreen';
import { setReAuth } from './redux/authActions';

import * as msal from "@azure/msal-browser";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { setRedirectLoading, setTokenLoading, getDynamicsToken, setDynamicsToken, unsetDynamicsToken, setCurrentAccount, setTokenResponse, unsetCurrentAccount, unsetTokenResponse, getCurrentUser, getViewAsUser } from './redux/authActions';
import { setFullscreenLoading, removeError } from './redux/ui/uiActions';

import AdminUsers from './screens/admin/AdminUsers';
import AdminLayout from './screens/admin/AdminLayout';
import AdminHome from './screens/admin/AdminHome';
// import Timesheets from './screens/Timesheets';
import Timesheets from './screens/Timesheets';
import Loading from './screens/Loading';
import AdminNewEmployees from './screens/admin/AdminNewEmployees';
import { normalFetch, FRONTEND_URL } from './api';
import AdminUsersLayout from './screens/admin/AdminUsersLayout';

import AdminTeamsLayout from './screens/admin/AdminTeamsLayout';

import AdminTeamsHome from './screens/admin/AdminTeamsHome';

import AdminProjectTeams from './screens/admin/AdminProjectTeams';
import AdminProjectTeamsNew from './screens/admin/AdminProjectTeamsNew';
import AdminProjectTeamsEdit from './screens/admin/AdminProjectTeamsEdit';

import AdminOfficeManagerTeams from './screens/admin/AdminOfficeManagerTeams';
import AdminOfficeManagerTeamsNew from './screens/admin/AdminOfficeManagerTeamsNew';
import AdminOfficeManagerTeamsEdit from './screens/admin/AdminOfficeManagerTeamsEdit';

import SupportTicketsLayout from './screens/SupportTicketsLayout';
import SupportTickets from './screens/SupportTickets';


// import AdminLogsLayout from './screens/admin/AdminLogsLayout';
import AdminTimesheets from './screens/admin/AdminTimesheets';




import AdminReportsLayout from './screens/admin/AdminReportsLayout';
import AdminReportsHome from './screens/admin/AdminReportsHome';
import AdminReportsBudgets from './screens/admin/AdminReportsBudgets';
import AdminReportsTimesheets from './screens/admin/AdminReportsTimesheets';

import AdminLogsLayout from './screens/admin/AdminLogsLayout';
import AdminLogsTimesheetPosting from './screens/admin/AdminLogsTimesheetPosting';

import AdminAdminLayout from './screens/admin/AdminAdminLayout.js';
import AdminSettingsLayout from './screens/admin/AdminSettingsLayout';
import ManagerTimesheetsLayout from './screens/manager/ManagerTimesheetsLayout';
import TimesheetsReview from './screens/manager/TimesheetsReview';
// import PrefectTimesheets from './screens/manager/PrefectTimesheets';
import TimesheetsApproved from './screens/manager/TimesheetsApproved';
import TimesheetReport from './screens/manager/TimesheetReport';
import TimesheetPostingLogs from './screens/manager/TimesheetPostingLogs';
import TimesheetUnposted from './screens/manager/TimesheetUnposted';
import TimesheetScreen from './screens/TimesheetScreen';
import TimesheetScreenTest from './screens/TimesheetScreenTest';
import TimesheetViewScreen from './screens/manager/TimesheetViewScreen';
import TimesheetPrintScreen from './screens/TimesheetPrintScreen';
import TimesheetPrintScreen2 from './screens/TimesheetPrintScreen2';
import { useApi } from './api';

import ExpenseLayout from './screens/manager/expense/ExpenseLayout';
import ExpenseHome from './screens/manager/expense/ExpenseHome';
import ExpenseReport from './screens/manager/expense/ExpenseReport';
import ApprovedExpenseReports from './screens/manager/expense/ApprovedExpenseReports';
import ReviewExpenseReportsPM from './screens/manager/expense/ReviewExpenseReportsPM';
import ReviewExpenseReportsFinal from './screens/manager/expense/ReviewExpenseReportsFinal';
import ReviewExpenseReports from './screens/manager/expense/ReviewExpenseReports';
import AllExpenseReports from './screens/manager/expense/AllExpenseReports';

import DelegateLayout from './screens/manager/delegate/DelegateLayout';
import DelegateHome from './screens/manager/delegate/DelegateHome';
import DelegateProjects from './screens/manager/delegate/DelegateProjects';
import DelegateTimesheets from './screens/manager/delegate/DelegateTimesheets';
// import ExpenseReport from './screens/manager/expense/ExpenseReport';
// import ApprovedExpenseReports from './screens/manager/expense/ApprovedExpenseReports';
// import ReviewExpenseReports from './screens/manager/expense/ReviewExpenseReports';


import BudgetingLayout from './screens/manager/BudgetingLayout';
import BudgetingHome from './screens/manager/BudgetingHome';
import BudgetingAll from './screens/manager/BudgetingAll';
import BudgetingReports from './screens/manager/BudgetingReports';
import BudgetingProject from './screens/manager/BudgetingProject';
import BudgetingReview from './screens/manager/BudgetingReview';
import BudgetingApproved from './screens/manager/BudgetingApproved';
import BudgetingList from './screens/manager/BudgetingList';
// import Print3 from './screens/Print3';
import BudgetPrint from './screens/manager/BudgetPrint';

// import BudgetPrint2 from './screens/manager/BudgetPrint2';

import Notifications from './screens/Notifications';
import DashboardLayout from './screens/DashboardLayout';

import NewExpenseReport from './screens/manager/expense/NewExpenseReport';
import GlobalSuccesses from './GlobalSuccesses';
import SupportButton from './SupportButton';
import TimesheetPostingLogsFailures from './screens/manager/TimesheetPostingLogsFailures.js';
import TimesheetPostingLogsSuccesses from './screens/manager/TimesheetPostingLogsSuccesses.js';
import TimesheetPostingLogsQueue from './screens/manager/TimesheetPostingLogsQueue.js';
import TimesheetPostingLogsDynamics from './screens/manager/TimesheetPostingLogsDynamics.js';
 
const msalConfig = {
  auth: {
    authority: 'https://login.microsoftonline.com/2acb8726-1f41-4be0-8ecb-d92b5113ac8a',
    clientId: '468fd0f8-dba8-459c-adb0-a7dc79705bd2',
    redirectUri: FRONTEND_URL + "/login-redirect"
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  }
};

const msalInstance = new msal.PublicClientApplication(msalConfig);


// msalInstance.handleRedirectPromise().then((response) => {
//   // Check if the tokenResponse is null
//   // If the tokenResponse !== null, then you are coming back from a successful authentication redirect. 
//   // If the tokenResponse === null, you are not coming back from an auth redirect.
//   if (response === null) {
//   }
//   else {
//     store.dispatch(setTokenResponse(response));
//   }
  

// }).catch((error) => {
//   // handle error, either in the library or coming back from the server
// });


const Fallback = () => {
  const navigate = useNavigate("/");
  
  useEffect(() => {
    navigate('/');
  }, []);
  
  return(
    <>
    </>
  )

}

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const LoginRedirect = ({fullscreenLoading}) => {
  useEffect(() => {
    store.dispatch(setRedirectLoading(true));
    
    return (() => {
      store.dispatch(setRedirectLoading(false));
    });
  }, []);

  return (
    <Loading />
  );
}

const LoginScreen = ({currentUser, selectAccount, allAccounts, account, tryLogin, tokenLoading, fullscreenLoading, accountLoading}) => {

  const multipleAccounts = allAccounts && allAccounts.length > 1;
  return (
    <div className="center-text margin-top-5">

      <div style={{
        
        backgroundImage: `url(https://www.hba.com/images/HBA-Home-Hero-Image-mock-1.jpg)`, 
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        position: 'fixed',
        left: 0,
        top: 0,
        height: '100vh',
        width: '100vw',
        zIndex:-1,
        opacity:.25,
        }}></div>
        <div style={{
        
        background: 'linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)',
        position: 'fixed',
        left: 0,
        top: 0,
        height: '100vh',
        width: '100vw',
        zIndex:-1,

        }}></div>
      <div className="center-text margin-bottom-3 margin-right-4">
        <img style={{height: '180px'}} src={logo8} alt="Logo" />
      </div>
      

      {multipleAccounts &&
        <>
        <div className="column inline-flex align-center justify-center">
        <table className="basic" style={{width: 'auto'}}>
          {allAccounts.map(account => {
            return(
              <tr>
                <td>
                  <div onClick={() => selectAccount(account)} className="button">Select</div>
                </td>
                <td>
                  {account.username}
                </td>
              </tr>
            )
          })}
        </table>
        </div>
        <br />
        <div onClick={tryLogin} className="marign-top-2 font-1 margin-top-2 ghost button">Log in with other account</div>

        </>
      }
      {!multipleAccounts &&
        <div onClick={tryLogin} className="marign-top-2 font-1-25 button">Log in to Kronos</div>
      }

      {process.env.REACT_APP_DEPLOYMENT_SLOT !== "prod" &&
        <div>{process.env.REACT_APP_DEPLOYMENT_SLOT}</div>
      }
    </div>
  )
}




/*--------------------------------------------------------------------------------------------------*/
/*--------------------------------------------------------------------------------------------------*/
/*--------------------------------------------------------------------------------------------------*/







const App = ({viewAsUser, globalErrors, reAuth, currentUser, redirectLoading, currentAccount, dynamicsToken, tokenResponse, tokenLoading, fullscreenLoading}) => {


  const [allAccounts, setAllAccounts] = useState(null);
  const [storedMsalAccount, setStoredMsalAccount] = useState(null);
  const [msalAccount, setMsalAccount] = useState(null);
  const [msalAccountLoading, setMsalAccountLoading] = useState(true);
  const [userResponse, userLoading, userLoaded, userError, doUserFetch, setUserResult] = useApi();
  const [vauserResponse, vauserLoading, vauserLoaded, vauserError, doVAUserFetch, setVAUserResult] = useApi();

  const [doneChecking, setDoneChecking] = useState(false);

  const [isAuthed, setIsAuthed] = useState(false);

  

  useEffect(() => {
    // alert(2);
    store.dispatch(setFullscreenLoading(true));

    msalInstance.handleRedirectPromise().then(handleResponse).catch(err => {
      console.error(err);
    });

  }, [])

  const selectAccount = (account) => {
    setStoredMsalAccount(account);
    msalInstance.setActiveAccount(account);
  }

  useEffect(() => {
    if (allAccounts && allAccounts.length === 1 && !storedMsalAccount) {
      setStoredMsalAccount(allAccounts[0])
    }
  }, [allAccounts, storedMsalAccount])


  function handleResponse(resp) {
    // alert(3);
    store.dispatch(setFullscreenLoading(true));
    
    // alert('here 2')
    // alert("resp: " + JSON.stringify(resp, null, 2))
    if (resp !== null) {
        // store.dispatch(setTokenResponse(resp));
        // accountId = resp.account.homeAccountId;
        setAllAccounts(null);
        msalInstance.setActiveAccount(resp.account);
        setStoredMsalAccount(resp.account);
    } else {
        // need to call getAccount here?
        const currentAccounts = msalInstance.getAllAccounts();
        setAllAccounts(currentAccounts);

        if (!currentAccounts || currentAccounts.length < 1) {
          store.dispatch(setFullscreenLoading(false));
          return;
        } else if (currentAccounts.length > 1) {
            // Add choose account code here
            // alert("MORE THAN ONE ACCOUNT");
        } else if (currentAccounts.length === 1) {
            const activeAccount = currentAccounts[0];
            msalInstance.setActiveAccount(activeAccount);
            setStoredMsalAccount(activeAccount);
            // accountId = activeAccount.homeAccountId;
        }
    }
  }

  const getTokenResponse = async (token = null) => {
    // alert('here 3')
    if (!storedMsalAccount) return

    var request = {  
      account: storedMsalAccount,
      scopes: ["https://graph.microsoft.com/User.Read"]
    };

    store.dispatch(setTokenLoading(true));

    msalInstance.acquireTokenSilent(request).then(tokenResponse => {
      store.dispatch(setTokenResponse(tokenResponse));
    }).catch(error => {
      if (error instanceof InteractionRequiredAuthError) {
        return msalInstance.acquireTokenRedirect(request)
      }
    });
  }

  useEffect(() => {
    // If the stored msal account is present, get token response
    if (storedMsalAccount) {
      store.dispatch(setFullscreenLoading(true));
      getTokenResponse(); 
    }
    else {
      // store.dispatch(setTokenLoading(false));
      // alert('here');
      // alert('a')
      // store.dispatch(setFullscreenLoading(false));

    }
  }, [storedMsalAccount]);

  useEffect(() => {
    // If API is requiring re-authentication, prompt new token response


    if (reAuth) {
      store.dispatch(setFullscreenLoading(true));
      getTokenResponse(); 
    }
    else {
      // alert('b')
      // store.dispatch(setFullscreenLoading(false));
      
    }
  }, [reAuth]);


  useEffect(() => {
    // After getting token response, compare with API User to check status 
    
    if (!tokenLoading && tokenResponse) {
      checkUserStatus();
    }

    if (!tokenLoading && !tokenResponse) {
      setIsAuthed(false);
    }
  }, [tokenResponse, tokenLoading]);


  // useEffect(() => {
  //   setMsalAccountLoading(userLoading || vauserLoading);
  // }, [userLoading, vauserLoading]);

  const checkUserStatus = async () => {
    doUserFetch(getCurrentUser);
    doVAUserFetch(getViewAsUser);
  }


  // const getMsalAccount = async () => {
  //   setMsalAccountLoading(true);
  //   const ha = await msalInstance.getAccountByHomeId(tokenResponse.account?.homeAccountId);
  //   setMsalAccountLoading(false);
  //   setMsalAccount(ha);   
    
  // }
 
  useEffect(() => {
    // Handle result of checking API User status
    // alert(JSON.stringify(userResponse, null, 2))
    let isEnabled = false;

    if (userLoaded) {

      isEnabled = userResponse?.enabled === 1;

      if (isEnabled) {
        setIsAuthed(true); 
        // alert('c')
        store.dispatch(setFullscreenLoading(false));
        // getMsalAccount();
      }
      else {
        setStoredMsalAccount(null);
        setAllAccounts(null);
        msalInstance.setActiveAccount(null);
        // setMsalAccount(null);
        // setMsalAccountLoading(false);

        store.dispatch(unsetCurrentAccount());
        store.dispatch(unsetTokenResponse());

        store.dispatch(setFullscreenLoading(false));
        
      }
    }
    
    if (userError) {
      setStoredMsalAccount(null);
      // setMsalAccount(null);
      setAllAccounts(null);
      msalInstance.setActiveAccount(null);
      // setMsalAccountLoading(false);

      store.dispatch(unsetCurrentAccount());
      store.dispatch(unsetTokenResponse());
      // alert('d')
      store.dispatch(setFullscreenLoading(false));
    }
  }, [userError, userLoaded]);

  // useEffect(() => {
  //   store.dispatch(setCurrentAccount(msalAccount));
  // }, [msalAccount]);


  const tryLogin = () => {
    const loginRequest = {
      scopes: ["https://graph.microsoft.com/User.Read"] // optional Array<string>
    };
    store.dispatch(setTokenLoading(true));
    store.dispatch(setRedirectLoading(true));

    msalInstance.setActiveAccount(null);

    try {
      // alert(1);
      msalInstance.loginRedirect(loginRequest);

    } catch (err) {
        // handle error
        alert('tryLogin error');
    }
  }

  const trySignout = async () => {
    try {
      // const r = await msalInstance.logout();
      const r = msalInstance.logoutRedirect({
          onRedirectNavigate: (url) => {
              // Return false if you would like to stop navigation after local logout
              return false;
          }
      });
      // setStoredMsalAccount(null);
      // // setMsalAccount(null);
      // // setMsalAccountLoading(false);

      // store.dispatch(unsetCurrentAccount());
      // store.dispatch(unsetTokenResponse());
      setStoredMsalAccount(null);
      // setMsalAccount(null);
      setAllAccounts(null);
      msalInstance.setActiveAccount(null);
      // setMsalAccountLoading(false);

      store.dispatch(unsetCurrentAccount());
      store.dispatch(unsetTokenResponse());
      // alert('d') 
      store.dispatch(setFullscreenLoading(false));
    } catch (err) {
      setStoredMsalAccount(null);
      // setMsalAccount(null);
      // setMsalAccountLoading(false);

      store.dispatch(unsetCurrentAccount());
      store.dispatch(unsetTokenResponse());
    }
  }


  if (fullscreenLoading) {
    return <Loading tokenLoading={tokenLoading}  msalAccountLoading={msalAccountLoading} fullscreenLoading={fullscreenLoading} />
  }


  
  return (
    <div style={{margin: '0px'}}>

      {/* <div className="code">
        <pre>
          {JSON.stringify(storedMsalAccount, null, 2)}
        </pre>
      </div>
   */}

      <Router>
        <ScrollToTop />
        <Routes>

            <Route path="login-redirect" element={<LoginRedirect redirectLoading={redirectLoading} fullscreenLoading={fullscreenLoading} />} />
          {fullscreenLoading &&
            <Route path="*" element={<Loading tokenLoading={tokenLoading}  msalAccountLoading={msalAccountLoading} fullscreenLoading={fullscreenLoading} />} />
          }

          {!fullscreenLoading && isAuthed && 
            <>
            
            <Route path="login-redirect" element={<LoginRedirect redirectLoading={redirectLoading} fullscreenLoading={fullscreenLoading} />} />
            <Route exact path="" element={<DashboardLayout test="TEST" trySignout={trySignout}><AdminHome /></DashboardLayout>} />
            <Route exact path="" element={<AdminLayout trySignout={trySignout}><Outlet /></AdminLayout>}>

              
              <Route path="/notifications" element={<Notifications />} />

              {viewAsUser && (viewAsUser.roleid === 7) &&
                <>


                  <Route path="/users" element={<AdminUsersLayout><AdminUsers /></AdminUsersLayout>} />
                  <Route path="users" element={<AdminUsersLayout><Outlet /></AdminUsersLayout>}>
                    <Route path="new-employees" element={<AdminNewEmployees />} />
                  </Route>

 
                </>
              }

              {viewAsUser && (viewAsUser.roleid === 2) &&
                <>
                  <Route path="/admin-timesheets" element={<AdminTimesheets />} />
                  <Route path="/support-tickets" element={<SupportTicketsLayout />} />
                  <Route path="support-tickets" element={<SupportTicketsLayout><Outlet /></SupportTicketsLayout>}>
                    <Route path="" element={<SupportTickets />} />    
                    <Route path=":id" element={<SupportTickets />} />   
                  </Route>
                </>
              }


              {viewAsUser && (viewAsUser.roleid === 2 || viewAsUser.roleid === 3) &&
                <>
                  <Route path="/settings" element={<AdminSettingsLayout />} />


                  <Route path="/reports" element={<AdminReportsLayout />} />
                  <Route path="reports" element={<AdminReportsLayout><Outlet /></AdminReportsLayout>}>
                    <Route path="" element={<AdminReportsHome />} />

                    <Route path="budgeted" element={<AdminReportsBudgets />} />
                    <Route path="consumed" element={<AdminReportsTimesheets />} />
                  </Route>

                  <Route path="/logs" element={<AdminLogsLayout />} />
                  <Route path="logs" element={<AdminLogsLayout><Outlet /></AdminLogsLayout>}>

                    {/* <Route path="/logs/timesheets" element={<TimesheetPostingLogs />} /> */}
                    <Route path="timesheets" element={<TimesheetPostingLogs><Outlet /></TimesheetPostingLogs>}>
                      <Route path="" element={<TimesheetPostingLogsFailures />} />
                      <Route path="failures" element={<TimesheetPostingLogsFailures />} />
                      <Route path="successes" element={<TimesheetPostingLogsSuccesses />} />
                      <Route path="queue" element={<TimesheetPostingLogsQueue />} />
                      <Route path="TSMTimesheetD365" element={<TimesheetPostingLogsDynamics />} />
                    </Route>


                    


                    <Route path="unposted-timesheets" element={<TimesheetUnposted />} />
                    <Route path="posting-logs" element={<TimesheetPostingLogs />} />
                  </Route>

                  <Route path="/administrator" element={<AdminAdminLayout />} />
                  <Route path="/users" element={<AdminUsersLayout><AdminUsers /></AdminUsersLayout>} />
                  <Route path="users" element={<AdminUsersLayout><Outlet /></AdminUsersLayout>}>
                    <Route path="new-employees" element={<AdminNewEmployees />} />
                  </Route>

                  

                  <Route path="/teams" element={<AdminTeamsLayout />} />
                  <Route path="teams" element={<AdminTeamsLayout><Outlet /></AdminTeamsLayout>}>
                    <Route path="" element={<AdminTeamsHome />} />

                    <Route path="project-manager" element={<AdminProjectTeams />} />
                    <Route path="project-manager/new" element={<AdminProjectTeamsNew />} />
                    <Route path="project-manager/andy/:id" element={<AdminProjectTeamsEdit />} />
                    <Route path="project-manager/edit/:id" element={<AdminProjectTeamsEdit />} />

                    <Route path="office-manager" element={<AdminOfficeManagerTeams />} />
                    <Route path="office-manager/new" element={<AdminOfficeManagerTeamsNew />} />
                    <Route path="office-manager/andy/:id" element={<AdminOfficeManagerTeamsEdit />} />
                    <Route path="office-manager/edit/:id" element={<AdminOfficeManagerTeamsEdit />} />
                  </Route>
                    {/* <Route path="new-employees" element={<AdminNewEmployees />} /> */}
                  {/* </Route> */}
                </>
              }

              {viewAsUser && (viewAsUser.roleid === 0 || viewAsUser.roleid === 6 || viewAsUser.roleid === 7) && <>
                <Route path="/delegate-timesheets" element={<DelegateLayout><DelegateTimesheets /></DelegateLayout>} /> 
              </>}       

              {viewAsUser && (viewAsUser.roleid === 0) && <>
                <Route path="/expense-reports" element={<ExpenseLayout><ExpenseHome /></ExpenseLayout>} />
                  <Route path="expense-reports" element={<ExpenseLayout><Outlet /></ExpenseLayout>}>
                    <Route path="" element={<ExpenseHome />} />
                    <Route path="view/:expense_report_id" element={<ExpenseReport />} />
                    <Route path="new" element={<ExpenseReport />} />
                    <Route path="pm-review" element={<ReviewExpenseReportsPM />} />
                    
                    <Route path="final-review" element={<ReviewExpenseReportsFinal />} />

                    <Route path="review/:tab" element={<ReviewExpenseReports />} />
                    <Route path="review" element={<ReviewExpenseReports />} />
                    <Route path="company" element={<AllExpenseReports />} />
                    <Route path="approved" element={<ApprovedExpenseReports />} />
                  </Route>
              </>}      

              {viewAsUser && (viewAsUser.roleid === 6 || viewAsUser.roleid === 5 || viewAsUser.roleid === 4 || viewAsUser.roleid === 1 || viewAsUser.roleid === 8 || viewAsUser.roleid === 9 || viewAsUser.roleid === 2 || viewAsUser.roleid === 3) &&
                <>
                  <Route path="/expense-reports" element={<ExpenseLayout><ExpenseHome /></ExpenseLayout>} />
                  <Route path="expense-reports" element={<ExpenseLayout><Outlet /></ExpenseLayout>}>
                    <Route path="" element={<ExpenseHome />} />
                    <Route path="view/:expense_report_id" element={<ExpenseReport />} />
                    <Route path="new" element={<ExpenseReport />} />
                    <Route path="pm-review" element={<ReviewExpenseReportsPM />} />
                    
                    <Route path="final-review" element={<ReviewExpenseReportsFinal />} />

                    <Route path="review/:tab" element={<ReviewExpenseReports />} />
                    <Route path="review" element={<ReviewExpenseReports />} />
                    <Route path="company" element={<AllExpenseReports />} />
                    <Route path="approved" element={<ApprovedExpenseReports />} />
                  </Route>


                  <Route path="/delegate-projects" element={<DelegateLayout><DelegateProjects /></DelegateLayout>} />
                  {/* <Route path="delegate-projects" element={<DelegateLayout><Outlet /></DelegateLayout>}>
                    <Route path="" element={<DelegateProjects />} />
                  </Route> */}

                  <Route path="/delegate-timesheets" element={<DelegateLayout><DelegateTimesheets /></DelegateLayout>} />
                  {/* <Route path="delegate-projects" element={<DelegateLayout><Outlet /></DelegateLayout>}>
                    <Route path="" element={<DelegateProjects />} />
                  </Route> */}




                  <Route path="/budgeting" element={<BudgetingLayout><BudgetingHome /></BudgetingLayout>} />
                  <Route path="budgeting" element={<BudgetingLayout><Outlet /></BudgetingLayout>}>
                    <Route path="" element={<BudgetingHome />} />
                    <Route path="projects/:projectid" element={<BudgetingProject />} />
                    <Route path="projects/:projectid/revision/:revisionid" element={<BudgetingProject />} />

                    <Route path="print/:projectid" element={<BudgetPrint />} />
                    {/* <Route path="/print2/:budgetheaderid" element={<BudgetPrint2 />} /> */}

                    {(viewAsUser.roleid === 2 || viewAsUser.roleid === 3) && 
                      <>
                        <Route path="review" element={<BudgetingReview />} />
                        <Route path="approved" element={<BudgetingApproved />} />
                        <Route path="all" element={<BudgetingAll />} />
                      </>
                    }
                    {(viewAsUser.roleid === 2 || viewAsUser.roleid === 4) && 
                      <>
                        <Route path="approved" element={<BudgetingApproved />} />
                        <Route path="all" element={<BudgetingAll />} />
                      </>
                    }
                    {(viewAsUser.roleid === 2) && 
                      <>
                        <Route path="reports" element={<BudgetingReports />} />
                      </>
                    }
                    {(viewAsUser.roleid === 2 || viewAsUser.roleid === 1 || viewAsUser.roleid === 8 || viewAsUser.roleid === 9 || viewAsUser.roleid === 4) && 
                      <>
                        <Route path="list/:status" element={<BudgetingList />} />
                      </>
                    }
                  </Route>
                </>
              }


              


              <Route path="/timesheets" element={<ManagerTimesheetsLayout><Timesheets /></ManagerTimesheetsLayout>} />
              <Route path="timesheets" element={<ManagerTimesheetsLayout><Outlet /></ManagerTimesheetsLayout>}>

                


                <Route path=":id" element={<AlphaTimesheet />} />
                <Route path=":id/view" element={<AlphaTimesheet viewOnly={true} />} />
                {/* <Route path=":timesheetId/print" element={<Print3 />} /> */}
                <Route path="report" element={<TimesheetReport/>} />
                <Route path="list/:tab" element={<Timesheets/>} />

                {/* <Route path="admin-timesheets" element={<AdminTimesheets />} /> */}
                <Route path="posting-logs" element={<TimesheetPostingLogs />} />
                <Route path="unposted" element={<TimesheetUnposted />} />
                <Route path="review" element={<TimesheetsReview />} /> 
                <Route path="review/:tab" element={<TimesheetsReview />} /> 
                <Route exact path="approved" element={<TimesheetsApproved/>} />
                <Route exact path="approved/:tag" element={<TimesheetsApproved/>} />
                {/* {viewAsUser && (viewAsUser.roleid === 6) && <>
                  <Route path="lists/created-by-me" element={<PrefectTimesheets />} /> 
                </>}   */}
              </Route>



              <Route path="*" element={<Loading />} />
              {/* <Route exact path="users" element={<AdminUsers />} />
              <Route exact path="/users/new-employees" element={<AdminNewEmployees />} /> */}
            </Route>
            </>
          }
          {/* !fullscreenLoading && !msalAccountLoading && !msalAccount && */}
          {!fullscreenLoading && !isAuthed &&
            <>
            
            <Route path="*" element={<LoginScreen allAccounts={allAccounts} selectAccount={selectAccount} account={msalAccount} tokenLoading={tokenLoading} msalAccountLoading={msalAccountLoading} fullscreenLoading={fullscreenLoading} tryLogin={tryLogin} />}></Route>
            
            </>
          }
          {/* {!fullscreenLoading && isAuthed &&
            <Route path="*" element={<Fallback viewAsUser={viewAsUser} />} />
          } */}
          
        </Routes>


      
        {!fullscreenLoading  && isAuthed === true && 
          <>
            <GlobalSuccesses  />

            {currentUser  &&
              <SupportButton />
            }
          </>
      }

      {globalErrors && globalErrors.length !== 0 &&
        <div className="global-error-cont ">
          {globalErrors.map((error, i) => 
            <div className="global-error flex-cont align-center">
              <i onClick={() => store.dispatch(removeError(i))} className="ghost button margin-right-1 fas fa-times">

              </i>
              {error}
            </div>
          )}

        </div>
      }
      </Router>



    </div>
  );
}

function mapState(state) {
  return { 
    currentUser: state.authState.currentUser,
    currentAccount: state.authState.currentAccount,
    tokenResponse: state.authState.tokenResponse,
    tokenLoading: state.authState.tokenLoading,
    redirectLoading: state.authState.redirectLoading,
    dynamicsToken: state.authState.dynamicsToken,
    fullscreenLoading: state.uiState.fullscreenLoading,
    reAuth: state.authState.reAuth,
    globalErrors: state.uiState.globalErrors,
    viewAsUser: state.authState.viewAsUser
  };
} 

export default connect(mapState)(App);
